import React from 'react';

const AboutUsEN = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6 text-center">Who Are We?</h1>
      
      <div className="prose prose-lg max-w-none">
        <h2 className="text-2xl font-bold mt-8 mb-4">Our Story</h2>
        <p>
          It's above all a story of passionate individuals… Since the very beginning, the founders of the ARGORI brand have been amazed by the incredible richness of nature, from which we derive products with exceptional benefits. Yes, but… How do you recognize a good product today with the overabundance of offers? Who can you trust? Are the products truly natural and effective? It's not so simple, ultimately, to find good products that respect their commitments and are accessible in terms of price…
        </p>
        <p>
          This is the challenge we have set for ourselves: to offer you a range of NATURAL ingredients at prices that make sense!
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Why Plant Oils?</h2>
        <p>
          Vegetable oils are probably the first beauty products used by humankind. From seeds, fruits, and flowers, which are mechanically cold-pressed, we obtain a pure and virgin oil, and that's it! This expertise allows us to preserve the integrity of the active ingredients and to fully benefit from the naturally occurring advantages! At <a href="https://www.argori.com" className="text-blue-600 hover:text-blue-800">argori.com</a>, we have developed a range of natural, plant-centered ingredients to help you restore meaning to your beauty. Simple products, exclusively derived from nature, for unparalleled lasting effectiveness and a guaranteed feeling of well-being with our truly exceptional products. …
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Our Daily Commitment</h2>
        <p>
          For Respectful Products
        </p>
        <p>
          Every day, our team puts its heart into not only searching for respectful and effective products, but also receiving and processing your orders, producing and packaging each item with care.
        </p>
        <p>
          Our mission? To transform each use into a moment of pure happiness, whether through fragrances that enhance your day or through treatments that transform your bathroom routine. Create an EMOTIONAL CONNECTION with your product and dive into the heart of nature with a sensory and original journey filled with escape!
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">All Ambassadors of Nature!</h2>
        <p>
          We want to democratize the use of natural products, healthy for our bodies and for our planet. We are actively working every day to improve our offer and our conditions. Like us, trust nature to take care of you, and together let's give meaning back to our beauty.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Contact Information</h2>
        <p>
          For any information or questions, our customer service is at your disposal:
        </p>
        <ul className="list-disc pl-6">
          <li>By e-mail: <a href="mailto:argori.info@gmail.com" className="text-blue-600 hover:text-blue-800">argori.info@gmail.com</a>, <a href="mailto:info@argori.com" className="text-blue-600 hover:text-blue-800">info@argori.com</a>, or <a href="mailto:contact@argori.com" className="text-blue-600 hover:text-blue-800">contact@argori.com</a>.</li>
          <li>By phone: +33 (0) 7.68.96.85.07 & +33 (0) 6.95.83.91.20.</li>
        </ul>
      </div>
    </div>
  );
};

export default AboutUsEN;