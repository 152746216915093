import React from 'react';

const GeneralConditionsEN = () => {
    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-6 text-center">General Terms and Conditions of Sale</h1>

            <div className="prose prose-lg max-w-none">
                <p>
                    The website: <a href="https://www.argori.com" className="text-blue-600 hover:text-blue-800">www.argori.com</a> (hereinafter referred to as the "Website") is accessible via the Internet, to any user of this network, hereinafter referred to as the "Customer". This Website is the property of S.A.S ARGORI, with a capital of €2000, represented by the manager Mr. ABDELMAJID GHAROUADI whose registered office is located at 29B, rue Salvador Allende, 92220, Bagneux (France).
                </p>
                <p>
                    These general terms and conditions of sale, hereinafter referred to as "GTC" are concluded exclusively between S.A.S ARGORI, hereinafter referred to as "seller" and any natural or legal person making a purchase via the website: <a href="https://www.argori.com" className="text-blue-600 hover:text-blue-800">www.argori.com</a>, hereinafter referred to as "customer".
                </p>
                <p>
                    Any order for Products offered on the Website is subject to the express and unconditional acceptance of these general terms and conditions of sale.
                </p>

                <h2 className="text-2xl font-bold mt-8 mb-4">Article 1. Purpose</h2>
                <p>
                    These general terms and conditions of sale "GTC" are intended to define the terms and conditions of online sales of products through the website as well as the obligations of each party. These apply to the exclusion of any other document. In the event of a contradiction between the terms of the general terms and conditions of sale and the terms of any other document (including quotes, invoices, letters of any kind, order summaries, etc.), the terms of the general terms and conditions of sale shall prevail.
                </p>

                <h2 className="text-2xl font-bold mt-8 mb-4">Article 2. Conditions of access to the service</h2>
                <p>
                    The website is accessible via a computer, smartphone or tablet or any other electronic or computer tool or device. When ordering, the Customer must also provide their identity, postal address, telephone number and an email address. The products available on the website are reserved for sale by individuals of legal age, with a delivery address in metropolitan France, Europe or worldwide.
                </p>
                <p>
                    Furthermore, some national laws restrict the import of certain Products. The Customer must therefore inquire about the legislation in force in their country before ordering on the Website and will be solely responsible for any offenses they may commit in the event of illegal importation.
                </p>

                <h2 className="text-2xl font-bold mt-8 mb-4">Article 3. Products and Prices</h2>
                <p>
                    The price in effect is the one appearing on the website on the date of the order placed by the customer, S.A.S ARGORI reserves the right to modify the price of its items at any time. The items displayed on the website are part of the stock of the S.A.S ARGORI store, in case of out-of-stock items, we will offer you either a replacement product or a waiting period for receipt of products, or a refund. The displayed prices of the products are indicated in euros (excluding VAT). In addition to the order, transport costs will be added, calculated according to the destination and the weight of the order.
                </p>

                <h2 className="text-2xl font-bold mt-8 mb-4">Article 4. Order Registration and Validation</h2>
                <p>
                    By checking the box "I have read the general terms and conditions of sale and declare to accept them without reservation" and then clicking on the button "I validate my order", the Customer confirms their order and, therefore, accepts without reservation all of these General Terms and Conditions of Sale. This confirmation as well as all the data that S.A.S ARGORI will have recorded will constitute proof of the transaction. S.A.S ARGORI reserves the right not to process any order in the event of:
                </p>
                <ul className="list-disc pl-6">
                    <li>Non-payment of the full order amount.</li>
                    <li>An existing dispute with the Customer.</li>
                    <li>Refusal of payment authorization from banking or transport organizations.</li>
                    <li>Refusal to accept the General Terms and Conditions of Sale.</li>
                </ul>
                <p>
                    The responsibility of S.A.S ARGORI cannot be engaged under any circumstances in these cases. S.A.S ARGORI will send the Customer an acknowledgement of receipt by email, validating or not the order, as soon as possible as well as the package tracking number so that the customer can track their order.
                </p>

                <h2 className="text-2xl font-bold mt-8 mb-4">Article 5. Online Card Payment and Bank Transfer</h2>
                <p>
                    The following payment methods are available via the STRIPE payment gateway: MasterCard, Visa, American Express, Cartes Bancaires, Apple Pay, Google Pay. To guarantee maximum security and reliability, your credit card data is encrypted using the SSL protocol and is never transmitted unencrypted over the network. Payment is made directly via STRIPE in France, the European Union and abroad while payment methods in Morocco are available via the CMI payment gateway. In case of product unavailability, the refund will also be made via these organizations or by other means in consultation with the customer.
                </p>
                <p>
                    We will send you the bank transfer payment details by e-mail. The transfer between banks takes up to one to three business days.
                </p>

                <h2 className="text-2xl font-bold mt-8 mb-4">Article 6. Invoice</h2>
                <p>
                    The order form that the Customer completes online does not constitute an invoice. The Customer will receive their invoice upon delivery, in their online account. S.A.S ARGORI keeps a digital copy of each invoice in its sales management system.
                </p>

                <h2 className="text-2xl font-bold mt-8 mb-4">Article 7. Retention of Title Clause</h2>
                <p>
                    According to the terms of law 80-335 of 12.05.1980, S.A.S ARGORI retains ownership of the Products delivered to the Customer until full payment of the order amount. However, during the entire retention of title period, the risks related to the delivered Products, in particular, deterioration, loss or theft and any damage will be entirely the responsibility of the Customer from the time of delivery.
                </p>

                <h2 className="text-2xl font-bold mt-8 mb-4">Article 8. Product Availability and Shipping Times</h2>
                <p>
                    The shipping time is 24 to 120 hours (business days) for a product from the validation of payment, by credit card, subject to product availability at the time of order via a carrier for the general public. The Customer has the possibility to track the progress of their order following the email sent with the references of the carrier ensuring the delivery of the order.
                </p>
                <p>
                    SHIPPING COSTS APPEAR ON THE ORDER SUMMARY BEFORE VALIDATION.
                </p>

                <h2 className="text-2xl font-bold mt-8 mb-4">Article 9. Delivery and Place of Delivery</h2>
                <p>
                    Products are delivered to the delivery address indicated by the Customer on the order form. In the event that the delivery address is different from the billing address, the Customer must indicate this when placing their order.
                </p>
                <ul className="list-disc pl-6">
                    <li>If the recipient is absent, the customer must notify S.A.S ARGORI of any change to avoid the loss of their package (order) or additional charges on their invoice.</li>
                    <li>In case of loss of a package, S.A.S ARGORI is required to respect the deadlines imposed by the carriers concerning the declaration of loss and the reimbursement of the shipment.</li>
                </ul>
                <p>
                    Therefore, the Customer is also bound by these deadlines: Consequently, the Customer has a maximum period of 10 working days from the receipt of the email confirming the shipment of their package to declare it lost to S.A.S ARGORI. After this period, no claim will be considered. If the Customer has declared their package lost within the period specified above, S.A.S ARGORI will take care of opening a claim file. In this context, it is therefore possible that they may ask the Customer for documents necessary for the constitution of this file, which the Customer now undertakes to provide.
                </p>
                <p>
                    Final responses concerning investigations are given by carriers within a period varying from one to three weeks. The response can be of two types: either the package is found and it is then returned to the customer following the normal procedure, or the package is declared lost by the carrier and the seller informs the Customer and proceeds with the refund (invoiced amount) or makes a second shipment at the Customer's choice, subject to available stock.
                </p>
                <ul className="list-disc pl-6">
                    <li>Any Product delivered must be inspected and any reservations noted on the carrier's slip in case of partial or total damage. Failing this, the product is deemed to be delivered in good condition and cannot be subject to any subsequent dispute. Furthermore, any damaged or defective product must be reported the same day of receipt or at the latest the first working day following receipt.</li>
                    <li>In case of non-conformity of the order and upon receipt of the package by the Customer, the latter must formulate by telephone, email or postal mail to S.A.S ARGORI any claim of non-conformity of the products in kind, quality or quantity compared to the indications appearing on the invoice within a period of 07 days. Any claim of non-conformity not made in accordance with the rules defined above and within the deadlines cannot be taken into account and will release the seller from any liability towards the Customer.</li>
                    <li>Delivery by courier in Morocco: We will choose the most suitable and reliable courier to deliver your order to your home or chosen address. You will be informed of the delivery. Usually, it is possible to specify a delivery window.</li>
                    <li>International shipping: We deliver your orders throughout the European Union. For international purchases, please contact us online for more information.</li>
                </ul>

                <h2 className="text-2xl font-bold mt-8 mb-4">Article 10. FORCE MAJEURE</h2>
                <p>
                    Neither party (seller and customer) will have failed in its contractual obligations, insofar as their execution is delayed, hindered or prevented by force majeure. Force majeure shall be considered any event beyond the control of a party, which could not reasonably be foreseen at the time of the conclusion of the Contract and whose effects cannot be avoided by appropriate measures, and which prevents the said party from fulfilling its obligation. Expressly, are considered as cases of force majeure, in addition to those usually retained by the jurisprudence of French courts and tribunals and without this being restrictive, natural disasters, earthquakes, fires, storms, floods, epidemics, wars, terrorist activities, social movements, the impossibility of using means of transport or telecommunication networks, public or private, the failure to obtain permits, licenses, visas or other types of registrations.
                </p>
                <p>
                    The party affected by such circumstances shall notify the other party by any means as soon as possible, the execution of the sale being then suspended until the cessation of the impediment. If the impediment persists beyond a reasonable period, the sale may be terminated by either party with immediate effect, by sending written notification to the other party.
                </p>

                <h2 className="text-2xl font-bold mt-8 mb-4">Article 11. Right of withdrawal</h2>
                <p>
                    The applicable legal provisions provide that if for any reason whatsoever the Customer is not satisfied with the product they have purchased, article L121-20 of the Consumer Code states that they have the option of cancelling their purchase within 07 days of receiving their package. Pursuant to article L121-20-2, the right of withdrawal does not apply to software, videos, CDs and DVDs, if these have been unsealed, to products made to the Customer's specifications or clearly personalized or which, due to their nature, cannot be returned or are likely to deteriorate or expire rapidly, such as sugared almonds. Customers who have the status of reseller cannot benefit from the right of withdrawal.
                </p>
                <p>
                    The right of withdrawal cannot therefore be exercised on opened products.
                </p>

                <h2 className="text-2xl font-bold mt-8 mb-4">Article 12.a Return, exchange or refund of a product purchased online</h2>
                <p>
                    Nothing obliges the seller (e-merchant: S.A.S ARGORI) to take back a product, unless it is defective. However, the customer is asked to consult the return policy and the Terms and Conditions which require the customer to bear the "transport and restocking costs" when returning the product because they have changed their mind.
                </p>
                <p>
                    That said, the Consumer Protection Act provides for situations where you can cancel an online purchase without charge. However, if a retailer has no return policy, they are not required to mention that they do not have one. The consumer could then assume that any online transaction with this merchant is final.
                </p>

                <h2 className="text-2xl font-bold mt-8 mb-4">Article 12.b Return, exchange or refund of a defective product</h2>
                <p>
                    When you purchase a product on our website: <a href="https://www.argori.com" className="text-blue-600 hover:text-blue-800">www.argori.com</a>, and you find upon receipt that this item is defective, you have the following obligations:
                </p>
                <ol className="list-decimal pl-6">
                    <li>The customer must check the condition of the product upon receipt and report any anomaly or defect to the carrier.</li>
                    <li>If the product is damaged or defective upon receipt, the customer must refuse delivery and inform the seller within 24 hours.</li>
                    <li>If your product is defective or damaged, please send us an email within 24 hours of receipt to our email addresses: <a href="mailto:argori.info@gmail.com" className="text-blue-600 hover:text-blue-800">argori.info@gmail.com</a>, <a href="mailto:contact@argori.com" className="text-blue-600 hover:text-blue-800">contact@argori.com</a>, or <a href="mailto:info@argori.com" className="text-blue-600 hover:text-blue-800">info@argori.com</a> so that we can coordinate a return for a replacement or refund via the original payment method.</li>
                    <li>The customer has the right to ask the carrier to cover the return costs of the defective product.</li>
                    <li>The customer must inform the seller of the product defect within 7 days by sending an email to our email addresses: <a href="mailto:argori.info@gmail.com" className="text-blue-600 hover:text-blue-800">argori.info@gmail.com</a>, <a href="mailto:contact@argori.com" className="text-blue-600 hover:text-blue-800">contact@argori.com</a>, or <a href="mailto:info@argori.com" className="text-blue-600 hover:text-blue-800">info@argori.com</a> from the date of receipt of the product in the event that the customer did not check or did not pay attention, upon receipt, to the condition of their order before the carrier's departure.</li>
                    <li>The customer must return the defective product to the seller in its original packaging, accompanied by the invoice and any other document justifying the purchase.</li>
                    <li>The seller reserves the right to verify the defect of the product before proceeding with the exchange or refund.</li>
                    <li>If the product is defective, the seller will exchange or refund the product, according to the customer's choice.</li>
                    <li>In this case, the return costs of the defective product are the responsibility of the seller.</li>
                </ol>

                <h2 className="text-2xl font-bold mt-8 mb-4">Article 12.c Return, exchange or refund of a product after a "final sale"</h2>
                <p>
                    The Consumer Protection Act does not provide for a specific rule on this aspect, but the seller is generally required, though not obligated, to respect what they promise their customers. The merchant may identify the sale of certain products as "final", insofar as this modality is specified before the consumer proceeds to payment.
                </p>

                <h2 className="text-2xl font-bold mt-8 mb-4">Article 13. Customer Service</h2>
                <p>
                    For any information or questions, our customer service is at your disposal:
                </p>
                <ul className="list-disc pl-6">
                    <li>By e-mail: <a href="mailto:argori.info@gmail.com" className="text-blue-600 hover:text-blue-800">argori.info@gmail.com</a>, <a href="mailto:info@argori.com" className="text-blue-600 hover:text-blue-800">info@argori.com</a>, or <a href="mailto:contact@argori.com" className="text-blue-600 hover:text-blue-800">contact@argori.com</a>.</li>
                    <li>By phone: +33 (0) 7.68.96.85.07 & +33 (0) 6.95.83.91.20 from Monday to Friday from 9am to 6pm.</li>
                </ul>

                <h2 className="text-2xl font-bold mt-8 mb-4">Article 14. Guarantees – Limits of liability</h2>
                <p>
                    The products offered comply with the applicable standards in France. There is no warranty on our products. The seller shall in no case be held liable for the misuse of the Products on sale on the website, in particular, use for a purpose other than that intended on our website, or failure to comply with the Instructions for Use, or the use of our products despite the customer being aware and knowledgeable of the danger they incur by opting for the ingredients of our products to which their skin, eyes, hair, nails and body in general are sensitive. Hence the need to consult their attending physician or a dermatologist of their choice and at their expense to ensure the side effects of the components of the product they choose before placing their order.
                </p>

                <h2 className="text-2xl font-bold mt-8 mb-4">Article 15. Intellectual Property</h2>
                <p>
                    The ARGORI brand and the logo or logos of S.A.S ARGORI appearing on the website: <a href="https://www.argori.com" className="text-blue-600 hover:text-blue-800">www.argori.com</a> or elsewhere, are registered trademarks, property of the company ARGORI. Any total or partial reproduction of this trademark or the seller's logos, without prior written authorization from ARGORI, is prohibited.
                </p>

                <h2 className="text-2xl font-bold mt-8 mb-4">Article 16. Applicable Law – Competent Jurisdiction</h2>
                <p>
                    These general terms and conditions of sale ''CGV'' express the entirety of the obligations of the parties. S.A.S ARGORI reserves the right to modify them at any time and without notice. The Customer is therefore invited to read the general terms and conditions of sale ''CGV'' prior to each order they place on the Website.
                </p>
                <p>
                    These general terms and conditions of sale ''CGV'' are governed by French law. This applies to both substantive and procedural rules, regardless of the location from which the Website is accessed. In the event of a dispute, and after an attempt at conciliation, express jurisdiction is attributed to the competent courts within the jurisdiction of the Court of Appeal of Paris, notwithstanding multiple defendants or third-party claims, even for conservatory proceedings, summary proceedings, or applications.
                </p>

                <p className="mt-8">
                    BAGNEUX ON: JANUARY 10, 2025
                </p>
            </div>
        </div>
    );
};

export default GeneralConditionsEN;