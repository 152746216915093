import React from 'react'
import { translation } from "@context/Index"
import { useShoppingCart } from '@context/ShoppingCartContext'

interface Add2CartProps {
  product?: any;
  isGridView?: boolean;
}

const Add2Cart: React.FC<Add2CartProps> = ({ product, isGridView = false }) => {

  let { addToCart } = useShoppingCart()
  let { t } = translation.useTranslation().i18n

  let handleAdd2Cart = () => {
    let cartItem = {
      ukey: product?.id,
      slug: product?.productTranslates[0]?.slug,
      product: product?.label,
      image: product?.productTranslates[0]?.productImages[0]?.image,
      price: product?.promotionPrice ?? product?.productPrice[0]?.price,
      currency: product?.productPrice[0]?.country?.currency,
      quantity: 1,
    };


    product && addToCart(cartItem)

  }
  return (
    <button
      onClick={handleAdd2Cart}
      className={`px-${isGridView ? '2' : '5'} py-2 text-xl bg-gold rounded-md transition-transform hover:scale-110 duration-500`}>
      {t('add2Cart')}
    </button>
  )
}

export default Add2Cart