import React, { useEffect } from 'react'
import { EmblaOptionsType } from 'embla-carousel'
import useEmblaCarousel from 'embla-carousel-react'
import Fade from 'embla-carousel-fade'
import {
    NextButton,
    PrevButton,
    usePrevNextButtons
} from './SliderArrows'
import { DotButton, useDotButton } from './SliderDots'
import './css/base.css'
import './css/embla.css'
import { getSlider } from '@utils/api/SliderApi'
import { useQuery } from '@tanstack/react-query'
import { translation } from '@context/Index'
import { uploadUrl } from '@helpers/Helpers'


const EmblaCarousel: React.FC = () => {

    const options: EmblaOptionsType = { loop: true, duration: 30 }
    const { language } = translation.useTranslation().i18n
    const { data: slides, isLoading: isLoadingSlider } = useQuery({
        queryKey: ['slider', language],
        queryFn: () => getSlider(language.split('-')[0]),
    })
    // const [slides, setSlides] = React.useState([1, 2, 3, 4, 5])

    const [emblaRef, emblaApi] = useEmblaCarousel(options, [Fade()])

    const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(emblaApi)

    const {
        prevBtnDisabled,
        nextBtnDisabled,
        onPrevButtonClick,
        onNextButtonClick
    } = usePrevNextButtons(emblaApi)

    // useEffect(() => {slides && console.log(JSON.stringify(slides, null, 2));
    // }, [slides])

    useEffect(() => {
        const autoPlay = setInterval(() => onNextButtonClick(), 5000)
        return () => clearInterval(autoPlay)
    }, [onNextButtonClick])

    return (
        <div className="embla_slider">
            <div className="embla__viewport" ref={emblaRef}>
                <div className="embla__container">
                    {slides?.map((item: any, index: number) => (
                        <div className="embla__slide" key={index}>
                            <img
                                className="embla__slide__img"
                                src={uploadUrl + item?.image}
                                loading="lazy"
                                alt="argori slider"
                            />
                        </div>
                    ))}
                </div>
            </div>

            <div className="embla__controls">


                <div className="embla__dots">
                    {scrollSnaps.map((_, index: number) => (
                        <DotButton
                            key={index}
                            onClick={() => onDotButtonClick(index)}
                            className={'embla__dot'.concat(
                                index === selectedIndex ? ' embla__dot--selected' : ''
                            )}
                        />
                    ))}
                </div>
                <div className="embla__buttons">
                    <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
                    <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
                </div>
            </div>
        </div>
    )
}

export default EmblaCarousel
