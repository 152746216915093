import { useQuery } from '@tanstack/react-query'
import { GetCategories } from '@utils/api/CategoriesApi'
import i18n from '@utils/translation/i18n'
import React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import {components, translation} from "@context/Index"

const ProductsPage = () => {

  let params = useParams() //for fetching subCategories in case use access page through url.
  let location = useLocation()
  let {state} = location
  let {slug} = params
  let {Header, Breadcrumbs, Categories, Products} = components
  let {t} = translation.useTranslation().i18n
  let [subCategories, setSubCategories] = React.useState([])
  // const initialRender = React.useRef(true); // Ref to track if this is the first render

  let categoryTranslates = state?.categoryTranslates[0] ?? '' //replace the empty string with the data fetched from the query.

//   const {
//     data,
//     isLoading,
//     isError,
//     isFetching,
// } = useQuery({
//     queryKey: ['subCategories', i18n.language, slug],
//     queryFn: () => GetCategories("NULL", i18n.language, slug),
//     staleTime: 5 * 60 * 1000,
//     enabled:false    
// });

React.useEffect(()=>{
  state && setSubCategories(state.subCategory)
},[state])

// React.useEffect(() => { 
//   initialRender.current = false
//   if(data){
//   }
// } ,[data])

  return (
    <div>
      <Header title={categoryTranslates?.title} />
      <Breadcrumbs/>
      {subCategories && <Categories importedCategories={subCategories}/>}
      {subCategories && <Products categorySlug={slug} disablePrimaryQuery={true}/>}
    </div>
  )
}

export default ProductsPage