import React, { useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { ProtectedRoute } from '@routes/ProtectedRoutes';
import { pages, hooks, translation } from "@context/Index"
import { useAuth } from '@context/AuthContext';
import CmiOkPage from '@pages/Payment/CmiOkPage';
import CmiFailPage from '@pages/Payment/CmiFailPage';
import OfflineCmiOkPage from '@pages/Payment/OfflineCmiFailPage';
import OfflineCmiFailPage from '@pages/Payment/OfflineCmiFailPage';
import OfflineOrderDetails from '@pages/order/OfflineOrderDetails';
import PackPage from '@pages/pack/PackPage';
import StorePage from '@pages/store/StorePage';
import { handleScrollTop } from '@mbs-dev/react-helpers';
import { PaymentSuccessPage } from '@pages/Payment/stripe/PaymentSuccessPage';
import { PaymentCancelPage } from '@pages/Payment/stripe/PaymentCancelPage';
import StripeCheckoutPage from '@pages/Payment/stripe/StripeCheckoutPage';
function MainNavigation() {

  let {
    Home,
    AboutUs,
    Contact,
    Profile,
    NotAuthorized,
    Login,
    ProductDetails,
    ProductsPage,
    OnlineCheckout,
    OfflineCheckout,
    WholeSaleMerchant,
    WhiteMaterialMerchant,
    Ambassador,
    SignUp,
    TermsAndConditions,
    PrivacyPolicy,
    ReturnPolicy,
    GeneralConditions,
    DeliveryAndRights,
    QualityAndCertifications,
    Ingredients
  } = pages

  let { userToken, usersCountryCode } = useAuth()
  let { language } = translation.useTranslation().i18n

  React.useEffect(() => {
    // console.log("country CODE 1 ::", usersCountryCode);
  }, [usersCountryCode])



  return (

    <Routes>
      {/* <HandleScroll/> */}
      {/* <Route path='/not-authorized' element={<NotAuthorized />} /> */}
      {/* <Route element={<HomeRoute />}>
      </Route> */}
      <Route path='/' element={<ProtectedRoute />}>
        <Route path='/' element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/profile" element={userToken ? <Profile /> : <Navigate to="/" />} />
        <Route path="/login" element={userToken ? <Navigate to="/" /> : <Login />} />
        <Route path="/categories" element={<Navigate to="/" />} />
        <Route path="/categories/:slug" element={<ProductsPage />} />
        <Route path="/products" element={<Navigate to="/" />} />
        <Route path="/products/:slug" element={<ProductDetails />} />
        <Route path="/checkout" element={userToken ? <OnlineCheckout /> : usersCountryCode === "MA" ? <OfflineCheckout /> : <Navigate to="/" />} />
        <Route path="/off" element={<OfflineCheckout />} />
        <Route path='/become-wholesalemerchant' element={<WholeSaleMerchant />} />
        <Route path='/become-whitematerialmerchant' element={<WhiteMaterialMerchant />} />
        <Route path='/become-ambassador' element={<Ambassador />} />
        <Route path='/sign-up' element={userToken ? <Navigate to="/" /> : <SignUp />} />
        <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/return-policy' element={<ReturnPolicy />} />
        <Route path='/general-terms-and-conditions-of-sale' element={<GeneralConditions />} />
        <Route path='/delivery-and-rights' element={<DeliveryAndRights />} />
        <Route path='/quality-and-certifications' element={<QualityAndCertifications />} />
        <Route path='/ingredients' element={<Ingredients />} />
        {/* <Route path='categories' element={<CategoryList />} /> */}

        {/* -------- Pack/Store */}
        <Route path="/pack" element={<PackPage />} />
        <Route path="/store" element={<StorePage />} />
        <Route path="/store/:categorySlug" element={<StorePage />} />


        {/* -------- Payment */}
        <Route path="/payments/cmi/ok" element={<CmiOkPage />} />
        <Route path="/payments/cmi/fail" element={<CmiFailPage />} />
        <Route path="/offline/payments/cmi/ok" element={<OfflineCmiOkPage />} />
        <Route path="/offline/payments/cmi/fail" element={<OfflineCmiFailPage />} />
        <Route path="/offline/details/:orderRef" element={<OfflineOrderDetails />} />
        {/* <Route path="/stripe/pay" element={<StripeCheckoutPage />} /> */}
        {/* <Route path="/stripe/checkout/:orderId" element={<StripeCheckoutPage />} /> */}
        <Route path="/payment/success" element={<PaymentSuccessPage />} />
        <Route path="/payment/cancel" element={<PaymentCancelPage />} />
      </Route>
    </Routes>
  );
}

export default MainNavigation;