import { components } from "@context/Index"
import { ENUMS } from "@helpers/ENUMS"
import Icons from "@helpers/IconsHelper"
import { AddressSectionProps, addressSelectProps, CommentInputProps, SubmitButtonProps, TermsCheckboxProps } from "@utils/interfaces/Order"
import { t } from "i18next"
import { Link } from "react-router-dom"

export const AddressSection: React.FC<AddressSectionProps> = ({
    title,
    addresses,
    selected,
    onSelect,
    onAdd,
    selectProps = addressSelectProps
}) => (
    <div className="flex flex-col gap-2">
        <div className='flex justify-between items-center'>
            <h4 className="text-xl font-bold">{title}</h4>
            {addresses.length === 0 && (
                <Icons.IoMdAddCircle
                    size={30}
                    color={ENUMS.COLORS.BRAND}
                    className='cursor-pointer'
                    onClick={onAdd}
                />
            )}
        </div>
        <components.Select
            {...selectProps}
            value={selected}
            options={addresses}
            onChange={onSelect}
        />
    </div>
)

export const CommentInput: React.FC<CommentInputProps> = ({ value, onChange }) => (
    <div className="flex flex-col gap-2">
        <div className="flex items-center gap-2">
            <h4 className="text-xl font-bold">{t("comment")}</h4>
            <p>({t("optional")})</p>
        </div>
        <textarea
            className="border-2 border-gray-300 rounded-md p-2 w-full"
            rows={3}
            placeholder={t("addComment")}
            value={value}
            onChange={onChange}
        />
    </div>
)

export const TermsCheckbox: React.FC<TermsCheckboxProps> = ({ checked, onChange, error }) => (
    <div className="mt-4">
        <div className="flex items-start gap-2">
            <input
                type="checkbox"
                name="cgvAccepted"
                id="cgvAccepted"
                checked={checked}
                onChange={onChange}
                className="w-5 h-5 accent-[#258f67] cursor-pointer"
            />
            <label htmlFor="cgvAccepted" className="text-gray-700 text-sm  cursor-pointer">
                {t("cgv.part1")}{" "}
                <Link to="/general-terms-and-conditions-of-sale" className="text-[#258f67] underline">
                    {t("cgv.part2")}
                </Link>{" "}
                {t("cgv.part3")}
            </label>
        </div>
        {error && <p className="text-red-500 text-sm">{error}</p>}
    </div>
)

export const SubmitButton: React.FC<SubmitButtonProps> = ({ loading, disabled, onSubmit }) => (
    <div className="flex justify-end p-3 gap-3">
        <button
            disabled={disabled || loading}
            onClick={onSubmit}
            className={`py-2 px-5 flex items-center gap-3 text-brand-darker rounded-full bg-gold ${disabled ? "opacity-50" : "opacity-100"
                }`}
        >
            <span className="font-bold">{t("checkout.postOrder")}</span>
            {loading ? (
                <components.ActivityIndicator size={25} color={ENUMS.COLORS.BRAND_DARK} />
            ) : (
                <Icons.FiChevronRight size="1.5rem" />
            )}
        </button>
    </div>
)