import { components, translation } from '@context/Index';
import { ENUMS } from '@helpers/ENUMS';
import { consumePromoCode } from '@utils/api/PromoCodeApi';
import React, { useState, useEffect } from 'react';
import { FiTrash2 } from 'react-icons/fi'; // Import Trash Icon

export type PromoCodeProps = {
    userToken: string;
    // setPromoCodeDiscount?: React.Dispatch<React.SetStateAction<number>>;
    setPromoCode?: React.Dispatch<React.SetStateAction<{ id: string | null; discount: number }>>;
};

const PromoCode: React.FC<PromoCodeProps> = ({ userToken, setPromoCode }) => {
    const { t } = translation.useTranslation().i18n;
    const [promo, setPromo] = useState('');
    const [isValid, setIsValid] = useState<boolean | null>(null);
    const [response, setResponse] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const { ActivityIndicator } = components;

    useEffect(() => {
        if (!promo) {
            setIsValid(null);
            return;
        }

        const timer = setTimeout(() => {
            setLoading(true);
            consumePromoCode(promo, userToken)
                .then(data => {
                    setIsValid(true);
                    setResponse(data.success);
                    // setPromoCode && setPromoCode(data.promoCode);
                    // setPromoId(data.promoCode.id); // ✅ Store promo code ID
                    setLoading(false);
                    setPromoCode?.({ id: data.promoCode.id, discount: data.promoCode.discount });
                })
                .catch(error => {
                    console.error("Error validating promo code:", error);
                    setResponse(error.error);
                    // setPromoCode && setPromoCode({
                    //     id: null,
                    //     discount: 0
                    // });
                    // setPromoId(null);
                    setPromoCode?.({ id: null, discount: 0 });
                    setIsValid(false);
                    setLoading(false);
                });
        }, 1000);

        return () => clearTimeout(timer);
    }, [promo]);

    const handleRemovePromo = () => {
        setPromo('');
        // setPromoCode && setPromoCode({
        //     id: null,
        //     discount: 0
        // });
        // setPromoId(null);
        setPromoCode?.({ id: null, discount: 0 });
        setIsValid(null);
        setResponse(null);
    };

    return (
        <div className="flex flex-row items-center justify-between">
            <p className="font-bold text-2xl">{t("checkout.codePromo")}</p>

            <div className='flex flex-col gap-2'>
                <div className="flex items-center gap-2">
                    {loading && <ActivityIndicator size={30} color={ENUMS.COLORS.BRAND_DARK} />}

                    <input
                        type="text"
                        placeholder={t("checkout.enterPromo")}
                        className="rounded-full border border-gray-300 px-4 py-2 outline-brand-darker text-center w-60"
                        value={promo}
                        onChange={(e) => setPromo(e.target.value)}
                    />

                    {/* Trash Icon Button */}
                    {isValid && (
                        <button
                            onClick={handleRemovePromo}
                            className="p-2 bg-red-500 hover:bg-red-600 text-white rounded-full transition-all"
                            title={t("promocode.remove")}
                        >
                            <FiTrash2 size={20} />
                        </button>
                    )}
                </div>

                {!loading && isValid !== null && (
                    <p className={isValid ? "text-green-500 text-sm" : "text-red-500 text-sm"}>
                        {t("checkout.validation." + response)}
                    </p>
                )}
            </div>
        </div>
    );
};


export default PromoCode;
