import { getSliderType } from "@utils/types/api/Sliders"
import { API } from "./API_INSTANCE"


export const getSlider:getSliderType = (languageCode) => {
    return API.get('/sliders',{
        headers: {
            Accept:'application/json'
        },
        params:{
            language_code : languageCode,
            'order[id]': 'asc',
        }
    })
    .then((res) => res.data)
    .catch((err) => { throw err })
}