import { API } from "./API_INSTANCE"

export const getReviews = (productId: string) => {
    // console.log(productId);
    
    return API.get('/reviews', {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        params:{
            "product.id": productId
        }
    })
        .then((res) => res.data)
        .catch(err => { throw err })
}


export const postReview = (body: any) => {
    return API.post('/reviews', body, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    })
        .then((res) => res.data)
        .catch(err => { throw err })
}
