import React, { ReactElement } from 'react'
import { translation } from "@context/Index"
import Icons from '@helpers/IconsHelper'
import { ENUMS } from '@helpers/ENUMS'

type contactItemProps = {
    icon: () => ReactElement,
    primaryField: string,
    secondaryField?: string,
    onClick?: () => void
}

type ContactDataProps = { websiteInfos: any }

const ContactData: React.FC<ContactDataProps> = ({ websiteInfos }) => {

    let iconsColor = ENUMS.COLORS.GOLD
    const {
        MdEmail,
        MdPhone,
        MdLocationPin,
        MdAccessTimeFilled,
        MdSupportAgent
    } = Icons

    const RenderData: contactItemProps[] = [
        {
            icon: () => <MdEmail key="emailIcon" size={25} color={iconsColor} />,
            primaryField: websiteInfos[0]?.primaryEmail,
            secondaryField: websiteInfos[0]?.secondaryEmail
        },
        {
            icon: () => <MdPhone key="phoneIcon" size={25} color={iconsColor} />,
            primaryField: websiteInfos[0]?.primaryPhone,
            secondaryField: websiteInfos[0]?.secondaryPhone
        },
        {
            icon: () => <MdLocationPin key="locationIcon" size={25} color={iconsColor} />,
            primaryField: websiteInfos[0]?.address,
            onClick: websiteInfos[0]?.address
                ? () => window.open(`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(websiteInfos[0].address)}`, '_blank')
                : undefined
        },
        {
            icon: () => <MdSupportAgent key="supportIcon" size={25} color={iconsColor} />,
            primaryField: websiteInfos[0]?.workingTime,
        }
    ]

    const { t } = translation.useTranslation().i18n
    const RenderContactItem: React.FC<contactItemProps> = ({ icon, primaryField, secondaryField, onClick }) => {
        return (
            <div
                className={`flex flex-row items-center gap-3 ${onClick ? "cursor-pointer" : ""}`}
                onClick={onClick}
            >
                {icon()}
                <div className={`w-2/3`}>
                    {primaryField && <p className={`text-white m-0 p-0 text-sm  ${onClick ? "underline underline-offset-2" : ""}`}>{primaryField}</p>}
                    {secondaryField && <p className="text-white text-sm">{secondaryField}</p>}
                </div>
            </div>
        )
    }

    return (
        <div className="flex flex-col gap-3 p-4">
            <h3 className="text-white text-xl text-nowrap">{t('footer.contactInfo')}</h3>
            <div className="flex flex-col gap-3">
                {RenderData.map((item, index) => <RenderContactItem key={index} {...item} />)}
            </div>
        </div>
    )
}

export default ContactData
