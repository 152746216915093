import { components, translation } from '@context/Index'
import React from 'react'

const OfflineCheckout = () => {
  let { Header, Breadcrumbs, OfflineOrder } = components
  let {t} = translation.useTranslation().i18n
  return (
    <>
      <Header title={t("checkout.title")}/>
      <Breadcrumbs />
      <OfflineOrder/>
    </>
  )
}

export default OfflineCheckout