import { components, translation } from '@context/Index'
import React from 'react'

const PrivacyPolicy = () => {
  
  const {language} = translation.useTranslation().i18n
  const {PrivacyPolicyEN, PrivacyPolicyFR} = components
    return (
    <>
        {language.split('-')[0] === "en" ? <PrivacyPolicyEN /> : <PrivacyPolicyFR />}
    </>
  )
}

export default PrivacyPolicy