import React, { JSXElementConstructor, ReactElement } from 'react'
import { Helmet } from 'react-helmet-async';
import { components } from '@context/Index'
import { AnimatePresence } from 'framer-motion';
import { useShoppingCart } from '@context/ShoppingCartContext';
import { handleScrollTop } from '@mbs-dev/react-helpers';

type LayoutType = { children: ReactElement<any, string | JSXElementConstructor<any>> | null }

const Layout: React.FC<LayoutType> = ({ children }) => {

    let { NavBar, Footer, CartModal } = components
    let { expanded } = useShoppingCart()
      const HandleScroll = () => {
        React.useEffect(() => {
          handleScrollTop();
        }, []);
        return null
      }

    return (
        <React.Fragment>
            <Helmet>
                <title>Argori - Produits de Beauté & Cosmétiques Naturels</title>
                <meta name="title" content="Argori - Produits de Beauté & Cosmétiques Naturels" />
                <meta name="description"
                    content="Découvrez Argori, votre boutique en ligne spécialisée dans les cosmétiques naturels et soins de beauté. Produits de haute qualité, livraison rapide et service client réactif." />
                <meta name="keywords"
                    content="Argori, cosmétiques naturels, beauté, soins de peau, maquillage bio, produits de beauté, soins visage, argan, huile naturelle" />
                <meta name="author" content="Argori" />
                <meta name="robots" content="index, follow" />

                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://argori.com/" />
                <meta property="og:title" content="Argori - Produits de Beauté & Cosmétiques Naturels" />
                <meta property="og:description"
                    content="Découvrez Argori, votre boutique en ligne spécialisée dans les cosmétiques naturels et soins de beauté." />
                <meta property="og:image" content="%PUBLIC_URL%/media/argori-social-preview.png" />

                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://argori.com/" />
                <meta property="twitter:title" content="Argori - Produits de Beauté & Cosmétiques Naturels" />
                <meta property="twitter:description"
                    content="Découvrez Argori, votre boutique en ligne spécialisée dans les cosmétiques naturels et soins de beauté." />
                <meta property="twitter:image" content="%PUBLIC_URL%/media/argori-social-preview.png" />
            </Helmet>
            <main>
                <AnimatePresence>
                    {expanded && <CartModal />}
                </AnimatePresence>
                <NavBar />
                <HandleScroll />
                {children}
                <Footer />
            </main>
        </React.Fragment>
    )
}

export default Layout