import React from 'react';
import { components, translation } from "@context/Index"
import { ENUMS } from '@helpers/ENUMS';
import { loadingType } from '@utils/types/Loading';



const Loading: React.FC<loadingType> = ({ item, showText = true }) => {

    let { t } = translation.useTranslation().i18n
    let { ActivityIndicator } = components

    let logo = require("@resources/logo.png");
    return (
        <div className="flex flex-col items-center justify-center h-screen ">
            <img src={logo} alt="Logo de l'application" className="w-64  mb-5" />
            <div className='w-64 flex flex-col justify-center items-center'>
                <ActivityIndicator size={30} color={ENUMS.COLORS.BRAND_DARK} />
                <p className="mt-4 text-lg text-gray-600 text-center">{`${showText ? t('loading') : ''} ${item?.toLowerCase()} ${showText ? '...' : ''}`}</p>
            </div>

        </div>
    );
};

export default Loading;
