import React from 'react';

const DeliveryAndRightsFR = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6 text-center">Livraison et droits</h1>
      
      <div className="prose prose-lg max-w-none">
        <p>
          Nous nous efforcerons de livrer les marchandises dans les délais estimés ; cependant, des retards sont parfois inévitables en raison de facteurs imprévus. Nous déclinons toute responsabilité en cas de retard ou de non-livraison des produits dans les délais estimés.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Erreurs, inexactitudes et omissions</h2>
        <p>
          Parfois, il peut y avoir des informations sur notre site web ou dans le Service qui contiennent des erreurs typographiques, des inexactitudes ou des omissions qui peuvent concerner les descriptions de produits, les prix, les promotions, les offres, les frais d'expédition des produits, les temps de transit et la disponibilité. Nous nous réservons le droit de corriger toute erreur, inexactitude ou omission, et de modifier ou de mettre à jour les informations ou d'annuler des commandes si des informations dans le Service ou sur tout site Web connexe sont inexactes à tout moment sans préavis (y compris après que vous ayez soumis votre commande).
        </p>
        <p>
          Nous n'assumons aucune obligation de mettre à jour, de modifier ou de clarifier les informations dans le Service ou sur tout site Web connexe, y compris, sans s'y limiter, les informations sur les prix, sauf si la loi l'exige. Aucune date de mise à jour ou d'actualisation spécifiée appliquée dans le Service ou sur tout site Web connexe ne doit être considérée comme indiquant que toutes les informations dans le Service ou sur tout site Web connexe ont été modifiées ou mises à jour.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Utilisations interdites</h2>
        <p>
          En plus des autres interdictions énoncées dans les Termes et Conditions d'utilisation, il vous est interdit d'utiliser le site ou son contenu :
        </p>
        <ol className="list-decimal pl-6">
          <li>À des fins illégales ;</li>
          <li>Solliciter d'autres personnes pour qu'elles accomplissent ou participent à des actes illégaux ;</li>
          <li>Enfreindre les réglementations, règles, lois ou ordonnances locales, internationales, fédérales, provinciales ou étatiques ;</li>
          <li>Enfreindre ou violer nos droits de propriété intellectuelle ou les droits de propriété intellectuelle d'autrui ;</li>
          <li>Harceler, abuser, insulter, blesser, diffamer, calomnier, dénigrer, intimider ou discriminer sur la base du sexe, de l'orientation sexuelle, de la religion, de l'origine ethnique, de la race, de l'âge, de l'origine nationale ou du handicap ;</li>
          <li>Soumettre des informations fausses ou trompeuses ;</li>
          <li>Télécharger ou transmettre des virus ou tout autre type de code malveillant qui sera ou pourra être utilisé d'une manière qui affectera la fonctionnalité ou le fonctionnement du Service ou de tout site Web connexe, d'autres sites Web ou d'Internet ;</li>
          <li>Collecter ou suivre les informations personnelles d'autrui ;</li>
          <li>Envoyer du spam, du phishing, du pharm, du prétexte, du spider, du crawl ou du scraping ;</li>
          <li>À des fins obscènes ou immorales ; ou</li>
          <li>Interférer avec ou contourner les fonctionnalités de sécurité du Service ou de tout site Web connexe, d'autres sites Web ou d'Internet.</li>
        </ol>
        <p>
          Nous nous réservons le droit de mettre fin à votre utilisation du Service ou de tout site Web connexe pour avoir enfreint l'une des utilisations interdites.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Exclusion de garanties; Limitation de responsabilité</h2>
        <p>
          Nous ne garantissons pas, ne représentons ni ne garantissons que votre utilisation de notre service sera ininterrompue, opportune, sécurisée ou sans erreur.
        </p>
        <p>
          Nous ne garantissons pas que les résultats pouvant être obtenus à partir de l'utilisation du service seront exacts ou fiables.
        </p>
        <p>
          Vous acceptez que de temps à autre, nous puissions supprimer le service pour des périodes indéterminées ou annuler le service à tout moment, sans préavis.
        </p>
        <p>
          Vous convenez expressément que votre utilisation ou votre incapacité à utiliser le service est à vos risques et périls. Le service et tous les produits et services qui vous sont fournis par le biais du service sont (sauf mention expresse de notre part) fournis « tels quels » et « tels que disponibles » pour votre usage, sans aucune représentation, garantie ou condition de quelque nature que ce soit, expresse ou implicite, y compris toutes les garanties ou conditions implicites de qualité marchande, de qualité marchande, d'adéquation à un usage particulier, de durabilité, de titre et d'absence de contrefaçon.
        </p>
        <p>
          Transmises ou autrement mises à disposition via le service, même si elles sont informées de leur possibilité. Étant donné que certains États ou juridictions n'autorisent pas l'exclusion ou la limitation de responsabilité pour les dommages indirects ou accessoires, dans ces États ou juridictions, notre responsabilité sera limitée dans la mesure maximale autorisée par la loi.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Indemnité</h2>
        <p>
          Vous acceptez d'indemniser, de défendre et de dégager de toute responsabilité Argori et notre société mère, nos filiales, nos sociétés affiliées, nos partenaires, nos dirigeants, nos administrateurs, nos agents, nos sous-traitants, nos concédants de licence, nos prestataires de services, nos sous-traitants, nos fournisseurs, nos stagiaires et nos employés, à l'abri de toute réclamation ou demande, y compris raisonnable les honoraires d'avocat, effectués par un tiers à cause de ou découlant de votre violation de ces Termes et Conditions d'utilisation ou des documents auxquels ils font référence, ou de votre violation de toute loi ou des droits d'un tiers.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Divisibilité</h2>
        <p>
          Dans le cas où une disposition de ces Termes et Conditions d'utilisation est jugée illégale, nulle ou inapplicable, cette disposition sera néanmoins exécutoire dans toute la mesure permise par la loi applicable, et la partie inapplicable sera réputée séparée de ces Termes et Conditions d'utilisation. Une telle détermination n'affectera pas la validité et l'applicabilité de toute autre disposition restante.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Résiliation</h2>
        <p>
          Les obligations et responsabilités des parties contractées avant la date de résiliation survivront à la résiliation du présent accord à toutes fins utiles.
        </p>
        <p>
          Ces Termes et Conditions d'utilisation sont en vigueur à moins et jusqu'à ce qu'elles soient résiliées par vous ou par nous. Vous pouvez résilier ces Termes et Conditions d'utilisation à tout moment en nous informant que vous ne souhaitez plus utiliser nos services ou lorsque vous cessez d'utiliser notre site web.
        </p>
        <p>
          Si, à notre seul jugement, vous ne respectez pas, ou nous soupçonnons que vous avez omis, de vous conformer à toute condition ou disposition des présentes Termes et Conditions d'utilisation, nous pouvons également résilier cet accord à tout moment sans préavis et vous resterez responsable de tous les montants dus jusqu'à et y compris la date de résiliation ; et/ou en conséquence peut vous refuser l'accès à nos Services (ou à une partie de ceux-ci).
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Accord complet</h2>
        <p>
          L'incapacité de notre part à exercer ou à appliquer tout droit ou disposition des présentes Termes et Conditions d'utilisation ne constitue pas une renonciation à ce droit ou à cette disposition.
        </p>
        <p>
          Ces Termes et Conditions d'utilisation et toutes les politiques ou règles de fonctionnement publiées par nous sur ce site web ou en ce qui concerne le service constituent l'intégralité de l'accord et de la compréhension entre vous et nous et régissent votre utilisation du service, remplaçant tout accord, communication et proposition antérieurs ou contemporains, qu'ils soient oraux ou écrits, entre vous et nous (y compris, mais sans s'y limiter, toute version antérieure des Termes et Conditions d'utilisation).
        </p>
        <p>
          Toute ambiguïté dans l'interprétation des présentes conditions d'utilisation ne doit pas être interprétée contre la partie rédactrice.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Modifications des conditions d'utilisation</h2>
        <p>
          Vous pouvez consulter la version la plus récente des Termes et Conditions d'utilisation à tout moment sur cette page.
        </p>
        <p>
          Nous nous réservons le droit, à notre seule discrétion, de mettre à jour, de modifier ou de remplacer toute partie de ces conditions d'utilisation en publiant des mises à jour et des modifications sur notre site Web. Il est de votre responsabilité de vérifier périodiquement notre site Web pour les changements. Votre utilisation continue ou votre accès à notre site Web ou au Service après la publication de toute modification des présentes Termes et Conditions d'utilisation constitue une acceptation de ces modifications.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Informations de contact</h2>
        <p>
          Pour toute information ou question, notre service clientèle est à votre disposition :
        </p>
        <ul className="list-disc pl-6">
          <li>Par e-mail : <a href="mailto:argori.info@gmail.com" className="text-blue-600 hover:text-blue-800">argori.info@gmail.com</a>, <a href="mailto:info@argori.com" className="text-blue-600 hover:text-blue-800">info@argori.com</a>, ou <a href="mailto:contact@argori.com" className="text-blue-600 hover:text-blue-800">contact@argori.com</a>.</li>
          <li>Par téléphone : +33 (0) 7.68.96.85.07 & +33 (0) 6.95.83.91.20 (du lundi au vendredi de 9h à 18h).</li>
        </ul>
      </div>
    </div>
  );
};

export default DeliveryAndRightsFR;