import { useState } from 'react'
import { useAuth } from '@context/AuthContext'
import { useBaseContext } from '@context/baseContext'
import { components, translation } from '@context/Index'
import { useQuery } from '@tanstack/react-query'
import { getUserOrders } from '@utils/api/OrderApi'
import { AnimatePresence } from 'framer-motion'
import Icons from '@helpers/IconsHelper'
import { ENUMS } from '@helpers/ENUMS'
import { PaymentData } from '@utils/interfaces/PaymentData'
import { processCmiPayment } from '@utils/api/PaymentProccess'
import { returnOrderStatusBG, returnPaymentStatusBG } from '@helpers/Helpers'
import "./Order.css"

const ITEMS_PER_PAGE = 5;

const Orders = () => {
    const { Loading, EmptyData, OrderDetailsModal } = components
    const { TiRefresh } = Icons
    const { user } = useAuth()
    const { getCurrencySymbol } = useBaseContext()
    const { t, language } = translation.useTranslation().i18n

    // Pagination State
    const [page, setPage] = useState(1);
    const [selectedOrder, setSelectedOrder] = useState<any>(null)
    const [isOpen, setIsOpen] = useState<boolean>(false)

    const { data, isLoading } = useQuery({
        queryKey: ['orders', language, page],
        queryFn: () => getUserOrders(user?.id as string, language?.split('-')[0], page, ITEMS_PER_PAGE),
        enabled: !!user?.id
    })

    const onOpen = (order: any) => {
        setSelectedOrder(order);
        setIsOpen(true);
    }
    const onClose = () => setIsOpen(false);

    const retryPayment = (order: any) => {
        if (order?.paymentMethod === ENUMS.PAYMENT.METHOD.CMI) {
            const paymentData: PaymentData = {
                userAddresses: user?.UserAddress,
                reference: order?.reference,
                totalPrice: order?.price,
                itemsQuantity: order?.itemsQuantity,
                authUser: { id: user?.id }
            };
            processCmiPayment(paymentData);
        }
    }

    if (isLoading) return <Loading item='orders' />
    if (data?.length === 0) return <EmptyData />

    return (
        <div className='overflow-auto p-6 bg-white shadow-lg rounded-lg'>
            <h2 className="text-2xl font-bold mb-4 text-brand-darker">{t("orders.title")}</h2>

            <AnimatePresence>
                {isOpen && <OrderDetailsModal isOpen={isOpen} onClose={onClose} order={selectedOrder} />}
            </AnimatePresence>

            <table className='w-full text-left border border-gray-300 shadow-md rounded-lg'>
                <thead className="bg-gray-100">
                    <tr className="text-gray-700">
                        <th className="p-3">{t("orders.tableHeaders.reference")}</th>
                        <th className="p-3">{t("orders.tableHeaders.date")}</th>
                        <th className="p-3">{t("orders.tableHeaders.total")}</th>
                        <th className="p-3">{t("orders.tableHeaders.status")}</th>
                        <th className="p-3">{t("orders.tableHeaders.paymentStatus")}</th>
                        <th className="p-3">{t("orders.tableHeaders.actions")}</th>
                    </tr>
                </thead>
                <tbody className='bg-white'>
                    {data?.map((order: any) => (
                        <tr key={order?.id} className="border-b hover:bg-gray-50 transition">
                            <td className="p-3">{order?.reference}</td>
                            <td className='p-3'>
                                <p>{new Date(order?.createdAt).toLocaleDateString(language.split('-')[0], { year: 'numeric', month: 'long', day: 'numeric' })}</p>
                                <p className='opacity-50 text-sm'>{new Date(order?.createdAt).toLocaleTimeString(language.split('-')[0], { hour: 'numeric', minute: 'numeric' })}</p>
                            </td>
                            <td className="p-3">
                                <p>{order?.price} {getCurrencySymbol(order?.orderItems[0]?.product?.geoInfo?.currency)}</p>
                                <p className="opacity-50 text-sm">{order?.orderItems?.length} {t("cart.items")}</p>
                            </td>
                            <td className="p-3">
                                <p className={returnOrderStatusBG(order?.orderStatus) + " px-3 py-1 rounded-full text-sm text-center"}>
                                    {t("orders.STATUS." + order?.orderStatus)}
                                </p>
                            </td>
                            <td className="p-3">
                                <div className="flex items-center">
                                    <span className={returnPaymentStatusBG(order?.paymentStatus) + " px-3 py-1 rounded-full text-sm text-center"}>
                                        {t("payments.STATUS." + order?.paymentStatus)}
                                    </span>
                                    {order?.paymentStatus === ENUMS.PAYMENT.STATUS.FAILED && (
                                        <button onClick={() => retryPayment(order)} className="ml-2 text-brand-darker hover:text-brand-color transition">
                                            <TiRefresh size={24} />
                                        </button>
                                    )}
                                </div>
                            </td>
                            <td className="p-3">
                                <button className='text-brand-color underline' onClick={() => onOpen(order)}>
                                    {t("orders.viewDetails")}
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Pagination Controls */}
            <div className="flex justify-between items-center mt-6">
                <button
                    className={`px-4 py-2 rounded-lg text-white bg-brand-darker ${page === 1 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-brand-color'}`}
                    onClick={() => setPage(prev => Math.max(prev - 1, 1))}
                    disabled={page === 1}
                >
                    {t("pagination.previous")}
                </button>
                <span className="font-semibold">{t("pagination.page")} {page}</span>
                <button
                    className={`px-4 py-2 rounded-lg text-white bg-brand-darker ${data?.length < ITEMS_PER_PAGE ? 'opacity-50 cursor-not-allowed' : 'hover:bg-brand-color'}`}
                    onClick={() => setPage(prev => prev + 1)}
                    disabled={data?.length < ITEMS_PER_PAGE}
                >
                    {t("pagination.next")}
                </button>
            </div>

            <div className='hidden'>
                <form name='cmiPaymentForm' id="cmiPaymentForm"></form>
            </div>
        </div>
    )
}

export default Orders;
