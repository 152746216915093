import React from 'react';

const TermsAndConditionsEN = () => {
    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-6 text-center">Terms and Conditions of Use</h1>
            
            <div className="prose prose-lg max-w-none">
                <p>
                    By browsing and using our website: <a href="https://www.argori.com" className="text-blue-600 hover:text-blue-800">www.argori.com</a>, you agree to comply with and be bound by our Terms and Conditions of Use and our <a href="/privacy-policy" className="text-blue-600 hover:text-blue-800">Privacy Policy</a>. Throughout these terms and conditions, we will refer to ourselves as Argori, 'we', 'us' and 'our' meaning “Argori”.
                </p>
                <p>
                    We reserve the right to modify these Terms and Conditions at any time. Such changes will be effective immediately upon posting on the website: <a href="https://www.argori.com" className="text-blue-600 hover:text-blue-800">www.argori.com</a> and it is your responsibility to read the Terms and Conditions each time you use this website.
                </p>
                <p>
                    By visiting our site and/or purchasing something from us, you engage in our ‘Service’ and agree to be bound by the following terms and conditions (“Terms of Service”, “Terms”), including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/or contributors of content.
                </p>
                <p>
                    Our website: <a href="https://www.argori.com" className="text-blue-600 hover:text-blue-800">www.argori.com</a>, provides us with the online e-commerce platform that allows us to sell our products and services to you online.
                </p>

                <h2 className="text-2xl font-bold mt-8 mb-4">Conditions of Use of Our Website: www.argori.com Online</h2>
                <p>
                    By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.
                </p>
                <p>
                    You may not use our products for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws). You must not transmit any worms or viruses or any code of a destructive nature. A breach or violation of any of the Terms will result in an immediate termination of your Services.
                </p>

                <h2 className="text-2xl font-bold mt-8 mb-4">Accuracy, Completeness and Timeliness of Information</h2>
                <p>
                    We are not responsible if information made available on this site is not accurate, complete or current. The material on this site is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or more timely sources of information. Any reliance on the material on this site is at your own risk.
                </p>
                <p>
                    This site may contain certain historical information. Historical information, necessarily, is not current and is provided for your reference only. We reserve the right to modify the contents of this site at any time, but we have no obligation to update any information on our site. You agree that it is your responsibility to monitor changes to our site: <a href="https://www.argori.com" className="text-blue-600 hover:text-blue-800">www.argori.com</a>.
                </p>

                <h2 className="text-2xl font-bold mt-8 mb-4">Product, Price, and Service Modifications</h2>
                <p>
                    Our products, and prices of our products and services, are subject to change without notice. We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time. We shall not be liable to you or to any third-party for any modification, price change, suspension or discontinuance of the Service.
                </p>

                <h2 className="text-2xl font-bold mt-8 mb-4">Our Products</h2>
                <p>
                    Our products or services are available exclusively online through the website: <a href="https://www.argori.com" className="text-blue-600 hover:text-blue-800">www.argori.com</a>. These products or services may have limited quantities and are subject to return or exchange only according to our <a href="/return-policy" className="text-blue-600 hover:text-blue-800">Return Policy</a>.
                </p>
                <p>
                    We have made every effort to display as accurately as possible the colors and images of our products that appear at the store. We cannot guarantee that your computer monitor's display of any color will be accurate.
                </p>
                <p>
                    We reserve the right, but are not obligated, to limit the sales of our products or Services to any person, geographic region or jurisdiction. We may exercise this right on a case-by-case basis. We reserve the right to limit the quantities of any products or services that we offer. All descriptions of products or product pricing are subject to change at any time without notice, at the sole discretion of us. We reserve the right to discontinue any product at any time. Any offer for any product or service made on this site is void where prohibited.
                </p>
                <p>
                    We do not warrant that the quality of any products, services, information, or other material purchased or obtained by you will meet your expectations, or that any errors in the Service will be corrected.
                </p>

                <h2 className="text-2xl font-bold mt-8 mb-4">Accuracy of Billing and Account Information</h2>
                <p>
                    We reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel quantities purchased per person, per household or per order. These restrictions may include orders placed by or under the same customer account, the same credit card, and/or orders that use the same billing and/or shipping address. In the event that we make a change to or cancel an order, we may attempt to notify you by contacting the e-mail and/or billing address/phone number provided at the time the order was made. We reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers or distributors.
                </p>
                <p>
                    You agree to provide current, complete and accurate purchase and account information for all purchases made at our website: <a href="https://www.argori.com" className="text-blue-600 hover:text-blue-800">www.argori.com</a>. You agree to promptly update your account and other information, including your email address and credit card numbers and expiration dates, so that we can complete your transactions and contact you as needed.
                </p>
                <p>
                    For more detail, please review our <a href="/return-policy" className="text-blue-600 hover:text-blue-800">Return Policy</a>.
                </p>

                <h2 className="text-2xl font-bold mt-8 mb-4">Optional Tools</h2>
                <p>
                    We may provide you with access to third-party tools over which we neither monitor nor have any control nor input. You acknowledge and agree that we provide access to these tools “as is” and “as available” without any warranties, representations or conditions of any kind and without any endorsement. We shall have no liability whatsoever arising from or relating to your use of optional third-party tools.
                </p>
                <p>
                    Any use by you of optional tools offered through the site is entirely at your own risk and discretion and you should ensure that you are familiar with and approve of the terms on which tools are provided by the relevant third-party provider(s). We may also, in the future, offer new services and/or features through the website: <a href="https://www.argori.com" className="text-blue-600 hover:text-blue-800">www.argori.com</a> (including, the release of new tools and resources). Such new features and/or services shall also be subject to these Terms of Service.
                </p>

                <h2 className="text-2xl font-bold mt-8 mb-4">Third-Party Links</h2>
                <p>
                    Certain content, products and services available via our Service may include materials from third-parties. Third-party links on this site may direct you to third-party websites that are not affiliated with us. We are not responsible for examining or evaluating the content or accuracy and we do not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or services of third-parties.
                </p>
                <p>
                    We are not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions made in connection with any third-party websites. Please review carefully the third-party's policies and practices and make sure you understand them before you engage in any transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to the third-party.
                </p>

                <h2 className="text-2xl font-bold mt-8 mb-4">User Comments, Feedback and Other Submissions</h2>
                <p>
                    If, at our request, you send certain specific submissions (for example contest entries) or without a request from us you send creative ideas, suggestions, proposals, plans, or other materials, whether online, by email, by postal mail, or otherwise (collectively, 'comments'), you agree that we may, at any time, without restriction, edit, copy, publish, distribute, translate and otherwise use in any medium any comments that you forward to us. We are and shall be under no obligation (1) to maintain any comments in confidence; (2) to pay compensation for any comments; or (3) to respond to any comments.
                </p>
                <p>
                    We may, but have no obligation to, monitor, edit or remove content that we determine in our sole discretion are unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene or otherwise objectionable or violates any party’s intellectual property or these Terms of Service.
                </p>
                <p>
                    You agree that your comments will not violate any right of any third-party, including copyright, trademark, privacy, personality or other personal or proprietary right. You further agree that your comments will not contain libelous or otherwise unlawful, abusive or obscene material, or contain any computer virus or other malware that could in any way affect the operation of the Service or any related website. You may not use a false e-mail address, pretend to be someone other than yourself, or otherwise mislead us or third-parties as to the origin of any comments. You are solely responsible for any comments you make and their accuracy. We take no responsibility and assume no liability for any comments posted by you or any third-party.
                </p>

                <h2 className="text-2xl font-bold mt-8 mb-4">Property Rights</h2>
                <p>
                    You acknowledge and agree that all copyright, trademarks, and all other intellectual property rights and all materials and/or content made available as part of your use of this website: <a href="https://www.argori.com" className="text-blue-600 hover:text-blue-800">www.argori.com</a> shall remain at all times vested in us or our licensor. You are permitted to use this material only as expressly authorized by us or our licensor. All rights in this website are owned by Argori. The website is for personal and non-commercial use only. Any attempt to copy, store or share content and information for commercial purposes is strictly prohibited. You must not attempt to copy, reproduce, transmit, publish, display, distribute, commercially exploit, or create derivative works from this website: <a href="https://www.argori.com" className="text-blue-600 hover:text-blue-800">www.argori.com</a>, all website content including artwork, images, videos, and other….
                </p>

                <h2 className="text-2xl font-bold mt-8 mb-4">Personal Information</h2>
                <p>
                    Your submission of personal information through the website: <a href="https://www.argori.com" className="text-blue-600 hover:text-blue-800">www.argori.com</a> is governed by our <a href="https://www.argori.com/privacy-policy" className="text-blue-600 hover:text-blue-800">Privacy Policy</a>.
                </p>

                <h2 className="text-2xl font-bold mt-8 mb-4">Payments</h2>
                <p>
                    Transactions are billed at the time the goods are ordered. Once your order has been placed, you will receive an acknowledgement e-mail confirming receipt of your order. This email will only be an acknowledgement and will not constitute acceptance of your order. A contract between us for the purchase of the goods will not be formed until your payment has been approved. By submitting an order to use via our website, you confirm that the payment details provided on your order are valid and correct.
                </p>
                <p>
                    All credit/debit cardholders are subject to validation checks and authorization by the card issuer. If the issuer of your payment card refuses to authorize payment for any reason, you will be notified of this at the time of payment or shortly after you submit your order. Unfortunately, during the sales period, we will not be able to accept discount codes on the website: <a href="https://www.argori.com" className="text-blue-600 hover:text-blue-800">www.argori.com</a>.
                </p>
            </div>
        </div>
    );
};

export default TermsAndConditionsEN;