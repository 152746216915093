import React from 'react';

const BecomeWhiteMaterialFR = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6 text-center">Grossiste Huiles Végétales Bio en France</h1>
      
      <div className="prose prose-lg max-w-none">
        <p>
          Opter pour un grossiste huile végétale bio en France à l’instar de la S.A.S ARGORI vous garantit des produits de qualité supérieure, extraits de graines rigoureusement sélectionnées. Que vous recherchiez une matière blanche des huiles pour vos formulations cosmétiques, vos produits alimentaires, ou pour un usage professionnel, notre gamme répond à vos besoins avec des huiles vierges et pressées à froid.
        </p>
        <p>
          Nous offrons un service complet, de la fourniture d’huiles végétales en vrac ‘’matière blanche’’ à des conditionnements adaptés aux particuliers et aux entreprises. Notre expertise couvre des produits essentiellement l’huile d’argan, huile de pépins de figue de barbarie et eau de rose, toutes extraites dans le respect des normes biologiques et adaptées aux soins du corps, visage, peau, angles et cheveux. Nous sommes fiers de fournir une qualité exceptionnelle de nos matières blanches et un service inégalé aux entreprises B2B du monde entier.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Pourquoi nous choisir</h2>
        <ul className="list-disc pl-6">
          <li>
            <strong>Qualité sans compromis :</strong> Nos produits de Huile d'argan biologique, Huile de pépins de figue de barbarie biologique et Eau de rose biologique sont soumis à des mesures de contrôle qualité rigoureuses pour garantir la pureté, la puissance et la cohérence. Nos produits sont certifiés Ecocert/USDA et Ecocert/Agriculture Biologique ‘’AB’’, garantissant leur qualité et leur respect de l'environnement. Nous proposons des produits bio et naturels, répondant aux exigences des consommateurs les plus délicats. Nous fournissons les fiches Material Safety Data Sheet (MSDS) de chaque produit, garantissant ainsi la sécurité et la conformité de nos produits.
          </li>
          <li>
            <strong>Chaîne d'approvisionnement fiable :</strong> En tant que fournisseur, importateur et exportateur de vrac d’Huile d'argan biologique, Huile de pépins de figue de barbarie biologique et Eau de rose biologique de confiance, nous garantissons une livraison rapide et un service fiable aux entreprises du monde entier.
          </li>
          <li>
            <strong>Support client exceptionnel :</strong> Notre équipe d'experts dévoués s'engage à fournir une assistance et des conseils personnalisés pour vous aider à trouver les solutions à base d’Huile d'argan biologique, Huile de pépins de figue de barbarie biologique et Eau de rose biologique parfaites pour votre entreprise.
          </li>
          <li>
            <strong>Tarifs compétitifs et conditions de livraison flexibles :</strong> Nous proposons des tarifs compétitifs et des conditions de livraison flexibles, pour répondre aux besoins de nos clients. Nous sommes un fournisseur de vrac, permettant aux entreprises B2B de développer leur business en cosmétique de manière efficace et rentable.
          </li>
        </ul>

        <h2 className="text-2xl font-bold mt-8 mb-4">Développez votre entreprise avec ARGORI</h2>
        <p>
          Développez votre entreprise avec les produits à base d’Huile d'argan biologique, Huile de pépins de figue de barbarie biologique et Eau de rose biologique de qualité supérieure ARGORI. En tant que fournisseur de vrac d’Huile d'argan biologique, Huile de pépins de figue de barbarie biologique et Eau de rose biologique réputé, nous nous engageons à aider votre marque à prospérer grâce à nos offres exceptionnelles et à notre service inégalé.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Rejoignez notre communauté de partenaires</h2>
        <p>
          Si vous êtes à la recherche de fournisseurs de vrac de produits cosmétiques bio et naturels, nous vous invitons à rejoindre notre communauté de partenaires. Nous sommes convaincus que nos produits pourront vous aider à développer votre business en cosmétique de manière efficace et rentable.
        </p>
        <p>
          Chez Argori.com, nous sommes spécialisés dans la fourniture de matière blanche ‘’vrac’’ de huile d'argan biologique, huile de figue de barbarie biologique, eau de rose biologique, certifiées bio (Ecocert/NOP & Ecocert/EOS). Nous proposons nos produits en vrac dans des volumes variés : 5L, 50L, 100L, 200L, 500L et 1000L.
        </p>
        <p>
          Pour adhérer à notre communauté de partenaires ou pour demander une commande, veuillez remplir le formulaire ci-dessous. Nous vous fournirons un devis dans les 24 heures qui suivent. Contactez-nous dès aujourd'hui pour en savoir plus sur la manière dont nous pouvons répondre aux besoins de votre entreprise.
        </p>
        <p>
          Nous sommes impatients de travailler avec vous !
        </p>
      </div>
    </div>
  );
};

export default BecomeWhiteMaterialFR;