import React from 'react';

const GeneralConditionsFR = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6 text-center">Conditions Générales de Vente</h1>
      
      <div className="prose prose-lg max-w-none">
        <p>
          Le site internet : <a href="https://www.argori.com" className="text-blue-600 hover:text-blue-800">www.argori.com</a> (ci-après dénommé le "Site web") est accessible par l’intermédiaire du réseau Internet, à tout utilisateur de ce réseau, ci-après dénommé le "Client". Ce Site web est la propriété de la société S.A.S ARGORI, au capital de 2000 €, représentée par le gérant Monsieur ABDELMAJID GHAROUADI dont le siège social est situé au 29B, rue Salvador Allende, 92220, Bagneux (France).
        </p>
        <p>
          Les présentes conditions générales de vente, ci-après dénommées "CGV" sont conclues exclusivement entre la société S.A.S ARGORI, ci-après dénommée "vendeur" et toute personne physique ou morale effectuant un achat via le site internet : <a href="https://www.argori.com" className="text-blue-600 hover:text-blue-800">www.argori.com</a>, ci-après dénommé "client".
        </p>
        <p>
          Toute commande, des Produits, proposée sur le Site web est subordonné à l'acceptation expresse et sans réserve des présentes conditions générales de vente.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Article 1. Objet</h2>
        <p>
          Les présentes conditions générales de vente "CGV" sont destinées à définir les termes et conditions de la vente en ligne des produits par l’intermédiaire du site web ainsi que les obligations de chacune des parties. Les présentes s'appliquent à l'exclusion de tout autre document. En cas de contradiction entre les termes des conditions générales de vente et les termes de tout autre document (notamment devis, factures, courriers de toute nature, récapitulatif des commandes...), les termes des conditions générales de vente prévaudront.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Article 2. Conditions d’accès au service</h2>
        <p>
          Le site web est accessible via un ordinateur, Smartphone ou tablette ou tout autre outil ou appareil électronique ou informatique. Lors de la commande, le Client doit également fournir son identité, ses coordonnées postales, téléphoniques ainsi qu'une adresse e-mail. Les produits disponibles sur le site web sont réservés à la vente par des personnes physiques, majeures, disposant d’une adresse de livraison en France métropolitaine, en Europe ou dans le monde.
        </p>
        <p>
          Par ailleurs, certaines législations nationales limitent l’importation de certains Produits. Le Client doit donc se renseigner sur la législation en vigueur dans son pays avant de commander sur le Site web et sera seul responsable des éventuelles infractions qu’il pourrait commettre en cas d’importation illégale.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Article 3. Produits et Prix</h2>
        <p>
          Le prix en vigueur est celui figurant sur le site web à la date de la commande effectuée par le client, la S.A.S ARGORI se réservant le droit de modifier le prix de ses articles à tout moment. Les articles exposés sur le site web font partis du stock du magasin de la S.A.S ARGORI, en cas de ruptures sur un article, nous vous proposerons soit un produit de remplacement soit une mise en attente de réception de produits, soit un remboursement. Les prix affichés des produits sont indiqués en euros (en HT). En sus à la commande seront ajoutés les frais de transport, calculé en fonction de la destination et du poids de la commande.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Article 4. Enregistrement et validation de la commande</h2>
        <p>
          En cochant la case "J'ai pris connaissance des conditions générales de vente et déclare les accepter sans réserve" puis en cliquant sur le bouton "Je valide ma commande", le Client confirme sa commande et, de ce fait, accepte sans réserve l’intégralité des présentes CGV. Cette confirmation ainsi que l’ensemble des données que la S.A.S ARGORI aura enregistré constitueront la preuve de la transaction. La S.A.S ARGORI se réserve le droit de ne pas prendre en compte toute commande en cas :
        </p>
        <ul className="list-disc pl-6">
          <li>Du non-paiement intégral de la commande.</li>
          <li>De litige existant avec le Client.</li>
          <li>De refus d'autorisation de paiement des organismes bancaires ou de transport.</li>
          <li>De refus d’accepter les CGV.</li>
        </ul>
        <p>
          La responsabilité de la S.A.S ARGORI ne pourra alors en aucun cas être engagée dans ces hypothèses. La S.A.S ARGORI adressera par email au Client un accusé de réception, validant ou non la commande, dans les plus brefs délais ainsi que le n° d’envoi du colis afin que le client puisse suivre sa commande.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Article 5. Paiement par carte en ligne et Transfert sur le compte</h2>
        <p>
          Les méthodes de paiement suivantes sont disponibles via la passerelle de paiement STRIPE: MasterCard, Visa, American Express, Cartes Bancaires, Apple Pay, Google Pay. Pour garantir un maximum de sécurité et de fiabilité, les données de votre carte de crédit sont cryptées grâce au protocole SSL et ne transitent jamais en clair sur le réseau. Le paiement est effectué directement via STRIPE en France, union européenne et à l’étranger tandis que les méthodes de paiement au Maroc sont disponibles via la passerelle de paiement CMI. En cas d’indisponibilité de produits, le remboursement sera aussi effectué via ces organismes ou par d’autres moyens en concertations avec le client.
        </p>
        <p>
          Nous vous enverrons par e-mail les modalités de paiement par virement bancaire. Le transfert entre les banques prend jusqu'au jour à trois jours ouvrables suivant.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Article 6. Facture</h2>
        <p>
          Le bon de commande que le Client établit en ligne ne vaut pas facture. Le Client recevra sa facture lors de la livraison, dans son compte Internet. La S.A.S ARGORI garde un exemplaire numérique de chaque facture dans sa gestion commerciale.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Article 7. Clause de Réserve de propriété</h2>
        <p>
          Selon les termes de la loi 80-335 du 12.05.1980, La S.A.S ARGORI se réserve la propriété des Produits livrés au Client jusqu'au paiement de l'intégralité du montant de la commande. Cependant, pendant toute la durée de la réserve de propriété, les risques afférents aux Produits livrés, notamment, de détérioration, de perte ou de vol et de dommages quelconques seront entièrement à la charge du Client dès la livraison.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Article 8. Disponibilité des Produits et délais d’expédition</h2>
        <p>
          Le délai d’expédition est de 24h à 120h (jours ouvrés) pour un produit à compter de la validation du paiement, par carte bancaire sous réserve de disponibilité des produits au moment de la commande via un transporteur pour le grand public. Le Client à la possibilité de suivre l’acheminement de sa commande suite au mail envoyé avec les références du transporteur assurant la délivrance de la commande.
        </p>
        <p>
          LES COUTS D'EXPEDITION APPARAISSENT SUR LE RECAPITULATIF DE LA COMMANDE AVANT SA VALIDATION.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Article 9. Livraison et Lieu de livraison</h2>
        <p>
          Les Produits sont livrés à l'adresse de livraison indiquée par le Client sur le bon de commande. Dans le cas où l'adresse de livraison serait différente de l'adresse de facturation, le Client devra l’indiquer lors de sa commande.
        </p>
        <ul className="list-disc pl-6">
          <li>Si le destinataire est absent, le client doit aviser la S.A.S ARGORI de tout changement pour éviter la perte de son colis (commande) ou des suppléments sur sa facture.</li>
          <li>En cas de perte de colis, la S.A.S ARGORI est tenu de respecter les délais imposés par les transporteurs concernant la déclaration de perte et le remboursement de l'envoi.</li>
        </ul>
        <p>
          De fait, le Client est également tenu par ces délais : En conséquence, le Client dispose d'un délai maximum de 10 jours ouvrés à compter de la réception du courriel de confirmation de l'expédition de son colis pour déclarer celui-ci perdu auprès de la S.A.S ARGORI. Passé de ce délai aucune réclamation ne sera prise en compte. Si le Client a bien déclaré son colis perdu dans le délai ci-dessus explicité, la S.A.S ARGORI s'occupe de faire ouvrir un dossier de réclamation. Dans ce cadre, il est donc possible qu'elle demande au Client des documents nécessaires à la constitution de ce dossier que le Client s’engage dès à présent à lui fournir.
        </p>
        <p>
          Les réponses définitives concernant les enquêtes sont données par les transporteurs sous un délai variant de une à trois semaines. La réponse peut être de deux types: soit le colis est retrouvé et il est alors renvoyé au client suivant la procédure normale, soit le colis est déclaré perdu par le transporteur et le vendeur en informe le Client et procède au remboursement (montant facturé) ou effectue un second envoi au choix du Client sous réserve de stock disponible.
        </p>
        <ul className="list-disc pl-6">
          <li>Tout Produit livré doit faire l'objet d'un contrôle et d'éventuelles réserves sur le bordereau du transporteur en cas de détérioration partielle ou totale. A défaut, le produit est réputé livré en bon état et ne pourra faire l'objet d'aucune contestation ultérieure. En outre, tout produit abîmé ou défectueux devra impérativement être signalé le jour même de la réception ou au plus tard le premier jour ouvré suivant la réception.</li>
          <li>En cas de non-conformité de la commande et à réception du colis par le Client, celui-ci doit formuler par téléphone, courrier électronique ou courrier postal auprès de la S.A.S ARGORI toute réclamation de non-conformité des produits en nature, en qualité ou en quantité par rapport aux indications figurant sur la facture dans un délai de 07 jours. Toute réclamation de non-conformité non effectuée dans les règles définies ci-dessus et dans les délais impartis ne pourra être prise en compte et dégagera le vendeur de toute responsabilité vis à vis du Client.</li>
          <li>Expédition par livreur au Maroc: Nous choisirons un livreur le plus convenable et fiable pour livrer votre commande à votre domicile ou adresse choisie. Vous serez informé de la livraison. Habituellement, il est possible de préciser un créneau de livraison.</li>
          <li>Envoi à l'étranger : Nous livrons vos commandes dans toute l'Union européenne. Pour les achats internationaux, veuillez nous contacter en ligne pour plus d’informations.</li>
        </ul>

        <h2 className="text-2xl font-bold mt-8 mb-4">Article 10. FORCE MAJEURE</h2>
        <p>
          Aucune des deux parties (vendeur et client) n'aura failli à ses obligations contractuelles, dans la mesure où leur exécution sera retardée, entravée ou empêchée par une force majeure. Sera considéré comme force majeure, tout événement échappant au contrôle d'une partie, qui ne pouvait être raisonnablement prévu lors de la conclusion du Contrat et dont les effets ne peuvent être évités par des mesures appropriées, et qui empêche ladite partie d'exécuter son obligation. De façon expresse, sont considérés comme cas de force majeure, outre ceux habituellement retenus par la jurisprudence des cours et des tribunaux français et sans que cela soit limitatif, les catastrophes naturelles, les tremblements de terre, les incendies, les tempêtes, les inondations, les épidémies, les guerres, les activités terroristes, les mouvements sociaux, l'impossibilité d'utiliser les moyens de transports ou les réseaux de télécommunication, publics ou privés, le fait de ne pas obtenir des permis, licences, visas ou autres types d'enregistrements.
        </p>
        <p>
          La partie touchée par de telles circonstances en avisera l'autre par tout moyen dans les meilleurs délais, l'exécution de la vente étant alors suspendue jusqu'à l'extinction de l'empêchement. En cas de persistance de l'empêchement au-delà d'un délai raisonnable, la vente pourra être résiliée par l'une des parties avec effet immédiat, par envoi d'une notification écrite à l'autre partie.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Article 11. Droit de rétractation</h2>
        <p>
          Les dispositions légales applicables prévoient que si pour quelque raison que ce soit le Client n'est pas satisfait du produit qu'il a acheté, l'article L121-20 du code de la consommation énonce qu'il dispose de la possibilité de renoncer à son achat dans les 07 jours suivant la réception de son colis. En application de l'article L121-20-2, le droit de rétractation ne s'applique pas aux logiciels, vidéo, CD et DVD, si ceux-ci ont été descellés, aux produits confectionnés selon les spécifications du Client ou nettement personnalisés ou qui, du fait de leur nature, ne peuvent être réexpédiés ou sont susceptibles de se détériorer ou de se périmer rapidement, comme par exemple les dragées. Les clients qui ont la qualité de revendeur ne peuvent pas bénéficier du droit de rétractation.
        </p>
        <p>
          Le droit de rétractation ne pourra en conséquence être exercé sur les produits ouverts.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Article 12.a Retour, échange ou remboursement d’un produit acheté en ligne</h2>
        <p>
          Rien n’oblige le vendeur (e-commerçant : S.A.S ARGORI) à reprendre un produit, à moins qu’il ne soit défectueux. Toutefois le client est appelé à consulter la politique de retour et les CGV qui exigent au client de prendre en charge les «frais de transport et de restockage» lorsqu’il retourne le produit parce qu’il a changé d’idée.
        </p>
        <p>
          Cela dit, la Loi sur la protection du consommateur prévoit des situations où vous pouvez annuler sans frais un achat en ligne. Si toutefois un détaillant n’a aucune politique de retour, il n’est pas tenu de mentionner qu’il n’en a pas. Le consommateur pourrait alors présumer que toute transaction en ligne avec ce commerçant est finale.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Article 12.b Retour, échange ou remboursement d’un produit défectueux</h2>
        <p>
          Lorsque vous achetez un produit sur notre site web : <a href="https://www.argori.com" className="text-blue-600 hover:text-blue-800">www.argori.com</a>, et que vous constatez lors de sa réception, que cet article est défectueux, vous avez les obligations suivantes :
        </p>
        <ol className="list-decimal pl-6">
          <li>Le client doit vérifier l'état du produit à la réception et signaler toute anomalie ou défectuosité au transporteur.</li>
          <li>Si le produit est endommagé ou défectueux à la réception, le client doit refuser la livraison et informer le vendeur dans les 24 heures.</li>
          <li>Si votre produit est défectueux ou endommagé, veuillez nous envoyer un e-mail dans les 24h qui suivent votre réception à nos adresses électroniques : <a href="mailto:argori.info@gmail.com" className="text-blue-600 hover:text-blue-800">argori.info@gmail.com</a>, <a href="mailto:contact@argori.com" className="text-blue-600 hover:text-blue-800">contact@argori.com</a>, ou <a href="mailto:info@argori.com" className="text-blue-600 hover:text-blue-800">info@argori.com</a> afin que nous puissions coordonner un retour pour un remplacement ou un remboursement via le mode de paiement d'origine.</li>
          <li>Le client à le droit de demander au transporteur de prendre en charge les frais de retour du produit défectueux.</li>
          <li>Le client doit informer le vendeur de la défectuosité du produit dans un délai de 7 jours en envoyant un courrier électronique sur nos adresses électroniques : <a href="mailto:argori.info@gmail.com" className="text-blue-600 hover:text-blue-800">argori.info@gmail.com</a>, <a href="mailto:contact@argori.com" className="text-blue-600 hover:text-blue-800">contact@argori.com</a>, ou <a href="mailto:info@argori.com" className="text-blue-600 hover:text-blue-800">info@argori.com</a> à compter de la réception du produit dans le cas ou le client n’a pas vérifié ou n’a pas fait attention, lors de la réception, de l’état de sa commande avant le départ du transporteur.</li>
          <li>Le client doit retourner le produit défectueux au vendeur dans son emballage d'origine, accompagné de la facture et de tout autre document justifiant l'achat.</li>
          <li>Le vendeur se réserve le droit de vérifier la défectuosité du produit avant de procéder à l'échange ou au remboursement.</li>
          <li>Si le produit est défectueux, le vendeur procédera à l'échange ou au remboursement du produit, selon le choix du client.</li>
          <li>Dans ce cas, Les frais de retour du produit défectueux sont à la charge du vendeur.</li>
        </ol>

        <h2 className="text-2xl font-bold mt-8 mb-4">Article 12.c Retour, échange ou remboursement d’un produit après une « vente finale »</h2>
        <p>
          La Loi sur la protection du consommateur ne prévoit pas de règle spécifique sur cet aspect, mais le vendeur est tenu, de manière générale et pas obligatoire, de respecter ce qu’il promet à sa clientèle. Le commerçant peut identifier la vente de certains produits comme étant «finale», dans la mesure où cette modalité est précisée avant que le consommateur ne procède au paiement.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Article 13. Service Clientèle</h2>
        <p>
          Pour toute information ou question, notre service clientèle est à votre disposition :
        </p>
        <ul className="list-disc pl-6">
          <li>Par e-mail : <a href="mailto:argori.info@gmail.com" className="text-blue-600 hover:text-blue-800">argori.info@gmail.com</a>, <a href="mailto:info@argori.com" className="text-blue-600 hover:text-blue-800">info@argori.com</a>, ou <a href="mailto:contact@argori.com" className="text-blue-600 hover:text-blue-800">contact@argori.com</a>.</li>
          <li>Par téléphone : +33 (0) 7.68.96.85.07 & +33 (0) 6.95.83.91.20 du lundi au vendredi de 9h à 18h.</li>
        </ul>

        <h2 className="text-2xl font-bold mt-8 mb-4">Article 14. Garanties – Limites de responsabilité</h2>
        <p>
          Les produits proposés sont conformes aux normes applicables en France. Il n’y a pas de garantie sur nos produits. Le vendeur ne saurait en aucun cas être tenu pour responsable du mauvais usage des Produits en vente sur le site web, notamment, l’utilisation pour un usage autre que celui prévu sur notre site Internet, ou le non-respect des Conseils d’utilisation, ou l’utilisation de nos produits malgré que le client est conscience et connaissance du danger qu’il recourt en optant pour les ingrédients de nos produits auxquels sa peau, ses yeux, ses cheveux, ses ongles et son corps en général est sensible. D’ou la nécessité de consulter son médecin traitant ou un dermatologue de son choix et à sa charge pour s’assurer des effets secondaires des composantes du produit qu’il choisi avant de procéder à sa commande.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Article 15. Propriété intellectuelle</h2>
        <p>
          La marque ARGORI et le logo ou les logos de S.A.S ARGORI figurant sur le site web : <a href="https://www.argori.com" className="text-blue-600 hover:text-blue-800">www.argori.com</a> ou ailleurs, sont les marques déposées, propriété de la société ARGORI. Toute reproduction totale ou partielle de cette marque ou de des logos du vendeur, sans autorisation préalable et écrite de ARGORI est prohibée.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Article 16. Droit Applicable – Juridiction compétente</h2>
        <p>
          Les présentes conditions générales de vente ‘’CGV’’ expriment l'intégralité des obligations des parties. La S.A.S ARGORI se réserve la liberté de les modifier à tout moment et sans préavis. Le Client est donc invité à prendre connaissance des conditions générales de vente ‘’CGV’’ préalablement à chaque commande qu’il effectuera sur le Site web.
        </p>
        <p>
          Les présentes conditions générales de vente ‘’CGV’’ sont régies par la loi française. Il en est ainsi pour les règles de fond comme pour les règles de forme et quel que soit le lieu de consultation du Site web. En cas de litige, et après une tentative de conciliation, compétence expresse est attribuée aux tribunaux compétents du ressort de la cour d'appel de paris, nonobstant pluralité de défendeurs ou appel en garantie, même pour les procédures conservatoires, en référé ou par requête.
        </p>

        <p className="mt-8">
          BAGNEUX LE : 10 JANVIER 2025
        </p>
      </div>
    </div>
  );
};

export default GeneralConditionsFR;