import { ENUMS } from '@helpers/ENUMS';
import Icons from '@helpers/IconsHelper';
import React, { ReactElement } from 'react';
import { components, translation } from "@context/Index";
import logo_gold from '@resources/logo_gold.png';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { postToNewsletter } from '@utils/api/NewsletterApi';
import { errorNotify, successNotify } from '@mbs-dev/react-helpers';

type DescAndIconsProps = { websiteInfos: any };

const DescAndIcons: React.FC<DescAndIconsProps> = ({ websiteInfos }) => {
  let iconsColor = ENUMS.COLORS.GOLD;
  let { t } = translation.useTranslation().i18n;
  let [loading, setLoading] = React.useState<boolean>(false);
  let { ActivityIndicator } = components

  let {
    FaFacebook,
    FaInstagram,
    FaLinkedinIn,
    FaWhatsapp,
    FaTiktok,
    FaYoutube,
    FaPinterest,
    FaSnapchat
  } = Icons;

  let socialMediaIcons: { [key: string]: () => ReactElement } = {
    facebook: () => <FaFacebook key="facebook" size={30} color={iconsColor} />,
    x: () => <FaFacebook key="x" size={30} color={iconsColor} />,
    instagram: () => <FaInstagram key="instagram" size={30} color={iconsColor} />,
    linkedin: () => <FaLinkedinIn key="linkedin" size={30} color={iconsColor} />,
    snapchat: () => <FaSnapchat key="snapchat" size={30} color={iconsColor} />,
    tiktok: () => <FaTiktok key="tiktok" size={30} color={iconsColor} />,
    pinterest: () => <FaPinterest key="pinterest" size={30} color={iconsColor} />,
    youtube: () => <FaYoutube key="youtube" size={30} color={iconsColor} />,
    whatsapp: () => <FaWhatsapp key="whatsapp" size={30} color={iconsColor} />,
  };

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: Yup.object({
      email: Yup.string().email(t("signUp.form.validation.invalidEmail")).required(t('signUp.form.validation.required', { field: t('signUp.form.fields.email') }))
    }),
    onSubmit: (values, { resetForm }) => {
      console.log('Newsletter email submitted:', values.email);
      setLoading(true);
      postToNewsletter(values)
        .then((res: any) => {
          setLoading(false);
          successNotify(t('footer.success'), 1500);
          resetForm();
        })
        .catch(err => {
          setLoading(false);
          console.error(err);
          errorNotify(t('footer.error'), 1500);
        })
    }
  });

  return (
    <div className="flex flex-col gap-5 p-4">
      <img src={logo_gold} alt="logo" className='w-32 mx-auto' />
      <p className='text-white text-sm text-center'>{t('footer.desc')}</p>
      <div className="flex flex-row items-center justify-center gap-3">
        {websiteInfos && websiteInfos[0]?.socialMedia?.map((item: any) => socialMediaIcons[item?.name]?.())}
      </div>
      <h3 className="text-white text-2xl">Newsletter</h3>
      <p className='text-white text-sm'>{t('footer.newsletter')}</p>
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-2">
        <div className="flex flex-row items-center justify-center gap-3 flex-wrap">
          <input
            type="text"
            name="email"
            placeholder="Email"
            className={`p-2 flex flex-grow border border-gold rounded-md outline-none ${formik.touched.email && formik.errors.email ? 'border-red-500' : ''}`}
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {loading 
          ? <ActivityIndicator size={25} color={ENUMS.COLORS.BRAND_DARK} /> 
          : <button type="submit" className="p-2 w-1/4 bg-gold rounded-md flex-grow">
            {t('footer.subscribe')}
          </button>}
        </div>
        {formik.touched.email && formik.errors.email && (
          <p className="text-red-500 text-sm text-center">{formik.errors.email}</p>
        )}
      </form>
    </div>
  );
};

export default DescAndIcons;