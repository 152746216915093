import React from 'react'
import { components, translation } from '@context/Index'
import { handleScrollTop } from '@mbs-dev/react-helpers'

const AboutUs = () => {

    const { AboutUsEN, AboutUsFR } = components
    const { language } = translation.useTranslation().i18n

    React.useEffect(() => {
        handleScrollTop();
      }, []);

    return (
        <div>
            { language.split('-')[0] === "en" ? <AboutUsEN /> : <AboutUsFR /> }
        </div>
    )
}

export default AboutUs