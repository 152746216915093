import React, { useEffect } from 'react'
import { useAuth } from '@context/AuthContext'
import { translation } from "@context/Index"

type profilePictureType = {
  size: number
}
const ProfileButton: React.FC = () => {

  let { user } = useAuth()
  let { t } = translation.useTranslation().i18n
  let profilePicture = process.env.REACT_APP_API_UPLOAD + user?.photo

  return (
    <img className="object-cover rounded-full w-full h-full" src={profilePicture} alt={t('navBar.menuButtons.profile')} />
  )
}

export default ProfileButton