import { API } from "./API_INSTANCE"

export type getPromoCodeType = (promoCode: string, userToken:string) => Promise<any>

export const consumePromoCode: getPromoCodeType = (promoCode, userToken) => {
    let body = { code: promoCode }
    return API.post('/promo_codes/use', body, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${userToken}`
        }
    })
        .then((res) => res.data)
        .catch((err) => { throw err.response.data })
}