import React from 'react'
import { uploadUrl } from '@helpers/Helpers'
import { components, translation } from "@context/Index"
import Icons from '@helpers/IconsHelper'
import { Link } from 'react-router-dom'
import { ENUMS } from '@helpers/ENUMS'

interface ProductsTileProps {
  product: any;
  isGridView?: boolean;
}

const ProductsTile: React.FC<ProductsTileProps> = ({ product, isGridView = false }) => {

  let finalProduct = product?.type === ENUMS.PRODUCT.TYPE.FINAL ? product : product?.child[0]
  let { label } = product
  let { productTranslates, productPrice, disponibilite, stock } = finalProduct
  let { image } = productTranslates[0]
  let { price, country } = productPrice[0]
  let imageURL = uploadUrl + productTranslates[0]?.image
  let [imageLoaded, setImageLoaded] = React.useState<boolean>(false)
  let { SkeletonLoader, Add2Cart } = components
  let { FaHeart, CiImageOff, IoMdPricetag } = Icons
  let { t } = translation.useTranslation().i18n

  // Get an array of valid promotion prices from the product's children
  const promotionPrices = product.child
    .filter((child: any) => child.hasOwnProperty("promotionPrice") && child.promotionPrice !== null)
    .map((child: any) => child.promotionPrice)

  // Determine the lowest promotion price if any exist
  const lowestPromotionPrice = promotionPrices.length > 0 ? Math.min(...promotionPrices) : null

  return (
    <Link
      to={(disponibilite && stock !== 0) && `/products/${product?.productTranslates[0].slug}`}
      state={product}
      className={`flex flex-col rounded-xl shadow-[0_0_15px_#258f671a] overflow-hidden justify-between relative bg-white ${!disponibilite ? "hidden" : ""}`}
    >
      {lowestPromotionPrice !== null && (
        <div className="absolute top-5 left-5 flex flex-row items-center gap-2 text-white py-1 px-4 rounded-full z-10 bg-red-500">
          <IoMdPricetag size={30} />
          <p>{t('SOLD')}</p>
        </div>

      )}
      <div className="flex h-80 sm:h-96 relative overflow-hidden justify-center items-center">
        {stock === 0 && (
          <div className="absolute inset-0 flex justify-center items-center bg-black bg-opacity-75 text-white text-lg sm:text-3xl uppercase font-bold">
            {t('OUTOFSTOCK')}
          </div>
        )}
        {(!imageLoaded && image) && <SkeletonLoader />}
        {image ? (
          <img
            src={imageURL}
            onLoad={() => setImageLoaded(true)}
            className={`w-full h-full object-cover ${imageLoaded ? "block" : "hidden"}`}
          />
        ) : (
          <CiImageOff size={"50%"} className="h-full opacity-10" />
        )}
      </div>

      <div className="p-4 sm:p-7 text-center flex flex-col flex-1 gap-4 w-ful justify-between">
        <p className="text-md sm:text-lg text-brand-darker font-bold">{label}</p>
        <div>
          {lowestPromotionPrice && (
            <p className="text-2xl sm:text-3xl text-brand-darker font-bold">
              {lowestPromotionPrice.toFixed(2) + ' ' + country?.currency}
            </p>
          )}
          <p className={`text-sm sm:text-lg text-brand-darkere text-[#258f67] font-bold ${lowestPromotionPrice && "line-through opacity-50"}`}>
            {price + ' ' + country?.currency}
          </p>
        <Add2Cart />
        </div>
      </div>

      {/* Heart icon (Favorite) */}
      <div className="absolute top-5 right-5 z-10 cursor-pointer border-2 p-2 border-gold rounded-full hover:bg-gold transition duration-300 group">
        <FaHeart size={20} className="text-gold group-hover:text-white" />
      </div>
    </Link>
  )
}

export default ProductsTile
