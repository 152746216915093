import React from 'react'
import { components, translation } from '@context/Index'

const QualityAndCertifications = () => {

    const { QualityAndCertificationEN, QualityAndCertificationFR } = components
    const { language } = translation.useTranslation().i18n

    return (
        <div>
            {language.split('-')[0] === "en" ? <QualityAndCertificationEN /> : <QualityAndCertificationFR />}
        </div>
    )
}

export default QualityAndCertifications