import { OfflineOrderFormIVType } from "@utils/interfaces/Order";

export const offlineOrderInitialValues: OfflineOrderFormIVType = {
    uid: '550e8400-e29b-41d4-a716-446655440000', // Random UUID
    reference: '12345', // Random integer
    fullName: 'John Doe',
    email: 'johndoe@example.com',
    telephone: '+1234567890',
    address: '123 Main St',
    ville: 'New York',
    zipCode: 10001,
    comment: 'This is a sample comment for testing purposes.',
    orderStatus: 'PENDING',
    paymentMethod: 'STRIPE',
    paymentStatus: 'PENDING',
};