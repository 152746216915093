import React from 'react';

const BecomeAmbassadorDescEN = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6 text-center">Become A Distributor!</h1>
      
      <div className="prose prose-lg max-w-none">
        <h2 className="text-2xl font-bold mt-8 mb-4">
          You & Us ⇢ Partners
        </h2>
        <p>
          Argori distributes natural & organic argan oil, prickly pear seed oil, and rose water cosmetics, as well as a range of beauty products.
        </p>
        <p>
          Reconciling economic development and ethical development for the respect of our consumers and our distributors, we move forward together for a committed, reasoned, and qualitative approach.
        </p>
        <p>
          As beauty professionals, you want to combine your expertise with responsible and effective products.
        </p>
        <p>
          Regular promotional offers, closely linked to seasonality, allow you to offer an Argori solution in every season.
        </p>
        <p>
          Original cosmetics draw extraordinary properties from their ingredients that provide targeted and effective solutions.
        </p>
        <p>
          Encourage the creation of emotional bonds with your clientele and immerse yourself in the heart of your profession by offering sensory and original journeys filled with escape!
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Positive Evolution</h2>
        <p>
          Of the growing natural & organic cosmetics market.
        </p>
        <p>
          "More and more consumers want to understand the composition of their products."
        </p>
        <p>
          We select original and sustainable raw materials: for customers seeking authenticity!
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Natural & Organic Products</h2>
        <ul className="list-disc pl-6">
          <li>
            <strong>Transparency:</strong> Our desire is to share our profession with you and to decipher the compositions of our products. Our primary argument: our generous formulas with authentic processes.
          </li>
          <li>
            <strong>Quality:</strong> Our products are cold-extracted according to an internally developed process. Energy-efficient and therefore more sustainable, it allows us not to heat the raw materials so as not to denature their active properties. Our constant research to optimize quality requires us to adopt more technical operating methods.
          </li>
          <li>
            <strong>Efficacy:</strong> Generous formulas for maximum effectiveness: optimization from the formulation base, incorporation of rich and active raw materials. Formulated without additives or fillers.
          </li>
          <li>
            <strong>Significant Content:</strong> Of targeted and objectified high-quality active ingredients from our biotechnology and beauty experts "proven effectiveness."
          </li>
          <li>
            <strong>Naturalness & Sourcing:</strong> Formulas that respect the skin and the environment with prodigious natural & organic raw materials! We meticulously select ingredients rich in active properties by collaborating with experts and partners of choice.
          </li>
        </ul>

        <h2 className="text-2xl font-bold mt-8 mb-4">Categories ⇢ Face, Hair & Body Beauty</h2>
        <p>
          We have developed innovative care categories incorporating our multiple specificities.
        </p>
        <p>
          We offer a wide selection of complete treatments for all beauty goals, adapted to aesthetic techniques. High-performance treatments for a targeted and effective response. Our compositions with original identities draw all their richness from the benefits of nature.
        </p>
        <p>
          Our creations enhance the organoleptic properties of our ingredients, their characteristics perceived by the senses, sight, smell, touch, with the aim of creating an emotional connection with nature during your treatments.
        </p>
      </div>
    </div>
  );
};

export default BecomeAmbassadorDescEN;