import React from 'react'
import { components, translation } from "@context/Index"
import { prodDetailsBodyType } from '@utils/types/Product'
import { uploadUrl } from '@helpers/Helpers'
import { useBaseContext } from '@context/baseContext'


const ProductDetailsBody: React.FC<prodDetailsBodyType> = ({ product, selectedVariant, setSelectedtVariant }) => {

  //product

  let { t } = translation.useTranslation().i18n
  let { Add2Cart, DescriptionElement, Carousel } = components
  let { child } = product
  let { label, productPrice, promotionPrice, stock } = selectedVariant
  let { ingredients, description, howToUse, productImages } = selectedVariant?.productTranslates[0]
  let { price, country } = productPrice[0]
  let { getCurrencySymbol } = useBaseContext()

  return (
    <div className='lg:container lg:mx-auto text-brand-darker'>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3 p-5">
        <div>
          <Carousel slides={productImages} />
        </div>
        <div className="flex flex-col flex-grow gap-6 px-4">
          <h4 className="text-3xl text-brand-darker">{label}</h4>
          <div className='flex flex-row items-center gap-3'>
            <h4 className={`text-3xl text-brand-darker ${promotionPrice && "line-through opacity-50 text-xl"}`}>{price + ' ' + getCurrencySymbol(country?.currency)}</h4>
            {promotionPrice && <h4 className="text-3xl text-brand-darker">{promotionPrice.toFixed(2) + ' ' + getCurrencySymbol(country?.currency)}</h4>}
          </div>
          {child?.length > 0 && <h4 className="text-lg font-bold underline">{t("variants")}</h4>}
          {child?.length > 0 && (
            <div className="flex rounded-md gap-3 flex-wrap">
              {child.map((item: any, index: number) => {
                return (
                  <div key={index} className="flex flex-col justify-end">
                    {item?.promotionPrice && <p className='text-red-600'>{t('SOLD')}</p>}
                    <button onClick={() => setSelectedtVariant(item)} className="flex flex-col justify-between border rounded-md overflow-hidden ">
                      {/* <img
                      src={`${uploadUrl}${item?.productTranslates[0]?.productImages[0]?.image}`}
                      key={`Variant ${index}`}
                      alt={`Variant ${index}`}
                      className="flex flex-grow object-cover"
                    /> */}

                      <p className={`transition-color duration-300 p-1 px-3 w-full ${selectedVariant?.id === item?.id ? "bg-brand-darker text-gold" : "bg-white text-brand-darker"}`}>{item?.productTranslates[0]?.title}</p>
                    </button>
                  </div>
                )
              })}
            </div>
          )}
          <div className=" flex flex-col items-start">
            {stock !== 0
              ? <Add2Cart product={selectedVariant} />
              : <p className="text-red-600">{t('OUTOFSTOCK')}</p>
            }
          </div>
          {ingredients ? <DescriptionElement title={t('productDetails.ingredients')} content={ingredients} /> : null}
          {description ? <DescriptionElement title={t('productDetails.description')} content={description} /> : null}
          {howToUse ? <DescriptionElement title={t('productDetails.how2Use')} content={howToUse} /> : null}
        </div>
      </div>

      {/* Similar Products Section */}
      {/* {product?.similar && (
        <div className="mt-12 px-5">
          <h3 className="text-2xl font-bold text-brand-darker mb-4">{t('productDetails.similarProducts')}</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {product.similar.child.map((variant: any) => (
              <SimilarProductCard key={variant.id} product={variant} />
            ))}
          </div>
        </div>
      )} */}
    </div>
  )
}

export default ProductDetailsBody