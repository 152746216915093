import React from 'react';

const TermsAndConditionsFR = () => {
    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-6 text-center">Termes et Conditions d'Utilisation</h1>
            
            <div className="prose prose-lg max-w-none">
                <p>
                    En naviguant et en utilisant notre site Web : <a href="https://www.argori.com" className="text-blue-600 hover:text-blue-800">www.argori.com</a>, vous acceptez de respecter et d'être lié par nos termes et conditions d'utilisation et notre <a href="/privacy-policy" className="text-blue-600 hover:text-blue-800">Politique de Confidentialité</a>. Tout au long de ces termes et conditions, nous nous référerons à nous-mêmes en tant qu'Argori, 'nous', 'notre' et 'notre' signifiant « Argori ».
                </p>
                <p>
                    Nous nous réservons le droit de modifier ces Termes et Conditions à tout moment. De tels changements entreront en vigueur dès leur publication sur le site Web: <a href="https://www.argori.com" className="text-blue-600 hover:text-blue-800">www.argori.com</a> et il est de votre responsabilité de lire les Termes et Conditions à chaque fois que vous utilisez ce site Web.
                </p>
                <p>
                    En visitant notre site et/ou en achetant quelque chose chez nous, vous vous engagez dans notre 'Service' et acceptez d'être lié par les termes et conditions suivants ('Conditions d'utilisation', 'Conditions'), y compris ces termes et conditions et politiques supplémentaires référencé ici et/ou disponible par hyperlien. Ces conditions d'utilisation s'appliquent à tous les utilisateurs du site, y compris, sans s'y limiter, les utilisateurs qui sont des navigateurs, des fournisseurs, des clients, des marchands et/ou des contributeurs de contenu.
                </p>
                <p>
                    Notre site web: <a href="https://www.argori.com" className="text-blue-600 hover:text-blue-800">www.argori.com</a>, nous fournissent la plateforme de commerce électronique en ligne qui nous permet de vous vendre en ligne de nos produits et de nos services.
                </p>

                <h2 className="text-2xl font-bold mt-8 mb-4">Conditions d’utilisation de notre site web: www.argori.com en ligne</h2>
                <p>
                    En acceptant ces conditions d'utilisation, vous déclarez que vous avez au moins l'âge de la majorité dans votre état ou province de résidence, ou que vous avez l'âge de la majorité dans votre état ou province de résidence et que vous nous avez donné votre consentement pour permettre à l'une de vos personnes à charge mineures d'utiliser ce site.
                </p>
                <p>
                    Vous ne pouvez pas utiliser nos produits à des fins illégales ou non autorisées et vous ne pouvez pas non plus, dans l'utilisation du Service, enfreindre les lois de votre juridiction (y compris, mais sans s'y limiter, les lois sur le droit d'auteur). Vous ne devez pas transmettre de vers, de virus ou de code de nature destructrice. Une violation ou une violation de l'une des Conditions entraînera une résiliation immédiate de vos Services.
                </p>

                <h2 className="text-2xl font-bold mt-8 mb-4">Exactitude, exhaustivité et actualité des informations</h2>
                <p>
                    Nous ne sommes pas responsables si les informations mises à disposition sur ce site ne sont pas exactes, complètes ou à jour. Le contenu de ce site est fourni à titre d'information générale uniquement et ne doit pas être invoqué ou utilisé comme seule base pour prendre des décisions sans consulter des sources d'information primaires, plus précises, plus complètes ou plus opportunes. Toute confiance dans le matériel de ce site est à vos risques et périls.
                </p>
                <p>
                    Ce site peut contenir certaines informations historiques. Les informations historiques, nécessairement, ne sont pas à jour et sont fournies à titre de référence uniquement. Nous nous réservons le droit de modifier le contenu de ce site web à tout moment, mais nous n'avons aucune obligation de mettre à jour les informations sur notre site web. Vous convenez qu'il est de votre responsabilité de surveiller les modifications apportées à notre site: <a href="https://www.argori.com" className="text-blue-600 hover:text-blue-800">www.argori.com</a>.
                </p>

                <h2 className="text-2xl font-bold mt-8 mb-4">Modifications des produits, des prix et du service</h2>
                <p>
                    Nos produits et les prix de nos produits et de nos services sont sujets à changement sans préavis. Nous nous réservons le droit à tout moment de modifier ou d'interrompre le Service (ou toute partie ou contenu de celui-ci) sans préavis à tout moment. Nous ne serons pas responsables envers vous ou envers un tiers pour toute modification, changement de prix, suspension ou interruption du Service.
                </p>

                <h2 className="text-2xl font-bold mt-8 mb-4">Nos produits</h2>
                <p>
                    Nos produits ou services sont disponibles exclusivement en ligne via le site Web: <a href="https://www.argori.com" className="text-blue-600 hover:text-blue-800">www.argori.com</a>. Ces produits ou services peuvent avoir des quantités limitées et ne peuvent être retournés ou échangés que conformément à notre <a href="/return-policy" className="text-blue-600 hover:text-blue-800">politique de retour</a>.
                </p>
                <p>
                    Nous nous sommes efforcés d'afficher le plus fidèlement possible les couleurs et les images de nos produits qui apparaissent en magasin. Nous ne pouvons pas garantir que l'affichage de n'importe quelle couleur sur votre écran d'ordinateur sera précis.
                </p>
                <p>
                    Nous nous réservons le droit, mais ne sommes pas obligés, de limiter les ventes de nos produits à toute personne, région géographique ou juridiction. Nous pouvons exercer ce droit au cas par cas. Nous nous réservons le droit de limiter les quantités de tous les produits que nous offrons. Toutes les descriptions de produits ou les prix des produits peuvent être modifiés à tout moment sans préavis, à notre seule discrétion. Nous nous réservons le droit d'interrompre tout produit à tout moment. Toute offre pour tout produit faite sur ce site web est nulle là où elle est interdite.
                </p>
                <p>
                    Nous ne garantissons pas que la qualité de tout produit, service, information ou autre matériel acheté ou obtenu par vous répondra à vos attentes, ou que toute erreur dans le Service sera corrigée.
                </p>

                <h2 className="text-2xl font-bold mt-8 mb-4">Exactitude des informations de facturation et de compte</h2>
                <p>
                    Nous nous réservons le droit de refuser toute commande que vous passez chez nous. Nous pouvons, à notre seule discrétion, limiter ou annuler les quantités achetées par personne, par foyer ou par commande. Ces restrictions peuvent inclure des commandes passées par ou sous le même compte client, la même carte de crédit et/ou des commandes utilisant la même adresse de facturation et/ou de livraison. Dans le cas où nous modifions ou annulons une commande, nous pouvons tenter de vous en informer en contactant l'adresse e-mail et/ou l'adresse de facturation/le numéro de téléphone fournis au moment de la commande. Nous nous réservons le droit de limiter ou d'interdire les commandes individuelles qui, à notre seul jugement, semblent être passées par des concessionnaires, revendeurs ou distributeurs.
                </p>
                <p>
                    Vous acceptez de fournir des informations d'achat et de compte actuelles, complètes et exactes pour tous les achats effectués dans notre site web: <a href="https://www.argori.com" className="text-blue-600 hover:text-blue-800">www.argori.com</a>. Vous acceptez de mettre à jour rapidement votre compte et d'autres informations, y compris votre adresse e-mail et vos numéros de carte de crédit et dates d'expiration, afin que nous puissions effectuer vos transactions et vous contacter si nécessaire.
                </p>
                <p>
                    Pour plus de détails, veuillez consulter notre <a href="https://www.argori.com" className="text-blue-600 hover:text-blue-800">politique de retour</a>.
                </p>

                <h2 className="text-2xl font-bold mt-8 mb-4">Outils optionnels</h2>
                <p>
                    Nous pouvons vous donner accès à des outils tiers sur lesquels nous ne surveillons ni n'avons aucun contrôle ni aucune contribution. Vous reconnaissez et acceptez que nous fournissons l'accès à ces outils 'tels quels' et 'tels que disponibles' sans aucune garantie, représentation ou condition d'aucune sorte et sans aucune approbation. Nous déclinons toute responsabilité découlant de ou liée à votre utilisation d'outils tiers facultatifs.
                </p>
                <p>
                    Toute utilisation par vous des outils optionnels proposés sur le site est entièrement à vos propres risques et à votre discrétion et vous devez vous assurer que vous connaissez et approuvez les conditions sur lesquelles les outils sont fournis par le ou les fournisseurs tiers concernés. Nous pouvons également, à l'avenir, proposer de nouveaux services et/ou fonctionnalités via le site Web: <a href="https://www.argori.com" className="text-blue-600 hover:text-blue-800">www.argori.com</a> (y compris la publication de nouveaux outils et ressources). Ces nouvelles fonctionnalités et/ou services seront également soumis aux présentes Conditions d'utilisation.
                </p>

                <h2 className="text-2xl font-bold mt-8 mb-4">Liens tiers</h2>
                <p>
                    Certains contenus, produits et services disponibles via notre site web peuvent inclure des éléments provenant de tiers. Les liens de tiers sur ce site peuvent vous diriger vers des sites Web de tiers qui ne sont pas affiliés à nous. Nous ne sommes pas responsables de l'examen ou de l'évaluation du contenu ou de l'exactitude et nous ne garantissons pas et n'assumons aucune responsabilité pour tout matériel ou site Web de tiers, ou pour tout autre matériel, produit ou service de tiers.
                </p>
                <p>
                    Nous ne sommes pas responsables de tout préjudice ou dommage lié à l'achat ou à l'utilisation de biens, services, ressources, contenus ou de toute autre transaction effectuée en relation avec des sites Web tiers. Veuillez lire attentivement les politiques et pratiques des tiers et assurez-vous de les comprendre avant de vous engager dans une transaction. Les plaintes, réclamations, préoccupations ou questions concernant les produits tiers doivent être adressées au tiers.
                </p>

                <h2 className="text-2xl font-bold mt-8 mb-4">Commentaires, rétroactions et autres soumissions des utilisateurs</h2>
                <p>
                    Si, à notre demande, vous envoyez certaines soumissions spécifiques (par exemple, des participations à des concours) ou sans demande de notre part, vous envoyez des idées créatives, des suggestions, des propositions, des plans ou d'autres documents, que ce soit en ligne, par e-mail, par courrier postal ou autrement (collectivement, les « commentaires »), vous acceptez que nous puissions, à tout moment, sans restriction, modifier, copier, publier, distribuer, traduire et autrement utiliser sur tout support les commentaires que vous nous transmettez. Nous ne sommes et ne serons pas tenus de maintenir la confidentialité des commentaires (1); payer une compensation pour tout commentaire (2); ou pour répondre à tout commentaire (3).
                </p>
                <p>
                    Nous pouvons, mais n'avons aucune obligation, surveiller, modifier ou supprimer le contenu que nous déterminons à notre seule discrétion comme étant illégal, offensant, menaçant, diffamatoire, pornographique, obscène ou autrement répréhensible ou violant la propriété intellectuelle de toute partie ou les présentes conditions d'utilisation.
                </p>
                <p>
                    Vous acceptez que vos commentaires ne violent aucun droit d'un tiers, y compris le droit d'auteur, la marque, la confidentialité, la personnalité ou tout autre droit personnel ou de propriété. Vous acceptez en outre que vos commentaires ne contiennent pas de matériel diffamatoire ou autrement illégal, abusif ou obscène, ou ne contiennent aucun virus informatique ou autre logiciel malveillant qui pourrait affecter de quelque manière que ce soit le fonctionnement du Service ou de tout site Web connexe. Vous ne pouvez pas utiliser une fausse adresse e-mail, prétendre être quelqu'un d'autre que vous-même, ou nous induire en erreur ou induire des tiers en erreur quant à l'origine de tout commentaire. Vous êtes seul responsable des commentaires que vous faites et de leur exactitude. Nous n'assumons aucune responsabilité pour les commentaires publiés par vous ou par un tiers.
                </p>

                <h2 className="text-2xl font-bold mt-8 mb-4">Droits de propriété</h2>
                <p>
                    Vous reconnaissez et acceptez que tous les droits d'auteur, marques de commerce et tous les autres droits de propriété intellectuelle et tous les matériaux et/ou contenus mis à disposition dans le cadre de votre utilisation de ce site Web: <a href="https://www.argori.com" className="text-blue-600 hover:text-blue-800">www.argori.com</a> resteront à tout moment la propriété de nous ou de notre concédant de licence. Vous êtes autorisé à utiliser ce matériel uniquement avec l'autorisation expresse de nous ou de notre concédant de licence. Tous les droits sur ce site Web appartiennent à Argori. Le site Web est réservé à un usage personnel et non commercial. Toute tentative de copier, stocker ou partager du contenu et des informations à des fins commerciales est fortement interdite. Vous ne devez pas tenter de copier, reproduire, transmettre, publier, afficher, distribuer, exploiter commercialement ou créer des œuvres dérivées à partir de ce site Web: <a href="https://www.argori.com" className="text-blue-600 hover:text-blue-800">www.argori.com</a>, tout le contenu du site Web, y compris les illustrations, images, vidéos, et autres….
                </p>

                <h2 className="text-2xl font-bold mt-8 mb-4">Renseignements personnels</h2>
                <p>
                    Votre soumission d'informations personnelles via le site web : <a href="https://www.argori.com" className="text-blue-600 hover:text-blue-800">www.argori.com</a> est régie par notre <a href="/privacy-policy" className="text-blue-600 hover:text-blue-800">Politique de Confidentialité</a>.
                </p>

                <h2 className="text-2xl font-bold mt-8 mb-4">Paiements</h2>
                <p>
                    Les transactions sont facturées au moment où les marchandises sont commandées. Une fois votre commande passée, vous recevrez un e-mail d'accusé de réception confirmant la réception de votre commande. Cet e-mail ne sera qu'un accusé de réception et ne constituera pas une acceptation de votre commande. Un contrat entre nous pour l'achat des marchandises ne sera pas formé tant que votre paiement n'aura pas été approuvé. En soumettant une commande à utiliser via notre site web, vous confirmez que les détails de paiement fournis sur votre commande sont valides et corrects.
                </p>
                <p>
                    Tous les titulaires de carte de crédit/débit sont soumis à des contrôles de validation et à l'autorisation de l'émetteur de la carte. Si l'émetteur de votre carte de paiement refuse ou n'autorise pas le paiement pour quelque raison que ce soit, vous en serez informé au moment du paiement ou peu de temps après avoir passé votre commande. Malheureusement, pendant la période de soldes, nous ne pourrons pas accepter les codes de réduction sur le site web : <a href="https://www.argori.com" className="text-blue-600 hover:text-blue-800">www.argori.com</a>.
                </p>
            </div>
        </div>
    );
};

export default TermsAndConditionsFR;