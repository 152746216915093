import { ENUMS } from "@helpers/ENUMS";
import { t } from "i18next";
import { ChangeEvent } from "react";

export interface OnlineOrderFormIVType {
    billingAddress: string;
    deliveryAddress: string;
    price: number;
    comment: string;
    paymentMethod: string;
}

export interface OfflineOrderFormIVType {
    uid: string;
    reference: string;
    fullName: string;
    email: string;
    telephone: string;
    address: string;
    ville: string;
    zipCode: number;
    comment: string;
    orderStatus: string;
    paymentMethod: string;
    paymentStatus: string;
}

export interface AddressOption {
    label: string
    value: string
}

export interface PaymentMethodOption {
    label: string
    value: string
}

export interface AddressSectionProps {
    title: string
    addresses: AddressOption[]
    selected: AddressOption | null
    onSelect: (option: AddressOption | null) => void
    onAdd: () => void
}

export interface CommentInputProps {
    value: string
    onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void
}

export interface TermsCheckboxProps {
    checked: boolean
    onChange: (e: ChangeEvent<HTMLInputElement>) => void
    error?: string
}

export interface SubmitButtonProps {
    loading: boolean
    disabled: boolean
    onSubmit: () => void
}

export interface SelectProps {
    isDisabled?: boolean
    components?: any
    styles?: any
    placeholder?: string
}


// Update AddressSection component interface
export interface AddressSectionProps {
    title: string
    addresses: AddressOption[]
    selected: AddressOption | null
    onSelect: (option: AddressOption | null) => void
    onAdd: () => void
    selectProps?: SelectProps  // Add select props to interface
}


const SelectOptionStyles = {
    control: (provided: any, state: any) => ({
        ...provided,
        borderRadius: "50px",
        padding: "5px",
        ":hover": { borderColor: ENUMS.COLORS.BRAND },
        borderColor: state.isFocused ? ENUMS.COLORS.BRAND_DARK : provided.borderColor,
        boxShadow: state.isFocused ? "0 0 0 1px " + ENUMS.COLORS.BRAND_DARK : provided.boxShadow,
        backgroundColor: state.isDisabled && "#fff",
        color: state.isDisabled && "#000"
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isSelected ? ENUMS.COLORS.BRAND_DARK : "#fff"
    })
}

export const addressSelectProps: SelectProps = {
    isDisabled: false,
    components: {
        IndicatorSeparator: () => null,
        DropdownIndicator: () => null
    },
    styles: SelectOptionStyles,
    placeholder: t("checkout.addAddress") + "..."
}

