import { OnlineOrderFormIVType } from "@utils/interfaces/Order";

export const OnlineOrderFormIV:OnlineOrderFormIVType = {
    billingAddress: "",
    deliveryAddress: "",
    price: 120,
    comment: "comment",
    paymentMethod: "",
    // orderStatus: "PENDING",
    // paymentStatus: "PENDING",
    // orderItems: [],
    // user: "api/users/1",
    // reference: "HVHTFYG76"
    // itemsQuantity: 2,
}