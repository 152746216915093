import { translation } from '@context/Index'
import Icons from '@helpers/IconsHelper'
import React from 'react'
export type EMptyDataProps = { content?: string }
const EmptyData: React.FC<EMptyDataProps> = ({ content }) => {

    const { PiEmpty } = Icons
    const { t } = translation.useTranslation().i18n

    return (
        <div className='flex flex-col items-center justify-center opacity-50'>
            <PiEmpty size={100} className='text-brand-darker' />
            <h4 className='text-2xl font-bold text-brand-darker'>{content && content} {t("notFound")}</h4>
        </div>
    )
}

export default EmptyData