import { useAuth } from '@context/AuthContext';
import { components } from '@context/Index';
import { ENUMS } from '@helpers/ENUMS';
import Icons from '@helpers/IconsHelper';
import { ProfilePictureEditorProps } from '@utils/types/ProfileType';
import React, { useRef, useState } from 'react';



const ProfileImage: React.FC<ProfilePictureEditorProps> = ({
    initialImage,
    onImageChange,
    loading
}) => {

    const [preview, setPreview] = useState<string | undefined>(initialImage);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const { CiEdit } = Icons
    const { user } = useAuth()
    const {ActivityIndicator} = components

    // Handle file input changes
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            // Create a URL for the selected file so we can show a preview
            const fileUrl = URL.createObjectURL(file);
            setPreview(fileUrl);
            onImageChange?.(file);
        }
    };

    // Trigger the hidden file input click
    const handleEditClick = () => {
        fileInputRef.current?.click();
    };

    return (
        <div className="flex flex-col gap-3 items-center">
            <div className="relative w-32 h-32">
                {/* Display the current image or a placeholder */}
                {
                    <div className="w-32 h-32 object-cover rounded-full border border-gray-300 relative overflow-hidden">
                        <img
                            src={preview || 'https://via.placeholder.com/150'}
                            alt="Profile"
                            className="w-full h-full object-cover rounded-full border border-gray-300"
                        />
                        {loading && <div className='absolute top-0 left-0 bg-[rgba(0,0,0,.5)] z-20  items-center justify-center flex w-full h-full'>
                            < ActivityIndicator size={30} color={ENUMS.COLORS.BRAND_DARK} />
                        </div>}
                    </div>
                }
                {/* Edit button overlay */}
                <button
                    onClick={handleEditClick}
                    className="absolute bottom-0 bg-brand-darker right-0 rounded-full p-2 hover:bg-gold transition-colors duration-300 focus:outline-none group"
                    aria-label="Edit Profile Picture"
                >
                    {/* Simple edit icon (SVG) */}
                    <CiEdit size={25} className='text-gold group-hover:text-brand-darker transition-colors duration-300' />
                </button>
            </div>
            {/* Hidden file input */}
            <input
                type="file"
                ref={fileInputRef}
                accept="image/*"
                className="hidden"
                onChange={handleFileChange}
            />
            <div className='items-center text-center'>
                <h2 className='text-4xl font-bold'>{user?.username}</h2>
                <h2 className='text-xl opacity-60'>{user?.fullName}</h2>
            </div>
        </div>
    );
};

export default ProfileImage;
