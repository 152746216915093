import React from 'react'
import { animation, components } from '@context/Index'

const MenuItems = ({items}:{items:any}) => {

    const {useAnimate, usePresence } = animation
    const [scope, animate] = useAnimate()
    const [isPresent, safeToRemove] = usePresence()
    const {MenuItem} = components
    

    React.useEffect(() => {
      if (isPresent) {
        const enterAnimation = async () => {
          await animate(scope.current, {height:"auto"})
          await animate(scope.current, {opacity:1})
        }
        enterAnimation()
      } else {
        const exitAnimation = async () => {
            await animate(scope.current, {opacity:0})
          await animate(scope.current, {height:0})
          safeToRemove()
        }
        exitAnimation()
      }
    }, [isPresent])


    return(
        <ul ref={scope} className={`gap-4 flex-column flex-grow xl:flex-grow-0 sm:flex h-0 opacity-0`}>
            {items.map((item:any, index:number) => (
                <MenuItem key={index} item={item} />
            ))}
        </ul>
    )
  }

export default MenuItems