import * as Yup from "yup"

export const OfflineOrderFormVS = Yup.object({
        uid: Yup.string().required('UID is required'),
        reference: Yup.number().required('Reference is required'),
        fullName: Yup.string().required('Full Name is required'),
        email: Yup.string().email('Invalid email address').required('Email is required'),
        telephone: Yup.string().required('Telephone is required'),
        address: Yup.string().required('Address is required'),
        ville: Yup.string().required('City is required'),
        zipCode: Yup.number().required('Zip Code is required').positive().integer(),
        comment: Yup.string().required('Comment is required'),
        orderStatus: Yup.string().required('Order Status is required'),
        paymentMethod: Yup.string().required('Payment Method is required'),
        paymentStatus: Yup.string().required('Payment Status is required'),
      });