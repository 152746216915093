import { useBaseContext } from '@context/baseContext'
import { animation, emptycart, translation } from '@context/Index'
import { useShoppingCart } from '@context/ShoppingCartContext'
import { uploadUrl } from '@helpers/Helpers'
import Icons from '@helpers/IconsHelper'
import React from 'react'
import { BsArrowRight } from 'react-icons/bs'
import { Link } from 'react-router-dom'

const CartModal: React.FC = () => {
    const { CiCircleMinus, CiCirclePlus, MdClose, RiDeleteBinLine, FiChevronRight } = Icons
    const { useAnimate, usePresence } = animation
    const [scope, animate] = useAnimate()
    const [isPresent, safeToRemove] = usePresence()
    const { t } = translation.useTranslation().i18n
    const { cartItems, toggleCart, incrementCartQuantity, decrementCartQuantity, removeFromCart, total, cartQuantity } = useShoppingCart()
    const { getCurrencySymbol } = useBaseContext()



    React.useEffect(() => {
        if (isPresent) {
            const enterAnimation = async () => {
                await animate([
                    [scope.current, { opacity: 1 }, { duration: .2 }],
                    ["div", { transform: "translateX(0%)" }, { duration: .3 }]
                ])
            }
            enterAnimation()
        } else {
            const exitAnimation = async () => {
                await animate([
                    ["div", { transform: "translateX(100%)" }, { duration: .3 }],
                    [scope.current, { opacity: 0 }, { duration: .2 }]
                ])
                safeToRemove()
            }
            exitAnimation()
        }
    }, [isPresent])

    const RenderItem = ({ item }: { item: any }) => {

        return (
            <div className="flex flex-row items-center gap-3 justify-between p-3">
                <div className="flex flex-row items-center gap-3">
                    <img src={uploadUrl + item?.image} className="w-32 h-16 object-cover rounded-md" />
                    <div>
                        <p>{item?.product}</p>
                        <p></p>
                        <div className="flex flex-row items-center gap-3">
                            <CiCircleMinus size={'1.5rem'} className="cursor-pointer" onClick={() => decrementCartQuantity(item?.ukey)} />
                            <p>{item?.quantity}</p>
                            <CiCirclePlus size={'1.5rem'} className="cursor-pointer" onClick={() => incrementCartQuantity(item?.ukey)} />
                            <RiDeleteBinLine size={'1.5rem'} className="cursor-pointer text-red-500" onClick={() => removeFromCart(item?.ukey)} />
                        </div>
                    </div>
                </div>
                <div className="flex flex-row items-center gap-2">
                    <p>{(item?.price * item?.quantity).toFixed(2)}</p>
                    <p>{getCurrencySymbol(item?.currency)}</p>
                </div>
            </div>
        )
    }

    return (
        <div className='fixed tranfrom opacity-0 bg-[rgba(0,0,0,0.5)] z-50 w-full h-full flex justify-end p-5 md:p-0' ref={scope} key="cart" onClick={toggleCart}>
            <div className="rounded-2xl md:rounded-s-2xl md:rounded-e-none bg-white transfrom translate-x-full overflow-hidden w-full h-full md:w-1/2 xl:w-1/3 flex flex-col" onClick={e => e.stopPropagation()}>
                <div className="flex flex-row items-center justify-between p-3 text-brand-darker" >
                    <p className='text-3xl font-bold p-3 text-left '>{t("cart.cart")}</p>
                    <MdClose size={'2rem'} className="cursor-pointer" onClick={toggleCart} />
                </div>
                {/* {cartItems.length === 0 && <p className='p-3'>{t("cart.empty")}</p>} */}
                {cartItems.length === 0 ?
                    <div className="flex flex-col items-center justify-center h-full text-gray-600">
                        <img
                            src={emptycart} // Ensure this asset is correctly linked
                            alt="Empty Cart"
                            className="w-40 h-40 mb-4 bounce-limited"
                        />
                        <p className="text-xl font-semibold">{t("cart.empty")}</p>
                        <Link
                            onClick={toggleCart}
                            to="/store"
                            className="mt-4 px-4 py-3 flex items-center justify-center gap-2 bg-green-600 text-white font-semibold rounded-lg hover:bg-green-700 transition duration-300"
                        >
                            {t("stripe.continueShopping")} <BsArrowRight />
                        </Link>
                    </div>
                    :
                    null}


                <div className="overflow-auto h-full p-4">
                    {cartItems?.map((item: any) => <RenderItem key={item.ukey} item={item} />)}
                </div>
                {/* displqy totql here */}
                {cartItems.length > 0 ?
                    <div className="flex flex-row items-center justify-between p-3 text-2xl font-bold  px-10">
                        <p>{t("cart.total")}</p>
                        <div className="flex flex-row items-center gap-2">
                            <p>{total.toFixed(2)}</p>
                            <p>{getCurrencySymbol(cartItems[cartItems.length - 1]?.currency)}</p>
                        </div>
                    </div>
                    :
                    null}
                <div className="flex items-center p-3 gap-3">
                    <Link onClick={toggleCart} to={cartQuantity < 1 ? "#" : "/checkout"} className={`py-2 px-5 flex flex-row items-center gap-3 text-brand-darker rounded-full bg-gold text-center ${cartQuantity < 1 ? "opacity-50" : "opacity-100"}`}>
                        <p className="font-bold">{t("cart.checkout")}</p>
                        <FiChevronRight size={'1.5rem'} />
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default CartModal