import { components, translation } from '@context/Index'
import React from 'react'

const TermsAndConditions = () => {

    const { language } = translation.useTranslation().i18n
    const { TermsAndConditionsEN, TermsAndConditionsFR } = components

    return (
        <>{language.split('-')[0] === "en" ? <TermsAndConditionsEN /> : <TermsAndConditionsFR />}</>
    )
}

export default TermsAndConditions