import React from 'react'

type UnderSliderItemItemProps = {
  Icon : () => JSX.Element,
  title:string,
  action?:()=>void
}
const UnderSliderItem:React.FC<UnderSliderItemItemProps> = ({Icon,title,action}) => {
  return (
    <div onClick={action} className={`flex flex-row items-center gap-2 text-brand-darker ${action ? "cursor-pointer" : "cursor-default" }`}>
      <Icon/>
      <p className='text-2xl'>{title}</p>
    </div>
  )
}

export default UnderSliderItem