import React from 'react';

const BecomeWholesaleDescEN = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6 text-center">Become a Wholesale Partner</h1>
      
      <div className="prose prose-lg max-w-none">
        <p>
          Welcome to ARGORI.COM, your premier destination for high-quality argan oil products. As a leading wholesale distributor and importer and exporter of argan oil, prickly pear seed oil, and rose water, and other products, we pride ourselves on providing exceptional product quality and unparalleled service to B2B businesses and retailers worldwide.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">ARGORI Product Highlights</h2>
        <ul className="list-disc pl-6">
          <li>Organic Argan Oil: 100% organic and 100% natural.</li>
          <li>Organic Prickly Pear Seed Oil: 100% organic and 100% natural.</li>
          <li>Rose Water: 100% organic and 100% natural.</li>
        </ul>

        <p>
          Our pure and rare Organic Argan Oil comes directly from the rich organic and natural argan tree plantations of Morocco, ensuring its authenticity and superior quality. Rich in antioxidants, vitamins, essential fatty acids, and natural actives, our Organic Argan Oil is perfect for formulating luxurious skincare, haircare, body care, and wellness products.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Certifications and Sustainability</h2>
        <p>
          Organic Argan Oil, Organic Prickly Pear Seed Oil, and Organic Rose Water: Organically certified, ECOCERT/NOP and ECOCERT/EOS and sustainably sourced, these three organic products are the ideal choice for companies committed to environmentally friendly practices.
        </p>
        <p>
          Cold-extracted using traditional methods, they retain the natural potency and effectiveness of Organic Argan Oil, Organic Prickly Pear Seed Oil, and Organic Rose Water, making them sought-after ingredients for organic beauty formulations.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Cosmetic Applications</h2>
        <p>
          Organic Argan Oil, Organic Prickly Pear Seed Oil, and Organic Rose Water for Cosmetics: Discover the transformative power of these pure and 100% organic products in cosmetic formulations. Our oils and rose water for cosmetics are lightweight, non-greasy, and easily absorbed, making them perfect for enhancing the performance of makeup, skincare, and personal care products.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Hair Care</h2>
        <p>
          Organic Argan Oil, Organic Prickly Pear Seed Oil, and Organic Rose Water for Hair Care: Give your hair care products a luxurious boost with our premium oils and rose water. Renowned for their nourishing and moisturizing properties, Organic Argan Oil, Organic Prickly Pear Seed Oil, and Organic Rose Water for hair care strengthen hair, promote shine, and tame frizz, making them essential ingredients in hair serums, masks, and styling products.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Wholesale Supplies</h2>
        <p>
          Organic Argan Oil, Organic Prickly Pear Seed Oil, and Organic Rose Water Wholesale Supplies: Whether you're a small boutique or a large-scale B2B enterprise, we offer wholesale Organic Argan Oil, Organic Prickly Pear Seed Oil, and Organic Rose Water supplies tailored to your business needs. Our flexible order options in volumes of 100, 500, 2500, and 10,000 units and competitive pricing ensure cost-effective solutions without compromising quality.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Why Choose Us</h2>
        <ul className="list-disc pl-6">
          <li>
            <strong>Uncompromising Quality:</strong> Our Organic Argan Oil, Organic Prickly Pear Seed Oil, and Organic Rose Water products undergo rigorous quality control measures to ensure purity, potency, and consistency.
          </li>
          <li>
            <strong>Reliable Supply Chain:</strong> As a trusted wholesale supplier, importer, and exporter of Organic Argan Oil, Organic Prickly Pear Seed Oil, and Organic Rose Water, we guarantee prompt delivery and dependable service to businesses worldwide.
          </li>
          <li>
            <strong>Exceptional Customer Support:</strong> Our team of dedicated experts is committed to providing personalized assistance and guidance to help you find the perfect Organic Argan Oil, Organic Prickly Pear Seed Oil, and Organic Rose Water solutions for your business.
          </li>
        </ul>

        <h2 className="text-2xl font-bold mt-8 mb-4">Grow Your Business with ARGORI</h2>
        <p>
          Grow your business with ARGORI's premium Organic Argan Oil, Organic Prickly Pear Seed Oil, and Organic Rose Water products. As a reputable wholesale supplier, we are dedicated to helping your business thrive with our exceptional offerings and unparalleled service.
        </p>
        <p>
          To join our community of partners or to request an order, please fill out the form below. We will provide you with a quote within 24 hours. Contact us today to learn more about how we can meet your business needs.
        </p>
      </div>
    </div>
  );
};

export default BecomeWholesaleDescEN;