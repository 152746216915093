import { API } from "./API_INSTANCE"

export const postOrder = async (body: any) => {
    try {
        const res = await API.post('/orders', body, {
            headers: {
                Accept: 'application/json'
            },
        })
        return res.data
    } catch (err) {
        throw err
    }
}

export const postOfflineOrder = (body: any) => {
    return API.post('/offline_orders', body, {
        headers: {
            Accept: 'application/json'
        },
    })
        .then((res) => res.data)
        .catch((err) => { throw err })
}

export const getUserOrders = (userID: string, language_code: string, page: number, itemsPerPage: number) => {
    return API.get('/orders', {
        headers: { Accept: 'application/json' },
        params: {
            "user.id": userID,
            "language_code": language_code,
            page: page,
            items_per_page: itemsPerPage
        }
    })
        .then((res) => res.data)
        .catch((err) => { throw err });
};



export const getLastOrder = async (type: string) => {
    try {
        const response = await API.get(`/order/last/${type}`)
        return response.data.id
    } catch (err) {
        throw err
    }
}