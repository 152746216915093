import React, { useEffect } from 'react'
import { components, translation } from "@context/Index"
import { useQuery } from '@tanstack/react-query'
import { getProducts } from '@utils/api/ProductsApi'
import { ENUMS } from '@helpers/ENUMS'
import icons from "@helpers/IconsHelper"
import { useAuth } from '@context/AuthContext'
import { Link } from 'react-router-dom';

type productsType = {
  categorySlug?: string,
  disablePrimaryQuery?: boolean
}

const Products: React.FC<productsType> = (props) => {
  let { categorySlug, disablePrimaryQuery = false } = props
  let { language, t } = translation.useTranslation().i18n
  let { ProductTile, Loading } = components
  let { PiSmileySad } = icons
  let { setUsersCountryCode } = useAuth()

  let { data: products, isLoading: isLoadingProducts } = useQuery({
    queryKey: ['products', language],
    queryFn: () => getProducts(language),
    enabled: !disablePrimaryQuery
  })

  let { data: categoryProducts, isLoading: isLoadingCategoryProducts } = useQuery({
    queryKey: ['categoryProducts', language, categorySlug],
    queryFn: () => getProducts(language, categorySlug),
    enabled: disablePrimaryQuery
  })

  React.useEffect(() => {
    // products && console.log("country CODE ::",products[0]?.geoInfo?.code);
    (products || categoryProducts) && setUsersCountryCode(products[0]?.geoInfo?.code ?? categoryProducts[0]?.geoInfo?.code)
  }, [products, categoryProducts])


  const displayedProducts = categoryProducts || products || [];

  if (!isLoadingProducts && displayedProducts.length === 0) {
    return (
      <div className="flex flex-row justify-center items-center gap-1 opacity-35">
        <h4 className="text-5xl p-10 text-center text-brand-darker">{t('noProducts')}</h4>
        <PiSmileySad className="text-5xl text-brand-darker" />
      </div>
    );
  }

  return (
    <div className="p-4 lg:p-0 lg:container lg:mx-auto">
      {displayedProducts.length !== 0 ?
        <h4 className="text-5xl p-10 text-center text-brand-darker">{t('products')}</h4>
        : null}

      {isLoadingProducts || isLoadingCategoryProducts ? (
        <Loading item={t('products')} />
      ) : (
        <>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3 xl:grid-cols-4">
            {displayedProducts.map((product: any) => (
              <ProductTile product={product} key={product?.productTranslates[0]?.slug} />
            ))}
          </div>

          <div className="flex justify-center mt-8">
            <Link to="/store">
              <button className="px-6 py-3 bg-[#258f67] text-white text-lg rounded-lg transition-all hover:bg-[#1e6d50]">
                {t('discoverOurProducts')}
              </button>

            </Link>
          </div>
        </>
      )}
    </div>
  );
}

export default Products;
