export const ENUMS = {
    LOGO_NAME: "ARGORI",
    COLORS: {
        BRAND: "#4c8c40",
        BRAND_DARK: "#214842",
        GOLD: "#efc368",
        BANNER_GOLD: "rgba(239, 195, 104, 0.18)",
        DANGER: "#dc3545",
        WARNING: "#ffc107",
        STATUS: {
            PENDING: "rgba(255, 193, 7, 0.18)",
            CONFIRMED: "rgba(76, 140, 64, 0.18)",
            COMPLETED: "rgba(76, 140, 64, 0.18)",
            IN_SHIPPING: "rgba(76, 140, 64, 0.18)",
            CANCELED: "rgba(220, 53, 69, 0.18)"
        }
    },
    PRODUCT: {
        TYPE: {
            PARENT: "PARENT",
            VARIANT: "VARIANT",
            FINAL: "FINAL",
        }
    },
    ORDER: {
        STATUS: {
            PENDING: 'PENDING',
            CONFIRMED: 'CONFIRMED',
            COMPLETED: 'COMPLETED',
            IN_SHIPPING: 'IN_SHIPPING',
            CANCELED: 'CANCELED'
        }
    },
    PAYMENT: {
        METHOD: {
            CMI: 'CMI',
            COD: 'COD',
            STRIPE: 'STRIPE'
        },
        STATUS: {
            PENDING: 'PENDING',
            COMPLETED: 'COMPLETED',
            PAID: 'PAID',
            UNPAID: 'UNPAID',
            FAILED: 'FAILED'
        }
    },
    ADDRESSTYPE: {
        BILLING: 'BILLING',
        DELIVERY: 'DELIVERY'
    },
    PARTNER_TYPE:{
        WHOLESALE_MERCHANT : "marchand de gros",
        BULK_WHITE_MATERIAL_MERCHANT : "marchand de vrac matiere blanche",
        BECOME_DARGORI_AMBASSADOR : "devenir ambassadeur d'argori"
    }

}