import * as Yup from "yup"
import { t } from "i18next"

export const newAddressFormVS = Yup.object({
    fullName: Yup.string().required(t('newAddressForm.errors.required', { field: t('newAddressForm.fullName') })),
    email: Yup.string()
        .email(t('newAddressForm.errors.invalidEmail'))
        .required(t('newAddressForm.errors.required', { field: t('newAddressForm.email') })),
    telephone: Yup.number()
        .typeError(t('newAddressForm.errors.invalidNumber', { field: t('newAddressForm.telephone') }))
        .required(t('newAddressForm.errors.required', { field: t('newAddressForm.telephone') }))
        .min(1000000000, t('newAddressForm.errors.telephoneLength'))
        .max(9999999999, t('newAddressForm.errors.telephoneLength')),
    ville: Yup.string().required(t('newAddressForm.errors.required', { field: t('newAddressForm.ville') })),
    zipCode: Yup.number()
        .typeError(t('newAddressForm.errors.invalidNumber', { field: t('newAddressForm.zipCode') }))
        .required(t('newAddressForm.errors.required', { field: t('newAddressForm.zipCode') }))
        .min(10000, t('newAddressForm.errors.zipCodeLength'))
        .max(99999, t('newAddressForm.errors.zipCodeLength')),
    address: Yup.string().required(t('newAddressForm.errors.required', { field: t('newAddressForm.address') })),
})