import ActivityIndicator from '@components/loading/ActivityIndicator';
import { components } from '@context/Index';
import { ENUMS } from '@helpers/ENUMS';
import React, { useState } from 'react';

interface ReviewProps {
    onSubmit: (rating: number, comment: string) => void;
    setFormHeight: React.Dispatch<React.SetStateAction<number>>;
    isLoading: boolean
}

const NewReview: React.FC<ReviewProps> = ({ onSubmit, setFormHeight, isLoading }) => {
    const [rating, setRating] = useState<number>(0);
    const [comment, setComment] = useState<string>('');
    let { Rating, ActivityIndicator } = components
    const formRef = React.useRef<HTMLFormElement>(null);

    // This function is called when a star is clicked.
    const handleRating = (rate: number) => {
        setRating(rate);
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        onSubmit(rating, comment);
        // Optionally, reset the form fields after submission.
        setRating(0);
        setComment('');
    };

    React.useEffect(() => {
        if (formRef.current) {
            setFormHeight(formRef.current.clientHeight);
        }
    }, [rating, comment]);

    return (
        <form
            ref={formRef}
            onSubmit={handleSubmit}
            className="p-6 bg-white rounded shadow space-y-4"
        >
            <h2 className="text-2xl font-bold ">Submit Your Review</h2>

            <div className="">
                <label className="block text-gray-700 font-semibold">
                    Rating:
                </label>
                <Rating onClick={handleRating} size={25} className='w-full' SVGclassName={'inline-block'} />
            </div>

            <div className="">
                <label htmlFor="review-comment" className="block text-gray-700 font-semibold">
                    Comment:
                </label>
                <textarea
                    id="review-comment"
                    className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-brand-color"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    placeholder="Write your review here..."
                    rows={4}
                />
            </div>

            {isLoading 
            ? <ActivityIndicator size={20} color={ENUMS.COLORS.BRAND_DARK}/> 
            :<button
                type="submit"
                className="py-2 px-4 bg-brand-color text-white rounded hover:bg-brand-darker transition duration-300"
            >
                Submit Review
            </button>}
        </form>
    );
};

export default NewReview;
