import React from 'react';

const ReturnPolicyFR = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6 text-center">Politique de retour, d'échange ou de remboursement</h1>
      
      <div className="prose prose-lg max-w-none">
        <p>
          Vous avez acheté un produit mais vous avez changé d'idée et vous voulez le retourner à S.A.S ARGORI ! La loi oblige-t-elle le vendeur à vous rembourser? Pas du tout! Voici tout ce qu’il faut savoir sur le retour, l’échange et le remboursement de marchandises.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Retour, échange ou remboursement d’un produit acheté en ligne</h2>
        <p>
          Rien n’oblige le vendeur (e-commerçant : S.A.S ARGORI) à reprendre un produit, à moins qu’il ne soit défectueux. Toutefois le client est appelé à consulter la politique de retour et les CGV qui exigent au client de prendre en charge les «frais de transport et de restockage» lorsqu’il retourne le produit parce qu’il a changé d’idée.
        </p>
        <p>
          Cela dit, la Loi sur la protection du consommateur prévoit des situations où vous pouvez annuler sans frais un achat en ligne. Si toutefois un détaillant n’a aucune politique de retour, il n’est pas tenu de mentionner qu’il n’en a pas. Le consommateur pourrait alors présumer que toute transaction en ligne avec ce commerçant est finale.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Retour, échange ou remboursement d’un produit défectueux</h2>
        <p>
          Lorsque vous achetez un produit sur notre site web : <a href="https://www.argori.com" className="text-blue-600 hover:text-blue-800">www.argori.com</a>, et que vous constatez lors de sa réception, que cet article est défectueux, vous avez les obligations suivantes :
        </p>
        <ol className="list-decimal pl-6">
          <li>Le client doit vérifier l'état du produit à la réception et signaler toute anomalie ou défectuosité au transporteur.</li>
          <li>Si le produit est endommagé ou défectueux à la réception, le client doit refuser la livraison et informer le vendeur dans les 24 heures.</li>
          <li>Si votre produit est défectueux ou endommagé, veuillez nous envoyer un e-mail dans les 24h qui suivent votre réception à nos adresses électroniques : <a href="mailto:argori.info@gmail.com" className="text-blue-600 hover:text-blue-800">argori.info@gmail.com</a>, <a href="mailto:contact@argori.com" className="text-blue-600 hover:text-blue-800">contact@argori.com</a>, ou <a href="mailto:info@argori.com" className="text-blue-600 hover:text-blue-800">info@argori.com</a> afin que nous puissions coordonner un retour pour un remplacement ou un remboursement via le mode de paiement d'origine.</li>
          <li>Le client à le droit de demander au transporteur de prendre en charge les frais de retour du produit défectueux.</li>
          <li>Le client doit informer le vendeur de la défectuosité du produit dans un délai de 7 jours en envoyant un courrier électronique sur nos adresses électroniques : <a href="mailto:argori.info@gmail.com" className="text-blue-600 hover:text-blue-800">argori.info@gmail.com</a>, <a href="mailto:contact@argori.com" className="text-blue-600 hover:text-blue-800">contact@argori.com</a>, ou <a href="mailto:info@argori.com" className="text-blue-600 hover:text-blue-800">info@argori.com</a> à compter de la réception du produit dans le cas ou le client n’a pas vérifié ou n’a pas fait attention, lors de la réception, de l’état de sa commande avant le départ du transporteur.</li>
          <li>Le client doit retourner le produit défectueux au vendeur dans son emballage d'origine, accompagné de la facture et de tout autre document justifiant l'achat.</li>
          <li>Le vendeur se réserve le droit de vérifier la défectuosité du produit avant de procéder à l'échange ou au remboursement.</li>
          <li>Si le produit est défectueux, le vendeur procédera à l'échange ou au remboursement du produit, selon le choix du client.</li>
          <li>Dans ce cas, Les frais de retour du produit défectueux sont à la charge du vendeur.</li>
        </ol>

        <h2 className="text-2xl font-bold mt-8 mb-4">Retour, échange ou remboursement d’un produit après une «vente finale»</h2>
        <p>
          La Loi sur la protection du consommateur ne prévoit pas de règle spécifique sur cet aspect, mais le vendeur est tenu, de manière générale et pas obligatoire, de respecter ce qu’il promet à sa clientèle. Le commerçant peut identifier la vente de certains produits comme étant «finale», dans la mesure où cette modalité est précisée avant que le consommateur ne procède au paiement.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Demande de remboursement pour un rabais annoncé après l’achat</h2>
        <p>
          Vous achetez un produit chez <a href="https://www.argori.com" className="text-blue-600 hover:text-blue-800">www.argori.com</a> et la semaine suivante, le site web annonce un rabais sur le même article? La loi n’oblige pas le vendeur à vous rembourser la différence, Le vendeur est libre de mettre en place une telle politique et d’en fixer les Conditions Générales de Vente ‘’CGV’’. Encore une fois, S.A.S ARGORI respecte la promesse faite à ses clients.
        </p>
        <p>
          De même si vous trouvez chez un autre vendeur un produit identique à nos produits à un prix plus bas. S.A.S ARGORI n’est pas tenu de vous rembourser la différence... sauf si S.A.S ARGORI affirme, clairement, dans ses publicités qu’il «égalise» les prix de ses concurrents.
        </p>
        <p>
          Les frais de transport retour sont à la charge du client. Celui-ci est également responsable de toute perte de valeur si l'article retourné ne se trouve pas dans l'état d'origine.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">GARANTIE DE REMBOURSEMENT</h2>
        <p>
          Pour l'huile d'argan biologique 100% pure et naturelle en volumes (30 ml et/ou 50 ml), huile de pépins de figue de barbarie en volume (15 ml et/ou 30 ml) et eau de rose tonifiante et biologique en volume de 100 ml : Nous sommes tellement convaincus des résultats de nos produits que nous offrons une garantie de remboursement si vous ne remarquez aucune amélioration visible de votre peau ou de vos cheveux dans les 90 jours qui suivent.
        </p>
        <p>
          Envoyez-nous simplement un e-mail sur l’un de nos adresses électroniques pour un remboursement via le mode de paiement d'origine en cas de non satisfaction.
        </p>
        <p>
          Cette offre dure 90 jours à compter de l'achat et n'est disponible que pour nos tailles supra-mentionnées, car nous vous recommandons d'essayer l'une des petites volumes 15 ml et 30 ml pour tester nos produits. Si 90 jours se sont écoulés depuis votre achat, nous ne pouvons malheureusement pas vous offrir un remboursement ou un échange. Cette offre ne peut être utilisée qu'une seule fois. Les remboursements excluent les frais de livraison.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">DROIT A L’ECHANGE</h2>
        <p>
          Si le produit est défectueux, le vendeur procédera à l'échange ou au remboursement du produit, selon le choix du client. Les frais de retour du produit défectueux sont à la charge du vendeur.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">FORCE MAJEURE</h2>
        <p>
          Aucune des deux parties (vendeur et client) n'aura failli à ses obligations contractuelles, dans la mesure où leur exécution sera retardée, entravée ou empêchée par une force majeure. Sera considéré comme force majeure, tout événement échappant au contrôle d'une partie, qui ne pouvait être raisonnablement prévu lors de la conclusion du Contrat et dont les effets ne peuvent être évités par des mesures appropriées, et qui empêche ladite partie d'exécuter son obligation. De façon expresse, sont considérés comme cas de force majeure, outre ceux habituellement retenus par la jurisprudence des cours et des tribunaux français et sans que cela soit limitatif, les catastrophes naturelles, les tremblements de terre, les incendies, les tempêtes, les inondations, les épidémies, les guerres, les activités terroristes, les mouvements sociaux, l'impossibilité d'utiliser les moyens de transports ou les réseaux de télécommunication, publics ou privés, le fait de ne pas obtenir des permis, licences, visas ou autres types d'enregistrements.
        </p>
        <p>
          La partie touchée par de telles circonstances en avisera l'autre par tout moyen dans les meilleurs délais, l'exécution de la vente étant alors suspendue jusqu'à l'extinction de l'empêchement. En cas de persistance de l'empêchement au-delà d'un délai raisonnable, la vente pourra être résiliée par l'une des parties avec effet immédiat, par envoi d'une notification écrite à l'autre partie.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Informations de contact</h2>
        <p>
          Pour toute information ou question, notre service clientèle est à votre disposition :
        </p>
        <ul className="list-disc pl-6">
          <li>Par e-mail : <a href="mailto:argori.info@gmail.com" className="text-blue-600 hover:text-blue-800">argori.info@gmail.com</a>, <a href="mailto:info@argori.com" className="text-blue-600 hover:text-blue-800">info@argori.com</a>, ou <a href="mailto:contact@argori.com" className="text-blue-600 hover:text-blue-800">contact@argori.com</a>.</li>
          <li>Par téléphone : +33 (0) 7.68.96.85.07 & +33 (0) 6.95.83.91.20 du lundi au vendredi de 9h à 18h.</li>
        </ul>
      </div>
    </div>
  );
};

export default ReturnPolicyFR;