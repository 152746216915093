import { useAuth } from '@context/AuthContext'
import { components } from '@context/Index'
import React from 'react'

function Profile() {
  const {Header, Breadcrumbs, ProfileBody} = components
  let { user } = useAuth()
  let profilePicture = process.env.REACT_APP_API_UPLOAD + user?.photo
  return (
    <div>
      <Header title={'Profile'}/>
      <Breadcrumbs/>
      <ProfileBody/>
    </div>
  )
}

export default Profile