import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { toFormData } from 'axios';
import { errorNotify, successNotify } from '@mbs-dev/react-helpers';
import { postUser } from '@utils/api/UserApi';
import { useAuth } from '@context/AuthContext';
import { components } from '@context/Index';
import { ENUMS } from '@helpers/ENUMS';
import { SignUpIV, SignUpProps } from '@utils/initValues/SignUpIV';
import { SignUpSchema } from '@utils/validation/YupSchema';

const SignUpForm: React.FC = () => {
    const { t } = useTranslation();
    const [preview, setPreview] = useState<string | null>(null);
    const { login } = useAuth();
    const [loading, setLoading] = useState(false);
    const { ActivityIndicator } = components;

    const formik = useFormik<SignUpProps>({
        initialValues: SignUpIV,
        validationSchema: SignUpSchema,
        onSubmit: (values) => {
            const formData = new FormData();
            Object.entries(values).forEach(([key, value]) => {
                if (value !== null && value !== undefined) {
                    if (key === 'newsletter') {
                        formData.append(key, value ? '1' : '0');
                    } else {
                        formData.append(key, value);
                    }
                }
            });

            setLoading(true);
            postUser(formData)
                .then(res => {
                    successNotify(t("signUp.form.success"), 1500);
                    let body = { email: values.username, password: values.password };
                    login(body, true);
                })
                .catch(err => {
                    console.error(err.response);
                    if(err?.response?.data?.detail?.includes("Duplicate")){
                        errorNotify(t("signUp.form.validation.duplicateEmail"), 1500);
                    }else{
                        errorNotify(t("signUp.form.error"), 1500);
                    }
                    setLoading(false);
                });
        },
    });

    const fields = [
        { name: 'fullName', label: t('signUp.form.fields.fullName'), type: 'text' },
        { name: 'username', label: t('signUp.form.fields.username'), type: 'text' },
        { name: 'email', label: t('signUp.form.fields.email'), type: 'email' },
        { name: 'password', label: t('signUp.form.fields.password'), type: 'password' },
        // { name: 'birthday', label: t('signUp.form.fields.birthday') + ' (' + t("optional") + ')', type: 'date' }, // Added Birthday Field
    ];

    useEffect(() => {
        return () => {
            if (preview) {
                URL.revokeObjectURL(preview);
            }
        };
    }, [preview]);

    return (
        <div className="max-w-3xl mx-auto p-4">
            <form onSubmit={formik.handleSubmit} className="space-y-4">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                    {fields.map((field) => (
                        <div key={field.name}>
                            <label htmlFor={field.name} className="block font-medium">
                                {field.label}
                            </label>
                            <input
                                id={field.name}
                                name={field.name}
                                type={field.type}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values[field.name as keyof SignUpProps] as string}
                                className="mt-1 block w-full border border-gray-300 rounded-full p-2"
                            />
                            {formik.touched[field.name as keyof SignUpProps] && formik.errors[field.name as keyof SignUpProps] && (
                                <div className="text-red-500 text-sm">{formik.errors[field.name as keyof SignUpProps]}</div>
                            )}
                        </div>
                    ))}
                </div>

                <div className="flex flex-col">
                    <label htmlFor="birthday" className="font-medium text-gray-700 mb-1">
                        {t("signUp.form.fields.birthday")} ({t("optional")})
                    </label>
                    <div className="relative">
                        <input
                            id="birthday"
                            name="birthday"
                            type="date"
                            className="w-full px-4 py-2 border border-gray-300 rounded-xl shadow-sm focus:outline-none focus:ring-2 focus:ring-brand-darker focus:border-brand-darker transition duration-300"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.birthday || ""}
                        />
                        <svg
                            className="w-5 h-5 text-gray-400 absolute right-4 top-1/2 transform -translate-y-1/2 pointer-events-none"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M8 7v1m8-1v1m-9 4h10M5 21h14a2 2 0 002-2V9a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                            />
                        </svg>
                    </div>
                    {formik.touched.birthday && formik.errors.birthday && (
                        <div className="text-red-500 text-sm mt-1">{formik.errors.birthday}</div>
                    )}
                </div>


                {/* Profile Picture Field */}
                {/* <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 justify-center items-center'>
                    {preview && (
                        <div className="flex justify-center items-center">
                            <img
                                src={preview}
                                alt="Profile Preview"
                                className="w-32 h-32 object-cover rounded-full"
                            />
                        </div>
                    )}
                    <div>
                        <label htmlFor="photo" className="block font-medium">
                            {t('signUp.form.fields.photo') + ' (' + t("optional") + ')'}
                        </label>
                        <input
                            id="photo"
                            name="photo"
                            type="file"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                if (event.currentTarget.files) {
                                    const file = event.currentTarget.files[0];
                                    if (preview) {
                                        URL.revokeObjectURL(preview);
                                    }
                                    formik.setFieldValue('photo', file);
                                    const previewUrl = URL.createObjectURL(file);
                                    setPreview(previewUrl);
                                }
                            }}
                            onBlur={formik.handleBlur}
                            className="mt-1 block w-full"
                        />
                        {formik.touched.photo && formik.errors.photo && (
                            <div className="text-red-500 text-sm">{formik.errors.photo as string}</div>
                        )}
                    </div>
                </div> */}
                <div className="flex flex-col items-center justify-center">
                    <label
                        htmlFor="photo"
                        className="flex flex-col items-center justify-center w-full cursor-pointer border-2 border-dashed border-gray-400 rounded-xl p-4 hover:border-brand-darker transition duration-300"
                    >
                        {preview ? (
                            <img
                                src={preview}
                                alt="Profile Preview"
                                className="w-32 h-32 object-cover rounded-full shadow-md"
                            />
                        ) : (
                            <>
                                <svg
                                    className="w-12 h-12 text-gray-500"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M7 16l4-4m0 0l4 4m-4-4v12m8-16V4a2 2 0 00-2-2H5a2 2 0 00-2 2v4m16 0v4m-4-4H7"
                                    />
                                </svg>
                                <p className="text-sm text-gray-600 mt-2">
                                    {t('signUp.form.fields.photo')} ({t("optional")})
                                </p>
                            </>
                        )}
                    </label>

                    <input
                        id="photo"
                        name="photo"
                        type="file"
                        className="hidden"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            if (event.currentTarget.files) {
                                const file = event.currentTarget.files[0];
                                if (preview) {
                                    URL.revokeObjectURL(preview);
                                }
                                formik.setFieldValue('photo', file);
                                const previewUrl = URL.createObjectURL(file);
                                setPreview(previewUrl);
                            }
                        }}
                        onBlur={formik.handleBlur}
                    />

                    {formik.touched.photo && formik.errors.photo && (
                        <div className="text-red-500 text-sm mt-2">{formik.errors.photo as string}</div>
                    )}
                </div>


                {/* Newsletter Signup Checkbox */}
                <div className="flex items-center">
                    <input
                        id="newsletter"
                        name="newsletter"
                        type="checkbox"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        checked={formik.values.newsletter}
                        className="mr-2"
                    />
                    <label htmlFor="newsletter" className="font-medium">
                        {t('signUp.form.fields.newsletter', 'Sign up for our newsletter') + ' (' + t("optional") + ')'}
                    </label>
                </div>

                {/* Login Link */}
                <p className="text-center text-gray-600 mt-4">
                    {t("signUp.alreadyHaveAccount")}{' '}
                    <Link to="/login" className="text-green-600 hover:underline">
                        {t("signUp.login")}
                    </Link>
                </p>

                {loading
                    ? <ActivityIndicator size={30} color={ENUMS.COLORS.BRAND_DARK} />
                    : <button
                        type="submit"
                        disabled={formik.isSubmitting}
                        className="py-2 px-4 bg-brand-color text-white rounded hover:bg-brand-darker transition duration-300"
                    >
                        {t('signUp.form.submit')}
                    </button>}
            </form>
        </div>
    );
};

export default SignUpForm;
