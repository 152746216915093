import React from 'react';

const ingredientsData = [
  {
    title: "Argan Oil",
    image: require("../../resources/ingredients/argan-min.png"),
    description: `The argan tree is a tree belonging to the Sapotaceae family, native only to Morocco, which can reach 10 meters in height. Its oil has been used since ancient times for its many nutritional and cosmetic properties, for the beauty and health of skin and hair.

Rich in unsaturated acids and vitamin E, it is ideal for fighting free radicals thanks to its antioxidant effect and promotes the good health of the nervous and muscular systems; its essential fatty acid content also has a beneficial effect on cardiovascular disorders.

Organic and 100% natural argan oil nourishes, hydrates and improves skin elasticity, tightens, firms, has anti-inflammatory and decongestant properties and extends the shelf life of natural cosmetic products. It effectively helps treat acne, eczema, psoriasis, dry skin and burns. Berber women offer the best proof of the wonderful effects of argan. Despite intense sun exposure and outdoor living conditions, they have youthful skin, strong nails and shiny hair. One of the key ingredients par excellence.`
  },
  {
    title: "Prickly Pear Seed Oil",
    image: require("../../resources/ingredients/Huile de pépins de figue de barbarie-min.png"),
    description: `Behind this somewhat long name hides a plant. A cactus, more precisely the prickly pear. Native to the arid zones of Latin America, it is now very present around the Mediterranean. To produce one liter of prickly pear seed oil, 30 kilos of seeds are needed, which represents one ton of fruit. These seeds are then washed and dried in the sun before being cold-pressed. Each seed contains about 5% pure oil. Prickly pear seed oil is therefore a rare commodity.

The fame of prickly pear seed oil comes mainly from its strong anti-aging power. It offers a tensor effect combined with protection against free radicals which helps to erase wrinkles, prevent their appearance and fight against skin sagging.

Like any vegetable oil, prickly pear seed oil is also recommended for its moisturizing, firming and sebum-regulating action. It is suitable for all skin types because it is a dry oil that does not leave a greasy film, it is used on the scalp to balance sebum production and on nails to strengthen them.

Finally, prickly pear seed oil has a healing action that will be very useful for reducing the appearance of scars, as well as stretch marks. It is a true gold mine in organic cosmetics. This oil is therefore found in creams, soaps, and serums.`
  },
  {
    title: "Damask Rose",
    image: require("../../resources/ingredients/rose-min.png"),
    description: `The Damask rose or Rose of Damascus (Rosa damascena), is a species of flowering plant in the Rosaceae family. It is a hybrid rose, considered one of the important types of old roses. It holds a prominent place in the pedigree of many other types of roses.

Damask rose hydrosol is made through a distillation process of the Damask rose flower. The rose petals, placed in a still, are distilled using steam. Two solutions are then collected: the hydrosol, also called “floral water”, and rose essential oil.

Damask rose water is known for its hydrating and nourishing properties that help keep skin soft and supple. This is due to its rose essential oil content, which is rich in vitamins and essential fatty acids. It is a humectant that helps retain moisture in the skin and prevent dehydration.

It is also a very beneficial anti-wrinkle treatment for mature skin because it is naturally rich in vitamins C and E. Thus, rose water promotes cell renewal which delays the facial aging process (wrinkles, fine lines, and other signs of skin aging). Rose water contains a significant amount of vitamin C, an antioxidant that helps stimulate collagen production: this helps keep skin firm and elastic.

Rose water is also a natural antibacterial and anti-inflammatory that cleanses and purifies very sensitive skin. This floral water is astringent, it tightens the pores of the epidermis and therefore combats excess sebum in oily or acne-prone skin. It also has antioxidant benefits that protect the epidermis against free radicals (UV rays, cigarettes, alcohol, stress, lack of sleep, air pollution, etc.). This product limits the appearance of redness and soothes irritations and other dermatological problems (acne, dermatitis, eczema, psoriasis, etc.). In addition to its hydrating, antioxidant, and antibacterial qualities, rose water is also known for its toning and soothing properties for the epidermis. This lotion is considered a tonic that stimulates blood circulation. It is therefore also good for the health of your body!

The sweet fragrance is pleasant and relaxing which can also help soothe the mind. This floral water can be particularly effective in reducing stress and anxiety.`
  },
  {
    title: "Corn Poppy",
    image: require("../../resources/ingredients/Coquelicot-min.png"),
    description: `It is an annual herbaceous plant, very abundant in freshly disturbed ground from spring onwards, which is distinguished by the red color of its flowers and by the fact that it often forms large colorful carpets visible from afar.

It belongs to the group of plants called messicoles because it has been associated with agriculture since very ancient times, thanks to its biological cycle adapted to cereal crops, flowering and seeding occurring before harvest.

Very common in various countries of Europe and North Africa, it has declined considerably due to the widespread use of herbicides and the improvement in the sorting of cereal seeds.

Aker Fassi powder is a traditional beauty product, derived from dried poppy petals, used for centuries in beauty rituals in Morocco. This fine and versatile powder is appreciated for its multiple benefits, both for skincare and makeup. Aker Fassi powder can be used in a variety of beauty treatments. Whether for revitalizing masks, gentle scrubs, or colored lip balms, it adds a natural touch to your beauty routine. As makeup, it offers a delicate red tint, perfect for a natural and refined look.

• Hydration: Deeply nourishes and hydrates the skin, leaving a soft and radiant complexion.
• Revitalization: Helps restore radiance and luminosity to the skin, while protecting it from external aggressions.
• Soothing: Reduces irritation and soothes sensitive skin thanks to its natural anti-inflammatory properties.
• Natural Makeup: Aker Fassi is also used as a natural colorant for lips and cheeks, offering a subtle and elegant red tint.`
  },
  {
    title: "Grapeseed Oil",
    image: require("../../resources/ingredients/Coquelicot-min.png"),
    description: `Grapeseed oil, a raw material, incorporates grapeseed oil known for its nourishing and antioxidant properties. It helps the epidermis regenerate while fighting cellular aging. It offers real protection of the membranes and combines perfectly with other active ingredients.

Grapeseed oil is used alone or in combination with other oils. Emollient and regenerating, it nourishes the skin. Non-greasy, it penetrates easily into the skin, which is why it is used as a massage oil for the face or body. It can be adapted to all skin types, even sensitive skin. It is even said to have virtues against cardiovascular and nervous disorders when consumed.

Grapeseed oil naturally penetrates the hair fibers. It is very nourishing and can therefore be a choice ally for curly or dry hair. It can be your ally. It softens, protects and regenerates the skin of the face and body and helps to delay skin aging.`
  },
  {
    title: "Jojoba Oil",
    image: require("../../resources/ingredients/jojoba-min.png"),
    description: `Jojoba oil is extracted from an evergreen plant growing in California, Mexico, and Arizona. Native Americans were the first to use the benefits of this plant; thanks to its oilseeds, they treated burns and skin inflammations.

This oil is rich in monounsaturated fatty acids and micronutrients such as vitamins A, B1, B2, B6, and E. It is used in the composition of skin and hair products, for its antiseptic, emollient, moisturizing, and nourishing characteristics. Its molecular structure is similar to that of the oil produced by our sebaceous glands.

Thanks to its properties, it hydrates and cleanses the skin and makes it softer, more elastic, and smoother. It is especially recommended for people with dry and chapped skin.`
  },
  {
    title: "Moringa",
    image: require("../../resources/ingredients/moringa-min.png"),
    description: `Referred to, depending on the region, as the tree of life, the purifying tree, the eternal tree, or even the miracle tree, Moringa oleifera, commonly called Moringa, is native to the valleys of the southern Himalayas (India).

Moringa is also a food plant, whose fruits are consumed in India, and the leaves as vegetables in certain Sahelian areas. In fact, each part of the plant, leaves, flowers, roots, and even bark, has benefits for health and beauty.

Its exceptional nourishing, softening, revitalizing, and repairing properties are due to its high content of vitamin E, antioxidants, and free radical scavengers.

Moringa oil helps the skin stay supple and elastic, preserves the protective hydrolipidic film, and stimulates the repair of the epidermis. It stimulates collagen production and fights against fine lines and wrinkles.

It strengthens fragile skin, calms sensitive and reactive skin prone to irritation and desquamation thanks to the contribution of vitamin A and unsaturated fatty acids (oleic acid).

On blemished skin, with regular use, it prevents the reappearance of spots due to pimple marks.

Moringa oil is also used in perfumery because it absorbs and retains scents.`
  },
  {
    title: "Rosemary",
    image: require("../../resources/ingredients/romarin-min.png"),
    description: `Rosemary, a sacred plant that brought good luck to the Romans, comes from the Lamiaceae family. Rosemary is native to the Mediterranean basin, Europe, and North Africa. Rosemary essential oil and its hydrosol are produced from a steam distillation process of the flowering tops. In cosmetics, Rosemary is used for its anti-infective, toning, and revitalizing properties.

Rosemary is recommended for its tonic and stimulating action, in cases of convalescence, fatigue, physical or mental overwork. Its essential oil is anti-infective and a respiratory decongestant (chemotype cineole), in cases of bronchitis, and also recommended against cystitis, candidiasis, gastroenteritis, sinusitis, otitis, and colds. On the skin, it is a regenerator, a stimulant of the scalp and hair regrowth. Used on the skin combined with a vegetable oil or in a day cream, Damask Rose essential oil regenerates and tones tired skin. It fights wrinkles and helps with skin healing. Thanks to its antiviral, antibacterial, and antifungal properties, it is indicated in cases of rosacea, eczema, cold sores, shingles, or any other skin inflammation.

Rosemary hydrosol (floral water), or even an infusion of rosemary flowering tops, can be poured directly into bath water for a tonic and revitalizing effect, including for children, against heavy legs or poor circulation. Rosemary alcoholature is used against rheumatism or toothaches.`
  },
  {
    title: "Licorice",
    image: require("../../resources/ingredients/reglisse-min.png"),
    description: `Licorice (Glycyrrhiza glabra L.) is a herbaceous plant of the Fabaceae family, native to Asia and southern Europe. It is its root (or rhizome) that is mainly used in traditional medicine, phytotherapy, and even in cooking. Well known for its use in confectionery, licorice is nevertheless found in many medicinal preparations. Its richness in active ingredients has made it an essential plant, in traditional Chinese medicine for example. It also makes it a choice cosmetic active ingredient, particularly against blemishes. Licorice is a recognized cosmetic ingredient, particularly for its brightening effects and its calming action for sensitive skin. Even if it contains other active molecules, such as glycyrrhizic acid, it is glabridin that is at the heart of its effectiveness.

Glabridin regulates melanin production, for luminous, radiant skin and a unified complexion. It acts synergistically on blemishes and hyperpigmentation with vitamin C. Licorice extract also has a soothing and calming action, but not only thanks to glabridin. Several active molecules in licorice have an anti-inflammatory effect, which helps soothe sensitive skin, but also fights against post-inflammatory hyperpigmentation. For all skin types, the soothing effect of licorice helps fight against the signs of skin aging. Inflammation is indeed a factor in premature skin aging.

Also recognized for its antioxidant properties in a routine focused on skin comfort, it fights against free radicals to protect the skin from oxidative stress, and its consequences on skin aging. Here again, licorice acts synergistically with vitamin C, also a powerful antioxidant.

Beyond cooking and traditional medicines, licorice proves to be a true cosmetic ally to reduce blemishes and relieve skin sensitivity.`
  },
  {
    title: "Shea Butter",
    image: require("../../resources/ingredients/BEURRE DE KARITÉ-min.png"),
    description: `Shea butter is one of the very precious key ingredients, extracted from the kernel of the fruit of a tree from Central Africa. Its extraction is still carried out at the place of origin, thanks to a traditional process that keeps its precious active ingredients intact.

Used for centuries, it has extraordinary regenerating properties.

Widely used in dermatology and cosmetics for its healing, emollient, antioxidant and soothing properties thanks to its vitamin E content.

What makes it even more precious is its very high level of unsaponifiable elements, substances essential for maintaining the natural elasticity of the skin, since they are involved in the formation of collagen and elastin. It is a perfect natural anti-wrinkle product that restores firmness to the skin. Its moisturizing, anti-dryness composition is ideal for protecting the skin from the sun, wind and frost.`
  },
  {
    title: "Sweet Almond Oil",
    image: require("../../resources/ingredients/almond-min.png"),
    description: `Sweet almond oil is extracted by pressing the seeds of the namesake trees, almond trees, which can be bitter or sweet.

Its composition has elastic and emollient properties that make it ideal for cosmetic or dermatological use, for example for moisturizing, antipruritic products or for problems related to burns. One of the essential key ingredients.

Applied to the skin, almond oil penetrates perfectly into the hydrolipidic film, preventing the evaporation of water from the skin, thus being beneficial for dry skin.`
  },
  {
    title: "Castor Oil",
    image: require("../../resources/ingredients/huil de raacin-min.png"),
    description: `Castor oil is a very dense vegetable oil, obtained by cold pressing the seeds of the Ricinus communis plant, used for hair, skin and eyelash care (for external use).

Castor oil is characterized by a high level of ricinoleic acid, an unsaturated fatty acid in the form of a triglyceride, not found in other lipid substrates.

It has a good affinity with keratin, the main component of hair, skin and nails; it is therefore mainly used as a moisturizing agent. Applied to the skin, it forms a film that reduces the evaporation of water. Castor oil is also used to strengthen and add volume to eyelashes.`
  },
  {
    title: "Green Tea",
    image: require("../../resources/ingredients/the vert-min.png"),
    description: `Green tea, which is made from the leaves of the Camellia Sinensis plant, is a key ingredient in skincare thanks to its many properties, beneficial to the health of your skin. Thanks to its anti-inflammatory, antioxidant and antimicrobial properties, the benefits of green tea are multiple. Green tea's ability to reduce feelings of irritation, redness and swelling of the skin makes it an essential part of your skincare routine. Green tea could help reduce the appearance of dark circles and puffy eyes, thanks in particular to the presence of caffeine and tannins, which can help shrink inflamed blood vessels around the eyes.

1. Green tea helps fight the signs of aging.
2. It reduces feelings of irritation, redness and swelling of the skin.
3. Its anti-inflammatory properties could help relieve the feelings of irritation and itching caused by psoriasis, dermatitis and rosacea.
4. Green tea is present in different products used for acne-prone skin because it has been shown to help fight against, in particular, pimples and oily skin thanks to its antioxidant and antimicrobial properties.
5. Green tea contains several vitamins, including vitamin E, with antioxidant properties that can be beneficial for mature skin.`
  },
  {
    title: "Tea Tree",
    image: require("../../resources/ingredients/arbre de the-min.png"),
    description: `Native to Australia, the antimicrobial properties of Tea Tree have been appreciated by Indigenous Australians for thousands of years and today, Tea Tree oil is widely used in skin care products that target blemishes and imperfections. Tea Tree essential oil is obtained by steam distillation of the leaves and branches of the Tea Tree. Whether you use it pure on a troublesome pimple or in cosmetic products dedicated to soothing your skin, Tea Tree oil is an essential ingredient for beauty.

• Antimicrobial and antibacterial.
• Tea Tree oil is very effective in the care of oily or blemished skin, helping to purify it.
• Helps soothe and heal problem-prone skin.`
  },
  {
    title: "Saffron",
    image: require("../../resources/ingredients/safron-min.png"),
    description: `Saffron has been used as a natural dye since its early prominence in history, coloring fabrics to signify social status and religious functions.

Aristocratic women and courtesans of the past used saffron as a cosmetic and skincare product. Facial masks mixed with saffron are applied by women to make their skin soft, smooth, and free of acne and pimples. Sandalwood and saffron mixed with milk used as a mask make the face smooth and radiant. Honey, coconut oil, and sugar can also be used to make face masks. Saffron powder also acts as an exfoliant, leaving the skin clear and glowing. Saffron extract contains antibacterial properties that are also said to treat pimples, acne, and other skin diseases such as psoriasis and leprosy.

Modern perfumers take advantage of the fact that saffron blends as a middle note with other essences and fragrances to create new floral perfume formulas.

It contains an impressive variety of plant compounds. They act as antioxidant molecules that protect your cells against free radicals and oxidative stress. It strengthens the immune system. It improves heart health. It can reduce blood sugar levels. It supports memory functions. It can help regulate mood. It may contribute to reducing blood pressure.

Finally, saffron is a beautiful and elegant gift for special occasions such as Christmas, weddings, Ramadan, and other religious events, as it is expensive and therefore has a high-status symbol.`
  },
  {
    title: "Coconut",
    image: require("../../resources/ingredients/noix de coco-min.png"),
    description: `Coconut comes from coconut palms naturally present in Asia, Africa, America, and the Pacific Islands. A single coconut palm can produce between 50 and 150 coconuts per year approximately, and it takes 11 to 12 months for the fruit to ripen. Coconut offers many benefits and can be used in different forms: oil, water, milk... We tell you everything!

Very rich in saturated fatty acid, lauric acid, the properties of coconut oil are in perfect affinity with the skin! Nourishing and soothing, it protects against dehydration and restores suppleness, comfort, and well-being to the skin.

Moreover, recognized as rich in vitamins, it has protective properties as well as soothing virtues. The skin appears more supple, soft, nourished, and the sensations of dryness or tightness diminish.

Thus, a facial treatment with coconut oil is suitable for all skin types, especially if you are looking for hydration and softness. Coconut oil as a body treatment is also perfect for nourishing the epidermis throughout the year, summer and winter!

Coconut oil isn't just suitable for skin, but also for hair! The fatty acid present in coconut oil also has a particular affinity with keratin, a natural protein representing about 95% of hair's composition. Indeed, coconut oil will penetrate the hair fiber to provide it with all its benefits. Hair appears more hydrated, protected, coated, and strengthened in particular; it will thus be better equipped to defend itself during styling, but also against external aggressions (pollution, sun, etc.).`
  },
  {
    title: "Tocopherol / Vitamin E",
    image: require("../../resources/ingredients/vitamin E-min.png"),
    description: `Tocopherol is a vitamin nutrient commonly called vitamin E. It prevents the oxidation of polyunsaturated lipids and acts as a biological antioxidant.

In the human body, tocopherol is the main fat-soluble antioxidant with a protective effect against oxidative stress. Foods of plant origin are rich in vitamin E, particularly seeds and the oils derived from them, followed by cereals, fruits, and vegetables.

As an antioxidant, it plays a major role in the development and preservation of cosmetic ingredients: indeed, it improves the stability of many elements with fatty content. Vitamin E is important in anti-inflammatory processes, for the inhibition of platelet aggregation and the improvement of immune system function. Its strong antioxidant activity helps reduce the reactivity of free radicals that damage cells, and protects against UV rays, smoking, and pollution, which degrade fatty acids present in the epidermis and alter the structure of collagen.

In this way, it helps maintain skin elasticity and delay the appearance of wrinkles. This is why it is one of the key ingredients in the formulation of anti-aging products.`
  },
  {
    title: "Hyaluronic Acid",
    image: require("../../resources/ingredients/acide hyaluronique-min.png"),
    description: `Hyaluronic acid is a natural component of the dermis that acts like a sponge by capturing and retaining water. Its quantity and quality diminish with age. Many cosmetics contain hyaluronic acid, as well as most wrinkle fillers. Hyaluronic acid treatments are integrated into a daily anti-aging skincare routine to help fight against skin aging and correct visible signs of aging. Morning and evening, after thoroughly cleansing the skin, applying a product containing hyaluronic acid will allow it to act deep within the epidermis.`
  },
  {
    title: "Niacinamide",
    image: require("../../resources/ingredients/niacinamide-min.png"),
    description: `Niacinamide is a form of vitamin B3 essential to your metabolism, as it is necessary for the production of the coenzymes NAD and NADP. A dietary deficiency in vitamin B3 causes a disease characterized by photosensitive dermatitis. Also used in cosmetics, niacinamide provides several benefits for the skin, particularly in the fight against skin aging, irritations, and inflammation.

Niacinamide (also called nicotinamide) is one of the three forms that vitamin B3 can take, the other two being niacin and nicotinamide riboside. These compounds share similar functions but have slightly different chemical characteristics that influence their use in cosmetics and nutrition.

Chemically, the structure of niacinamide gives it unique properties, allowing for high solubility in water and stability in cosmetic formulations, making it a preferred choice in many skincare products.

Niacinamide is therefore often the preferred vitamin B3 in the field of skincare, and several of its benefits for the skin have been identified through studies. One of the main advantages of niacinamide concerns its action on the skin barrier. Indeed, it stimulates the production of ceramides, lipids that play a crucial role in maintaining skin hydration and protection against external aggressions. Thus, it significantly improves the skin's barrier function, reducing transepidermal water loss and increasing epidermal hydration. It also helps reduce the visible signs of skin aging. By stimulating collagen production and neutralizing free radicals (thanks to its antioxidant properties), it helps reduce the appearance of wrinkles and fine lines. Result: it helps you regain visibly more supple and toned skin.

Note that niacinamide is extremely renowned because it is commonly used to soothe oily, acne-prone skin. Indeed, its anti-inflammatory action rebalances sebum production to prevent new imperfections. Similarly, these properties make it highly appreciated by skin prone to rosacea or eczema, reducing redness and irritation related to these skin conditions.`
  },
  {
    title: "Coffea Arabica",
    image: require("../../resources/ingredients/Coffea arabica-min.png"),
    description: `Coffea Arabica
The coffee plant, botanically known as Coffea arabica, is a perennial evergreen shrub belonging to the Rubiaceae family.  It produces red fruits resembling cherries. These fruits each contain two seeds, which are the Arabica coffee beans. Native to Ethiopia, the coffee plant thrives and is particularly cultivated in tropical regions of the planet such as East Africa and Central America (Costa Rica, Honduras) and South America (Brazil). The coffee plant can reach 10 meters in height in the wild. It is mainly cultivated for its seeds, the source of the famous beverage, coffee. Nearly 150 million 60 kg bags of coffee are produced each year worldwide. Today, the world's largest producer is Brazil. 
What is "Coffea Arabica Seed Oil" and what is it used for? 
This is the I.N.C.I. name for green coffee oil macerate. Rich in caffeine, this ingredient is known for its draining and toning properties. When applied to the skin, caffeine has slimming properties, thanks to its lipolytic activity on fat cells. 
Green Coffee Oil Macerate, Extraction Process and Cosmetic Use
The I.N.C.I. name for green coffee oil macerate is therefore: "Coffea Arabica Seed oil". It is obtained from a long maceration of previously dried arabica beans followed by agitation in a vegetable oil. The resulting compound is a yellow-green liquid rich in carbohydrates, lipids (sterols, hydrocarbons, tocopherols, diterpene alcohols) and phenolic acids (quinic acid, caffeic acid, chlorogenic acid). 
Green coffee oil macerate has draining, toning and slimming properties. It promotes the hydrolysis of triglycerides accumulated in adipocytes (fat cells) and their release. Its mode of action is based on the inhibition of the activity of phosphodiesterase, an enzyme responsible for the destruction of AMPc, a chemical mediator involved in the breakdown of triglycerides. It is therefore recommended to reduce the appearance of cellulite and restore tone to sagging skin. 
In addition, the caffeine contained in the oil macerate is an excellent vascular toner that helps to drain the eye area by stimulating microcirculation. By narrowing the caliber of small blood vessels, the purplish pigmentation of dark circles due to the color of the blood capillaries fades durably. Caffeine is also very effective on puffiness, as it helps to reduce water retention under the eyes and thus visibly reduce swelling. 
Finally, caffeine has powerful antioxidant properties. It helps protect cells against free radicals generated by UV radiation and slows down the skin's photoaging process. 
In Which Skincare Products Can Green Coffee Oil Macerate Be Found? 
This ingredient is especially recommended for loose skin lacking tone. It is thus present in certain body care products such as anti-cellulite creams, scrubs or firming gels. In facial care, it is mainly used to reduce the appearance of dark circles and puffiness, so it is mainly found in creams and gels for the eye area. 
At ARGORI, this compound is present in our premium anti-dark circle and anti-wrinkle eye contour oil which firms and tones the skin, known for its draining, toning, and slimming properties. It promotes the hydrolysis of triglycerides and is very effective on puffiness.`
  }
];


const IngredientsEN = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <header className="mb-8 text-center">
        <h1 className="text-3xl font-bold text-brand-color">
          ARGORI COSMETICS: KEY INGREDIENTS AND THEIR EFFECTS
        </h1>
        <p className="mt-4 text-lg text-gray-600">
          Discover the Key Ingredients Found in Our Organic Cosmetics.
          ARGORI Cosmetics was born from the desire to create exceptional quality organic cosmetic products, made in Morocco, without using ingredients harmful to health or tested on animals.
          This French brand intends for its name to become synonymous with cosmetic products that are as natural and "pure" as possible, without its clients having to sacrifice high-quality performance. The company's commitment and value in the field of "organic" are guaranteed by the ECOCERT NOP & ECOCERT BIO EU certifications.
        </p>
        <h2 className="mt-6 text-2xl font-semibold">
          Our Key Ingredients Are Essential: Nature at the Heart of Our Cosmetics
        </h2>
      </header>
      {ingredientsData.map((ingredient, index) => (
        <div
          key={index}
          className={`flex flex-col md:flex-row ${index % 2 !== 0 ? "md:flex-row-reverse" : ""
            } items-center mb-12`}
        >
          <div className="md:w-1/2">
            <img
              src={ingredient.image}
              alt={ingredient.title}
              className="w-full h-auto rounded-lg shadow-md"
            />
          </div>
          <div className="md:w-1/2 md:px-6 mt-6 md:mt-0">
            <h3 className="text-xl font-bold mb-4 text-brand-color">{ingredient.title.toUpperCase()}</h3>
            <p className="text-gray-700 whitespace-pre-line">
              {ingredient.description}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default IngredientsEN;
