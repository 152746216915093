export const appUrl = process.env.REACT_APP_URL
export const apiUrl = process.env.REACT_APP_API_URL
export const uploadUrl = process.env.REACT_APP_API_UPLOAD

// Env
export const isDev = process.env.NODE_ENV === 'development'
export const isProd = process.env.NODE_ENV === 'production'

// Uplaod Folders Urls
export const productImgUrl = `${uploadUrl}/product_images`
export const PMultiImgUrl = `${uploadUrl}/product_multi_images`
export const variantImgUrl = `${uploadUrl}/variant_images`
export const variantIconUrl = `${uploadUrl}/variant_icons`
export const VMultiImgUrl = `${uploadUrl}/variant_multi_images`
export const packImgUrl = `${uploadUrl}/pack_images`
export const brandLogoUrl = `${uploadUrl}/brand_logos`
export const brandImgUrl = `${uploadUrl}/brand_images`
export const categoryImgUrl = `${uploadUrl}/category_images`
export const sliderImgUrl = `${uploadUrl}/slider_images`
export const specialOffersImgUrl = `${uploadUrl}/offer_images`
export const blogImgUrl = `${uploadUrl}/blog_images`
export const blogMultiImgUrl = `${uploadUrl}/blog_multi_images`
export const blogPostImgUrl = `${uploadUrl}/blog_post_images`



//Standard Functions
export const returnOrderStatusBG = (status: string) => {
    switch (status) {
        case "PENDING":
            return "bg-yellow-100"
        case "DELIVERED":
            return "bg-green-100"
        case "CANCELLED":
            return "bg-red-100"
        default:
            return "bg-gray-100"
    }
}

export const returnPaymentStatusBG = (status: string) => {
    switch (status) {
        case "PENDING":
            return "bg-yellow-100"
        case "PAID":
            return "bg-green-100"
        case "FAILED":
            return "bg-red-100"
        case "UNPAIED":
            return "bg-gray-100"
        case "COMPLETED":
            return "bg-green-100"
        default:
            return "bg-gray-100"
    }
}