export interface SignUpProps {
    fullName: string;
    email: string;
    username: string;
    password: string;
    photo: File | null;
    newsletter: boolean;
    birthday?: string | null;
}

export const SignUpIV: SignUpProps = {
    fullName: '',
    email: '',
    username: '',
    password: '',
    photo: null,
    newsletter: false,
    birthday: null,
};