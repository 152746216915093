import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { API } from "@utils/api/API_INSTANCE";
import { useTranslation } from "react-i18next";
import { BsExclamationTriangleFill } from "react-icons/bs";

export const PaymentCancelPage = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const sessionId = params.get("session_id");

        if (!sessionId) {
            setError(t("stripe.missingSession"));
            return;
        }

        const cancelPayment = async () => {
            try {
                await API.get(`/cancel-payment`, { params: { session_id: sessionId } });
            } catch (err) {
                setError(
                    axios.isAxiosError(err) && err.response?.data?.error
                        ? err.response.data.error
                        : t("stripe.failedCancellation")
                );
            }
        };

        cancelPayment();
    }, [location, t]);

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-50 px-4 sm:px-6 lg:px-8">
            <div className="max-w-lg w-full bg-white rounded-lg shadow-lg p-8 text-center">
                {/* Warning Message */}
                <div className="flex flex-col items-center">
                    <BsExclamationTriangleFill className="text-red-500 w-12 h-12 mb-4" />
                    <h2 className="text-2xl font-semibold text-gray-900">{t("stripe.paymentCanceled")}</h2>
                    <p className="text-gray-600 mt-2">{t("stripe.paymentCanceledMessage")}</p>
                </div>

                {/* Error Message */}
                {error && (
                    <div className="mt-6 p-4 bg-red-100 text-red-700 rounded-lg">
                        <h3 className="text-lg font-semibold">{t("stripe.cancellationError")}</h3>
                        <p className="text-sm">{error}</p>
                    </div>
                )}

                {/* Retry Payment Button */}
                <div className="mt-6">
                    <button
                        onClick={() => navigate("")}
                        className="px-6 py-3 bg-red-600 text-white font-medium rounded-lg shadow hover:bg-red-700 transition duration-300"
                    >
                        {t("stripe.retryPayment")}
                    </button>
                </div>
            </div>
        </div>
    );
};
