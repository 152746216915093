import { components, translation } from '@context/Index'
import React from 'react'

const OnlineCheckout = () => {
  let { Header, Breadcrumbs, OnlineOrder } = components
  let {t} = translation.useTranslation().i18n
  return (
    <>
      <Header title={t("checkout.title")}/>
      <Breadcrumbs />
      <OnlineOrder />
    </>
  )
}

export default OnlineCheckout