import React from 'react'
import { components, translation, formikHook, hooks } from "@context/Index"
import { Link } from 'react-router-dom'
import {loginIV} from "@utils/initValues/LoginIV"
import { loginVS } from '@utils/schemas/LoginSchema'
import { useAuth } from '@context/AuthContext'
import { ENUMS } from '@helpers/ENUMS'




const LoginForm:React.FC = () => {

    const {login, loading, loadingUser} = useAuth()
    const { useLocalStorage } = hooks
    const { useFormik } = formikHook
    const {t} = translation.useTranslation().i18n
    const {LoginInput, ActivityIndicator} = components
    const [rememberMe,setRememberMe] = React.useState<boolean>(false)
    const inputClass:string = "w-full outline-none p-3 border border-brand-darker rounded-full hover:cursor-text"
    
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: loginIV,
        validationSchema:loginVS,
        onSubmit: (values) => {
            login(values, rememberMe)
        }
    })

    let {
        handleChange,
        handleBlur,
        errors,
        handleSubmit
    } = formik

  return (
    <div className="flex flex-row justify-center items-center lg:container mx-auto">
        <form 
        onSubmit={handleSubmit}
        className="flex flex-col justify-center items-center gap-1 p-8 w-full sm:w-2/3 lg:w-1/2"
        >
            <LoginInput 
                label={t('login.email') + " / " + t('login.username')}
                type="text" 
                name="email"
                id="email"
                className={inputClass}
                required
                onChange={handleChange('email')}
                onBlur={handleBlur('email')}
                errors={errors.email}
            />
            <LoginInput 
                label={t('login.password')}
                type="password" 
                name="password"
                id="password"
                className={inputClass}
                required
                onChange={handleChange('password')}
                onBlur={handleBlur('password')}
                errors={errors.password}
            />
            <div className="flex flex-row justify-between items-center w-full py-3 gap-3 flex-wrap">
            <div className="flex flex-row flex-grow  items-center gap-2">
                <input 
                    type="checkbox" 
                    name="rememberMe" 
                    id="rememberMe" 
                    checked={rememberMe}
                    onChange={()=>setRememberMe(!rememberMe)} 
                />
                <label htmlFor="rememberMe" className="text-sm opacity-50">{t('login.rememberMe')}</label>
            </div>
            <Link to="/resetPassword" className="text-sm opacity-50 flex-grow">{t('login.forgotPassword')}</Link>
            <button type="submit" disabled={loading} className='bg-gold py-3 px-6 rounded-full flex flex-row w-full md:w-auto flex-grow items-center justify-center'>
                {(loading || loadingUser) ? <ActivityIndicator size={25} color={ENUMS.COLORS.BRAND_DARK}/> : <p>{t('login.login')}</p>}
            </button>
            </div>
            <div className="flex flex-row justify-center items-center gap-1 text-xl">
                <p>{t('login.noAccount')}</p>
                <Link to="/sign-up" className="underline">{t('login.signUp')}</Link>   
            </div>
        </form>
    </div>
  )
}

export default LoginForm