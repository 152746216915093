import { API } from "./API_INSTANCE"

export type postNewPartnerType = (body: any) => Promise<any>

export const postNewPartner: postNewPartnerType = (body) => {
    return API.post('/partner_requests', body, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    })
        .then((res) => res.data)
        .catch((err) => { throw err })
}