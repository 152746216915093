import React from 'react'
import Icons from '@helpers/IconsHelper';
import {
  brandLogo,
  components,
  translation,
  animation,
  hooks
} from '@context/Index'
import { useWindowWidthContext } from '@context/WindowWidthContext';
import { useAuth } from '@context/AuthContext';
import { useBaseContext } from '@context/baseContext';
import { Link, NavLink } from 'react-router-dom';


function NavBar() {

  let { LuUser2, MdFavoriteBorder, RxHamburgerMenu } = Icons
  let { Menu, ProfileButton, CartNavBtn } = components
  let { windowWidth } = useWindowWidthContext()
  let { t } = translation.useTranslation().i18n
  let { categories } = useBaseContext()
  let [expandResponsive, setExpandResponsive] = React.useState<boolean>(false)
  let scrollUp = hooks.useNavBarScrollControl()
  let { userToken, user } = useAuth()
  let currentScrollY = window.scrollY

  let relations = [
    { tag: t('navBar.wholeSaleMerchant'), uri: "/become-wholesalemerchant" },
    { tag: t('navBar.whiteMaterialMerchant'), uri: "/become-whitematerialmerchant" },
    { tag: t('navBar.ambassador'), uri: "/become-ambassador" }
  ]



  let MenuItems = [
    { name: t('navBar.home'), uri: "/" },
    { name: t('navBar.store'), uri: "/store" },
    {
      name: t('navBar.categories'),
      uri: "/categories",
      children: categories,
      renderItem: (item: any) => <Link to={`/store/${item?.categoryTranslates[0]?.slug}`} state={item}>
        <div className='hover:bg-brand-darker hover:text-gold p-4 transition-color duration-300 text-sm'>
          {item?.categoryTranslates[0]?.title}
        </div>
      </Link>
    },
    {
      name: t('navBar.relations'),
      uri: "/privacy",
      children: relations,
      renderItem: (item: any) => <Link to={`${item?.uri}`} className='text-nowrap'>
        <div className='hover:bg-brand-darker hover:text-gold p-4 transition-color duration-300 text-sm'>
          {item?.tag}
        </div>
      </Link>
    },
    { name: t('navBar.aboutUs'), uri: "/about-us" },
    { name: t('navBar.contactUs'), uri: "/contact" },

  ]

  let menuButtons = [
    {
      title: t('navBar.menuButtons.menu'),
      Icon: () => <RxHamburgerMenu className={"text-brand-darker w-full h-full m-2"} />,
      action: () => setExpandResponsive(!expandResponsive),
      inheritedClasses: "sm:hidden"
    },
    {
      title: userToken ? t('navBar.menuButtons.profile') : t('navBar.menuButtons.login'),
      Icon: () => userToken && user?.photo ? <ProfileButton /> : <LuUser2 className="text-brand-darker w-full h-full m-2" />,
      uri: userToken ? "/profile" : "/login"
    },
    {
      title: t('navBar.menuButtons.favorits'),
      Icon: () => <MdFavoriteBorder className="text-brand-darker w-full h-full m-2" />,
      uri: "/favorits"
    },
    {
      title: t('navBar.menuButtons.cart'),
      Icon: () => <CartNavBtn />,
    },
  ]

  React.useEffect(() => { windowWidth >= 640 ? setExpandResponsive(true) : setExpandResponsive(false) }, [windowWidth])



  return (
    <div className={`flex flex-wrap z-40 sticky top-0 transition-transform justify-between p-4 md:py-3 md:px-20 bg-white items-center duration-300 ${scrollUp ? 'translate-y-0' : '-translate-y-full'}`}>
      <div className="flex-grow flex justify-center items-center xl:flex-grow-0 p-2 mx-4">
        <NavLink to='/'>
          <img src={brandLogo} alt="Argori logo" className='w-28' />
        </NavLink>
      </div>
      <Menu items={MenuItems} buttons={menuButtons} expandResponsive={expandResponsive} />
    </div>
  )
}

export default NavBar