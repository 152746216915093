import React from 'react';

const BecomeAmbassadorDescFR = () => {
  return (
    <div className="container mx-auto px-4 py-8 text-brand-darker">
      <h1 className="text-3xl font-bold mb-6 text-center">Devenez Distributeur !</h1>
      
      <div className="prose prose-lg max-w-none">
        <h2 className="text-2xl font-bold mt-8 mb-4 capitalize">
          Vous & Nous ⇢ Partenaires
        </h2>
        <p>
          Argori distribue des cosmétiques d’huile d’argan, huile de pépins de figue de barbarie et eau de rose naturelles & biologiques ainsi qu’une gamme de produits de beauté.
        </p>
        <p>
          Conciliant développement économique et développement éthique pour le respect de nos consommateurs et de nos distributeurs, nous avançons ensemble pour une démarche engagée, raisonnée et qualitative.
        </p>
        <p>
          En tant que professionnelles de la beauté, vous souhaitez associer votre expertise à des produits responsables et efficaces.
        </p>
        <p>
          Des offres promotionnelles régulières, étroitement liées à la saisonnalité, pour vous permettre d’offrir une réponse Argori en toute saison.
        </p>
        <p>
          La cosmétique originelle puise dans ses ingrédients des propriétés extraordinaires qui apportent des réponses ciblées et efficaces.
        </p>
        <p>
          Suscitez la création de liens émotionnels avec votre clientèle et plongez au cœur de votre métier en offrant des voyages sensoriels et originels empreints d’évasion !
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Évolution Positive</h2>
        <p>
          Du marché grandissant des cosmétiques naturels & bio.
        </p>
        <p>
          « Des consommateurs de plus en plus nombreux à vouloir comprendre la composition de leurs produits. »
        </p>
        <p>
          Nous sélectionnons des matières premières originelles et durables : pour des clients en quête d’authenticité !
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Produits Naturels & Bio</h2>
        <ul className="list-disc pl-6">
          <li>
            <strong>Transparence :</strong> Notre volonté est de vous faire partager notre métier et de vous décrypter les compositions de nos produits. Notre premier argument : nos formules généreuses aux process authentiques.
          </li>
          <li>
            <strong>Qualité :</strong> Nos produits sont extraits à froid selon un procédé développé en interne. Économe en énergie donc plus durable, il nous permet de ne pas chauffer les matières premières pour ne pas en dénaturer leurs propriétés actives. Notre recherche constante pour optimiser la qualité nous oblige à adopter des modes opératoires plus techniques.
          </li>
          <li>
            <strong>Efficacité :</strong> Des formules généreuses pour un maximum d’efficacité : optimisation dès la base de formulation, incorporation de matières premières riches et actives. Formulé sans additifs, ni agents de remplissage.
          </li>
          <li>
            <strong>Teneurs importantes :</strong> D’actifs ciblés et objectivés de haute qualité en provenance de nos experts en biotechnologie et beauté « efficacités prouvées ».
          </li>
          <li>
            <strong>Naturalité & Sourcing :</strong> Des formules respectueuses de la peau et de l’environnement aux matières premières prodigieuses naturelles & bio ! Nous sélectionnons minutieusement des ingrédients riches en propriétés actives en collaborant avec des experts et des partenaires de choix.
          </li>
        </ul>

        <h2 className="text-2xl font-bold mt-8 mb-4">Catégories ⇢ Beauté Du Visage, Des Cheveux & Du Corps</h2>
        <p>
          Nous avons développé des catégories de soins innovants intégrant nos multiples spécificités.
        </p>
        <p>
          Nous vous proposons un large choix de soins complets pour tous les objectifs beauté, adaptés aux techniques esthétiques. Des soins performants pour une réponse ciblée et efficace. Nos compositions aux identités originelles puisent toutes leurs richesses des bienfaits de la nature.
        </p>
        <p>
          Nos créations valorisent les propriétés organoleptiques de nos ingrédients, leurs caractéristiques perçues par les sens, la vue, l’odorat, le toucher dans la perspective de créer un lien émotionnel lors de vos soins avec la nature.
        </p>
      </div>
    </div>
  );
};

export default BecomeAmbassadorDescFR;