import React from 'react';

const AboutUsFR = () => {
    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-6 text-center">Qui sommes-nous ?</h1>

            <div className="prose prose-lg max-w-none">
                <h2 className="text-2xl font-bold mt-8 mb-4">Notre Histoire</h2>
                <p>
                    C’est avant tout une histoire de passionnées… Depuis toujours, les fondatrices de la marque ARGORI sont émerveillés par l’incroyable richesse de la nature dont nous tirons des produits aux vertus exceptionnelles. Oui mais … Comment reconnaitre un bon produit aujourd’hui avec la surabondance d’offres? À qui faire confiance? Les produits sont-ils vraiment naturels et efficaces? Pas si simple finalement de trouver de bons produits qui respectent leurs engagements et qui soient accessibles au niveau prix…
                </p>
                <p>
                    C’est ce défi que nous nous sommes lancés, vous offrir une gamme d’ingrédients NATURELS à des tarifs qui ont du sens !
                </p>

                <h2 className="text-2xl font-bold mt-8 mb-4">Pourquoi les huiles végétales ?</h2>
                <p>
                    Les huiles végétales sont probablement les premiers produits de beauté utilisés par l’humanité. À partir de graines, de fruits et de fleurs, que l’on presse mécaniquement à froid, on obtient une huile pure et vierge, et c’est tout ! Ce savoir-faire permet de préserver l’intégrité des principes actifs et de profiter pleinement des bienfaits présents à l’état naturel ! Chez <a href="https://www.argori.com" className="text-blue-600 hover:text-blue-800">argori.com</a>, nous avons développé une gamme d’ingrédients naturels centrée sur le végétal, pour vous aider à redonner du sens à votre beauté. Des produits simples, exclusivement issus de la nature, pour une efficacité durable inégalée et une sensation de bien-être garantie avec nos produits vraiment exceptionnels.…
                </p>

                <h2 className="text-2xl font-bold mt-8 mb-4">Notre engagement quotidien</h2>
                <p>
                    Pour des produits respectueux
                </p>
                <p>
                    Chaque jour, notre équipe met tout son cœur non seulement à chercher des produits respectueux et efficaces, mais également à recevoir et traiter vos commandes, produire et emballer chaque article avec soin.
                </p>
                <p>
                    Notre mission ? Transformer chaque utilisation en un moment de pur bonheur, que ce soit par des parfums qui subliment votre journée ou par des soins qui transforment votre routine de salle de bain. Créez un LIEN EMOTIONNEL avec votre produit et plongez au cœur de la nature avec un voyage sensoriel et originel empreints d’évasion !
                </p>

                <h2 className="text-2xl font-bold mt-8 mb-4">Tous ambassadeurs de la nature !</h2>
                <p>
                    Nous souhaitons démocratiser l’utilisation de produits naturels, sains pour notre corps et pour notre planète. Nous travaillons activement chaque jour pour améliorer notre offre et nos conditions. Comme nous, faites confiance à la nature pour prendre soin de vous, et ensemble redonnons du sens à notre beauté.
                </p>

                <h2 className="text-2xl font-bold mt-8 mb-4">Informations de contact</h2>
                <p>
                    Pour toute information ou question, notre service clientèle est à votre disposition :
                </p>
                <ul className="list-disc pl-6">
                    <li>Par e-mail : <a href="mailto:argori.info@gmail.com" className="text-blue-600 hover:text-blue-800">argori.info@gmail.com</a>, <a href="mailto:info@argori.com" className="text-blue-600 hover:text-blue-800">info@argori.com</a>, ou <a href="mailto:contact@argori.com" className="text-blue-600 hover:text-blue-800">contact@argori.com</a>.</li>
                    <li>Par téléphone : +33 (0) 7.68.96.85.07 & +33 (0) 6.95.83.91.20.</li>
                </ul>
            </div>
        </div>
    );
};

export default AboutUsFR;