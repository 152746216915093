import React, { useState, useEffect } from 'react';
import { components, translation } from "@context/Index";
import { useLocation, useParams } from 'react-router-dom';
import { ENUMS } from '@helpers/ENUMS';
import { useQuery } from '@tanstack/react-query';
import { getProduct } from '@utils/api/ProductsApi';

const ProductDetails: React.FC = () => {
  const { Header, Breadcrumbs, ProductDetailsBody, Loading, Reviews } = components;
  const params = useParams();
  const { slug } = params;
  const { state } = useLocation();
  const { language, t } = translation.useTranslation().i18n;

  const [product, setProduct] = useState<any>();
  const [selectedVariant, setSelectedVariant] = useState<any>();
  const [languageChanged, setLanguageChanged] = useState(false);

  let currentlanguage = state.productTranslates[0].language.code
  let i18Language = language.split('-')[0]

  const { data, isLoading: isLoadingProducts } = useQuery({
    queryKey: ['product', language, slug],
    queryFn: () => getProduct(i18Language, slug as string),
    enabled: !state || languageChanged,
  });

  useEffect(() => {
    if (currentlanguage !== i18Language) {
      setLanguageChanged(true);
    }
  }, [language]);

  useEffect(() => {
    setProduct(undefined);
    setSelectedVariant(undefined);
  }, [language]);

  useEffect(() => {
    if (state && !languageChanged) {
      setProduct(state);
      setSelectedVariant(state.type === ENUMS.PRODUCT.TYPE.FINAL ? state : state?.child?.[0]);
    } else if (data?.[0]) {
      setProduct(data[0]);
      setSelectedVariant(data[0].type === ENUMS.PRODUCT.TYPE.FINAL ? data[0] : data[0]?.child?.[0]);
    }
  }, [state, data]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [slug, language]);

  return (
    <div>
      <Header title={selectedVariant?.label} background={ENUMS.COLORS.BANNER_GOLD} />
      <Breadcrumbs />
      {!isLoadingProducts && product
        ? <ProductDetailsBody product={product} selectedVariant={selectedVariant} setSelectedtVariant={setSelectedVariant} />
        : <Loading item={t('product')} />}
      <Reviews productId={selectedVariant?.id} />
    </div>
  );
};

export default ProductDetails;