import React from 'react';

const DeliveryAndRightsEN = () => {
    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-6 text-center">Delivery and Rights</h1>

            <div className="prose prose-lg max-w-none">
                <p>
                    We will endeavor to deliver the goods within the estimated timeframe; however, delays are sometimes unavoidable due to unforeseen factors. We do not accept any liability for any delay or non-delivery of products within the estimated timeframe.
                </p>

                <h2 className="text-2xl font-bold mt-8 mb-4">Errors, Inaccuracies, and Omissions</h2>
                <p>
                    Occasionally there may be information on our website or in the Service that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, promotions, offers, product shipping charges, transit times and availability. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information in the Service or on any related website is inaccurate at any time without prior notice (including after you have submitted your order).
                </p>
                <p>
                    We undertake no obligation to update, amend or clarify information in the Service or on any related website, including without limitation, pricing information, except as required by law. No specified update or refresh date applied in the Service or on any related website, should be taken to indicate that all information in the Service or on any related website has been modified or updated.
                </p>

                <h2 className="text-2xl font-bold mt-8 mb-4">Prohibited Uses</h2>
                <p>
                    In addition to other prohibitions as set forth in the Terms and Conditions of Use, you are prohibited from using the site or its content:
                </p>
                <ol className="list-decimal pl-6">
                    <li>For any unlawful purpose;</li>
                    <li>To solicit others to perform or participate in any unlawful acts;</li>
                    <li>To violate any international, federal, provincial or state regulations, rules, laws, or local ordinances;</li>
                    <li>To infringe upon or violate our intellectual property rights or the intellectual property rights of others;</li>
                    <li>To harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability;</li>
                    <li>To submit false or misleading information;</li>
                    <li>To upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet;</li>
                    <li>To collect or track the personal information of others;</li>
                    <li>To spam, phish, pharm, pretext, spider, crawl, or scrape;</li>
                    <li>For any obscene or immoral purpose; or</li>
                    <li>To interfere with or circumvent the security features of the Service or of any related website, other websites, or the Internet.</li>
                </ol>
                <p>
                    We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses.
                </p>

                <h2 className="text-2xl font-bold mt-8 mb-4">Disclaimer of Warranties; Limitation of Liability</h2>
                <p>
                    We do not guarantee, represent or warrant that your use of our service will be uninterrupted, timely, secure or error-free.
                </p>
                <p>
                    We do not warrant that the results that may be obtained from the use of the service will be accurate or reliable.
                </p>
                <p>
                    You agree that from time to time we may remove the service for indefinite periods of time or cancel the service at any time, without notice to you.
                </p>
                <p>
                    You expressly agree that your use of, or inability to use, the service is at your sole risk. The service and all products and services delivered to you through the service are (except as expressly stated by us) provided 'as is' and 'as available' for your use, without any representation, warranties or conditions of any kind, either express or implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement.
                </p>
                <p>
                    Transmitted or otherwise made available via the service, even if advised of their possibility. Because some states or jurisdictions do not allow the exclusion or the limitation of liability for consequential or incidental damages, in such states or jurisdictions, our liability shall be limited to the maximum extent permitted by law.
                </p>

                <h2 className="text-2xl font-bold mt-8 mb-4">Indemnification</h2>
                <p>
                    You agree to indemnify, defend and hold harmless Argori and our parent, subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns and employees, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third-party due to or arising out of your breach of these Terms and Conditions of Service or the documents they incorporate by reference, or your violation of any law or the rights of a third-party.
                </p>

                <h2 className="text-2xl font-bold mt-8 mb-4">Severability</h2>
                <p>
                    In the event that any provision of these Terms and Conditions of Service is determined to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms and Conditions of Service. Such determination shall not affect the validity and enforceability of any other remaining provisions.
                </p>

                <h2 className="text-2xl font-bold mt-8 mb-4">Termination</h2>
                <p>
                    The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all purposes.
                </p>
                <p>
                    These Terms and Conditions of Service are effective unless and until terminated by either you or us. You may terminate these Terms and Conditions of Service at any time by notifying us that you no longer wish to use our Services, or when you cease using our website.
                </p>
                <p>
                    If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of these Terms and Conditions of Service, we also may terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the date of termination; and/or accordingly may deny you access to our Services (or any part thereof).
                </p>

                <h2 className="text-2xl font-bold mt-8 mb-4">Entire Agreement</h2>
                <p>
                    The failure of us to exercise or enforce any right or provision of these Terms and Conditions of Service shall not constitute a waiver of such right or provision.
                </p>
                <p>
                    These Terms and Conditions of Service and any policies or operating rules posted by us on this site or in respect to The Service constitutes the entire agreement and understanding between you and us and govern your use of the Service, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms and Conditions of Service).
                </p>
                <p>
                    Any ambiguity in the interpretation of these terms of use shall not be construed against the drafting party.
                </p>

                <h2 className="text-2xl font-bold mt-8 mb-4">Modifications to the Terms of Use</h2>
                <p>
                    You can review the most current version of the Terms and Conditions of Use at any time on this page.
                </p>
                <p>
                    We reserve the right, at our sole discretion, to update, change or replace any part of these Terms of Use by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website or the Service following the posting of any changes to these Terms and Conditions of Use constitutes acceptance of those changes.
                </p>

                <h2 className="text-2xl font-bold mt-8 mb-4">Contact Information</h2>
                <p>
                    For any information or questions, our customer service is at your disposal:
                </p>
                <ul className="list-disc pl-6">
                    <li>By email: <a href="mailto:argori.info@gmail.com" className="text-blue-600 hover:text-blue-800">argori.info@gmail.com</a>, <a href="mailto:info@argori.com" className="text-blue-600 hover:text-blue-800">info@argori.com</a>, or <a href="mailto:contact@argori.com" className="text-blue-600 hover:text-blue-800">contact@argori.com</a>.</li>
                    <li>By phone: +33 (0) 7.68.96.85.07 & +33 (0) 6.95.83.91.20 (from Monday to Friday from 9am to 6pm).</li>
                </ul>
            </div>
        </div>
    );
};

export default DeliveryAndRightsEN;