import { components, translation } from '@context/Index'
import React from 'react'

const ReturnPolicy = () => {

  const { ReturnPolicyFR, ReturnPolicyEN } = components
  const { language } = translation.useTranslation().i18n

  return (
    <div>
      { language.split('-')[0] === "en" ? <ReturnPolicyEN /> : <ReturnPolicyFR /> }
    </div>
  )
}

export default ReturnPolicy