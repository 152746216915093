import { components } from '@context/Index'
import React from 'react'

const ProfileBody = () => {

    const { ProfileTabs, Orders, ManageAddresses, ProfileData } = components
    const [selectedTab, setSelectedTab] = React.useState("Orders")

    return (
        <div className='lg:container lg:mx-auto'>
            <div className="grid grid-cols-1 lg:grid-cols-4 gap-3 p-5">
                <div className="col-span-1">
                    <ProfileTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
                </div>
                <div className="col-span-1 lg:col-span-3">
                    {selectedTab === "Orders" && <Orders/>}
                    {selectedTab === "Addresses" && <ManageAddresses/>}
                    {selectedTab === "Account details" && <ProfileData/>  }
                </div>
            </div>
        </div>
    )
}

export default ProfileBody