import { useBaseContext } from '@context/baseContext';
import { animation, translation } from '@context/Index';
import { returnOrderStatusBG, returnPaymentStatusBG } from '@helpers/Helpers';
import { OrderModalProps, TranslatableFieldProps } from '@utils/types/orderType';
import React from 'react';



const OrderDetailsModal: React.FC<OrderModalProps> = ({ order, isOpen, onClose }) => {

    const { useAnimate, usePresence } = animation
    const [scope, animate] = useAnimate()
    const [isPresent, safeToRemove] = usePresence()
    const { getCurrencySymbol } = useBaseContext()

    React.useEffect(() => {
        if (isPresent) {
            const enterAnimation = async () => {
                await animate([
                    [scope.current, { opacity: 1 }, { duration: .2 }],
                ])
            }
            enterAnimation()
        } else {
            const exitAnimation = async () => {
                await animate([
                    [scope.current, { opacity: 0 }, { duration: .2 }]
                ])
                safeToRemove()
            }
            exitAnimation()
        }
    }, [isPresent])

    if (!isOpen) return null;
    const { t } = translation.useTranslation().i18n


    const TranslatableField: React.FC<TranslatableFieldProps> = ({ labelKey, value, valueStyle }) => {
        return (
            <div className='flex gap-1'>
                <span className="font-semibold">{t(labelKey)} : </span>
                <span className={`${valueStyle} opacity-70`}>{value}</span>
            </div>
        );
    };

    return (
        // Modal wrapper
        <div className="fixed inset-0 z-50 flex items-center justify-center opacity-0" ref={scope} key="orderDetailsModal">
            {/* Background overlay */}
            <div className="fixed inset-0 bg-black opacity-50" onClick={onClose}></div>

            {/* Modal content */}
            <div className="relative bg-white rounded-lg shadow-lg mx-4 p-6 z-10">
                {/* Close button */}
                <div className='flex flex-row items-center justify-between'>
                    <h2 className="text-2xl font-bold">Order Details</h2>
                    <button
                        onClick={onClose}
                        className="text-gray-600 hover:text-gray-800 text-2xl"
                        aria-label="Close modal"
                    >
                        &times;
                    </button>

                </div>
                <div className='grid grid-cols-1 lg:grid-cols-3 p-4 gap-5'>
                    {/* Order basic info */}
                    <div className="col-span-3 lg:col-span-1">
                        <TranslatableField labelKey="orders.orderDetails.price" value={order.price + " " + getCurrencySymbol(order?.orderItems[0]?.product?.geoInfo?.currency)} />
                        <TranslatableField labelKey="orders.orderDetails.itemsQuantity" value={order.itemsQuantity} />
                        <TranslatableField labelKey="orders.orderDetails.comment" value={order.comment} />
                        <TranslatableField labelKey="orders.orderDetails.orderStatus" value={t("orders.STATUS." + order?.orderStatus)} valueStyle={returnOrderStatusBG(order?.orderStatus) + ' px-3 rounded-full'} />
                        <TranslatableField labelKey="orders.orderDetails.paymentMethod" value={order.paymentMethod} />
                        <TranslatableField labelKey="orders.orderDetails.paymentStatus" value={t("payments.STATUS." + order?.paymentStatus)} valueStyle={returnPaymentStatusBG(order?.paymentStatus) + ' px-3 rounded-full'}/>
                        <TranslatableField labelKey="checkout.billingAddress" value={order?.billingAddress?.address + ", " + order?.billingAddress?.ville} />
                        <TranslatableField labelKey="checkout.shippingAddress" value={order?.deliveryAddress?.address + ", " + order?.deliveryAddress?.ville} />
                    </div>

                    {/* Order items list */}
                    <div className="col-span-3 lg:col-span-2">
                        <h3 className="text-xl font-semibold mb-3">Order Items</h3>
                        <div className="space-y-4 max-h-96 overflow-auto">
                            {order.orderItems.map((item: any) => (
                                <div key={item.id} className="border rounded p-4">
                                    <TranslatableField labelKey="orders.orderModalItem.productLabel" value={item.product.child[0].label} />
                                    <TranslatableField labelKey="orders.orderModalItem.reference" value={item.product.reference} />
                                    <TranslatableField labelKey="orders.orderModalItem.barcode" value={item.product.barcode} />
                                    <TranslatableField labelKey="orders.orderModalItem.quantity" value={item.quantity} />
                                    <TranslatableField labelKey="orders.orderModalItem.price" value={item.price + " " + getCurrencySymbol(item.product?.geoInfo?.currency)} />
                                </div>
                            ))}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default OrderDetailsModal;
