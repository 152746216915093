//Custom hooks
import { useLocalStorage } from "@hooks/LocalStorage";
import useClickOutside from "@hooks/useClickOutside";
import useClickInside from "@hooks/useClickInside";
import useNavBarScrollControl from "@hooks/NavBarScrollControl";

//Animations
import { useAnimate, AnimatePresence, usePresence, useTransform, useMotionValue } from "framer-motion";

//Formik
import { useFormik } from "formik";

//Translation i18n
import { useTranslation } from "react-i18next"

//Drag scroll hook
import { useDraggable } from "react-use-draggable-scroll";

//Svgs
import Fr from "@resources/svg/flags/Fr"
import Uk from "@resources/svg/flags/Uk"

//Pngs
import login from "@resources/pngs/banners/login/login.png"
import CMI from "@resources/payments/logo_cmi.png"
import STRIPE from "@resources/payments/secure_code_logo.png"

//Components
import MenuItem from '@components/navBar/MenuItem'
import MenuButton from '@components/navBar/MenuButton'
import LangSelect from '@components/navBar/LangSelect'
import Menu from '@components/navBar/Menu'
import DropDownMenu from '@components/navBar/DropDownMenu'
import FlagsDropdown from '@components/navBar/FlagsDropdown'
import MenuItems from '@components/navBar/MenuItems'
import NavBar from '@components/navBar/NavBar';
import Loading from "@components/loading/Loading";
import Layout from "@layouts/Layout"
import Header from "@components/header/Header"
import Breadcrumbs from "@components/breadcrumbs/Breadcrumbs"
import LoginForm from "@components/login/LoginForm";
import LoginInput from "@components/login/LoginInput";
import ProfileButton from "@components/profile/ProfileButton";
import CategoryTile from "@components/categories/CategoryTile";
import Categories from "@components/categories/Categories";
import ActivityIndicator from '@components/loading/ActivityIndicator'
import SkeletonLoader from "@components/loading/SkeletonLoader"
import Products from "@components/products/Products";
import ProductTile from "@components/products/ProductTile";
import ProductDetailsBody from "@components/products/ProductDetailsBody";
import Add2Cart from "@components/products/Add2Cart";
import DescriptionElement from "@components/products/DescriptionElement";
import ProductSlider from "@components/products/ProductSlider";
import { Thumb } from "@components/carousel/productsCarousel/CarouselThumbsButton"
import Carousel from "@components/carousel/productsCarousel/Carousel";
import { CategoriesCarousel } from "@components/carousel/categoriesCarousel/CategoriesCarousel"
import Footer from "@components/footer/Footer"
import DescAndIcons from "@components/footer/DescAndIcons"
import ContactData from "@components/footer/ContactData"
import FooterCategories from "@components/footer/FooterCategories"
import FooterLinks from "@components/footer/FooterLinks"
import CartNavBtn from "@components/cart/cartNavBtn";
import CartModal from "@components/cart/CartModal";
import Select from 'react-select';
import NewAddressModal from "@components/order/NewAddress";
import Slider from '@components/slider/Slider';
import UnderSlider from "@components/underSLider/UnderSlider";
import UnderSliderItem from "@components/underSLider/UnderSliderItem";
import Payments from "@components/footer/Payments";
import ProfileTab from "@components/profile/ProfileTab";
import ProfileTabs from "@components/profile/ProfileTabs";
import ProfileBody from "@components/profile/ProfileBody";
import Orders from "@components/orders/Orders";
import EmptyData from "@components/empty/emptyData";
import OrderDetailsModal from "@components/orders/OrderDetailsModal";
import ManageAddresses from "@components/manageAddresses/manageAddresses";
import ProfileImage from "@components/profile/ProfileImage";
import ProfileInfo from "@components/profile/ProfileInfo";
import ProfileData from "@components/profile/ProfileData";
import ReviewCard from "@components/reviews/ReviewCard";
import Reviews from "@components/reviews/Reviews";
import { Rating } from 'react-simple-star-rating'
import NewReview from "@components/reviews/newReview";
import { ReviewsCarousel } from "@components/carousel/reviewsCarousel/ReviewsCarousel";
import PromoCode from "@components/promoCode/PromoCode";
import BecomePartnerForm from "@components/becomePartnerForm/becomePartnerForm";
import SignUpForm from "@components/signUpForm/signUpForm";
import TermsAndConditionsFR from "@components/termsAndConditions/termsAndConditionsFR";
import TermsAndConditionsEN from "@components/termsAndConditions/termsAndConditionsEN";
import PrivacyPolicyEN from "@components/PrivacyPolicy/PrivacyPolicyEN";
import PrivacyPolicyFR from "@components/PrivacyPolicy/PrivacyPolicyFR";
import ReturnPolicyFR from "@components/ReturnPolicy/ReturnPolicyFR";
import ReturnPolicyEN from "@components/ReturnPolicy/ReturnPolicyEN";
import GeneralConditionsFR from "@components/generalConditions/generalConditionsFR";
import GeneralConditionsEN from "@components/generalConditions/generalConditionsEN";
import DeliveryAndRightsEN from "@components/DeliveryAndRights/DeliveryAndRightsEN";
import DeliveryAndRightsFR from "@components/DeliveryAndRights/DeliveryAndRightsFR";
import AboutUsEN from "@components/aboutUs/aboutUsEN";
import AboutUsFR from "@components/aboutUs/aboutUsFR";
import QualityAndCertificationFR from "@components/QualityAndCertification/QualityAndCertificaitonFR";
import QualityAndCertificationEN from "@components/QualityAndCertification/QualityAndCertificaitonEN";
import BecomeWholesaleDescFR from "@components/becomePartnerForm/becomePartnerDescriptions/becomeWholeSaleDesc/becomeWholesaleDescFR";
import BecomeWholesaleDescEN from "@components/becomePartnerForm/becomePartnerDescriptions/becomeWholeSaleDesc/becomeWholesaleDescEN";
import BecomeAmbassadorDescEN from "@components/becomePartnerForm/becomePartnerDescriptions/becomeAbassadorDesc/becomeAmbassadorDescEN";
import BecomeAmbassadorDescFR from "@components/becomePartnerForm/becomePartnerDescriptions/becomeAbassadorDesc/becomeAmbassadorDescFR";
import BecomeWhiteMaterialEN from "@components/becomePartnerForm/becomePartnerDescriptions/becomeWhiteMaterielDesc/becomeWhiteMaterialEN";
import BecomeWhiteMaterialFR from "@components/becomePartnerForm/becomePartnerDescriptions/becomeWhiteMaterielDesc/becomeWhiteMaterialFR";
import IngredientsEN from "@components/ingredients/ingredientsEN";
import IngredientsFR from "@components/ingredients/ingredientsFR";
import AmbassadorSubHeaderElement from "@components/ambassadorSubHeader/AmbassadorSubHeaderElement";
import AmbassadorSubHeader from "@components/ambassadorSubHeader/AmbassadorSubHeader";

//Pages
import Home from '@pages/home/Home';
import Contact from "@pages/contact/Contact";
import AboutUs from '@pages/aboutUs/AboutUs';
import Profile from "@pages/profile/Profile";
import NotAuthorized from '@pages/notAuthorized/NotAuthorized';
import Login from "@pages/login/Login";
import ProductDetails from "@pages/product/ProductDetails"
import ProductsPage from "@pages/products/Products";
import OfflineCheckout from "@pages/checkout/OfflineCheckout";
import OnlineCheckout from "@pages/checkout/OnlineCheckout";
import OnlineOrder from "@components/order/OnlineOrder";
import OrderDetails from "@components/order/OrderDetails";
import OnlineOrderForm from "@components/order/OnlineOrderForm";
import OfflineOrder from "@components/order/OfflineOrder";
import WholeSaleMerchant from "@pages/becomePartnerOrSeller/WholeSaleMerchant";
import Ambassador from "@pages/becomePartnerOrSeller/Ambassador";
import WhiteMaterialMerchant from "@pages/becomePartnerOrSeller/WhiteMaterialMerchant";
import SignUp from "@pages/signUp/signUp";
import TermsAndConditions from "@pages/termsAndConditions/termsAndConditions";
import PrivacyPolicy from "@pages/privacy-policy/PrivacyPolicy";
import ReturnPolicy from "@pages/return-policy/returnPolicy";
import GeneralConditions from "@pages/generalConditions/generalConditions";
import DeliveryAndRights from "@pages/deliveryAndRights/DeliveryAndRights";
import QualityAndCertifications from "@pages/QualityAndCertification/QualityAndCertification";
import Ingredients from "@pages/ingredients/Ingredients";

//!exports must stay below imports

//Images
export { default as brandLogo } from "@resources/logo.png"
export { default as defaultProfile } from "@resources/profile/default-profile.png"
export { default as cod } from "@resources/payments/cod.png"
export { default as card } from "@resources/payments/card.png"
export { default as stripe } from "@resources/payments/stripe.png"
export { default as emptycart } from "@resources/cart/emptycart.png"

export const hooks = {
    useLocalStorage,
    useClickOutside,
    useClickInside,
    useNavBarScrollControl,
    useDraggable
}

export const svgs = {
    Fr,
    Uk
}

export const pngs = {
    login,
    CMI,
    STRIPE
}

export const formikHook = {
    useFormik
}

export const translation = {
    useTranslation
}

export const components = {
    AmbassadorSubHeaderElement,
    AmbassadorSubHeader,
    IngredientsEN,
    IngredientsFR,
    BecomeWhiteMaterialFR,
    BecomeWhiteMaterialEN,
    BecomeAmbassadorDescFR,
    BecomeAmbassadorDescEN,
    BecomeWholesaleDescEN,
    BecomeWholesaleDescFR,
    QualityAndCertificationFR,
    QualityAndCertificationEN,
    AboutUsEN,
    AboutUsFR,
    DeliveryAndRightsEN,
    DeliveryAndRightsFR,
    GeneralConditionsEN,
    GeneralConditionsFR,
    ReturnPolicyEN,
    ReturnPolicyFR,
    PrivacyPolicyFR,
    PrivacyPolicyEN,
    TermsAndConditionsEN,
    TermsAndConditionsFR,
    SignUpForm,
    BecomePartnerForm,
    PromoCode,
    ReviewsCarousel,
    NewReview,
    Rating,
    Reviews,
    ReviewCard,
    ProfileData,
    ProfileInfo,
    ProfileImage,
    ManageAddresses,
    OrderDetailsModal,
    EmptyData,
    Orders,
    ProfileBody,
    ProfileTabs,
    ProfileTab,
    Payments,
    UnderSliderItem,
    UnderSlider,
    Slider,
    OfflineOrder,
    NewAddressModal,
    Select,
    CartModal,
    CartNavBtn,
    FooterLinks,
    FooterCategories,
    ContactData,
    DescAndIcons,
    Footer,
    CategoriesCarousel,
    Carousel,
    Thumb,
    ProductSlider,
    DescriptionElement,
    Add2Cart,
    ProductDetailsBody,
    Products,
    ProductTile,
    SkeletonLoader,
    ActivityIndicator,
    Categories,
    CategoryTile,
    ProfileButton,
    MenuItem,
    MenuItems,
    MenuButton,
    LangSelect,
    Menu,
    DropDownMenu,
    FlagsDropdown,
    NavBar,
    Layout,
    Loading,
    Header,
    Breadcrumbs,
    LoginForm,
    LoginInput,
    OnlineOrder,
    OrderDetails,
    OnlineOrderForm
}

export const pages = {
    Ingredients,
    QualityAndCertifications,
    DeliveryAndRights,
    GeneralConditions,
    ReturnPolicy,
    PrivacyPolicy,
    TermsAndConditions,
    WhiteMaterialMerchant,
    Ambassador,
    SignUp,
    ProductsPage,
    ProductDetails,
    Home,
    AboutUs,
    Contact,
    Profile,
    NotAuthorized,
    Login,
    OnlineCheckout,
    OfflineCheckout,
    WholeSaleMerchant
}

export const animation = {
    useAnimate,
    AnimatePresence,
    usePresence,
    useTransform,
    useMotionValue
}

