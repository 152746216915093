import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

const OfflineCmiFailPage: FC = () => {
    const navigate = useNavigate();

    return (
            <div className="flex flex-col items-center justify-center h-100 px-4">
                <div className="bg-red-100 border border-red-400 text-red-700 px-6 py-4 rounded-lg text-center">
                    <p className="text-lg font-semibold">
                        Votre paiement a échoué! Merci de réessayer plus tard.
                    </p>
                </div>
                <button
                    className="mt-6 px-6 py-3 bg-blue-600 text-white rounded-lg font-semibold shadow hover:bg-blue-700 transition"
                    onClick={() => navigate('/')}
                >
                    Retour à l'accueil
                </button>
            </div>
    );
};

export default OfflineCmiFailPage;
