import React from 'react';

const PrivacyPolicyEN = () => {
    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold text-center mb-6">Privacy Policy</h1>
            
            <p className="mb-4">
                The company S.A.S ARGORI manages the website: <a href="https://www.argori.com" className="text-blue-500 hover:underline">www.argori.com</a>. S.A.S ARGORI is, therefore, responsible for the collection, processing, and use of your personal or professional data and for the compliance of data processing with applicable data protection laws.
            </p>
            
            <p className="mb-4">
                Your trust is very important to us, and we take the issue of data protection very seriously to guarantee you maximum security. We comply with the current European legal data protection regulations, in particular the General Data Protection Regulation (GDPR).
            </p>
            
            <h2 className="text-2xl font-semibold mt-6 mb-4">What Data Do We Process When You Visit Our Website?</h2>
            <p className="mb-4">
                We ask for your personal (B2C) and professional (B2B) contact information, including your name and email address. When a purchase is made, we will also ask for a delivery address and payment information. When you browse our website: <a href="https://www.argori.com" className="text-blue-500 hover:underline">www.argori.com</a>, we automatically receive your computer's IP address, which helps us learn about your browser and operating system.
            </p>
            
            <h2 className="text-2xl font-semibold mt-6 mb-4">Do We Already Collect Data from You When You Visit Our Website?</h2>
            <p className="mb-4">
                When you visit our website: <a href="https://www.argori.com" className="text-blue-500 hover:underline">www.argori.com</a>, our servers temporarily save every access in a log file. We collect and store the following technical data without your intervention until they are automatically deleted, as is the case for any connection to a web server:
            </p>
            <ul className="list-disc list-inside mb-4">
                <li>The IP address of the computer used</li>
                <li>The date and time of access</li>
                <li>The website from which access was made (referrer URL), if applicable with the search term used</li>
                <li>The name and URL of the retrieved file</li>
                <li>The status code (e.g., error message)</li>
                <li>Your computer's operating system</li>
                <li>The browser used (type, version, and language)</li>
                <li>The transmission protocol used (e.g., HTTP/1.1), and ….</li>
                <li>The username for registration/authentication, if applicable</li>
            </ul>
            
            <h2 className="text-2xl font-semibold mt-6 mb-4">What Data Do We Collect When It Comes to B2B?</h2>
            <p className="mb-4">
                Through our website, you have the option to request a quote, information, and advice regarding our products or other goods. For this, we ask you for the following information, in particular, in order to create an account:
            </p>
            <ul className="list-disc list-inside mb-4">
                <li>First and Last Name</li>
                <li>Company Name</li>
                <li>Phone Number</li>
                <li>Email Address</li>
                <li>SIREN or SIRET Number</li>
                <li>VAT Number</li>
            </ul>
            
            <h2 className="text-2xl font-semibold mt-6 mb-4">How Do You Get My Consent?</h2>
            <p className="mb-4">
                When you provide us with personal information to complete a transaction, verify your credit card, place an order, arrange for a delivery, or return a purchase, we imply that you consent to our collecting it and using it for that specific reason only.
            </p>
            
            <h2 className="text-2xl font-semibold mt-6 mb-4">What are cookies? Are cookies really necessary?</h2>
            <p className="mb-4">
                A cookie is a small file which asks permission to be placed on your computer's hard drive. Cookies allow web applications to respond to you as an individual and help us provide you with a better website, by tailoring it to your needs. You can choose to accept or decline cookies, this may prevent you from taking full advantage of the website.
            </p>
            
            <h2 className="text-2xl font-semibold mt-6 mb-4">Are Tracking Tools Really Necessary?</h2>
            <p className="mb-4">
                We use the Google Analytics web analytics service to continuously design and optimize our website to meet your needs. As part of this, pseudonymised user profiles are created and small text files ("cookies") are stored on your computer. The information generated by the cookies about your use of this website is transmitted to the servers of the providers of these services, stored there and then processed by us.
            </p>
            
            <h2 className="text-2xl font-semibold mt-6 mb-4">Storage and Exchange of Data with Third Parties?</h2>
            <p className="mb-4">
                Your data helps us understand your needs and provide you with a better service. We may occasionally send emails regarding new products, special offers or other information which we think you may find interesting using the email address which you have provided. Argori will not disclose any of your information to third parties except where it is part of providing a service.
            </p>
            
            <h2 className="text-2xl font-semibold mt-6 mb-4">Do You Have Any Other Questions?</h2>
            <p className="mb-4">
                You can exercise your right to access, rectification, cancellation, and restriction of data processing as well as your right to data transfer. You have the right to request information about the personal data we store about you. You also have the right to have incorrect data corrected and to request the deletion of your personal data, as long as there is no legal obligation to store it, or authorization that allows us to process this data.
            </p>
            
            <p className="mb-4">
                If you have any questions or comments about our legal notices or data protection, please write to us at <a href="mailto:argori.info@gmail.com" className="text-blue-500 hover:underline">argori.info@gmail.com</a>, <a href="mailto:info@argori.com" className="text-blue-500 hover:underline">info@argori.com</a>, or <a href="mailto:contact@argori.com" className="text-blue-500 hover:underline">contact@argori.com</a>.
            </p>
        </div>
    );
};

export default PrivacyPolicyEN;