import { components, translation } from '@context/Index'
import React from 'react'

const AmbassadorSubHeader = () => {
    const {t} = translation.useTranslation().i18n
    const Steps = [
        {
            stepTitle: t('becomeAmbassador.subHeader.steps.apply'),
            imageUrl: require("@resources/other/image1.png")
        }, 
        {
            stepTitle: t('becomeAmbassador.subHeader.steps.test'),
            imageUrl: require("@resources/other/image2.png")
        },
        {
            stepTitle: t('becomeAmbassador.subHeader.steps.share'),
            imageUrl: require("@resources/other/face.webp")
        }
    ]

    const { AmbassadorSubHeaderElement } = components

    return (
        <div className='flex flex-col gap-4 items-center'>
            <h2 className='text-2xl font-bold text-brand-darker uppercase'>
                {t('becomeAmbassador.subHeader.title')}
            </h2>
            <div className='flex gap-4 items-center justify-center'>
                {Steps?.map((step, index) => (
                    <AmbassadorSubHeaderElement
                        key={index}
                        stepNumber={index + 1}
                        stepTitle={step.stepTitle}
                        imageUrl={step.imageUrl}
                    />
                ))}
            </div>
        </div>
    )
}

export default AmbassadorSubHeader