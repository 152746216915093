import { Navigate, useOutlet } from "react-router-dom";
import { useAuth } from "@context/AuthContext";
import { useState } from "react";
import {components} from "@context/Index"
import MaintenancePage from "@pages/global/MaintenancePage";

export const ProtectedRoute = () => {

    let {Loading, Layout} = components

    let { 
        userToken, 
        // roles: userRoles, 
        loading 
    } = useAuth();

    let outlet = useOutlet();


    // if (loading) {
    //     // Afficher le composant de chargement avec Tailwind CSS
    //     return <Loading />;
    // }

    // if (!userToken) {
    //     // L'utilisateur n'est pas authentifié
    //     return <Navigate to="/" />;
    // }

    // if (roles.length > 0 && !roles.some(role => userRoles.includes(role))) {
    //     // L'utilisateur n'a pas les rôles requis pour accéder à cette route
    //     return <Navigate to="/not-authorized" />;
    // }



    // L'utilisateur est authentifié et a les rôles requis
    return (
        <Layout>
            <div className="min-h-screen mx-auto" >
                {outlet}
            </div>
        </Layout>
        // <MaintenancePage/>
    );
};