import React from 'react';
import logoEecocert from '@resources/certifications-logos/logo-ecocert.webp'
import logoUSDA from '@resources/certifications-logos/logo-usda.png'

const QualityAndCertificationEN = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6 text-center">Quality & Certifications</h1>
      <div>
      </div>
      <div className="prose prose-lg max-w-none space-y-4">
        <h2 className="text-2xl font-bold mt-8 ">Ecocert Certification</h2>
        <p>
          Since its creation in 2023, Argori has been committed to offering products that are respectful of both you and the environment. As a guarantee of quality, we have chosen to offer you certified organic products. ARGORI cosmetic products are certified Organic Cosmetics, to guarantee a pleasurable experience and natural ingredients. To assure consumers of the reliability of its products and the strength of its values, the Argori brand has always chosen to be committed with ECOCERT for the certification and labeling of its products certified organic by ecocert sa, Products certified organic by FR-BIO-01.
        </p>
        <p>
          The Ecocert ecological and organic cosmetic certification guarantees respect for the environment, the consumer, and the promotion of organically sourced natural substances.
        </p>
        <p>
          With this certification, we guarantee the traceability of ingredients right through to the finished product, thus controlling quality throughout the entire production chain, in order to guarantee you the greatest possible transparency.
        </p>

        <h2 className="text-2xl font-bold mt-8 ">Exceptional Quality</h2>
        <p>
          All our prestigious plant ingredients, organic argan oil, organic prickly pear seed oil, and organic rose water, are pure, virgin, 100% natural, and certified Organic according to the ECOCERT/USDA standard for the USA, ECOCERT/EOS for the European Union, and organic agriculture ''AB''.
        </p>
        <p>
          Our organic argan oil (100% organic), prickly pear seed oil (100% organic), and rose water (100% organic) are single-ingredient (no blends, additives, or preservatives have been added). Because the effectiveness of our products is our best argument, we pay close attention to the preservation of active ingredients: all our oils are fresh and have been cold-pressed this year.
        </p>

        <div className="flex flex-col gap-5 lg:items-center lg:flex-row">
          <img src={logoEecocert} alt="logo eecocert" className="w-32 cursor-pointer" onClick={() => window.open("https://www.ecocert.com")} />
          <p>
            An organic certification organization, founded in France in 1991. It is based in Europe but conducts inspections in over 80 countries, making it one of the largest operations of its kind in the world.

            ECOCERT primarily certifies food and food products, but also certifies cosmetics, detergents, perfumes, and textiles. The company inspects about 70% of the organic food industry in France and about 30% worldwide. ECOCERT is also a leading certifier of fair trade food, cosmetics and textiles as per ECOCERT Fair Trade standards
          </p>
        </div>
        <div className="flex flex-col gap-5 lg:items-center lg:flex-row">
          <img src={logoUSDA} alt="logo eecocert" className="w-32 cursor-pointer" onClick={() => window.open("https://www.usda.gov")} />
          <p>
            The National Organic Program (NOP) is the federal regulatory framework in the United States of America governing organic food. It is also the name of the United States Department of Agriculture (USDA) Agricultural Marketing Service (AMS) program responsible for administering and enforcing the regulatory framework. The core mission of the NOP is to protect the integrity of the USDA organic seal. The seal is used for products adhering
          </p>
        </div>

        <h2 className="text-2xl font-bold mt-8 ">Technical Documentation</h2>
        <p>
          For each product, we provide the ECOCERT certification and the MSDS safety data sheet. Our quality experts support us on various regulatory issues.
        </p>

        <h2 className="text-2xl font-bold mt-8 ">Reasonable Prices</h2>
        <p>
          How do we achieve this? By controlling our product range and focusing on a selection of vegetable oils. With us, no unnecessary packaging or artistic bottles. It is this simplicity that allows us to offer you exceptional products at competitive prices. We are particularly well-placed for wholesale quantities (greater than or equal to 100 units per product) and bulk (greater than or equal to 5 Kg or 5L) for our Argori products.
        </p>

        <h2 className="text-2xl font-bold mt-8 ">Sliding Scale Prices!</h2>
        <p>
          We offer you these treasures of nature at competitive prices. We are particularly well-placed starting from 5 Liters.
        </p>

        <h2 className="text-2xl font-bold mt-8 ">An Attractive Minimum Order</h2>
        <p>
          To make things easier for you, we have set the minimum order for bulk at 5L and wholesale at 100 units for all cosmetic products of our ARGORI brand.
        </p>
      </div>
    </div>
  );
};

export default QualityAndCertificationEN;