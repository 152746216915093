import React from 'react'
import { components, translation } from '@context/Index'

const GeneralConditions = () => {

    const { GeneralConditionsEN, GeneralConditionsFR } = components
    const { language } = translation.useTranslation().i18n


    return (
        <div>
            { language.split('-')[0] === "en" ? <GeneralConditionsEN /> : <GeneralConditionsFR /> }
        </div>
    )
}

export default GeneralConditions