import React, {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useState,
} from "react";
import { hooks, translation } from "@context/Index";
import {  deleteNotify, successNotify} from '@mbs-dev/react-helpers';

// Define a base interface that all cart items must extend
interface CartItemBase {
    ukey: number | string;
    quantity: number;
}

// Define your OrderContextType
interface OrderContextType {
    paymentMethod: string;
    totalPrice: number;
}

// Now you can define your cart item interface elsewhere
interface MyCartItem extends CartItemBase {
    product: number | string;
    price: number;
    currency: string;
}

// Define the context type with a generic parameter
interface ShoppingCartContext<TItem extends CartItemBase> {
    getItemQuantity: (ukey: TItem["ukey"]) => number;
    addToCart: (item: TItem, quantity?: number) => void;
    incrementCartQuantity: (ukey: TItem["ukey"]) => void;
    decrementCartQuantity: (ukey: TItem["ukey"]) => void;
    removeFromCart: (ukey: TItem["ukey"]) => void;
    emptyShoppingCart: () => void;
    cartQuantity: number;
    cartItems: TItem[];
    total: number;
    orderData: OrderContextType;
    setOrderData: React.Dispatch<React.SetStateAction<OrderContextType>>;
    getItemCountByProperty: (property: keyof TItem, value: any) => number;
    expanded: boolean,
    toggleCart: () => void
}

// Create a generic context
function createShoppingCartContext<TItem extends CartItemBase>() {
    const ShoppingCartContext = createContext<
        ShoppingCartContext<TItem> | undefined
    >(undefined);

    const useShoppingCart = () => {
        const context = useContext(ShoppingCartContext);
        if (!context) {
            throw new Error(
                "useShoppingCart must be used within a ShoppingCartProvider"
            );
        }
        return context;
    };

    const ShoppingCartProvider = ({ children }: { children: ReactNode }) => {
        const { useLocalStorage } = hooks;
        const { t } = translation.useTranslation().i18n	;
        const [expanded, setExpanded] = React.useState<boolean>(false);
        const [cartItems, setCartItems] = useLocalStorage<TItem[]>(
            "shopping-cart",
            []
        );

        const [orderData, setOrderData] = useState<OrderContextType>({
            paymentMethod: "",
            totalPrice: 0,
        });

        const cartQuantity = cartItems.reduce(
            (quantity: number, item: TItem) => item.quantity + quantity,
            0
        );

        const [total, setTotal] = useState(0);

        useEffect(() => {
            const newTotal = cartItems.reduce(
                (sum: number, item: TItem) => sum + item.quantity * (item as any).price,
                0
            );
            setTotal(newTotal);
        }, [cartItems]);

        const getItemQuantity = (ukey: TItem["ukey"]) => {
            return cartItems.find((item: TItem) => item.ukey === ukey)?.quantity || 0;
        };

        const getItemCountByProperty = (
            property: keyof TItem,
            value: any
        ): number => {
            return cartItems
                .filter((item: TItem) => item[property] === value)
                .reduce((total: number, item: TItem) => total + item.quantity, 0);
        };

        const addToCart = (item: TItem, quantity: number = 1) => {
            setCartItems((currItems: TItem[]) => {
                const existingItem = currItems.find((ci) => ci.ukey === item.ukey);
                if (existingItem) {
                    return currItems.map((ci) =>
                        ci.ukey === item.ukey
                            ? { ...ci, quantity: ci.quantity + quantity }
                            : ci
                    );
                } else {
                    return [...currItems, { ...item, quantity }];
                }
            },true);
            successNotify(t("cart.added"), 1500);
        };

        const incrementCartQuantity = (ukey: TItem["ukey"]) => {
            setCartItems((currItems: TItem[]) =>
                currItems.map((item) =>
                    item.ukey === ukey
                        ? { ...item, quantity: item.quantity + 1 }
                        : item
                )
            ,true);
        };

        const decrementCartQuantity = (ukey: TItem["ukey"]) => {
            setCartItems((currItems: TItem[]) => {
                const targetItem = currItems.find((item) => item.ukey === ukey);
                if (targetItem && targetItem.quantity > 1) {
                    return currItems.map((item) =>
                        item.ukey === ukey
                            ? { ...item, quantity: item.quantity - 1 }
                            : item
                    );
                } else {
                    return currItems.filter((item) => item.ukey !== ukey);
                }
            }, true);
        };

        const removeFromCart = (ukey: TItem["ukey"]) => {
            setCartItems((currItems: TItem[]) =>
                currItems.filter((item) => item.ukey !== ukey)
            , true);
            successNotify(t("cart.removed"), 1500);
        };

        const emptyShoppingCart = () => {
            setCartItems([], true);
        };

        const toggleCart = () => {
            setExpanded(!expanded)
        }

        return (
            <ShoppingCartContext.Provider
                value={{
                    expanded,
                    toggleCart,
                    getItemQuantity,
                    addToCart,
                    incrementCartQuantity,
                    decrementCartQuantity,
                    removeFromCart,
                    emptyShoppingCart,
                    cartItems,
                    cartQuantity,
                    total,
                    orderData,
                    setOrderData,
                    getItemCountByProperty,
                }}
            >
                {children}
            </ShoppingCartContext.Provider>
        );
    };

    return { ShoppingCartProvider, useShoppingCart };
}


// Create context and provider for your specific cart item
const {
    ShoppingCartProvider,
    useShoppingCart,
} = createShoppingCartContext<MyCartItem>();

export { ShoppingCartProvider, useShoppingCart };
