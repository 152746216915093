import { useBaseContext } from '@context/baseContext';
import { translation } from '@context/Index';
import { ENUMS } from '@helpers/ENUMS';
import Icons from '@helpers/IconsHelper';
import React from 'react'
import { Link } from 'react-router-dom';

type RenderItemProps = { item: any }

const FooterCategories: React.FC = () => {
    
    const { FiChevronRight } = Icons;
    const { categories } = useBaseContext()
    const { t } = translation.useTranslation().i18n

    const RenderItem: React.FC<RenderItemProps> = ({ item }) => {
        return (
            <Link to={`/store/${item?.categoryTranslates[0]?.slug}`}
                state={item}
                className="flex flex-row p-1 items-center"
            >
                <FiChevronRight color={ENUMS.COLORS.GOLD} size={20} />
                <p className="text-white text-sm w-2/3 hover:text-gold after:content-[''] after:block after:w-0 after:h-[2px] after:bg-gold after:transition-all after:duration-300 hover:after:w-full">
                    {item?.categoryTranslates[0]?.title}
                </p>
            </Link>
        )
    }

    return (
        <div className="flex flex-col gap-3 p-4">
            <h3 className="text-white text-xl">{t('categories')}</h3>
            <div className="grid grid-cols-2 gap-2">
                {categories?.map((item: any) => (
                    <RenderItem key={item.id} item={item} />
                ))}
            </div>
        </div>
    )
}

export default FooterCategories;
