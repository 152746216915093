import { AiFillHome } from 'react-icons/ai';
import { 
    BiPlus, 
    BiHome,
    BiChevronDown,
    BiCartAdd 
} from 'react-icons/bi';
import { PiSmileySad, PiShoppingBagFill, PiEmpty  } from "react-icons/pi";
import {
    BsCheckCircleFill,
    BsChatRightDots,
    BsChatLeftText,
    BsCircle,
    BsDot,
    BsFacebook,
    BsFillTrashFill,
    BsGridFill,
    BsInstagram,
    BsPlusCircle,
    BsPlusCircleDotted,
    BsSearch,
    BsStars,
    BsTelephone,
    BsCartCheck 
} from 'react-icons/bs';
import {
    CiCircleMore,
    CiEdit,
    CiFacebook,
    CiGrid2H,
    CiImageOff,
    CiCircleMinus,
    CiCirclePlus,
    CiLocationOn 
} from 'react-icons/ci';
import {
    FaFacebook,
    FaFacebookF,
    FaFilter,
    FaPinterest,
    FaInstagram,
    FaWhatsapp,
    FaTiktok,
    FaLinkedinIn,
    FaShoppingBasket,
    FaTelegramPlane,
    FaYoutube,
    FaUser,
    FaHeart,
    FaSnapchat,
    FaClock,
    FaDollarSign 
} from 'react-icons/fa';
import { FaLocationDot, FaEarthAfrica, FaTruckFast } from 'react-icons/fa6'
import {
    FiLogOut,
    FiSearch,
    FiShoppingBag,
    FiShoppingCart,
    FiChevronRight,
    FiUser
} from 'react-icons/fi';
import {
    GrContact,
    GrContactInfo,
    GrFavorite,
    GrFormClose,
} from 'react-icons/gr';
import {
    HiMinusSm,
    HiOutlineMinusSm,
    HiOutlineUserCircle,
} from 'react-icons/hi';
import { ImPhone } from 'react-icons/im';
import { 
    IoMdLocate,
    IoMdLogIn,
    IoMdAddCircle,
    IoMdPricetag 
} from 'react-icons/io';
import {
    MdContactMail,
    MdDelete,
    MdEmail,
    MdExpandLess,
    MdExpandMore,
    MdModeEditOutline,
    MdOutlineFavorite,
    MdOutlineFavoriteBorder,
    MdOutlineHelpOutline,
    MdOutlineManageAccounts,
    MdOutlineRateReview,
    MdShoppingCart,
    MdShoppingCartCheckout,
    MdWhatsapp,
    MdFavoriteBorder,
    MdPhone,
    MdLocationPin,
    MdAccessTimeFilled,
    MdClose,
    MdSupportAgent 
} from 'react-icons/md';
import { RiCoupon5Line, RiInstagramFill, RiMailOpenFill, RiDeleteBinLine, RiLogoutBoxRLine   } from 'react-icons/ri';
import { RxDashboard, RxHamburgerMenu } from 'react-icons/rx';
import { LuUser2  } from "react-icons/lu";
import { IoHeartCircleOutline, IoHomeOutline } from "react-icons/io5";
import { TbShieldDollar } from "react-icons/tb";
import { TiRefresh } from "react-icons/ti";


const Icons = {
    TiRefresh,
    IoMdPricetag,
    PiEmpty,
    FiUser,
    CiLocationOn,
    BsCartCheck,
    PiShoppingBagFill,
    IoHomeOutline,
    MdSupportAgent,
    FaDollarSign,
    TbShieldDollar,
    FaTruckFast,
    IoMdAddCircle,
    RiDeleteBinLine,
    MdClose,
    CiCircleMinus,
    CiCirclePlus,
    MdAccessTimeFilled ,
    FaClock,
    MdLocationPin ,
    FaSnapchat,
    FaPinterest,
    FaWhatsapp,
    FaTiktok,
    FaFacebook,
    PiSmileySad,
    CiImageOff,
    FaHeart,
    IoHeartCircleOutline,
    FiChevronRight ,
    LuUser2,
    BiChevronDown,
    AiFillHome,
    BiPlus,
    BiHome,
    BsCheckCircleFill,
    BsChatRightDots,
    BsChatLeftText,
    BsCircle,
    BsDot,
    BsFacebook,
    BsFillTrashFill,
    BsGridFill,
    BsInstagram,
    BsPlusCircle,
    BsPlusCircleDotted,
    BsSearch,
    BsStars,
    BsTelephone,
    CiCircleMore,
    CiEdit,
    CiFacebook,
    CiGrid2H,
    FaFacebookF,
    FaFilter,
    FaInstagram,
    FaLinkedinIn,
    FaLocationDot,
    FaEarthAfrica,
    FaShoppingBasket,
    FaTelegramPlane,
    FaYoutube,
    FiLogOut,
    FiSearch,
    FiShoppingBag,
    FiShoppingCart,
    GrContact,
    GrContactInfo,
    GrFavorite,
    GrFormClose,
    HiMinusSm,
    HiOutlineMinusSm,
    HiOutlineUserCircle,
    ImPhone,
    IoMdLocate,
    MdContactMail,
    MdDelete,
    MdEmail,
    MdPhone,
    MdExpandLess,
    MdExpandMore,
    MdModeEditOutline,
    MdOutlineFavorite,
    MdOutlineFavoriteBorder,
    MdOutlineHelpOutline,
    MdOutlineManageAccounts,
    MdOutlineRateReview,
    MdShoppingCart,
    MdShoppingCartCheckout,
    MdWhatsapp,
    RiCoupon5Line,
    RiInstagramFill,
    RiMailOpenFill,
    RxDashboard,
    IoMdLogIn,
    MdFavoriteBorder,
    BiCartAdd,
    RxHamburgerMenu,
    FaUser
};

export default Icons;
