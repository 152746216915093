import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { API } from '@utils/api/API_INSTANCE';
import { useTranslation } from 'react-i18next';
import { BsCheckCircleFill, BsArrowRight, BsBoxSeam, BsTruck, BsCalendarCheck } from 'react-icons/bs';
import { Spinner } from './Spinner';
import { useBaseContext } from '@context/baseContext';

export const PaymentSuccessPage = () => {
    const { t } = useTranslation();
    const { getCurrencySymbol, geoInfo } = useBaseContext()
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [orderDetails, setOrderDetails] = useState<any>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const verifyPayment = async () => {
            const sessionId = searchParams.get('session_id');
            if (!sessionId) return;

            try {
                const { data } = await API.get('/verify-payment', {
                    params: { session_id: sessionId },
                });

                if (data.status !== 'PAID') {
                    throw new Error('Payment not confirmed');
                }

                // Fetch order details
                const { data: orderData } = await API.get(`/orders/${data.orderId}`);
                setOrderDetails(orderData);
            } catch (err) {
                console.error('Payment verification failed:', err);
                navigate('/payment/cancel');
            } finally {
                setLoading(false);
            }
        };

        verifyPayment();
    }, [searchParams, navigate]);

    return (
        <div className="flex min-h-screen items-center justify-center bg-gray-100 px-6">
            <div className="max-w-lg w-full bg-white shadow-lg rounded-lg p-8 text-center">
                <BsCheckCircleFill className="text-green-600 text-6xl mx-auto mb-4" />
                <h2 className="text-3xl font-bold text-gray-900 mb-2">{t("stripe.paymentSuccess")}</h2>
                <p className="text-gray-600 mb-4">{t("stripe.paymentSuccessMessage")}</p>

                {loading ? (
                    <div className="flex justify-center">
                        <Spinner />
                    </div>
                ) : (
                    orderDetails && (
                        <div className="bg-gray-50 rounded-lg p-4 shadow-sm">
                            <h3 className="text-lg font-semibold text-gray-800 mb-3">{t("stripe.orderSummary")}</h3>

                            <div className="flex items-center justify-between text-gray-700 border-b pb-2">
                                <span className="flex items-center gap-2">
                                    <BsBoxSeam className="text-green-500" /> {t("stripe.orderId")}:
                                </span>
                                <span className="font-semibold">{orderDetails.reference}</span>
                            </div>

                            <div className="flex items-center justify-between text-gray-700 border-b pb-2 mt-2">
                                <span className="flex items-center gap-2">
                                    <BsCalendarCheck className="text-blue-500" /> {t("stripe.orderDate")}:
                                </span>
                                <span className="font-semibold">
                                    {new Date(orderDetails.createdAt).toLocaleDateString()}
                                </span>
                            </div>

                            <div className="flex items-center justify-between text-gray-700 border-b pb-2 mt-2">
                                <span className="flex items-center gap-2">
                                    <BsTruck className="text-purple-500" /> {t("stripe.estimatedDelivery")}:
                                </span>
                                <span className="font-semibold">{t("stripe.businessDays")}</span>
                            </div>

                            <div className="flex items-center justify-between text-gray-700 border-b pb-2 mt-2">
                                <span className="flex items-center gap-2">
                                    <BsBoxSeam className="text-yellow-500" /> {t("stripe.items")}:
                                </span>
                                <span className="font-semibold">{orderDetails.itemsQuantity} {t("stripe.items")}</span>
                            </div>

                            <div className="flex items-center justify-between text-gray-900 font-bold mt-2">
                                <span>{t("stripe.totalPaid")}:</span>
                                <span className="text-lg">{orderDetails.price.toFixed(2)} {getCurrencySymbol(geoInfo.currency)}</span>
                            </div>
                        </div>
                    )
                )}

                <div className="mt-6 flex flex-col gap-4">
                    {/* <button
                        onClick={() => navigate('/orders')}
                        className="w-full flex items-center justify-center gap-2 px-6 py-3 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700 transition duration-300"
                    >
                        View My Orders <BsArrowRight />
                    </button> */}
                    <button
                        onClick={() => navigate('/store')}
                        className="w-full flex items-center justify-center gap-2 px-6 py-3 bg-green-600 text-white font-semibold rounded-lg hover:bg-green-700 transition duration-300"
                    >
                        {t("stripe.continueShopping")} <BsArrowRight />
                    </button>
                </div>
            </div>
        </div>
    );
};
