import React, { useEffect, useState } from "react";
import axios from "axios";
import PackCard from "../../components/products/PackCard"; // Import the PackCard component

const PackPage = () => {
  const [packs, setPacks] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPacks = async () => {
      try {
        const response = await axios.get("https://127.0.0.1:8000/api/packs.json");
        setPacks(response.data);
      } catch (err) {
        setError("Failed to load packs. Please check your API.");
      } finally {
        setLoading(false);
      }
    };

    fetchPacks();
  }, []);

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">Available Packs</h1>

      {loading && <p>Loading packs...</p>}
      {error && <p className="text-red-500">{error}</p>}

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {packs.map((pack) => (
          <PackCard key={pack.id} pack={pack} />
        ))}
      </div>
    </div>
  );
};

export default PackPage;
