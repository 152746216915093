import { useAuth } from '@context/AuthContext'
import { useBaseContext } from '@context/baseContext'
import { pngs, translation } from '@context/Index'
import React, { useEffect } from 'react'

const Payments = () => {

    const { CMI, STRIPE } = pngs
    const { t } = translation.useTranslation().i18n
    const { geoInfo } = useBaseContext()

    // React.useEffect(
    //     ()=>{ geoInfo && console.log({geoInfo});
    //     },[geoInfo]
    // )

    return (
        <div className='flex flex-row flex-wrap items-center justify-between p-5 gap-5 text-gold'>
            <p>{t("rightsReserved")}</p>
            <div className='flex flex-row flex-wrap items-center gap-3'>
                {geoInfo?.code === "MA"
                    ? <img src={CMI} alt="logo_cmi" className='w-24' />
                    : <img src={STRIPE} alt="logo_stripe" className='w-72' />}
            </div>
        </div>
    )
}

export default Payments