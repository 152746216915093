import { PaymentData } from '@utils/interfaces/PaymentData';
import { API } from './API_INSTANCE';
import { notify } from '@mbs-dev/react-helpers';
import { ENUMS } from '@helpers/ENUMS';

const getAddressByType = (addresses: any[], addressType: any): any | undefined => {
    return addresses.find((address) => address.addressType === addressType);
};

export const processCmiPayment = async (paymentData: PaymentData) => {
    const {
        userAddresses,
        reference,
        totalPrice,
        itemsQuantity,
        authUser,
    } = paymentData;

    try {
        const billingAddress = getAddressByType(userAddresses, ENUMS.ADDRESSTYPE.BILLING);

        //----------- Start CMI Form
        const cmiForm = new FormData();
        const billToStreet = `${billingAddress?.address}, ${billingAddress?.ville}`;

        cmiForm.append('user', String(authUser?.id));
        cmiForm.append('BillToStreet', billToStreet);
        cmiForm.append('totalPrice', String(totalPrice));
        cmiForm.append('reference', String(reference));
        cmiForm.append('itemsQuantity', String(itemsQuantity));
        cmiForm.append('email', String(billingAddress?.email));
        cmiForm.append('telephone', String(billingAddress?.telephone));
        //----------- End CMI Form

        try {
            // Start CMI Request
            const response = await API.post('/payments/cmi', cmiForm, {
                headers: {
                    Accept: 'application/json'
                },
            })

            if (response.status === 200) {
                const { url, data } = response.data;
                const form = document.getElementById('cmiPaymentForm') as HTMLFormElement;
                form.method = 'POST';
                form.action = url;
                Object.keys(data).forEach((name) => {
                    const input = document.createElement('input');
                    input.name = name;
                    input.value = data[name];
                    form.appendChild(input);
                });

                form.submit();
            } else {
                notify.frCustomeErrorNotify();
            }
            // End CMI Request
        } catch (error) {
            notify.frCustomeErrorNotify();
        }
    } catch (error) {
        notify.frCustomeErrorNotify();
    }
};