import React from 'react'
import { Link } from 'react-router-dom'
import { AnimationScope } from 'framer-motion'
import { animation } from '@context/Index'

let DropDownMenu = ({ item }: { item: any }) => {

  const { useAnimate, usePresence } = animation
  const [scope, animate] = useAnimate()
  const [isPresent, safeToRemove] = usePresence()

  React.useEffect(() => {
    if (isPresent) {
      const enterAnimation = async () => {
        await animate(scope.current, { opacity: 1 }, { duration: .3 })
      }
      enterAnimation()
    } else {
      const exitAnimation = async () => {
        await animate(scope.current, { opacity: 0 }, { duration: .3 })
        safeToRemove()
      }
      exitAnimation()
    }
  }, [isPresent])


  return (
    <div
      ref={scope}
      className={`flex flex-col opacity-0 absolute top-full z-20 my-1 bg-white border rounded overflow-hidden overflow-y-scroll max-h-64 hide-scrollbar`}
    >
      <ul className="flex flex-col">
        {item?.children?.map((item2: any, index: number) => (
          <li key={index}>
            {item?.renderItem(item2)}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default DropDownMenu