import React, { useState } from "react";
import { useFormik } from "formik";
import { API } from "@utils/api/API_INSTANCE";
import { ContactIV } from "@utils/initValues/ContactIV";
import { ContactVS } from "@utils/schemas/ContactSchema";
import { Link } from "react-router-dom";

const Contact: React.FC = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const formik = useFormik({
    initialValues: ContactIV,
    validationSchema: ContactVS,
    onSubmit: async (values, { resetForm }) => {
      try {
        await API.post("/contact_requests", values, {
          headers: { "Content-Type": "application/json" },
        });
        setIsSubmitted(true);
        resetForm();
      } catch (error) {
        console.error("Erreur lors de l'envoi du formulaire", error);
      }
    },
  });

  return (
    <div className="max-w-[63rem] mx-auto p-6 bg-white rounded-lg shadowmd">
      <h2 className="font-medium md:text-4xl text-center text-[#258f67] mb-4 md:mb-5 tracking-wide">
        Nous contacter
      </h2>
      <p className="text-center text-gray-700 font-medium leading-relaxed mb-6 md:mb-8 mmax-w-[60rem] mx-auto">
        Pour contacter nos conseillères beauté par e-mail, merci de compléter le formulaire ci-dessous. <br />
        Elles répondront à toutes vos questions dans un délai de 24 heures.
      </p>

      {isSubmitted ? (
        <div className="bg-green-100 border border-green-400 text-green-700 px-6 py-4 rounded-lg text-center">
          <p className="text-lg font-semibold">
            ✅ Votre message a été envoyé avec succès !
          </p>

        </div>
      ) : (
        <>
          <form onSubmit={formik.handleSubmit} className="space-y-6">
            {/* Gender Selection */}
            <div>
              <label className="block text-gray-700 mb-1">Civilité</label>
              <div className="flex gap-4">
                <label className="flex items-center gap-2 cursor-pointer">
                  <input
                    type="radio"
                    name="gender"
                    value="F"
                    checked={formik.values.gender === "F"}
                    onChange={formik.handleChange}
                    className="accent-[#258f67]"
                  />
                  <span>Mme</span>
                </label>

                <label className="flex items-center gap-2 cursor-pointer">
                  <input
                    type="radio"
                    name="gender"
                    value="M"
                    checked={formik.values.gender === "M"}
                    onChange={formik.handleChange}
                    className="accent-[#258f67]"
                  />
                  <span>Mr</span>
                </label>
              </div>
              {formik.touched.gender && formik.errors.gender && (
                <p className="text-red-500">{formik.errors.gender}</p>
              )}
            </div>

            {/* Full Name & Email */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-gray-700">Nom complet</label>
                <input
                  type="text"
                  name="fullName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.fullName}
                  className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#258f67]"
                />
                {formik.touched.fullName && formik.errors.fullName && (
                  <p className="text-red-500">{formik.errors.fullName}</p>
                )}
              </div>

              <div>
                <label className="block text-gray-700">Email</label>
                <input
                  type="email"
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#258f67]"
                />
                {formik.touched.email && formik.errors.email && (
                  <p className="text-red-500">{formik.errors.email}</p>
                )}
              </div>
            </div>

            {/* Client Number & Telephone Fixe */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-gray-700">N° de cliente</label>
                <input
                  type="text"
                  name="clienteNumber"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.clienteNumber}
                  className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#258f67]"
                />
                {formik.touched.clienteNumber && formik.errors.clienteNumber && (
                  <p className="text-red-500">{formik.errors.clienteNumber}</p>
                )}
              </div>

              <div>
                <label className="block text-gray-700">Téléphone fixe</label>
                <input
                  type="text"
                  name="telephoneFixe"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.telephoneFixe}
                  className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#258f67]"
                />
                {formik.touched.telephoneFixe && formik.errors.telephoneFixe && (
                  <p className="text-red-500">{formik.errors.telephoneFixe}</p>
                )}
              </div>
            </div>

            {/* Subject */}
            <div>
              <label className="block text-gray-700">Sujet</label>
              <input
                type="text"
                name="subject"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.subject}
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#258f67]"
              />
              {formik.touched.subject && formik.errors.subject && (
                <p className="text-red-500">{formik.errors.subject}</p>
              )}
            </div>

            {/* Message */}
            <div>
              <label className="block text-gray-700">Message</label>
              <textarea
                name="message"
                rows={4}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.message}
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#258f67]"
              ></textarea>
              {formik.touched.message && formik.errors.message && (
                <p className="text-red-500">{formik.errors.message}</p>
              )}
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="w-full p-3 bg-[#258f67] text-white rounded-lg hover:bg-[#1f7a54] transition"
            >
              Je valide
            </button>
          </form>

          <div className="mt-6 text-gray-600 text-base leading-relaxed">
            <h3 className="font-semibold text-lg">Protection de votre vie privée :</h3>
            <p className="mt-2">
              En cliquant sur <strong>« Je valide »</strong>, vous consentez à ce que les informations
              recueillies soient utilisées par l’équipe de <strong>S.A.S ARGORI</strong> en sa qualité
              de responsable de traitement pour traiter et répondre à votre demande.
            </p>
            <p className="mt-2">
              Conformément à la réglementation applicable en matière de protection des
              données personnelles, pour en savoir plus sur le traitement de vos
              données, connaître l’intégralité de vos droits et savoir comment les
              exercer, consultez notre{" "}
              <Link to='/privacy-policy' className="text-[#258f67] font-medium cursor-pointer underline">
                politique de confidentialité
              </Link>.
            </p>
            <p className="mt-2">
              Nos conseillères beauté vous réservent un accueil chaleureux et un conseil
              professionnel. <strong>Du lundi au samedi de 9h à 18h.</strong>
            </p>
            <p className="mt-2">
              Vous bénéficiez d'un <strong>droit de rétractation</strong> que vous pouvez exercer dans un
              délai de <strong>14 jours</strong> à compter de la réception par vos soins de l'intégralité
              de votre commande, conformément à la réglementation.
            </p>
            <p className="mt-4 font-semibold text-lg">📞 Contact :</p>
            <ul className="mt-2 text-sm">
              <li className="mb-1">📧 Email : <span className="font-medium">argori.info@gmail.com | info@argori.com | contact@argori.com</span></li>
              <li>📞 Téléphone : <span className="font-medium">+33 (0) 7.68.96.85.07 | +33 (0) 6.95.83.91.20</span></li>
            </ul>
          </div>
        </>
      )}

    </div>
  );
};

export default Contact;
