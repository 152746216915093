import React from 'react'
import { components, translation } from '@context/Index'

const DeliveryAndRights = () => {

    const { DeliveryAndRightsEN, DeliveryAndRightsFR } = components
    const { language } = translation.useTranslation().i18n


    return (
        <div>
            { language.split('-')[0] === "en" ? <DeliveryAndRightsEN /> : <DeliveryAndRightsFR /> }
        </div>
    )
}

export default DeliveryAndRights