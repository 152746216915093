import { useAuth } from '@context/AuthContext'
import { components } from '@context/Index'
import { uploadUrl } from '@helpers/Helpers'
import React from 'react'
import ProfileInfo from './ProfileInfo'

const ProfileData = () => {

    return (
        <div className="p-4">
            <h1 className="text-xl font-bold mb-4">Edit Profile</h1>
            <ProfileInfo/>
        </div>
    )
}

export default ProfileData