import React from "react";
import { uploadUrl } from "@helpers/Helpers";
import { Link } from "react-router-dom";

const PackCard = ({ pack }: { pack: any }) => {
    // Extract pack details
    let { packTranslates, packItems, packPrice, packImages } = pack;
    let packImage = packTranslates?.[0]?.image;
    let packTitle = packTranslates?.[0]?.title;
    let price = packPrice?.[0]?.price;
    let packImageURL = packImage ? uploadUrl + packImage : "";

    return (
        <div className="w-[28rem] h-[35rem] flex flex-col bg-white shadow-md rounded-xl overflow-hidden p-4">
            {/* Pack Image */}
            <div className="relative w-[25rem] h-[25rem] flex justify-center items-center overflow-hidden mx-auto">
                {packImage ? (
                    <img
                        src={packImageURL}
                        alt={packTitle}
                        className="w-full h-full object-cover rounded-xl"
                    />
                ) : (
                    <div className="w-full h-full flex justify-center items-center bg-gray-200 text-gray-500 rounded-xl">
                        No Image
                    </div>
                )}
            </div>

            {/* Pack Title */}
            <p className="text-lg font-semibold text-center mt-4">{packTitle}</p>

            {/* Price */}
            <p className="text-lg text-brand-darker font-bold flex justify-center items-center">
                {price + " MAD"}
            </p>

            {/* Divider */}
            <hr className="my-4 border-gray-300" />

            {/* Pack Items (Product Images) */}
            <div className="flex gap-2 overflow-x-auto pb-2">
                {packItems.map((item: any, index: number) => {
                    let productImage = item.product?.productTranslates?.[0]?.image;
                    // let productImage = packImages?.[0]?.image;
                    let productImageURL = productImage ? uploadUrl + productImage : "";

                    return (
                        <div key={index} className="w-16 h-16 flex-shrink-0">
                            {productImage ? (
                                <img
                                    src={productImageURL}
                                    alt={`Product ${index}`}
                                    className="w-full h-full object-cover border rounded-lg"
                                />
                            ) : (
                                <div className="w-full h-full flex justify-center items-center bg-gray-200 text-gray-500 rounded-lg">
                                    No Image
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default PackCard;
