import { MenuProps } from '@utils/types/MenuTypes'
import { components,animation } from '@context/Index'
import React from 'react'

function Menu({items,buttons, expandResponsive}:MenuProps) {

  const {AnimatePresence} = animation

  let {
    MenuButton,
    LangSelect,
    MenuItems
  } = components

  return (
    <nav className={`flex w-full sm:w-auto sm:flex-grow flex-wrap-reverse justify-center xl:justify-between items-center gap-3`}>
        <AnimatePresence>
          {expandResponsive && <MenuItems items={items}  key="dropdown"/>}
        </AnimatePresence>
        <ul className="gap-4 flex w-full sm:w-auto items-center justify-center">
          {buttons.map((item, index) => (
            <MenuButton key={index} item={item} />
          ))}
        <LangSelect />
        </ul>
    </nav>
  )
}

export default Menu