import React, { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from '@hooks/LocalStorage';
import { Authenticate } from '@api/AuthApi';
import { authInitialValuesTypes } from '@utils/types/AuthTypes';
import { getUser } from '@api/UserApi';
import { User } from "@interfaces/User"
import { authBody } from '@utils/types/api/Auth';
import { useQuery } from '@tanstack/react-query';

let initialValues: authInitialValuesTypes = {
    userToken: null,
    user: null,
    // roles: [],
    login: async () => { },
    logout: () => { },
    loading: false, // Initialisez loading à true
    loadingUser: false,
    refetchUser: () => { },
    usersCountryCode: null,
    setUsersCountryCode: () => { }
}

let AuthContext = createContext(initialValues);

function AuthProvider({ children }: { children: ReactNode }) {

    let [userToken, setUserToken] = useLocalStorage('userToken', null);
    // let [roles, setRoles] = React.useState<string[]>([]);
    let [loading, setLoading] = React.useState<boolean>(false); // Initialisez loading à true
    let [usersCountryCode, setUsersCountryCode] = React.useState<string | null>(null);

    let login = useCallback((body: authBody, rememberMe: boolean) => {
        setLoading(true);
        return Authenticate(body)
            .then((res: any) => {
                setUserToken(res.data.token, rememberMe);
                setLoading(false);
            })
            .catch((err: any) => {
                console.error("Authentication failed", { err });
                setLoading(false);
            });
    }, [userToken])

    let logout = useCallback(() => {
        setUserToken(null);
        // setRoles([]);
    }, [userToken])


    let { data: user, isLoading: loadingUser, refetch: refetchUser, isError, failureReason } = useQuery({
        queryKey: ['user', userToken],
        queryFn: () => getUser(userToken),
        enabled: !!userToken
    })

    React.useEffect(() => {
        if (isError) {
            console.error("Failed to get user data", failureReason);
            logout();
            setLoading(false);
        }
    }, [isError])

    React.useEffect(() => {
        // usersCountryCode && console.log("usersCountryCode", usersCountryCode);
    },[usersCountryCode])

    let value = useMemo(() => ({
        userToken,
        user,
        // roles,
        login,
        logout,
        loading,
        loadingUser,
        refetchUser,
        usersCountryCode,
        setUsersCountryCode
    }), [user, userToken, loading, usersCountryCode]);

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthProvider;

export const useAuth = () => useContext(AuthContext);