import React from 'react';
import PromoCode from '@components/promoCode/PromoCode';
import { useAuth } from '@context/AuthContext';
import { useBaseContext } from '@context/baseContext';
import { translation } from '@context/Index';
import { useShoppingCart } from '@context/ShoppingCartContext';

export type OrderDetailsProps = {
    // promoCodeDiscount?: number;
    promoCode?: { id: string | null; discount: number }
    // setPromoCodeDiscount?: React.Dispatch<React.SetStateAction<number>>;
    hasWelcomeDiscount?: boolean;
    setPromoCode?: React.Dispatch<React.SetStateAction<{ id: string | null; discount: number }>>;
};

const OrderDetails: React.FC<OrderDetailsProps> = ({ hasWelcomeDiscount, promoCode = { id: null, discount: 0 }, setPromoCode }) => {

    const { t } = translation.useTranslation().i18n;
    const { cartQuantity, cartItems, total } = useShoppingCart();
    const { getCurrencySymbol } = useBaseContext();
    // const { PromoCode } = components;
    const { userToken } = useAuth();

    // Initial total before discounts
    let finalPrice = total;

    // Calculate discounts separately on the original total
    let welcomeDiscountAmount = hasWelcomeDiscount ? total * 0.1 : 0; // 10% of total
    let promoCodeDiscountAmount = (promoCode.id !== null && promoCode.discount > 0) ? (total * promoCode.discount) / 100 : 0;
    // let promoCodeDiscountAmount = (promoCodeDiscount && promoCodeDiscount > 0) ? (total * promoCodeDiscount) / 100 : 0;

    // Apply both discounts
    finalPrice = total - welcomeDiscountAmount - promoCodeDiscountAmount;

    return (
        <div className="p-5 rounded-xl shadow-md flex flex-col gap-3 bg-white">
            <h3 className='text-3xl font-bold'>{t('checkout.orderSummary')}</h3>
            <p>{t('cart.items')} : {cartQuantity}</p>
            <table className='w-full'>
                <thead>
                    <tr>
                        <th className="text-left">{t('cart.product')}</th>
                        <th className="text-left">{t('cart.price')}</th>
                    </tr>
                </thead>
                <tbody className='w-full'>
                    {cartItems?.map(item => (
                        <tr key={item?.ukey} >
                            <td className="pe-1">
                                <p>{item?.quantity} x {item?.product}</p>
                            </td>
                            <td>
                                <p className='text-nowrap'>{(item?.price * item?.quantity).toFixed(2)} {getCurrencySymbol(item?.currency)}</p>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Apply Promo Code */}
            {userToken && <PromoCode userToken={userToken} setPromoCode={setPromoCode} />}

            {/* Display Welcome Discount if applicable */}
            {(hasWelcomeDiscount && welcomeDiscountAmount > 0) ?
                <div className="flex flex-row items-center justify-between text-lg font-bold">
                    <p>{t("welcome.discount")}</p>
                    <p className='text-red-500'>-10% ({welcomeDiscountAmount.toFixed(2)} {getCurrencySymbol(cartItems[0]?.currency)})</p>
                </div>
                : null
            }

            {/* Display Promo Code Discount if applicable */}
            {(promoCode.id !== null && promoCode.discount > 0) ?
                <div className="flex flex-row items-center justify-between text-lg font-bold">
                    <p>{t("promocode.discount")}</p>
                    <p className='text-red-500'>-{promoCode.discount}% ({promoCodeDiscountAmount.toFixed(2)} {getCurrencySymbol(cartItems[0]?.currency)})</p>
                </div>
                : null
            }

            {/* Display Total Price Before & After Discounts */}
            <div className="flex flex-row items-center justify-between text-2xl font-bold">
                <p>{t("cart.total")}</p>
                <div className="flex flex-row items-center gap-2">
                    {(hasWelcomeDiscount || (promoCode.id !== null && promoCode.discount > 0)) ? (
                        <>
                            <p className='line-through opacity-50 text-xl'>{total.toFixed(2)}</p>
                            <p className='text-green-600'>{finalPrice.toFixed(2)}</p>
                        </>
                    ) : (
                        <p>{total.toFixed(2)}</p>
                    )}
                    <p>{getCurrencySymbol(cartItems[0]?.currency)}</p>
                </div>
            </div>
        </div>
    );
}

export default OrderDetails;
