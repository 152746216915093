import React from 'react';
import logoEecocert from '@resources/certifications-logos/logo-ecocert.webp'
import logoUSDA from '@resources/certifications-logos/logo-usda.png'

const QualityAndCertificationFR = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6 text-center">Qualité & Certifications</h1>

      <div className="prose prose-lg max-w-none space-y-4">
        <h2 className="text-2xl font-bold mt-8 ">Certification Ecocert</h2>
        <p>
          Depuis sa création en 2023, Argori a à cœur de proposer des produits respectueux aussi bien pour vous que pour l’environnement. Gage de qualité, nous avons fait le choix de vous proposer des produits certifiés bio. Les produits cosmétiques ARGORI sont certifiés Cosmétiques BIO, pour garantir un plaisir d’utilisation et des ingrédients naturels. Pour assurer aux consommateurs la fiabilité de ses produits et la force de ses valeurs, la marque Argori a fait le choix depuis toujours d’être engagée avec ECOCERT pour la certification et la labellisation de ses produits certifiés organic by ecocert sa, Produits biologiques certifiés par FR-BIO-01.
        </p>
        <p>
          La certification cosmétique écologique et biologique Ecocert permet de garantir le respect de l’environnement, du consommateur et une valorisation des substances d’origine naturelle biologique.
        </p>
        <p>
          Avec cette certification, nous garantissons la traçabilité des ingrédients jusqu’au produit fini, maîtrisant alors la qualité tout au long de la chaîne de production, afin de vous garantir la plus grande des transparences possibles.
        </p>

        <h2 className="text-2xl font-bold mt-8 ">Une qualité exceptionnelle</h2>
        <p>
          Tous nos ingrédients végétaux prestigieux, huile d’argan biologique, huile de pépins de figue de barbarie biologique et eau de rose biologique, sont pures, vierges, 100% naturelles et certifiées Biologique selon le référentiel ECOCERT/USDA pour les USA, ECOCERT/EOS pour l’Union Européenne et Agriculture Biologique ‘’AB’’.
        </p>
        <p>
          Nos huiles d’argan biologique (100% biologique), de pépins de figue de barbarie (100% biologique) et eau de rose (100% biologique) sont mono-ingrédients (aucun mélange, ni additif, ni conservateur n’ont été ajouté). Parce que l’efficacité de nos produits est notre meilleur argument, nous sommes très attentifs à la préservation des principes actifs : toutes nos huiles sont fraîches et ont été pressées à froid dans cette même année.
        </p>
        <div>
          <div className="flex flex-col gap-5 lg:items-center lg:flex-row">
            <img src={logoEecocert} alt="logo-ecocert" className="w-32 cursor-pointer" onClick={() => window.open("https://www.ecocert.com")} />
            <p>
              Une organisation de certification biologique, fondée en France en 1991. Elle est basée en Europe mais effectue des inspections dans plus de 80 pays, ce qui en fait l'une des plus grandes opérations de ce type au monde.

              ECOCERT certifie principalement des aliments et des produits alimentaires, mais certifie également des cosmétiques, des détergents, des parfums et des textiles. L'entreprise inspecte environ 70 % de l'industrie alimentaire biologique en France et environ 30 % dans le monde. ECOCERT est également un certificateur leader des produits alimentaires, cosmétiques et textiles issus du commerce équitable selon les normes ECOCERT Fair Trade.
            </p>
          </div>
        </div>
        <div>
          <div className="flex flex-col gap-5 lg:items-center lg:flex-row">
            <img src={logoUSDA} alt="logo-usda" className="w-32 cursor-pointer" onClick={() => window.open("https://www.usda.gov")}/>
            <p>
              Le Programme National Biologique (NOP) est le cadre réglementaire fédéral aux États-Unis d'Amérique régissant les aliments biologiques. C'est également le nom du programme du Service de Commercialisation Agricole (AMS) du Département de l'Agriculture des États-Unis (USDA) responsable de l'administration et de l'application du cadre réglementaire. La mission principale du NOP est de protéger l'intégrité du sceau biologique de l'USDA. Le sceau est utilisé pour les produits adhérant
            </p>
          </div>
        </div>

        <h2 className="text-2xl font-bold mt-8 ">Documentation technique</h2>
        <p>
          Pour chaque produit, nous tenons à votre disposition la certification ECOCERT et la fiche de sécurité MSDS. Nos experts qualité nous accompagnent sur les différentes questions réglementaires.
        </p>

        <h2 className="text-2xl font-bold mt-8 ">Des prix raisonnés</h2>
        <p>
          Comment y arrivons-nous? En maîtrisant notre gamme de produits et en nous concentrant sur une sélection d’huiles végétales. Chez nous, pas d’emballage superflu, ni de flacons artistiques. C’est cette simplicité qui nous permet de vous offrir des produits d’exception à des tarifs compétitifs. Nous sommes particulièrement bien placés sur les quantités de gros (supérieures ou égales à 100 pièces par produit) et de vrac (supérieures ou égales à 5 Kg ou 5L) pour nos produits Argori.
        </p>

        <h2 className="text-2xl font-bold mt-8 ">Des tarifs dégressifs !</h2>
        <p>
          Nous vous proposons ces trésors de la nature à des prix compétitifs. Nous sommes particulièrement bien placés à partir de 5 Litres.
        </p>

        <h2 className="text-2xl font-bold mt-8 ">Un minimum de commande attractif</h2>
        <p>
          Pour vous faciliter la tâche, nous avons fixé le minimum de commande en vrac à 5L et en gros à 100 pièces pour tous les produits cosmétiques de notre marque ARGORI.
        </p>
      </div>
    </div>
  );
};

export default QualityAndCertificationFR;