import React from 'react'
import Icons from '@helpers/IconsHelper'
import { useShoppingCart } from '@context/ShoppingCartContext'
import { animation, translation, components } from '@context/Index'


const CartNavBtn: React.FC = () => {

  let { BiCartAdd } = Icons
  let { t } = translation.useTranslation().i18n
  let { cartItems } = useShoppingCart()
  let {toggleCart} = useShoppingCart()

  return (
    <div className='relative'>
      {cartItems?.length > 0 && <p className='flex text-xs absolute w-5 h-5 -top-3 -right-2 rounded-full p-1 items-center justify-center bg-red-500 text-white '>{cartItems.length}</p>}
      <BiCartAdd className={"text-brand-darker w-full h-full"} size={30} onClick={toggleCart} />
    </div>
  )
}

export default CartNavBtn