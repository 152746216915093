import { components, translation } from '@context/Index'
import React from 'react'

const SignUp = () => {

    const { Header, Breadcrumbs, SignUpForm } = components
    const { t } = translation.useTranslation().i18n
    return (
        <div>
            <Header title={t("signUp.title")} />
            <Breadcrumbs />
            <SignUpForm/>
        </div>
    )
}

export default SignUp