import { useAuth } from '@context/AuthContext'
import { components, formikHook } from '@context/Index'
import { useShoppingCart } from '@context/ShoppingCartContext'
import { ENUMS } from '@helpers/ENUMS'
import Icons from '@helpers/IconsHelper'
import { errorNotify, referenceGenerator, successNotify } from '@mbs-dev/react-helpers'
import { getLastOrder, postOfflineOrder } from '@utils/api/OrderApi'
import { t } from 'i18next'
import React from 'react'
import { offlineOrderInitialValues } from '@utils/initValues/OfflineOrderFormIV'
import { OfflineOrderFormVS } from '@utils/schemas/Order'
import { Link } from 'react-router-dom'

const OfflineOrder = () => {
    let { user } = useAuth()
    let { useFormik } = formikHook
    let { FiChevronRight } = Icons
    let { OrderDetails, Select, ActivityIndicator } = components
    let { total, cartItems, cartQuantity, emptyShoppingCart } = useShoppingCart()
    let paymentMethodes = [{ label: t("paymentMethods." + ENUMS.PAYMENT.METHOD.COD), value: ENUMS.PAYMENT.METHOD.COD }]
    let [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState<{ label: string; value: string } | null>(paymentMethodes[0])
    let [loading, setLoading] = React.useState<boolean>(false)

    let SelectOption = {
        control: (provided: any, state: any) => ({
            ...provided,
            borderRadius: "50px",
            padding: "5px",
            ":hover": { borderColor: ENUMS.COLORS.BRAND },
            borderColor: state.isFocused ? ENUMS.COLORS.BRAND_DARK : provided.borderColor,
            boxShadow: state.isFocused ? "0 0 0 1px " + ENUMS.COLORS.BRAND_DARK : provided.boxShadow,
            backgroundColor: state.isDisabled && "#fff",
            color: state.isDisabled && "#000"
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: state.isSelected ? ENUMS.COLORS.BRAND_DARK : "#fff"
        })
    }

    const formik = useFormik({
        initialValues: {
            ...offlineOrderInitialValues,
            cgvAccepted: false,
        },
        validationSchema: OfflineOrderFormVS,
        validate: (values) => {
            let errors: any = {};
            if (!values.cgvAccepted) {
                errors.cgvAccepted = "Vous devez accepter les conditions générales de vente.";
            }
            return errors;
        },
        onSubmit: async (values) => {
            if (cartItems.length > 0 && formik.isValid) {
                if (!values.cgvAccepted) {
                    errorNotify("Veuillez accepter les conditions générales de vente.");
                    return;
                }
                setLoading(true)

                const lastOrder = await getLastOrder('off');
                const orderRef = `${referenceGenerator(undefined, "year", "month", "day")}00${lastOrder + 1}`;

                let body = {
                    ...values,
                    paymentMethod: selectedPaymentMethod?.value,
                    price: total,
                    itemsQuantity: cartQuantity,
                    reference: orderRef,
                    cgvAccepted: values.cgvAccepted,
                    offlineOrderItems: cartItems.map((item: any) => ({
                        product: "api/products/" + item?.ukey,
                        price: item?.price,
                        quantity: item?.quantity
                    })),
                }

                postOfflineOrder(body)
                    .then((res) => {
                        setLoading(false)
                        successNotify(t("checkout.success"), 2000)
                        // emptyShoppingCart()
                    })
                    .catch((err) => {
                        setLoading(false)
                        errorNotify("checkout.error", 2000)
                        console.error(err)
                    })
            }
        }
    })

    const {
        values,
        isValid,
        handleChange,
        handleBlur,
        errors,
        handleSubmit
    } = formik

    return (
        <div className='lg:container lg:mx-auto'>
            <div className='grid grid-cols-3 gap-2 p-5 lg:p-0'>
                <div className='col-span-3 lg:col-span-1 p-5 rounded-xl flex flex-col gap-4 bg-white'>
                    <div className="flex flex-col gap-2">
                        <div className='flex flex-row items-center gap-2 justify-between'>
                            <h4 className="text-xl font-bold">{t("checkout.form")}</h4>
                        </div>
                    </div>
                    {/* Full Name */}
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700">{t("newAddressForm.fullName")}</label>
                        <input
                            type="text"
                            name="fullName"
                            value={formik.values.fullName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-full shadow-sm focus:outline-none focus:border-brand-darker"
                        />
                        {formik.touched.fullName && formik.errors.fullName ? (
                            <div className="text-red-500 text-sm mt-1">{formik.errors.fullName}</div>
                        ) : null}
                    </div>

                    {/* Email */}
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700">{t("newAddressForm.email")}</label>
                        <input
                            type="email"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-full shadow-sm focus:outline-none focus:border-brand-darker"
                        />
                        {formik.touched.email && formik.errors.email ? (
                            <div className="text-red-500 text-sm mt-1">{formik.errors.email}</div>
                        ) : null}
                    </div>

                    {/* Telephone */}
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700">{t("newAddressForm.telephone")}</label>
                        <input
                            type="text"
                            name="telephone"
                            value={formik.values.telephone}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-full shadow-sm focus:outline-none focus:border-brand-darker"
                        />
                        {formik.touched.telephone && formik.errors.telephone ? (
                            <div className="text-red-500 text-sm mt-1">{formik.errors.telephone}</div>
                        ) : null}
                    </div>

                    {/* Address */}
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700">{t("newAddressForm.address")}</label>
                        <input
                            type="text"
                            name="address"
                            value={formik.values.address}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-full shadow-sm focus:outline-none focus:border-brand-darker"
                        />
                        {formik.touched.address && formik.errors.address ? (
                            <div className="text-red-500 text-sm mt-1">{formik.errors.address}</div>
                        ) : null}
                    </div>

                    {/* City */}
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700">{t("newAddressForm.ville")}</label>
                        <input
                            type="text"
                            name="ville"
                            value={formik.values.ville}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-full shadow-sm focus:outline-none focus:border-brand-darker"
                        />
                        {formik.touched.ville && formik.errors.ville ? (
                            <div className="text-red-500 text-sm mt-1">{formik.errors.ville}</div>
                        ) : null}
                    </div>

                    {/* Zip Code */}
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700">{t("newAddressForm.zipCode")}</label>
                        <input
                            type="number"
                            name="zipCode"
                            value={formik.values.zipCode}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-full shadow-sm focus:outline-none focus:border-brand-darker"
                        />
                        {formik.touched.zipCode && formik.errors.zipCode ? (
                            <div className="text-red-500 text-sm mt-1">{formik.errors.zipCode}</div>
                        ) : null}
                    </div>

                    <div className="flex flex-col gap-2">
                        <div className='flex flex-row items-center gap-2 justify-between'>
                            <h4 className="text-xl font-bold">{t("checkout.paymentMethod")}</h4>
                        </div>
                        <Select
                            styles={SelectOption}
                            value={selectedPaymentMethod}
                            defaultValue={selectedPaymentMethod}
                            onChange={setSelectedPaymentMethod}
                            options={paymentMethodes}
                        />
                        <div className="flex flex-col gap-2">
                            <div className="flex flex-row items-center gap-2">
                                <h4 className="text-xl font-bold">{t("checkout.comment")}</h4>
                                <p>(Optional)</p>
                            </div>
                            <textarea
                                className="border-2 border-gray-300 rounded-md p-2 w-full"
                                rows={3}
                                placeholder="Add a comment..."
                                value={values?.comment}
                                onChange={handleChange("comment")}
                            />
                        </div>
                    </div>
                </div>
                <div className='col-span-3 lg:col-span-2 h-full'>
                    <OrderDetails />
                    <div className="flex items-start gap-2 mt-4">
                        <input
                            type="checkbox"
                            name="cgvAccepted"
                            id="cgvAccepted"
                            checked={formik.values.cgvAccepted}
                            onChange={formik.handleChange}
                            className="w-5 h-5 accent-[#258f67] cursor-pointer"
                        />
                        <label htmlFor="cgvAccepted" className="text-gray-700 text-sm">
                            {t("cgv.part1")}{" "}
                            <Link to="/general-terms-and-conditions-of-sale" className="text-[#258f67] underline">
                                {t("cgv.part2")}
                            </Link>{" "}
                            {t("cgv.part3")}
                        </label>
                    </div>

                    {formik.errors.cgvAccepted && formik.touched.cgvAccepted && (
                        <p className="text-red-500 text-sm">{formik.errors.cgvAccepted}</p>
                    )}

                    <div className="flex justify-end items-center p-3 gap-3">
                        <button
                            disabled={loading}
                            onClick={() => handleSubmit()}
                            className={`py-2 px-5 flex flex-row items-center gap-3 text-brand-darker rounded-full bg-gold text-center ${cartQuantity < 1 ? "opacity-50" : "opacity-100"}`}
                        >
                            <p className="font-bold">{t("checkout.postOrder")}</p>
                            {loading ? <ActivityIndicator size={25} color={ENUMS.COLORS.BRAND_DARK} /> : <FiChevronRight size={'1.5rem'} />}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OfflineOrder