import React from 'react';

const ingredientsData = [
  {
    title: "HUILE D’ARGAN",
    image: require("../../resources/ingredients/argan-min.png"),
    description: `L’arganier est un arbre appartenant à la famille des Sapotaceae, originaire uniquement du Maroc, qui peut atteindre 10 mètres de hauteur. Son huile est utilisée depuis l’Antiquité pour ses nombreuses propriétés alimentaires et cosmétiques, pour la beauté et la santé de la peau et des cheveux.
    
Riche en acides insaturés et en vitamine E, elle est idéale pour lutter contre les radicaux libres grâce à un effet antioxydant et favorise la bonne santé des systèmes nerveux et musculaire; son contenu en acides gras essentiels a également un effet bénéfique sur les troubles cardiovasculaires.
    
L’huile d’argan biologique et 100% naturelle nourrit, hydrate et améliore l’élasticité de la peau, retend, raffermit, a des propriétés anti-inflammatoires et décongestionnantes et prolonge la durée de vie des produits cosmétiques naturels. Elle aide efficacement à traiter l’acné, l’eczéma, le psoriasis, la peau sèche et les brûlures. Les femmes berbères offrent la meilleure preuve des merveilleux effets de l’argan. En dépit de l’intense rayonnement solaire et des conditions de vie à l’extérieur, elles ont une peau jeune, des ongles résistants et des cheveux brillants. Un des ingrédients phares par excellence.`
  },
  {
    title: "Huile de pépins de figue de barbarie",
    image: require("../../resources/ingredients/Huile de pépins de figue de barbarie-min.png"),
    description: `Derrière ce nom un peu long se cache une plante. Un cactus plus précisément le figuier de Barbarie. Originaire des zones arides d’Amérique Latine, il est aujourd’hui très présent sur le pourtour méditerranéen. Pour produire un litre d’huile de graines de figue de Barbarie, 30 kilos de pépins sont nécessaires, ce qui représente une tonne de fruits. Ces pépins sont ensuite lavés et séchés au soleil avant d'être pressés à froid. Chaque pépin contient environ 5% d’huile pure. L'huile de graines de figue de Barbarie est de ce fait une denrée rare.
    
La renommée de l’huile de pepins de figue de Barbarie provient essentiellement de son fort pouvoir anti-âge. Elle offre un effet tenseur doublé d’une protection contre les radicaux libres qui permettent de gommer les rides, de prévenir leur apparition et lutter contre le relâchement de la peau.
    
Comme toute huile végétale, l’huile de graines de figue de Barbarie est également recommandée pour son action hydratante, raffermissante et séborégulatrice. Elle convient à tous les types de peaux car il s’agit d’une huile sèche qui ne laisse pas de film gras, elle est utilisée sur le cuir chevelu pour équilibrer la production de sébum et sur les ongles pour les fortifier.
    
Enfin, l’huile de pépins de figue de Barbarie a une action cicatrisante qui sera très utile pour réduire l’aspect des cicatrices, mais également des vergetures. Elle est une véritable mine d'or en cosmétique bio. Cette huile se retrouve donc dans les crèmes, les savons et les sérums.`
  },
  {
    title: "Rose de damascena",
    image: require("../../resources/ingredients/rose-min.png"),
    description: `La rose de damascena ou encore rose de Damas (Rosa damascena), est une espèce de plantes à fleurs de la famille des rosacées. C'est un rosier hybride, considéré comme l'un des types importants des roses anciennes. Elle tient une place de choix dans le pédigrée de nombreux autres types de rosiers.
    
L’hydrolat de rose damascena se réalise via un processus de distillation de la fleur de la rose damascena. Les pétales de rose mis dans un alambic, sont distillés grâce à la vapeur d’eau. On récolte alors deux solutions: l’hydrolat dit aussi “eau florale” et l’huile essentielle de rose.
    
L'eau de rose damascena est connue pour ses propriétés hydratantes et nourrissantes qui aident à maintenir la peau douce et souple. Ceci est dû à sa teneur en huile essentielle de rose, qui est riche en vitamines et en acides gras essentiels. C’est un humectant qui aide à retenir l'humidité dans la peau et à prévenir la déshydratation.
    
C’est aussi un antiride très bénéfique pour les peaux matures car elle est naturellement riche en vitamine C et E. Ainsi, l’eau de rose favorise le renouvellement cellulaire ce qui repousse le processus de vieillissement du visage (rides, ridules et autres marques de vieillissement cutané). L'eau de rose contient une quantité importante de vitamine C, un antioxydant qui aide à stimuler la production de collagène : cela contribue à maintenir la peau ferme et élastique.
    
L’eau de rose est aussi un antibactérien et un anti-inflammatoire naturel qui nettoie et purifie les peaux très sensibles. Cette eau florale est astringente, elle resserre les pores de l’épiderme et de ce fait, combat l’excès de sébum des peaux grasses ou à tendance acnéique. Elle a aussi des bienfaits antioxydants qui protègent l’épiderme contre les radicaux libres (rayons UV, cigarettes, alcool, stress, manque de sommeil, pollution de l’air…). Ce produit limite les apparitions de rougeurs et apaise les irritations et autres problèmes dermatologiques (acné, dermatite, eczéma, psoriasis…). En plus de ses qualités hydratantes, antioxydantes et antibactériennes, l'eau de rose est également connue pour ses propriétés tonifiantes et apaisantes pour l’épiderme. Cette lotion est considérée comme un tonique qui stimule la circulation sanguine. Elle est donc aussi bonne pour la santé de votre corps !
    
Le parfum doux est agréable et relaxant ce qui peut aussi aider à apaiser l’esprit. Cette eau florale peut être particulièrement efficace pour réduire le stress et l’anxiété.`
  },
  {
    title: "Coquelicot",
    image: require("../../resources/ingredients/Coquelicot-min.png"),
    description: `C'est une plante herbacée annuelle, très abondante dans les terrains fraîchement remués à partir du printemps, qui se distingue par la couleur rouge de ses fleurs et par le fait qu'elle forme souvent de grands tapis colorés visibles de très loin.
    
Elle appartient au groupe des plantes dites messicoles car elle est associée à l'agriculture depuis des temps très anciens, grâce à son cycle biologique adapté aux cultures de céréales, la floraison et la mise à graines intervenant avant la moisson.
    
Très commune dans différents pays d’Europe et du nord de l’Afrique, elle a beaucoup régressé du fait de l'emploi généralisé des herbicides et de l'amélioration du tri des semences de céréales.
    
La poudre de Aker Fassi est un produit de beauté traditionnel, dérivé des pétales de coquelicot séchés, utilisé depuis des siècles dans les rituels de beauté au Maroc. Cette poudre fine et polyvalente est appréciée pour ses multiples bienfaits, tant pour les soins de la peau que pour le maquillage. La poudre d'Aker Fassi peut être utilisée dans une variété de soins de beauté. Que ce soit pour des masques revitalisants, des gommages doux ou des baumes à lèvres colorés, elle apporte une touche naturelle à votre routine de beauté. En tant que maquillage, elle offre une teinte rouge délicate, parfaite pour un look naturel et raffiné.
    
• Hydratation : Nourrit en profondeur et hydrate la peau, laissant un teint doux et éclatant.
• Revitalisation : Aide à redonner éclat et luminosité à la peau, tout en la protégeant des agressions extérieures.
• Apaisement : Réduit les irritations et apaise les peaux sensibles grâce à ses propriétés anti-inflammatoires naturelles.
• Maquillage Naturel : L’aker fassi est également utilisé comme un colorant naturel pour les lèvres et les joues, offrant une teinte rouge subtile et élégante.`
  },
  {
    title: "Huile de pépins de raisin",
    image: require("../../resources/ingredients/Coquelicot-min.png"),
    description: `L’huile de pépins de raisin, une matière Brute incorpore l’huile de pépins de raisin connue pour ses propriétés nourrissantes et antioxydantes. Elle aide l’épiderme à se régénérer tout en luttant contre le vieillissement cellulaire. Elle offre une réelle protection des membranes et s’associe parfaitement à d’autres actifs.
    
L’huile de pépins de raisin est utilisée seule ou en association avec d’autres huiles. Émolliente et régénérante, elle nourrit la peau. Non grasse, elle pénètre facilement dans la peau, c’est pourquoi elle est utilisée comme huile de massage pour le visage ou le corps. Elle peut s’adapter à tous les types de peaux, même les peaux sensibles. On lui prêterait même des vertus contre les troubles cardio-vasculaires et nerveux lorsqu’elle est consommée.
    
L’huile de pépins de raisin pénètre naturellement dans les fibres capillaires. Elle est très nutritive et peut donc être une alliée de choix pour les cheveux bouclés ou secs. Elle émollie, protège et régénère la peau du visage et du corps et aide à retarder le vieillissement de la peau.`
  },
  {
    title: "Huile de jojoba",
    image: require("../../resources/ingredients/jojoba-min.png"),
    description: `L’huile de jojoba est extraite d’une plante aux feuilles persistantes poussant en Californie, au Mexique et en Arizona. Les Amérindiens furent les premiers à recourir aux bénéfices de cette plante ; grâce à ses graines oléagineuses, ils soignaient les brûlures et les inflammations cutanées.
    
Cette huile est riche en acides gras monoinsaturés et en micronutriments tels que les vitamines A, B1, B2, B6 et E. Elle est utilisée dans la composition de produits pour la peau et les cheveux, pour ses caractéristiques antiseptiques, émollientes, hydratantes et nourrissantes. Sa structure moléculaire est similaire à celle de l’huile produite par nos glandes sébacées.
    
Grâce à ses propriétés, elle hydrate et assainit la peau et la rend plus douce, élastique et lisse. Elle est surtout recommandée pour les personnes ayant la peau sèche et crevassée.`
  },
  {
    title: "Moringa",
    image: require("../../resources/ingredients/moringa-min.png"),
    description: `Qualifié, selon les régions d’arbre à vie, arbre purificateur, arbre éternel ou même arbre à miracles, le Moringa oleifera, communément appelé Moringa, est originaire des vallées du sud Himalaya (Inde).
    
Le Moringa est aussi une plante vivrière, dont les fruits sont consommés en Inde, les feuilles comme légumes dans certaines zones sahéliennes. De fait, chaque partie de la plante, feuilles, fleurs, racines et même écorce présente un bénéfice pour la santé et la beauté.
    
Ses propriétés nourrissantes, assouplissantes, revitalisantes et réparatrices exceptionnelles sont dues à sa teneur élevée en vitamine E, antioxydants et anti-radicaux libres.
    
L’huile de Moringa aide la peau à rester souple et élastique, préserve le film hydrolipidique protecteur, stimule la réparation de l’épiderme. Elle stimule la production de collagène et lutte contre les ridules et les rides.
    
Elle renforce les peaux fragiles, calme les peaux sensibles et réactives sujettes aux irritations et desquamations grâce à l’apport de vitamine A, et d’acides gras insaturés (acide oléique).
    
Sur les peaux à imperfection, en utilisation régulière, elle prévient la réapparition de taches dues aux traces de boutons.
    
L’huile de Moringa sert aussi en parfumerie car elle absorbe et retient les senteurs.`
  },
  {
    title: "Romarin",
    image: require("../../resources/ingredients/romarin-min.png"),
    description: `Le Romarin, plante sacrée qui portait bonheur pour les Romains, est issue de la famille des Lamiacées. Le Romarin est originaire du bassin méditerranéen, d'Europe et d'Afrique du nord. L'huile essentielle de Romarin et son hydrolat sont produits à partir d'un processus de distillation à la vapeur d'eau des sommités fleuries. En cosmétique, le Romarin est utilisé pour ses propriétés anti-infectieuses, toniques et revitalisantes.
    
Le Romarin est conseillé pour son action tonique et stimulante, en cas de convalescence, de fatigue, de surmenage physique ou psychique. Son huile essentielle est anti-infectieuse et décongestionnante respiratoire (chémotype cinéole), en cas de bronchite, et conseillée également contre les cystites, les candidoses, les gastro-entérites, les sinusites, les otites, les refroidissements. Sur le plan cutané, c’est un régénérateur, un stimulant du cuir chevelu et de la repousse des cheveux. Utilisée sur la peau associée à une huile végétale ou dans une crème de jour, l’huile essentielle de Rose de Damas régénère et tonifie les peaux fatiguées. Elle combat les rides et aide à la cicatrisation de la peau. Grâce à ses propriétés antivirales, antibactériennes et antifongiques, elle est indiquée en cas de couperose, d’eczéma, d’herpès labial, de zona ou de toute autre inflammation cutanée.
    
L’hydrolat (eau florale) de Romarin, ou bien même l’infusion de sommités fleuries de romarin peuvent être directement versées dans l’eau du bain pour un effet tonique et revitalisant, y compris pour les enfants, contre les jambes lourdes ou une mauvaise circulation. L’alcoolature de Romarin est utilisée contre les rhumatismes ou les maux de dents.`
  },
  {
    title: "La réglisse",
    image: require("../../resources/ingredients/reglisse-min.png"),
    description: `La réglisse (Glycyrrhiza glabra L.) est une plante herbacée de la famille des Fabacées originaire d’Asie et du sud de l’Europe. C’est sa racine (ou rhizome) qui est principalement utilisée en médecine traditionnelle, en phytothérapie ou encore en cuisine. Très connue pour son usage en confiserie, la réglisse est pourtant retrouvée dans de nombreuses préparations médicinales. Sa richesse en principes actifs en a ainsi fait une plante incontournable, en médecine traditionnelle chinoise par exemple. Elle en fait également un actif cosmétique de choix, en particulier contre les taches. La réglisse est un ingrédient cosmétique reconnu, en particulier pour ses effets éclaircissants et son action calmante pour les peaux sensibles. Même si elle contient d’autres molécules actives, comme l’acide glycyrrhizique, c’est la glabridine qui est au cœur de son efficacité.
    
La glabridine régule la production de mélanine, pour une peau lumineuse, éclatante et un teint unifié. Elle agit en synergie sur les taches et l’hyperpigmentation avec la vitamine C. L’extrait de réglisse possède également une action adoucissante et apaisante, mais pas seulement grâce à la glabridine. Plusieurs molécules actives de la réglisse ont ainsi un effet anti-inflammatoire, qui permet d’apaiser les peaux sensibles, mais aussi de lutter contre l’hyperpigmentation post-inflammatoire. Pour toutes les peaux, l’effet apaisant de la réglisse contribue à lutter contre les signes de vieillissement cutané. L’inflammation est en effet un facteur de vieillissement prématuré de la peau.
    
Reconnue également pour ses propriétés antioxydantes dans une routine ciblée sur le confort cutané, elle lutte contre les radicaux libres afin de protéger la peau du stress oxydatif, et de ses conséquences sur le vieillissement cutané. Là encore, la réglisse agit en synergie avec la vitamine C, elle aussi un puissant antioxydant.
    
Au-delà de la cuisine et des médecines traditionnelles, la réglisse se révèle un véritable allié cosmétique pour atténuer les taches et soulager la sensibilité cutanée.`
  },
  {
    title: "BEURRE DE KARITÉ",
    image: require("../../resources/ingredients/BEURRE DE KARITÉ-min.png"),
    description: `Le beurre de karité est un des ingrédients phares très précieux, extrait du noyau des fruits d’un arbre d’Afrique centrale. Son extraction s’effectue encore sur le lieu d’origine, grâce à un processus artisanal qui permet de garder intact ses précieux principes actifs.
    
Utilisé depuis des siècles, il possède des propriétés régénérantes extraordinaires.
    
Très employé en dermatologie et en cosmétique pour ses vertus cicatrisantes, émollientes, antioxydantes et apaisantes grâce à sa teneur en vitamine E.
    
Ce qui le rend plus précieux encore est son taux très élevé en éléments insaponifiables, substances indispensables au maintien de l’élasticité naturelle de la peau, puisqu’elles interviennent dans la formation du collagène et de l’élastine. C’est un parfait produit anti-rides naturel qui redonne fermeté à la peau. Sa composition hydratante, anti-sècheresse, est idéale pour protéger la peau du soleil, du vent et du gel.`
  },
  {
    title: "HUILE D’AMANDE DOUCE",
    image: require("../../resources/ingredients/almond-min.png"),
    description: `L’huile d’amande douce est extraite par pression des graines des arbres homonymes, les amandiers, qui peuvent être amères ou douces.
    
Sa composition a des vertus élastiques et émollientes qui la rendent idéale pour une utilisation cosmétique ou dermatologique, par exemple pour des produits hydratants, antiprurigineux ou contre les problèmes liés aux brûlures. Un des ingrédients phares incontournables.
    
Appliquée sur la peau, l’huile d’amande pénètre parfaitement dans le film hydrolipidique, évitant l’évaporation de l’eau de la peau, étant ainsi bénéfique pour les peaux sèches.`
  },
  {
    title: "HUILE DE RICIN",
    image: require("../../resources/ingredients/huil de raacin-min.png"),
    description: `L’huile de ricin est une huile végétale très dense, obtenue par pression à froid des graines de la plante Ricinus communis, utilisée pour le soin des cheveux, de la peau et des cils (à usage externe).
    
L’huile de ricin est caractérisée par un fort taux en acide ricinoléique, un acide gras insaturé sous forme de triglycéride, non repérable dans les autres substrats lipidiques.
    
Elle possède une bonne affinité avec la kératine, principal constituant des poils, des cheveux et des ongles ; elle est donc surtout utilisée comme agent hydratant. Appliquée sur la peau, elle forme une pellicule qui réduit l’évaporation de l’eau hors. L’huile de ricin est aussi utilisée pour renforcer et donner du volume aux cils.`
  },
  {
    title: "Thé vert",
    image: require("../../resources/ingredients/the vert-min.png"),
    description: `Le thé vert qui est fabriqué à partir des feuilles de la plante Camellia Sinensis, est un ingrédient phare dans les soins de la peau grâce à ses nombreuses propriétés, bénéfiques à la santé de votre peau. Grâce à ses propriétés anti-inflammatoires, antioxydantes et antimicrobiennes, les bienfaits du thé vert sont multiples. La capacité du thé vert à réduire les sensations d'irritations, les rougeurs et les gonflements de la peau en fait un élément incontournable de votre routine de soins de la peau. Le thé vert pourrait contribuer à réduire l'apparence des cernes et des yeux gonflés notamment grâce à la présence de caféine et de tanins, qui peuvent aider à rétrécir les vaisseaux sanguins enflammés autour des yeux.
    
1. Le thé vert aiderait à lutter contre les signes du vieillissement.
2. Il réduirait les sensations d'irritations, les rougeurs et les gonflements de la peau.
3. Ses propriétés anti-inflammatoires pourraient contribuer à soulager les sensations d'irritations et de démangeaisons causées par le psoriasis, la dermatite et la rosacée.
4. Le thé vert est présent dans différents produits utilisés pour la peau sujette à l'acné car il a été démontré qu'il aide à lutter contre, notamment les boutons et la peau grasse grâce à ses propriétés antioxydantes et antimicrobiennes.
5. Le thé vert contient plusieurs vitamines, dont la vitamine E, aux propriétés antioxydantes qui peuvent être bénéfiques pour la peau mature.`
  },
  {
    title: "Arbre à thé",
    image: require("../../resources/ingredients/arbre de the-min.png"),
    description: `Originaire d'Australie, les propriétés antimicrobiennes de l'Arbre à Thé sont appréciées par les indigènes Australiens depuis des milliers d'années et aujourd'hui, l'huile d'Arbre à Thé est largement utilisée dans les produits de soins de la peau qui ciblent les taches et les imperfections. L'huile essentielle d'Arbre à Thé est obtenue par distillation à la vapeur d'eau des feuilles et des branches de l'Arbre à Thé. Que vous l'utilisiez pure sur un bouton gênant ou dans des produits cosmétiques dédiés à apaiser votre peau, l'huile d'Arbre à Thé est un ingrédient incontournable pour la beauté.
    
• Antimicrobienne et antibactérienne.
• L'huile d'Arbre à Thé est très efficace dans les soins de la peau grasse ou à imperfections, aidant à la purifier.
• Aide à apaiser et à soigner les peaux sujettes aux problèmes.`
  },
  {
    title: "Le safran",
    image: require("../../resources/ingredients/safron-min.png"),
    description: `Le safran a été utilisé comme colorant naturel depuis sa première importance dans l'histoire, colorant les tissus pour signifier le statut social et les fonctions religieuses.
    
Les femmes aristocratiques et les courtisanes d'autrefois utilisaient le safran comme cosmétique et soin de la peau. Les masques faciaux mélangés au safran sont appliqués par les femmes pour rendre leur peau douce, lisse et exempte d'acné et de boutons. Le bois de santal et le safran mélangés au lait utilisé comme masque rendent le visage lisse et éclatant. Le miel, l'huile de noix de coco et le sucre peuvent également être utilisés pour fabriquer des masques faciaux. La poudre de safran agit également comme un exfoliant, laissant la peau claire et éclatante. L'extrait de safran contient des propriétés antibactériennes qui traiteraient également les boutons, l'acné et d'autres maladies de la peau telles que le psoriasis et la lèpre.
    
Les parfumeurs modernes profitent du fait que le safran se mélange en note de cœur avec d'autres essences et parfums pour créer de nouvelles formules de parfums floraux.
    
Il contient une variété impressionnante de composés végétaux. Agissent comme des molécules antioxydantes qui protègent vos cellules contre les radicaux libres et le stress oxydatif. Il renforce le système immunitaire. Il améliore la santé cardiaque. Il peut réduire le taux de sucre dans le sang. Il soutient les fonctions de la mémoire. Il peut aider à réguler l'humeur. Il peut contribuer à réduire la pression artérielle.
    
Enfin, le safran est un cadeau beau et élégant lors d'occasions spéciales telles que Noël, les mariages, le Ramadan et d'autres événements religieux, car il est cher et a donc un symbole de statut élevé.`
  },
  {
    title: "Noix de coco",
    image: require("../../resources/ingredients/noix de coco-min.png"),
    description: `La noix de coco provient des cocotiers présents naturellement en Asie, en Afrique, en Amérique et sur les îles du Pacifique. Un seul cocotier peut produire entre 50 et 150 noix de coco par an environ, et il faut 11 à 12 mois pour que le fruit soit mûr. La noix de coco offre de nombreux bienfaits, et s’utilise sous différentes formes : huile, eau, lait... On vous dit tout !
    
Très riche en acide gras saturé, l’acide laurique, les propriétés de l’huile de coco sont en parfaite affinité avec la peau ! Nourrissante et apaisante, elle protège de la déshydratation et redonne de la souplesse, du confort et du bien-être à la peau.
    
De plus, reconnue riche en vitamines, elle possède des propriétés protectrices ainsi que des vertus apaisantes. La peau paraît plus souple, douce, nourrie et les sensations de sécheresse ou de tiraillement diminuent.
    
Ainsi, un soin visage avec de l’huile de coco est adapté à tous les types de peaux, particulièrement si vous recherchez hydratation et douceur. L’huile de coco en tant que soin du corps est également parfaite pour nourrir l’épiderme tout au long de l’année, été comme hiver !
    
L’huile de coco n’est pas seulement adaptée à la peau, mais également aux cheveux ! L’acide gras présent dans l’huile de coco possède également une affinité particulière avec la kératine, une protéine naturelle représentant environ 95% de la composition du cheveu. En effet, l’huile de coco va pénétrer la fibre capillaire afin de lui apporter toutes ses vertus. Le cheveu paraît plus hydraté, protégé, gainé et fortifié notamment, il sera ainsi mieux armé pour se défendre lors du coiffage, mais aussi contre les agressions extérieures (pollution, soleil, etc.).`
  },
  {
    title: "TOCOPHÉROL / VITAMINE E",
    image: require("../../resources/ingredients/vitamin E-min.png"),
    description: `Le tocophérol est un nutriment vitaminique communément appelé vitamine E. Il prévient l’oxydation des lipides polyinsaturés et agit comme antioxydant biologique.
    
Dans le corps humain, le tocophérol est le principal antioxydant liposoluble ayant un effet protecteur contre le stress oxydant. Sont riches en vitamine E les aliments d’origine végétale, en particulier les graines et les huiles qui en sont dérivées, suivies des céréales, des fruits et des légumes.
    
En tant qu’antioxydant, il joue un grand rôle dans l’élaboration et la conservation des ingrédients cosmétiques : en effet, il améliore la stabilité de nombreux éléments à contenu gras. La vitamine E est importante dans les processus anti-inflammatoires, pour l’inhibition de l’agrégation des thrombocytes et l’amélioration du fonctionnement du système immunitaire. Sa grande activité antioxydante permet d’atténuer la réactivité des radicaux libres qui endommagent les cellules, et protègent des rayons UV, du tabagisme et de la pollution qui dégradent les acides gras présents dans l’épiderme et altèrent la structure du collagène.
    
De cette façon, elle aide à maintenir l’élasticité de la peau et à retarder l’apparition des rides. C’est pourquoi elle est l’un des ingrédients phares dans la formulation des produits anti-âge.`
  },
  {
    title: "L’acide hyaluronique",
    image: require("../../resources/ingredients/acide hyaluronique-min.png"),
    description: `L’acide hyaluronique est un constituant naturel du derme qui agit comme une éponge en captant et maintenant l’eau. Sa quantité et sa qualité diminuent avec l’âge. De nombreux cosmétiques contiennent de l’acide hyaluronique, ainsi que la plupart des produits de comblement des rides. Les soins à l’acide hyaluronique s’intègrent dans une routine de soins anti-âge au quotidien pour permettre de lutter contre le vieillissement cutané et corriger les signes de vieillesse apparents. Matin et soir, suite au nettoyage complet de la peau, l’application d’un produit contenant de l’acide hyaluronique permettra d’agir sur l’épiderme en profondeur.`
  },
  {
    title: "La niacinamide",
    image: require("../../resources/ingredients/niacinamide-min.png"),
    description: `La niacinamide est une forme de vitamine B3 essentielle à votre métabolisme, dans la mesure où elle est nécessaire à la fabrication des coenzymes NAD et NADP. Une carence alimentaire en vitamine B3 provoque une maladie caractérisée par une dermatite photosensible. Utilisée également dans le domaine des cosmétiques, la niacinamide apporte plusieurs bienfaits pour la peau, notamment en matière de lutte contre le vieillissement cutané, les irritations et les inflammations.
    
La niacinamide (également appelée nicotinamide) est l'une des trois formes que peut prendre la vitamine B3, les deux autres étant la niacine et le nicotinamide riboside. Ces composés partagent des fonctions similaires, mais possèdent des caractéristiques chimiques légèrement différentes qui influencent leur utilisation en cosmétique et en nutrition.
    
Chimiquement, la structure de la niacinamide lui confère des propriétés uniques, permettant une grande solubilité dans l’eau et une stabilité dans les formulations cosmétiques, ce qui en fait un choix privilégié dans de nombreux produits de soins de la peau.
    
La niacinamide est donc souvent la vitamine B3 privilégiée dans le domaine des soins de la peau, et un certain nombre de ses bienfaits pour la peau ont été identifiés au fil des études effectuées. L’un des principaux avantages de la niacinamide concerne son action sur la barrière cutanée. En effet, elle stimule la production de céramides, des lipides qui jouent un rôle crucial dans le maintien de l’hydratation de la peau et la protection contre les agressions extérieures. Ainsi, elle améliore significativement la fonction barrière de la peau, réduisant la perte d’eau transepidermique et augmentant l’hydratation de l’épiderme. Elle aide aussi à réduire les signes visibles du vieillissement cutané. En stimulant la production de collagène et en neutralisant les radicaux libres (grâce à ses propriétés antioxydantes), elle contribue à diminuer l’apparence des rides et des ridules. Résultat : elle vous aide à retrouver une peau visiblement plus souple et tonique.
    
Sachez que la niacinamide est extrêmement réputée car elle est couramment utilisée pour apaiser les peaux grasses à tendance acnéique. En effet, son action anti-inflammatoire rééquilibre la production de sébum pour prévenir les nouvelles imperfections. De même, ces propriétés la rendent très appréciées des peaux sujettes à la rosacée ou à l’eczéma, réduisant les rougeurs et les irritations liées à ces affections cutanées.`
  },
  {
    title: "Coffea Arabica",
    image: require("../../resources/ingredients/Coffea arabica-min.png"),
    description: `Le caféier, d'appellation botanique Coffea arabica, est un arbuste vivace au feuillage persistant appartenant à la famille des Rubiacées. Celui-ci produit des fruits rouges ressemblant à des cerises. Ces fruits contiennent chacun deux graines, qui sont les graines de café Arabica. Originaire d'Éthiopie, le caféier s'épanouit et se cultive particulièrement dans les zones tropicales de la planète comme l'Afrique de l'Est et l'Amérique Centrale (Costa Rica, Honduras) et du Sud (Brésil). Le caféier peut atteindre 10 mètres de hauteur à l'état sauvage. On le cultive principalement pour ses graines, à l'origine de la fameuse boisson, le café. Près de 150 millions de sacs de 60 kg de café sont produits chaque année à travers le monde. Aujourd’hui, le plus gros producteur mondial est le Brésil.
 Qu’est-ce-que le "Coffea Arabica Seed Oil" et quelle est son utilité ? 
Il s'agit du nom I.N.C.I. pour désigner le macérât huileux de café vert. Riche en caféine, cet ingrédient est reconnu pour ses propriétés drainantes et tonifiantes. En application cutanée, la caféine possède des propriétés amincissantes, grâce à son activité lipolytique sur les cellules graisseuses. 
Le macérât huileux de café vert, procédé d'obtention et utilisation cosmétique :
Le macérât huileux de café vert a donc pour appellation I.N.C.I. :"Coffea Arabica Seed oil". Il est issu d'une macération longue des graines arabica préalablement séchées suivie d'une agitation dans une huile végétale. Le composé qui en résulte est un liquide de couleur jaune-vert riche en glucides, en lipides (stérols, hydrocarbures, tocophérols, alcools diterpéniques) et en acides phénols (acide quinique, acide caféique, acide chlorogénique).
Le macérât huileux de café vert possède des propriétés drainantes, tonifiantes et amincissantes. Il favorise l'hydrolyse des triglycérides accumulés dans les adipocytes (cellules graisseuses) et leur libération. Son mode d'action repose sur l'inhibition de l’activité de la phosphodiestérase, une enzyme responsable de la destruction de l’AMPc, un médiateur chimique impliqué dans la rupture des triglycérides. Il est ainsi préconisé pour atténuer l'aspect de la cellulite et redonner du tonus aux peaux relâchées. 
En outre, la caféine contenue dans le macérât huileux est un excellent tonifiant vasculaire qui permet de drainer le contour des yeux en stimulant la microcirculation. En rétrécissant le calibre des petits vaisseaux sanguins, la pigmentation violacée des cernes due à la couleur des capillaires sanguins s'estompe durablement. La caféine s'avère également très efficace sur les poches, dans la mesure où elle permet de diminuer la rétention d'eau sous les yeux et donc de réduire visiblement les gonflements.
Enfin, la caféine a de puissantes propriétés antioxydantes. Elle aide à protéger les cellules contre les radicaux libres générés par les rayonnements UV et ralentit le processus de photovieillissement de la peau.
Dans quels soins retrouver du macérât huileux de café vert ?
Cet ingrédient est surtout préconisé pour les peaux relâchées, manquant de tonus. Il est ainsi présent dans certains soins pour le corps comme des crèmes anticellulite, des gommages ou encore des gels raffermissants. En soins visage, il est surtout utilisé pour atténuer l'aspect des cernes et des poches donc on le retrouve principalement dans des crèmes et gels à destination du contour des yeux.
Chez ARGORI, ce composé est présent dans notre premium huile contours des yeux anti-cernes et anti-rides qui raffermit et tonifie la peau reconnu pour ses propriétés drainantes, tonifiantes et amincissantes. Il favorise l'hydrolyse des triglycérides et très efficace sur les poches.
`
  }
];

const IngredientsFR = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <header className="mb-8 text-center">
        <h1 className="text-3xl font-bold text-brand-color">
          ARGORI COSMETICS : LES INGRÉDIENTS PHARES ET LEURS EFFETS
        </h1>
        <p className="mt-4 text-lg text-gray-600">
          Découvrez les ingrédients phares présents dans nos cosmétiques bio. ARGORI Cosmétiques est né de l’envie de créer des produits cosmétiques de qualité exceptionnelle et biologiques, fabriqués au Maroc, sans utiliser d’ingrédients nocifs pour la santé ni testés sur les animaux. Cette marque française a l’intention que son nom devienne synonyme de produits cosmétiques qui soient le plus naturel et « pur » possible, sans que ses clients ne doivent renoncer à des performances de haute qualité. L’engagement et la valeur de l’entreprise dans le domaine du « bio » sont garantis par les certifications ECOCERT NOP & ECOCERT BIO UE.
        </p>
        <h2 className="mt-6 text-2xl font-semibold">
          NOS INGRÉDIENTS PHARES SONT INCONTOURNABLES: LA NATURE AU CENTRE DE NOS COSMÉTIQUES
        </h2>
      </header>
      {ingredientsData.map((ingredient, index) => (
        <div
          key={index}
          className={`flex flex-col md:flex-row ${index % 2 !== 0 ? "md:flex-row-reverse" : ""
            } items-center mb-12`}
        >
          <div className="md:w-1/2">
            <img
              src={ingredient.image}
              alt={ingredient.title}
              className="w-full h-auto rounded-lg shadow-md"
            />
          </div>
          <div className="md:w-1/2 md:px-6 mt-6 md:mt-0">
            <h3 className="text-xl font-bold mb-4 text-brand-color">{ingredient.title.toUpperCase()}</h3>
            <p className="text-gray-700 whitespace-pre-line">
              {ingredient.description}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default IngredientsFR;
