import { decodeHtmlTags } from '@mbs-dev/react-helpers'
import { DescriptionElementType } from '@utils/types/Product'
import React from 'react'

type DecodeDataProps = {
  content: string,
  className?: string
}

let DescriptionElement: React.FC<DescriptionElementType> = ({ title, content }) => {

  function DecodeData({ content, className }: DecodeDataProps) {
    return (
      <span className={`${className}`}
        dangerouslySetInnerHTML={{ __html: decodeHtmlTags(content.replaceAll('\\n', "<br>")) }}>
      </span>
    )
  }

  return (
    <div className='flex flex-col gap-2 '>
      <h5 className="text-2xl font-bold underline">{title} :</h5>
      <DecodeData content={content} className="overflow-wrap: break-words" />
    </div>
  )
}

export default DescriptionElement