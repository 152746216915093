import { API } from "./API_INSTANCE"

type websiteInfoType = (languageCode : string) => Promise<void> 

export const getWebsiteInfo:websiteInfoType = ( languageCode ) => {
    return API.get('/website_infos',{
        headers: {
            Accept:'application/json'
        },
        params:{
            language_code : languageCode,
        }
    })
    .then((res) => res.data)
    .catch((err) => { throw err })
}