import { ProfileTabProps } from '@utils/types/ProfileType'
import React from 'react'

const ProfileTab: React.FC<ProfileTabProps> = ({
  title,
  action,
  Icon,
  selected = false
}) => {
  return (
    <div
      className={`border p-3 rounded-xl cursor-pointer flex flex-row items-center gap-2 transition-colors duration-300
        ${selected ? 'border-brand-darker' : 'hover:border-brand-darker'}
      `}
      onClick={action}
    >
      <Icon
        size={30}
        className={`transition-colors duration-300 
          ${selected ? 'text-brand-darker' : 'text-gray-400 hover:text-brand-darker'}
        `}
      />
      <p
        className={`text-xl font-bold transition-colors duration-300
          ${selected ? 'text-brand-darker' : 'text-gray-500 hover:text-brand-darker'}
        `}
      >
        {title}
      </p>
    </div>
  )
}

export default ProfileTab
