import { components, translation } from '@context/Index'
import React from 'react'

const Ingredients = () => {

    const { IngredientsEN, IngredientsFR } = components
    const { language } = translation.useTranslation().i18n

    return (
        <div>
            {language.split('-')[0] === "en" ? <IngredientsEN /> : <IngredientsFR />}
        </div>
    )
}

export default Ingredients