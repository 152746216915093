import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { postNewPartner } from '@utils/api/BecomePartnerApi';
import { errorNotify, successNotify } from '@mbs-dev/react-helpers';
import { components } from '@context/Index';
import { ENUMS } from '@helpers/ENUMS';

interface FormValues {
  fullName: string;
  societe: string;
  responsabilite: string;
  sirenne: string;
  address: string;
  email: string;
  telephone: string;
  message: string;
  acceptTerms: boolean;
}

const initialValues: FormValues = {
  fullName: 'Dupont',
  societe: 'TechCorp',
  responsabilite: 'Project Manager',
  sirenne: '123456789',
  address: '123 Rue de la République, Paris',
  email: 'jean.dupont@example.com',
  telephone: '+33123456789',
  message: 'I am interested in becoming a partner and would like to discuss further opportunities.',
  acceptTerms: true,
};

export type BecomePartnerFormProps = { partnerType: string };

const BecomePartnerForm: React.FC<BecomePartnerFormProps> = ({ partnerType }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState<boolean>(false);
  const { ActivityIndicator } = components

  const validationSchema = Yup.object({
    fullName: Yup.string().required(t('becomePartner.form.validation.required', { field: t('becomePartner.form.fields.fullName') })),
    societe: Yup.string().required(t('becomePartner.form.validation.required', { field: t('becomePartner.form.fields.societe') })),
    responsabilite: Yup.string().required(t('becomePartner.form.validation.required', { field: t('becomePartner.form.fields.responsabilite') })),
    sirenne: Yup.string().required(t('becomePartner.form.validation.required', { field: t('becomePartner.form.fields.sirenne') })),
    address: Yup.string().required(t('becomePartner.form.validation.required', { field: t('becomePartner.form.fields.address') })),
    email: Yup.string().email(t('becomePartner.form.validation.invalidEmail')).required(t('becomePartner.form.validation.required', { field: t('becomePartner.form.fields.email') })),
    telephone: Yup.string().required(t('becomePartner.form.validation.required', { field: t('becomePartner.form.fields.telephone') })),
    message: Yup.string().required(t('becomePartner.form.validation.required', { field: t('becomePartner.form.fields.message') })),
    acceptTerms: Yup.boolean().oneOf([true], t('becomePartner.form.validation.acceptTerms')),
  });

  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      let body = {
        ...values,
        type: partnerType
      }
      console.log( body);
      setLoading(true);
      postNewPartner(body)
        .then(res => {
          setLoading(false);
          successNotify(t('becomePartner.form.success'), 1500);
        })
        .catch(err => {
          setLoading(false);
          errorNotify(t('becomePartner.form.error'), 1500);
          console.error(err);
        })
      // Add your submission logic here (e.g., API call)
    },
  });

  const fields = [
    { name: 'fullName', label: t('becomePartner.form.fields.fullName'), type: 'text' },
    { name: 'societe', label: t('becomePartner.form.fields.societe'), type: 'text' },
    { name: 'responsabilite', label: t('becomePartner.form.fields.responsabilite'), type: 'text' },
    { name: 'sirenne', label: t('becomePartner.form.fields.sirenne') + " / " + t('becomePartner.form.fields.sirette') , type: 'text' },
    { name: 'address', label: t('becomePartner.form.fields.address'), type: 'text' },
    { name: 'email', label: t('becomePartner.form.fields.email'), type: 'email' },
    { name: 'telephone', label: t('becomePartner.form.fields.telephone'), type: 'tel' },
  ];

  return (
    <div className="max-w-3xl mx-auto p-4">
      <form onSubmit={formik.handleSubmit} className="space-y-4">
        <div className="flex flex-row flex-wrap gap-2 box-content">
          {fields.map((field) => (
            <div key={field.name} className='min-w-[calc(50%-0.5rem)] flex-grow'>
              <label htmlFor={field.name} className="block font-medium">
                {field.label}
              </label>
              <input
                id={field.name}
                name={field.name}
                type={field.type}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values[field.name as keyof FormValues] as string}
                className="block w-full border border-gray-300 rounded-full p-2"
              />
              {formik.touched[field.name as keyof FormValues] && formik.errors[field.name as keyof FormValues] && (
                <div className="text-red-500 text-sm">
                  {formik.errors[field.name as keyof FormValues]}
                </div>
              )}
            </div>
          ))}
        </div>

        <div>
          <label htmlFor="message" className="block font-medium">
            {t('becomePartner.form.fields.message')}
          </label>
          <textarea
            id="message"
            name="message"
            rows={4}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.message}
            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
          />
          {formik.touched.message && formik.errors.message && (
            <div className="text-red-500 text-sm">{formik.errors.message}</div>
          )}
        </div>

        <div className="flex items-center">
          <input
            id="acceptTerms"
            name="acceptTerms"
            type="checkbox"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            checked={formik.values.acceptTerms}
            className="mr-2"
          />
          <label htmlFor="acceptTerms" className="font-medium">
            {t('becomePartner.form.fields.acceptTerms')}
          </label>
        </div>
        {formik.touched.acceptTerms && formik.errors.acceptTerms && (
          <div className="text-red-500 text-sm">{formik.errors.acceptTerms}</div>
        )}

        {loading
          ? <ActivityIndicator size={30} color={ENUMS.COLORS.BRAND_DARK} />
          :<button
          type="submit"
            disabled={formik.isSubmitting}
            className="py-2 px-4 bg-brand-color text-white rounded hover:bg-brand-darker transition duration-300"
          >
            {t('becomePartner.form.submit')}
          </button>}
      </form>
    </div>
  );
};

export default BecomePartnerForm;