import { formikHook } from '@context/Index'
import { OnlineOrderFormIV } from '@utils/initValues/OnlineOrderForm'
import React from 'react'

const OnlineOrderForm = () => {
    const { useFormik } = formikHook
    // const formik = useFormik({
    //     initialValues:OnlineOrderFormIV,
        
    // })
    return (
        <div>
            
        </div>
    )
}

export default OnlineOrderForm