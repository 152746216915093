import React from 'react';

const PrivacyPolicyFR = () => {
    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold text-center mb-6">Politique de confidentialité</h1>
            
            <p className="mb-4">
                La société S.A.S ARGORI gère le site Web : <a href="https://www.argori.com" className="text-blue-500 hover:underline">www.argori.com</a>. La société S.A.S ARGORI est, par conséquent, responsable de la collecte, du traitement et de l’utilisation de vos données à caractère personnel ou professionnel et de la conformité du traitement des données avec les lois sur la protection des données en vigueur.
            </p>
            
            <p className="mb-4">
                Votre confiance étant très importante pour nous, nous prenons très au sérieux la question de la protection des données pour vous garantir une sécurité maximale. Nous respectons les dispositions réglementaires légales européennes de protection des données en vigueur, en particulier le règlement général sur la protection des données (RGPD).
            </p>
            
            <h2 className="text-2xl font-semibold mt-6 mb-4">Quelles données traitons-nous lorsque vous visitez notre site Web ?</h2>
            <p className="mb-4">
                Nous vous demandons vos coordonnées personnelles (B2C) et professionnelles (B2B), y compris votre nom et votre adresse e-mail. Lorsqu'un achat est effectué, nous vous demanderons également une adresse de livraison ainsi que des informations de paiement. Lorsque vous naviguez sur notre site Web : <a href="https://www.argori.com" className="text-blue-500 hover:underline">www.argori.com</a>, nous recevons automatiquement l'adresse IP de votre ordinateur, ce qui nous aide à en savoir plus sur votre navigateur et votre système d'exploitation.
            </p>
            
            <h2 className="text-2xl font-semibold mt-6 mb-4">Est-ce que nous collectons déjà des données auprès de vous lorsque vous visitez notre site Web ?</h2>
            <p className="mb-4">
                Lorsque vous vous rendez sur notre site Web : <a href="https://www.argori.com" className="text-blue-500 hover:underline">www.argori.com</a>, nos serveurs enregistrent temporairement tout accès dans un fichier journal. Nous collectons et enregistrons sans votre intervention les données techniques suivantes jusqu’à ce qu’elles soient automatiquement supprimées, comme c’est le cas pour toute connexion à un serveur Web :
            </p>
            <ul className="list-disc list-inside mb-4">
                <li>L’adresse IP de l’ordinateur utilisé</li>
                <li>La date et l’heure d’accès</li>
                <li>Le site Web d’où l’accès a été réalisé (URL du référent), le cas échéant avec le terme de recherche utilisé</li>
                <li>Le nom et l’URL du fichier récupéré</li>
                <li>Le code d’état (par ex. message d’erreur)</li>
                <li>Le système d’exploitation de votre ordinateur</li>
                <li>Le navigateur utilisé (type, version et langue)</li>
                <li>Le protocole de transmission utilisé (par ex. HTTP/1.1), et …..</li>
                <li>Le nom d’utilisateur de l’inscription/authentification, le cas échéant</li>
            </ul>
            
            <h2 className="text-2xl font-semibold mt-6 mb-4">Quelles données collectons-nous lorsqu’il s’agit de B2B ?</h2>
            <p className="mb-4">
                Via notre site Web, vous avez la possibilité de demander un devis, des informations et des conseils concernant nos produits ou d’autres marchandises. Pour cela, nous vous demandons les informations suivantes, notamment, afin de créer un compte :
            </p>
            <ul className="list-disc list-inside mb-4">
                <li>Nom et Prénom</li>
                <li>Nom de l’entreprise</li>
                <li>Numéro de téléphone</li>
                <li>Adresse électronique</li>
                <li>Numéro de Sirène ou Siret</li>
                <li>Numéro de TVA</li>
            </ul>
            
            <h2 className="text-2xl font-semibold mt-6 mb-4">Comment obtenez-vous mon consentement ?</h2>
            <p className="mb-4">
                Lorsque vous nous fournissez des informations personnelles pour effectuer une transaction, vérifier votre carte de crédit, passer une commande, organiser une livraison ou retourner un achat, nous impliquons que vous consentez à ce que nous les collections et les utilisions pour cette raison spécifique uniquement.
            </p>
            
            <h2 className="text-2xl font-semibold mt-6 mb-4">Que sont des cookies ? Est-ce que les cookies sont vraiment nécessaires ?</h2>
            <p className="mb-4">
                Un cookie est un petit fichier qui demande la permission d'être placé sur le disque dur de votre ordinateur. Les cookies permettent aux applications Web de vous répondre en tant qu'individu et nous aident à vous fournir un meilleur site Web, en l'adaptant à vos besoins. Vous pouvez choisir d'accepter ou de refuser les cookies, cela peut vous empêcher de profiter pleinement du site.
            </p>
            
            <h2 className="text-2xl font-semibold mt-6 mb-4">Est-ce que les outils de suivi sont vraiment nécessaires ?</h2>
            <p className="mb-4">
                Nous utilisons le service d’analyse Web de Google Analytics afin de concevoir et d’optimiser en permanence notre site Web afin de répondre à vos besoins. Dans ce cadre, des profils utilisateur pseudonymisés sont créés et de petits fichiers texte (« cookies ») sont enregistrés sur votre ordinateur. Les informations de votre utilisation de ce site Web générées par les cookies sont transmises aux serveurs des fournisseurs de ces services, y sont stockées, puis nous les traitons.
            </p>
            
            <h2 className="text-2xl font-semibold mt-6 mb-4">Stockage et échange de données avec des tiers ?</h2>
            <p className="mb-4">
                Vos données nous aident à comprendre vos besoins et à vous fournir un meilleur service. Nous pouvons occasionnellement envoyer des e-mails concernant de nouveaux produits, des offres spéciales ou d'autres informations que nous pensons susceptibles de vous intéresser en utilisant l'adresse e-mail que vous avez fournie. Argori ne divulguera aucune de vos informations à des tiers, sauf dans le cas où cela fait partie de la fourniture d'un service.
            </p>
            
            <h2 className="text-2xl font-semibold mt-6 mb-4">Avez-vous d’autres questions ?</h2>
            <p className="mb-4">
                Vous pouvez exercer votre droit à l’accès, la rectification, l’annulation et la limitation du traitement des données ainsi que votre droit au transfert de données. Vous avez le droit de demander des informations sur les données à caractère personnel que nous stockons vous concernant. Vous avez également le droit de faire corriger des données incorrectes et de demander la suppression de vos données à caractère personnel, tant qu’aucune obligation légale n’exige leur stockage, ou autorisation qui nous permette de traiter ces données.
            </p>
            
            <p className="mb-4">
                Si vous avez des questions ou souhaitez faire des commentaires sur nos mentions légales ou sur la protection des données, écrivez-nous à <a href="mailto:argori.info@gmail.com" className="text-blue-500 hover:underline">argori.info@gmail.com</a>, <a href="mailto:info@argori.com" className="text-blue-500 hover:underline">info@argori.com</a>, ou <a href="mailto:contact@argori.com" className="text-blue-500 hover:underline">contact@argori.com</a>.
            </p>
        </div>
    );
};

export default PrivacyPolicyFR;