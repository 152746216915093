import React, { createContext, useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useAuth } from "@context/AuthContext";
// import { useTranslation } from 'react-i18next';
import { translation } from "@context/Index"
import { GetCategories } from '@utils/api/CategoriesApi';
import { getWebsiteInfo } from '@utils/api/WebsiteInfo';
import { getGeoInfo } from '@utils/api/GlobalApi';

interface GeoInfoProps {
    code: string;
    name: string;
    currency: string;
    latitude: string;
    longitude: string;
}


const BaseContextContext = createContext<{
    categories: any;
    websiteInfos: any;
    geoInfo: GeoInfoProps;
    // countries: Country[] | undefined;
    // languages: Language[] | undefined;
    // userLanguage: string | undefined;
    isLoadingCategories: boolean;
    isErrorCategories: boolean;
    isFetchingCategories: boolean;
    isLoadingWebsiteInfos: boolean;
    isErrorWebsiteInfos: boolean;
    isFetchingWebsiteInfos: boolean;
    isLoadingGeoInfo: boolean
    isErrorGeoInfo: boolean
    isFetchingGeoInfo: boolean
    getCurrencySymbol: (currencyCode: string) => string;
}>({
    categories: [],
    websiteInfos: [],
    geoInfo: {
        code: '',
        name: '',
        currency: '',
        latitude: '',
        longitude: ''
    },
    // countries: [],
    // languages: [],
    // userLanguage: undefined,
    isLoadingCategories: false,
    isErrorCategories: false,
    isFetchingCategories: false,
    isLoadingWebsiteInfos: false,
    isErrorWebsiteInfos: false,
    isFetchingWebsiteInfos: false,
    isLoadingGeoInfo: false,
    isErrorGeoInfo: false,
    isFetchingGeoInfo: false,
    getCurrencySymbol: () => "",
});

export const useBaseContext = () => useContext(BaseContextContext);

export const BaseProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {

    const { i18n } = translation.useTranslation();
    const currencySymbols: { [key: string]: string } = {
        MAD: "MAD",
        USD: "$",
        EUR: "€",
        GBP: "£",
        JPY: "¥",
        AUD: "$",
        CAD: "$",
        CHF: "Fr.",
        CNY: "¥",
        INR: "₹",
        RUB: "₽",
        BRL: "R$",
        MXN: "$",
        ZAR: "R",
        KRW: "₩",
        SEK: "kr",
        NOK: "kr",
        DKK: "kr",
        NZD: "$",
        SGD: "$",
        HKD: "$",
        PLN: "zł"
    };

    const getCurrencySymbol = (currencyCode: string): string => currencySymbols[currencyCode] || "";

    const {
        data: categories,
        isLoading: isLoadingCategories,
        isError: isErrorCategories,
        isFetching: isFetchingCategories,
    } = useQuery({
        queryKey: ['categories', i18n.language],
        queryFn: () => GetCategories("NULL", i18n.language),
        staleTime: 5 * 60 * 1000,
        refetchOnWindowFocus: true,
    });

    const {
        data: websiteInfos,
        isLoading: isLoadingWebsiteInfos,
        isError: isErrorWebsiteInfos,
        isFetching: isFetchingWebsiteInfos,
    } = useQuery({
        queryKey: ['websiteInfos', i18n.language],
        queryFn: () => getWebsiteInfo(i18n.language),
        staleTime: 5 * 60 * 1000,
    });


    const {
        data: geoInfo,
        isLoading: isLoadingGeoInfo,
        isError: isErrorGeoInfo,
        isFetching: isFetchingGeoInfo,
    } = useQuery({
        queryKey: ['geoInfo'],
        queryFn: getGeoInfo,
        staleTime: 5 * 60 * 1000,
    });

    // const {
    //     data: countries,
    //     isLoading: isLoadingCountries,
    //     isError: isErrorCountries,
    //     isFetching: isFetchingCountries,
    // } = useQuery({
    //     queryKey: ['countries'],
    //     queryFn: () => API.get('/countries.json').then((res) => res.data),
    //     staleTime: 5 * 60 * 1000,
    //     refetchOnWindowFocus: true,
    // });


    // const {
    //     data: languages,
    //     isLoading: isLoadingLanguages,
    //     isError: isErrorLanguages,
    //     isFetching: isFetchingLanguages,
    // } = useQuery({
    //     queryKey: ['languages'],
    //     queryFn: () => API.get('/languages.json').then((res) => res.data),
    //     staleTime: 5 * 60 * 1000,
    //     refetchOnWindowFocus: true,
    // });


    // const userLanguage = navigator.language
    // React.useEffect(() => { i18n.changeLanguage(userLanguage); }, [userLanguage])

    const baseValues = {
        categories,
        websiteInfos,
        geoInfo,
        // countries, 
        // languages, 
        // userLanguage,
        isLoadingCategories,
        isErrorCategories,
        isFetchingCategories,
        isLoadingWebsiteInfos,
        isErrorWebsiteInfos,
        isFetchingWebsiteInfos,
        isLoadingGeoInfo,
        isErrorGeoInfo,
        isFetchingGeoInfo,
        getCurrencySymbol
    }
    return (
        <BaseContextContext.Provider value={baseValues}>
            {children}
        </BaseContextContext.Provider>
    );
};
