import { components, translation } from '@context/Index'
import { ENUMS } from '@helpers/ENUMS'
import React from 'react'

const Ambassador = () => {
  const { Header, Breadcrumbs, BecomePartnerForm, BecomeAmbassadorDescEN, BecomeAmbassadorDescFR, AmbassadorSubHeader } = components
  const {t, language} = translation.useTranslation().i18n
  return (
    <>
      <Header title={t('navBar.become') + ' ' + t('navBar.ambassador').toLowerCase()}/>
      <Breadcrumbs />
      { language.split('-')[0] === 'en' ? <BecomeAmbassadorDescEN /> :<BecomeAmbassadorDescFR />  }
      <AmbassadorSubHeader/>
      <BecomePartnerForm partnerType={ENUMS.PARTNER_TYPE.BECOME_DARGORI_AMBASSADOR}/>
    </>
  )
}

export default Ambassador