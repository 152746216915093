import { uploadUrl } from '@helpers/Helpers';
import React from 'react';


export interface Review {
  id: number;
  rating: number;
  message: string;
  status: boolean;
  user: any;
}

interface ReviewCardProps {
  review: Review;
}

const ReviewCard: React.FC<ReviewCardProps> = ({ review }) => {
  // If review is not approved, you can either hide it or display a message.
  // Here we display the review in a different style with a "Pending" badge.
  if (!review.status) {
    return (
      <div className="bg-gray-100 border border-gray-300 rounded-lg p-6 flex flex-col gap-4 h-full">
        <div className="flex items-center ">
          <img
            src={uploadUrl +review.user.photo}
            alt={review.user.username}
            className="w-12 h-12 rounded-full mr-4"
          />
          <div>
            <h2 className="text-lg font-bold">{review.user.fullName}</h2>
            <p className="text-gray-600 text-sm">{review.user.email}</p>
          </div>
        </div>
        <div className="">
          <p className="text-gray-500 italic">
            This review is pending approval.
          </p>
        </div>
        <div className="flex items-center">
          {[...Array(5)].map((_, index) => (
            <svg
              key={index}
              className={`w-5 h-5 ${
                index < review.rating ? 'text-yellow-500' : 'text-gray-300'
              }`}
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M9.049 2.927C9.309 2.149 10.69 2.149 10.951 2.927l1.286 3.948a1 1 0 00.95.69h4.146c.969 0 1.371 1.24.588 1.81l-3.357 2.44a1 1 0 00-.364 1.118l1.287 3.947c.26.778-.637 1.423-1.305.95L10 13.011l-3.357 2.44c-.668.473-1.565-.172-1.305-.95l1.287-3.947a1 1 0 00-.364-1.118L2.3 9.375c-.783-.57-.38-1.81.588-1.81h4.146a1 1 0 00.95-.69L9.05 2.927z" />
            </svg>
          ))}
        </div>
      </div>
    );
  }

  // If the review is approved, display the full review.
  return (
    <div className=" bg-white shadow-md rounded-lg p-6 flex flex-col gap-4 h-full">
      <div className="flex items-center ">
        <img
          src={uploadUrl +  review.user.photo}
          alt={review.user.username}
          className="w-12 h-12 rounded-full mr-4"
        />
        <div>
          <h2 className="text-lg font-bold">{review.user.fullName}</h2>
          <p className="text-gray-600 text-sm">{review.user.email}</p>
        </div>
      </div>
      <div className="">
        <p className="text-gray-800">{review.message}</p>
      </div>
      <div className="flex items-center">
        {[...Array(5)].map((_, index) => (
          <svg
            key={index}
            className={`w-5 h-5 ${
              index < review.rating ? 'text-yellow-500' : 'text-gray-300'
            }`}
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M9.049 2.927C9.309 2.149 10.69 2.149 10.951 2.927l1.286 3.948a1 1 0 00.95.69h4.146c.969 0 1.371 1.24.588 1.81l-3.357 2.44a1 1 0 00-.364 1.118l1.287 3.947c.26.778-.637 1.423-1.305.95L10 13.011l-3.357 2.44c-.668.473-1.565-.172-1.305-.95l1.287-3.947a1 1 0 00-.364-1.118L2.3 9.375c-.783-.57-.38-1.81.588-1.81h4.146a1 1 0 00.95-.69L9.05 2.927z" />
          </svg>
        ))}
      </div>
    </div>
  );
};

export default ReviewCard;
