import { t } from 'i18next';
import * as Yup from 'yup';

export const SignUpSchema = Yup.object({
    fullName: Yup.string().required(
        t('signUp.form.validation.required', { field: t('signUp.form.fields.fullName') })
    ),
    email: Yup.string()
        .email(t('signUp.form.validation.invalidEmail'))
        .required(t('signUp.form.validation.required', { field: t('signUp.form.fields.email') })),
    username: Yup.string().required(
        t('signUp.form.validation.required', { field: t('signUp.form.fields.username') })
    ),
    password: Yup.string()
        .min(6, t('signUp.form.validation.minLength', { field: t('signUp.form.fields.password'), length: 6 }))
        .required(t('signUp.form.validation.required', { field: t('signUp.form.fields.password') })),
    photo: Yup.mixed().nullable(),
    newsletter: Yup.boolean(),
    birthday: Yup.string()
        .nullable()
        .matches(
            /^\d{4}-\d{2}-\d{2}$/,
            t('signUp.form.validation.invalidDate')
        )
});