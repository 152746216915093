import { useAuth } from '@context/AuthContext';
import { components, translation } from '@context/Index';
import { ENUMS } from '@helpers/ENUMS';
import Icons from '@helpers/IconsHelper';
import { AnimatePresence } from 'framer-motion';
import React from 'react'

const ManageAddresses = () => {

    let [selectedBillingAddress, setSelectedBillingAddress] = React.useState<{ label: string; value: string } | null>(null)
    let [selectedDeliveryAddress, setSelectedDeliveryAddress] = React.useState<{ label: string; value: string } | null>(null)
    let [BILLINGADDRESSES, setBILLINGADDRESSES] = React.useState([])
    let [DELIVERYADDRESSES, setDELIVERYADDRESSES] = React.useState([])
    let [defaultAddressType, setDefaultAddressType] = React.useState<string | null>(null)
    let [isOpen, setIsOpen] = React.useState<boolean>(false)
    let { user } = useAuth()
    let { NewAddressModal, Select } = components
    let { IoMdAddCircle, MdDelete, CiEdit } = Icons
    let { t } = translation.useTranslation().i18n

    let onOpen = (defaultAddressType?: string) => {
        defaultAddressType && setDefaultAddressType(defaultAddressType)
        setIsOpen(true)
    }

    let onClose = () => setIsOpen(false)

    let SelectOption = {
        control: (provided: any, state: any) => ({
            ...provided,
            borderRadius: "50px",
            padding: "5px",
            ":hover": { borderColor: ENUMS.COLORS.BRAND },
            borderColor: state.isFocused ? ENUMS.COLORS.BRAND_DARK : provided.borderColor, // Change border color when focused
            boxShadow: state.isFocused ? "0 0 0 1px " + ENUMS.COLORS.BRAND_DARK : provided.boxShadow,
            backgroundColor: state.isDisabled && "#fff",
            color: state.isDisabled && "#000"
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: state.isSelected ? ENUMS.COLORS.BRAND_DARK : "#fff"
        })
    }

    React.useEffect(() => {
        if (user) {
            setBILLINGADDRESSES(
                user?.UserAddress?.filter((address: any) => address?.addressType === ENUMS.ADDRESSTYPE.BILLING)?.map((address: any) => ({
                    label: address?.address + ", " + address?.ville, value: "api/user_addresses/" + address?.id
                })))
            setDELIVERYADDRESSES(
                user?.UserAddress?.filter((address: any) => address?.addressType === ENUMS.ADDRESSTYPE.DELIVERY)?.map((address: any) => ({ label: address?.address + ", " + address?.ville, value: "api/user_addresses/" + address?.id })))
        }
    }, [user])

    React.useEffect(() => {
        BILLINGADDRESSES && setSelectedBillingAddress(BILLINGADDRESSES[0])
        DELIVERYADDRESSES && setSelectedDeliveryAddress(DELIVERYADDRESSES[0])
    }, [BILLINGADDRESSES])

    return (
        <div className=" flex flex-col gap-3 mx-auto p-6 bg-white shadow-md rounded">
            <div className='flex flex-row items-center gap-2 justify-between'>
                <h4 className="text-3xl font-bold">{t("profile.manageAddresses")}</h4>
            </div>
            <AnimatePresence>
                {isOpen && <NewAddressModal isOpen={isOpen} onClose={onClose} defaultType={defaultAddressType} isUpdate={BILLINGADDRESSES.length >= 1 || DELIVERYADDRESSES.length >= 1 } />}
            </AnimatePresence>
            <div className="flex flex-row flex-wrap items-center gap-3">
                <div className='flex flex-col flex-grow gap-2'>
                    <div className='flex flex-row items-center gap-2 justify-between'>
                        <h4 className="text-xl font-bold">{t("checkout.billingAddress")}</h4>
                        <div className='flex flex-row items-center gap-2'>
                            {BILLINGADDRESSES.length < 1 && <IoMdAddCircle size={30} color={ENUMS.COLORS.BRAND} className='cursor-pointer' onClick={() => onOpen(ENUMS.ADDRESSTYPE.BILLING)} />}
                            {BILLINGADDRESSES.length >= 1 && <CiEdit size={30} color={"#000"} className='cursor-pointer' onClick={() => onOpen(ENUMS.ADDRESSTYPE.BILLING)} />}
                            {BILLINGADDRESSES.length >= 1 && <MdDelete size={30} color={ENUMS.COLORS.DANGER} className='cursor-pointer' onClick={() => onOpen(ENUMS.ADDRESSTYPE.BILLING)} />}
                        </div>
                    </div>
                    <Select
                        isDisabled
                        components={{ IndicatorSeparator: () => null, DropdownIndicator: () => null }}
                        styles={SelectOption}
                        value={selectedBillingAddress}
                        defaultValue={selectedBillingAddress}
                        onChange={setSelectedBillingAddress}
                        options={BILLINGADDRESSES}
                        placeholder={t("checkout.addBillingAddress") + "..."}
                    />
                </div>
                <div className='flex flex-col flex-grow gap-2'>
                    <div className='flex flex-row items-center gap-2 justify-between'>
                        <h4 className="text-xl font-bold">{t("checkout.shippingAddress")}</h4>
                        <div className='flex flex-row items-center gap-2'>
                            {DELIVERYADDRESSES.length < 1 && <IoMdAddCircle size={30} color={ENUMS.COLORS.BRAND} className='cursor-pointer' onClick={() => onOpen(ENUMS.ADDRESSTYPE.DELIVERY)} />}
                            {DELIVERYADDRESSES.length >= 1 && <CiEdit size={30} color={"#000"} className='cursor-pointer' onClick={() => onOpen(ENUMS.ADDRESSTYPE.DELIVERY)} />}
                            {DELIVERYADDRESSES.length >= 1 && <MdDelete size={30} color={ENUMS.COLORS.DANGER} className='cursor-pointer' onClick={() => onOpen(ENUMS.ADDRESSTYPE.DELIVERY)} />}
                        </div>
                    </div>
                    <Select
                        isDisabled
                        components={{ IndicatorSeparator: () => null, DropdownIndicator: () => null }}
                        styles={SelectOption}
                        value={selectedDeliveryAddress}
                        defaultValue={selectedDeliveryAddress}
                        onChange={setSelectedDeliveryAddress}
                        options={DELIVERYADDRESSES}
                        placeholder={t("checkout.addDeliveryAddress") + "..."}
                    />
                </div>
            </div>
        </div>
    )
}

export default ManageAddresses