import React from 'react'
import Icons from '@helpers/IconsHelper'
import { components, translation } from '@context/Index'

const UnderSlider = () => {
    const { FaTruckFast, TbShieldDollar, FaDollarSign, MdSupportAgent } = Icons
    const { UnderSliderItem } = components
    const { t } = translation.useTranslation().i18n
    type item = {
        icon: () => JSX.Element
        title: string,
        action?: () => void
    }

    const items: item[] = [
        {
            icon: () => <FaTruckFast size={30} />,
            title: t("underSlider.rapidDelivery")
        },
        {
            icon: () => <FaDollarSign size={30} />,
            title: t("underSlider.bestPrices")
        },
        {
            icon: () => <TbShieldDollar size={30} />,
            title: t("underSlider.securePayment")
        },
        {
            icon: () => <MdSupportAgent size={30} />,
            title: t("underSlider.customerSupport"),
            action: () => {}
        },

    ]

    return (
        <div className="flex flex-row flex-wrap items-center gap-3 justify-evenly px-10">
            {items.map((item, index) => <UnderSliderItem key={index} Icon={item.icon} title={item.title} action={item.action} />)}
        </div>
    )
}

export default UnderSlider