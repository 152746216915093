import { API } from "./API_INSTANCE"

export type postToNewsletterType = { email: string }
export const postToNewsletter = (body: postToNewsletterType) => {
    return API.post('/news_emails', body, {
        headers: {
            Accept: 'application/json'
        }
    })
        .then((res) => res)
        .catch(err => { throw err })
}