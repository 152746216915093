import React from 'react';

const ReturnPolicyEN = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6 text-center">Return, Exchange, or Refund Policy</h1>
      
      <div className="prose prose-lg max-w-none">
        <p>
          You purchased a product but changed your mind and want to return it to S.A.S ARGORI! Is the seller legally obligated to refund you? Not at all! Here's everything you need to know about returning, exchanging, and refunding merchandise.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Return, Exchange, or Refund of a Product Purchased Online</h2>
        <p>
          Nothing obliges the seller (e-retailer: S.A.S ARGORI) to take back a product, unless it is defective. However, the customer is advised to consult the return policy and the Terms and Conditions of Sale (CGV) which require the customer to bear the "shipping and restocking fees" when returning the product because they have changed their mind.
        </p>
        <p>
          That said, the Consumer Protection Law provides for situations where you can cancel an online purchase without charge. However, if a retailer has no return policy, they are not required to mention that they do not have one. The consumer could then assume that any online transaction with this merchant is final.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Return, Exchange, or Refund of a Defective Product</h2>
        <p>
          When you purchase a product on our website: <a href="https://www.argori.com" className="text-blue-600 hover:text-blue-800">www.argori.com</a>, and you find upon receipt that this item is defective, you have the following obligations:
        </p>
        <ol className="list-decimal pl-6">
          <li>The customer must check the condition of the product upon receipt and report any anomaly or defect to the transport carrier.</li>
          <li>If the product is damaged or defective upon receipt, the customer must refuse delivery and inform the seller within 24 hours.</li>
          <li>If your product is defective or damaged, please send us an email within 24 hours of receipt to our email addresses: <a href="mailto:argori.info@gmail.com" className="text-blue-600 hover:text-blue-800">argori.info@gmail.com</a>, <a href="mailto:contact@argori.com" className="text-blue-600 hover:text-blue-800">contact@argori.com</a>, or <a href="mailto:info@argori.com" className="text-blue-600 hover:text-blue-800">info@argori.com</a> so that we can coordinate a return for a replacement or refund via the original payment method.</li>
          <li>The customer has the right to ask the transport carrier to cover the return shipping costs of the defective product.</li>
          <li>The customer must inform the seller of the product defect within 7 days by sending an email to our email addresses: <a href="mailto:argori.info@gmail.com" className="text-blue-600 hover:text-blue-800">argori.info@gmail.com</a>, <a href="mailto:contact@argori.com" className="text-blue-600 hover:text-blue-800">contact@argori.com</a>, or <a href="mailto:info@argori.com" className="text-blue-600 hover:text-blue-800">info@argori.com</a> from the date of receipt of the product in the event that the customer did not check or did not pay attention, upon receipt, to the condition of their order before the departure of the transport carrier.</li>
          <li>The customer must return the defective product to the seller in its original packaging, accompanied by the invoice and any other document justifying the purchase.</li>
          <li>The seller reserves the right to verify the defect of the product before proceeding with the exchange or refund.</li>
          <li>If the product is defective, the seller will proceed with the exchange or refund of the product, according to the customer's choice.</li>
          <li>In this case, the return costs of the defective product are the responsibility of the seller.</li>
        </ol>

        <h2 className="text-2xl font-bold mt-8 mb-4">Return, Exchange, or Refund of a Product After a "Final Sale"</h2>
        <p>
          The Consumer Protection Law does not provide a specific rule on this aspect, but the seller is generally, though not obligatorily, required to respect what they promise their customers. The merchant can identify the sale of certain products as "final," as long as this condition is specified before the consumer makes the payment.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Request for a Refund for a Discount Announced After Purchase</h2>
        <p>
          You buy a product at <a href="https://www.argori.com" className="text-blue-600 hover:text-blue-800">www.argori.com</a> and the following week, the website announces a discount on the same item? The law does not oblige the seller to refund you the difference. The seller is free to implement such a policy and to set the General Terms and Conditions of Sale "CGV". Once again, S.A.S ARGORI respects the promise made to its customers.
        </p>
        <p>
          Similarly, if you find an identical product to ours at a lower price from another seller, S.A.S ARGORI is not obligated to refund the difference... unless S.A.S ARGORI clearly states in its advertising that it "matches" competitor prices.
        </p>
        <p>
          Return shipping costs are the responsibility of the customer. The customer is also responsible for any loss in value if the returned item is not in its original condition.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">MONEY-BACK GUARANTEE</h2>
        <p>
          For the 100% pure and natural organic argan oil in volumes (30 ml and/or 50 ml), prickly pear seed oil in volume (15 ml and/or 30 ml), and invigorating organic rose water in a volume of 100 ml: We are so convinced of the results of our products that we offer a money-back guarantee if you do not notice any visible improvement in your skin or hair within 90 days.
        </p>
        <p>
          Simply send us an email to one of our email addresses for a refund via the original payment method in case of dissatisfaction.
        </p>
        <p>
          This offer lasts for 90 days from the date of purchase and is only available for our above-mentioned sizes, as we recommend trying one of the smaller 15 ml and 30 ml volumes to test our products. If 90 days have passed since your purchase, we unfortunately cannot offer you a refund or exchange. This offer can only be used once. Refunds exclude delivery charges.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">RIGHT TO EXCHANGE</h2>
        <p>
          If the product is defective, the seller will exchange or refund the product, according to the customer's choice. The return costs of the defective product are the responsibility of the seller.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">FORCE MAJEURE</h2>
        <p>
          Neither party (seller and customer) will have failed in its contractual obligations, insofar as their performance is delayed, hindered, or prevented by force majeure. Force majeure shall be considered any event beyond the control of a party, which could not reasonably have been foreseen at the time of the conclusion of the Contract and whose effects cannot be avoided by appropriate measures, and which prevents the said party from fulfilling its obligation. Expressly considered as cases of force majeure, in addition to those usually recognized by the jurisprudence of French courts and tribunals and without this being restrictive, are natural disasters, earthquakes, fires, storms, floods, epidemics, wars, terrorist activities, social movements, the impossibility of using means of transport or telecommunication networks, public or private, the failure to obtain permits, licenses, visas or other types of registrations.
        </p>
        <p>
          The party affected by such circumstances shall notify the other party by any means as soon as possible, the execution of the sale being then suspended until the cessation of the impediment. If the impediment persists beyond a reasonable period, the sale may be terminated by either party with immediate effect, by sending written notification to the other party.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Contact Information</h2>
        <p>
          For any information or questions, our customer service is at your disposal:
        </p>
        <ul className="list-disc pl-6">
          <li>By email: <a href="mailto:argori.info@gmail.com" className="text-blue-600 hover:text-blue-800">argori.info@gmail.com</a>, <a href="mailto:info@argori.com" className="text-blue-600 hover:text-blue-800">info@argori.com</a>, or <a href="mailto:contact@argori.com" className="text-blue-600 hover:text-blue-800">contact@argori.com</a>.</li>
          <li>By phone: +33 (0) 7.68.96.85.07 & +33 (0) 6.95.83.91.20 from Monday to Friday from 9 a.m. to 6 p.m.</li>
        </ul>
      </div>
    </div>
  );
};

export default ReturnPolicyEN;