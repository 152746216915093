import AuthProvider from "@context/AuthContext";
import { BaseProvider } from "@context/baseContext";
import { WindowWidthProvider } from "@context/WindowWidthContext";
import MainNavigation from "@navigation/MainNavigation";
import i18n from "@utils/translation/i18n";
import { HelmetProvider } from "react-helmet-async";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ShoppingCartProvider } from "@context/ShoppingCartContext";
import { ToastContainer } from '@mbs-dev/react-helpers'
import "react-toastify/dist/ReactToastify.css";


function App() {

  const client = new QueryClient()

  return (
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <QueryClientProvider client={client}>
          <ToastContainer />
          <AuthProvider>
            <BaseProvider>
              <WindowWidthProvider>
                <HelmetProvider>
                  <ShoppingCartProvider>
                    <MainNavigation />
                  </ShoppingCartProvider>
                </HelmetProvider>
              </WindowWidthProvider>
            </BaseProvider>
          </AuthProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </I18nextProvider>
  );
}

export default App;
