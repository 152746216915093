import { useCallback, useMemo, useEffect, useState } from 'react'
import { useAuth } from '@context/AuthContext'
import { components, formikHook } from '@context/Index'
import { useShoppingCart } from '@context/ShoppingCartContext'
import { ENUMS } from '@helpers/ENUMS'
import { errorNotify, referenceGenerator, successNotify } from '@mbs-dev/react-helpers'
import { getLastOrder, postOrder } from '@utils/api/OrderApi'
import { OnlineOrderFormIV } from '@utils/initValues/OnlineOrderForm'
import { t } from 'i18next'
import { AnimatePresence } from 'framer-motion'
import { processCmiPayment } from '@utils/api/PaymentProccess'
import { PaymentData } from '@utils/interfaces/PaymentData'
import { AddressOption, addressSelectProps, PaymentMethodOption } from '@utils/interfaces/Order'
import { AddressSection, CommentInput, SubmitButton, TermsCheckbox } from './Helpers'
import { useBaseContext } from '@context/baseContext'
import { API } from '@utils/api/API_INSTANCE'

const OnlineOrder = () => {
  const { useFormik } = formikHook
  const { OrderDetails, Select, NewAddressModal } = components
  const { total, cartItems, cartQuantity, emptyShoppingCart } = useShoppingCart()
  const { geoInfo, isLoadingGeoInfo } = useBaseContext();
  const { user } = useAuth()

  // Address State
  const [addresses, setAddresses] = useState<{ billing: AddressOption[]; delivery: AddressOption[] }>({ billing: [], delivery: [] })
  const [selectedValues, setSelectedValues] = useState<{ billing: AddressOption | null; delivery: AddressOption | null; payment: PaymentMethodOption | null }>({ billing: null, delivery: null, payment: null })

  const [loading, setLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [defaultAddressType, setDefaultAddressType] = useState<string | null>(null)
  const [promoCode, setPromoCode] = useState<{ id: string | null; discount: number }>({
    id: null,
    discount: 0,
  });

  // Check if the user has a welcome discount (10%)
  const hasWelcomeDiscount = user?.welcomeDiscount && user.welcomeDiscount === 10

  // Memoized payment methods
  const paymentMethods = useMemo<PaymentMethodOption[]>(() => {
    if (!geoInfo || isLoadingGeoInfo) return []; // Ensure geoInfo is defined

    return geoInfo.code === "MA"
      ? Object.keys(ENUMS.PAYMENT.METHOD)
        .filter(method => method !== ENUMS.PAYMENT.METHOD.STRIPE)
        .map(method => ({
          label: t(`paymentMethods.${method}`),
          value: method
        }))
      : [{
        label: t(`paymentMethods.${ENUMS.PAYMENT.METHOD.STRIPE}`),
        value: ENUMS.PAYMENT.METHOD.STRIPE
      }];
  }, [geoInfo?.code, isLoadingGeoInfo]);


  // Memoized address processing
  const processAddresses = useCallback((userAddress: any[]) => {
    const billing = userAddress.filter(address => address?.addressType === ENUMS.ADDRESSTYPE.BILLING).map(createAddressOption)
    const delivery = userAddress.filter(address => address?.addressType === ENUMS.ADDRESSTYPE.DELIVERY).map(createAddressOption)
    return { billing, delivery }
  }, [])

  const createAddressOption = (address: any): AddressOption => ({
    label: `${address.address}, ${address.ville}`,
    value: `api/user_addresses/${address.id}`
  })

  useEffect(() => {
    if (user?.UserAddress) {
      const processed = processAddresses(user.UserAddress)
      setAddresses(processed)
      setSelectedValues(prev => ({ ...prev, billing: processed.billing[0] || null, delivery: processed.delivery[0] || null }))
    }
  }, [user, processAddresses])

  useEffect(() => {
    setSelectedValues(prev => ({ ...prev, payment: paymentMethods[0] || null }))
  }, [paymentMethods])

  const handleAddressModal = (type?: string) => {
    if (type) setDefaultAddressType(type)
    setIsModalOpen(true)
  }

  const formik = useFormik({
    initialValues: {
      ...OnlineOrderFormIV,
      cgvAccepted: false,
    },
    validate: (values) => ({
      ...(!values.cgvAccepted && { cgvAccepted: t('errors.cgvRequired') })
    }),
    onSubmit: async (values) => {
      if (cartItems.length === 0) return;
      if (!values.cgvAccepted) {
        errorNotify(t("errors.cgvRequired"));
        return;
      }

      setLoading(true);
      try {
        const lastOrder = await getLastOrder("on");
        const orderRef = `${referenceGenerator(undefined, "year", "month", "day")}00${lastOrder + 1}`;

        // Apply discounts
        let finalPrice = total;
        if (hasWelcomeDiscount) finalPrice *= 0.9;
        if (promoCode.id !== null) finalPrice -= total * (promoCode.discount / 100);

        // Prepare order body
        const orderBody = {
          comment: values.comment,
          billingAddress: selectedValues.billing?.value,
          deliveryAddress: selectedValues.delivery?.value,
          paymentMethod: selectedValues.payment?.value,
          price: finalPrice,
          itemsQuantity: cartQuantity,
          orderStatus: "PENDING",
          paymentStatus: "PENDING",
          reference: orderRef,
          cgvAccepted: values.cgvAccepted,
          user: `api/users/${user?.id}`,
          orderItems: cartItems.map((item) => ({
            product: `api/products/${item.ukey}`,
            price: item.price,
            quantity: item.quantity,
          })),
        };

        if (promoCode.id !== null && promoCode.discount > 0) {
          (orderBody as any).promoCode = `api/promo_codes/${promoCode.id}`;
        }

        // Send Order Request
        const createdOrder = await postOrder(orderBody);
        successNotify(t("checkout.success"));

        // Handle Payment Processing
        if (selectedValues.payment?.value === ENUMS.PAYMENT.METHOD.CMI) {
          const paymentData: PaymentData = {
            userAddresses: user?.UserAddress || [],
            reference: orderRef,
            totalPrice: finalPrice,
            itemsQuantity: cartQuantity,
            authUser: { id: user?.id },
          };
          processCmiPayment(paymentData);
        } else if (selectedValues.payment?.value === ENUMS.PAYMENT.METHOD.STRIPE) {
          const { data } = await API.post("/create-checkout-session", { orderId: createdOrder.id });
          window.location.href = data.redirectUrl;
        }

        // emptyShoppingCart();
      } catch (error: any) {
        
        if (error.response?.status === 500 && error.response?.data?.detail?.includes("promo code")) {
          errorNotify(t("promocode.alreadyUsed"));
          // errorNotify(error.response?.data?.detail);
        } else {
          errorNotify(t("checkout.error"));
        }
        // console.error("Order submission error:", error);
      } finally {
        setLoading(false);
      }
    }

  })

  return (
    <div className='lg:container lg:mx-auto lg:px-0 px-2'>
      <AnimatePresence>
        {isModalOpen && <NewAddressModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} defaultType={defaultAddressType} />}
      </AnimatePresence>

      <div className='grid grid-cols-3 gap-2'>
        <div className='col-span-3 lg:col-span-1 p-5 rounded-xl flex flex-col gap-4'>
          <AddressSection title={t("checkout.billingAddress")} addresses={addresses.billing} selected={selectedValues.billing} onSelect={(addr: any) => setSelectedValues(prev => ({ ...prev, billing: addr }))} onAdd={() => handleAddressModal(ENUMS.ADDRESSTYPE.BILLING)} />
          <AddressSection title={t("checkout.shippingAddress")} addresses={addresses.delivery} selected={selectedValues.delivery} onSelect={(addr: any) => setSelectedValues(prev => ({ ...prev, delivery: addr }))} onAdd={() => handleAddressModal(ENUMS.ADDRESSTYPE.DELIVERY)} />
          <Select {...addressSelectProps} value={selectedValues.payment} onChange={(option) => setSelectedValues(prev => ({ ...prev, payment: option }))} options={paymentMethods} placeholder={t("paymentMethod")} />
          <CommentInput value={formik.values.comment} onChange={formik.handleChange('comment')} />
        </div>

        <div className='col-span-3 lg:col-span-2 h-full'>
          <OrderDetails hasWelcomeDiscount={hasWelcomeDiscount} promoCode={promoCode} setPromoCode={setPromoCode} />
          <TermsCheckbox checked={formik.values.cgvAccepted} onChange={formik.handleChange} error={formik.errors.cgvAccepted} />
          <SubmitButton loading={loading} disabled={cartQuantity === 0} onSubmit={formik.handleSubmit} />

          {/* {orderId && selectedValues.payment?.value === ENUMS.PAYMENT.METHOD.STRIPE && <StripeCheckoutButton orderId={orderId} price={total} />} */}
          {/* <SubmitButton loading={loading} disabled={cartQuantity === 0} onSubmit={formik.handleSubmit} /> */}
        </div>
      </div>

      <div className='hidden'>
        <form name='cmiPaymentForm' id="cmiPaymentForm"></form>
      </div>
    </div>
  )
}

export default OnlineOrder
