import { useBaseContext } from '@context/baseContext';
import { translation } from '@context/Index';
import { ENUMS } from '@helpers/ENUMS';
import Icons from '@helpers/IconsHelper';
import React from 'react'
import { Link } from 'react-router-dom';

type RenderItemProps = { item: { name: string, uri: string } }
const FooterLinks: React.FC = () => {

    const { FiChevronRight } = Icons;
    const { t } = translation.useTranslation().i18n
    const links = [
        { name: t('navBar.termsConditions'), uri: "/terms-and-conditions" },
        { name: t('navBar.generalConditions'), uri: "/general-terms-and-conditions-of-sale" },
        { name: t('navBar.privacyPolicy'), uri: "/privacy-policy" },
        { name: t('navBar.returnPolicy'), uri: "/return-policy" },
        { name: t('navBar.deliveryAndRights'), uri: "/delivery-and-rights" },
        { name: t('navBar.qualityAndCertifications'), uri: "/quality-and-certifications" },
        { name: t('navBar.ingredients'), uri: "/ingredients" },
        { name: t('navBar.contactUs'), uri: "/contact" }
    ]
    const RenderItem: React.FC<RenderItemProps> = ({ item }) => {
        return (
            <Link to={`${item?.uri}`} key={item.name} className="flex flex-row p-1 items-center">
                <FiChevronRight color={ENUMS.COLORS.GOLD} size={20} />
                <p className="text-white w-2/3 text-sm hover:text-gold after:content-[''] after:block after:w-0 after:h-[2px] after:bg-gold after:transition-all after:duration-300 hover:after:w-full">{item?.name}</p>
            </Link>
        )
    }

    return (
        <div className="flex flex-col gap-3 p-4  ">
            <h3 className="text-white text-xl">{t('usefullLinks')}</h3>
            <div className="grid grid-cols-2 gap-3">
                {links.map((item: any) => <RenderItem key={item.name} item={item} />)}
            </div>
        </div>
    )
}

export default FooterLinks