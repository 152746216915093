import React from 'react';

interface StepCardProps {
  stepNumber: number;
  stepTitle: string;
  imageUrl: string;
}

/**
 * StepCard component displays:
 * - A step label (e.g., "3. Partagez")
 * - An image (e.g., a product demonstration)
 * - An optional play button overlay (just an example)
 */
const AmbassadorSubHeaderElement: React.FC<StepCardProps> = ({ stepNumber, stepTitle, imageUrl }) => {
  return (
    <div className="flex flex-col items-center space-y-4">
      {/* Step label */}
      <h3 className="text-xl font-semibold">
        {stepNumber}. {stepTitle}
      </h3>
      
      {/* Image container */}
      <div className="relative w-64 h-64 shadow-md rounded-lg">
        <img
          src={imageUrl}
          alt={stepTitle}
          className="w-full h-full object-cover rounded-lg"
        />
        
        {/* Play button overlay (if you want a clickable video icon) */}
        <div className="absolute inset-0 flex items-center justify-center">
          <button
            className="bg-white bg-opacity-75 rounded-full p-3 hover:bg-opacity-90 transition"
            aria-label="Play Video"
          >
          
          </button>
        </div>
      </div>
    </div>
  );
};

export default AmbassadorSubHeaderElement;
