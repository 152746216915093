import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { animation, components, translation } from "@context/Index";
import { useAuth } from "@context/AuthContext";
import { ENUMS } from "@helpers/ENUMS";
import { postUserAddress, updatetUserAddress } from "@utils/api/UserApi";
import { errorNotify, successNotify } from "@mbs-dev/react-helpers";
import { newAddressFormVS } from "@utils/schemas/NewAddressFormSchema";
import { newAddressFormIV } from "@utils/initValues/newAddressFormIV";

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    defaultType?: string | null,
    isUpdate?: boolean
}

const NewAddressModal: React.FC<ModalProps> = ({ isOpen, onClose, defaultType, isUpdate }) => {

    const { t } = translation.useTranslation().i18n
    const { useAnimate, usePresence } = animation
    const [scope, animate] = useAnimate()
    const [isPresent, safeToRemove] = usePresence()
    const { user, userToken, refetchUser } = useAuth()
    const { Select, ActivityIndicator } = components
    const addressTypes = Object.keys(ENUMS.ADDRESSTYPE).map((type: any) => ({ label: type === ENUMS.ADDRESSTYPE.BILLING ? t('checkout.billingAddress') : t('checkout.shippingAddress'), value: type }))
    const [selectedAddressType, setselectedAddressType] = React.useState<{ label: string; value: string } | null>(addressTypes[0])
    const [isLoading, setIsLoading] = React.useState<boolean>(false)

    React.useEffect(() => {
        if (isPresent) {
            const enterAnimation = async () => {
                await animate([
                    [scope.current, { opacity: 1 }, { duration: .2 }],
                ])
            }
            enterAnimation()
        } else {
            const exitAnimation = async () => {
                await animate([
                    [scope.current, { opacity: 0 }, { duration: .2 }]
                ])
                safeToRemove()
            }
            exitAnimation()
        }
    }, [isPresent])

    let SelectOption = {
        control: (provided: any, state: any) => ({
            ...provided,
            borderRadius: "5px",
            padding: "5px",
            ":hover": { borderColor: ENUMS.COLORS.BRAND },
            borderColor: state.isFocused ? ENUMS.COLORS.BRAND_DARK : provided.borderColor, // Change border color when focused
            boxShadow: state.isFocused ? "0 0 0 1px " + ENUMS.COLORS.BRAND_DARK : provided.boxShadow,
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: state.isSelected ? ENUMS.COLORS.BRAND_DARK : "#fff"
        })
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...newAddressFormIV,
            addressType: defaultType ?? selectedAddressType?.value,
            user: "api/users/" + user?.id,
        },
        validationSchema: newAddressFormVS,
        onSubmit: (values) => {
            if (formik.isValid) {
                setIsLoading(true)
                if (isUpdate) {
                    let { user: addressUser, addressType, ...rest } = values
                    updatetUserAddress(rest, user?.UserAddress.find((item: any) => item.addressType === defaultType)?.id as string, userToken as string)
                        .then((res) => {
                            setIsLoading(false);
                            successNotify(t("newAddressForm.success"), 3000);
                            refetchUser();
                            onClose();
                        })
                        .catch(err => {
                            setIsLoading(false)
                            errorNotify(t("newAddressForm.error"), 3000);
                            console.error("Error adding address", err);
                        })
                } else {
                    postUserAddress(values, userToken as string)
                        .then((res) => {
                            setIsLoading(false);
                            successNotify(t("newAddressForm.success"), 3000);
                            refetchUser();
                            onClose();
                        })
                        .catch(err => {
                            setIsLoading(false)
                            errorNotify(t("newAddressForm.error"), 3000);
                            console.error("Error adding address", err);
                        })
                }
            }

        },
    });

    useEffect(() => {
        isUpdate && formik.setValues(user?.UserAddress.find((item: any) => item.addressType === defaultType))
    }, [isUpdate])


    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50 opacity-0" onClick={onClose} ref={scope} key="newAddressModal">
            <div className="bg-white p-6 rounded-lg w-96 shadow-lg" onClick={(e) => e.stopPropagation()}>
                <h2 className="text-xl font-bold mb-4">{t("newAddress")}</h2>
                <form onSubmit={formik.handleSubmit} className="space-y-4">
                    {Object.keys(formik.initialValues).filter((field) => field !== "user").map((field) => {
                        if (field === "addressType") {
                            if (addressTypes) return null
                            return (
                                <div key={field}>
                                    <label className="block text-sm font-medium capitalize" htmlFor={field}>
                                        {t("newAddressForm." + field).replace(/([A-Z])/g, " $1").trim()}
                                    </label>
                                    <Select
                                        styles={{ ...SelectOption }}
                                        value={selectedAddressType}
                                        defaultValue={selectedAddressType}
                                        onChange={setselectedAddressType}
                                        options={addressTypes}
                                    />
                                </div>
                            )
                        } else {
                            return (
                                <div key={field}>
                                    <label className="block text-sm font-medium capitalize" htmlFor={field}>
                                        {field.replace(/([A-Z])/g, " $1").trim()}
                                    </label>
                                    <input
                                        type={
                                            field === "telephone" ? "tel"
                                                : field === "email" ? "email"
                                                    : "text"
                                        }
                                        id={field}
                                        name={field}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values[field as keyof typeof formik.initialValues]}
                                        className="mt-1 p-2 w-full border rounded-md focus:ring-1 focus:ring-brand-darker outline-none"
                                    />
                                    {formik.touched[field as keyof typeof formik.initialValues] &&
                                        formik.errors[field as keyof typeof formik.initialValues] && (
                                            <p className="text-red-500 text-sm">
                                                {formik.errors[field as keyof typeof formik.initialValues]}
                                            </p>
                                        )}
                                </div>
                            )
                        }
                    })}
                    <div className="flex justify-end space-x-2">
                        <button
                            type="button"
                            onClick={onClose}
                            className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 transition-colors duration-300"
                        >
                            {t("newAddressForm.cancel")}
                        </button>
                        <button
                            type="submit"
                            className="px-4 py-2 bg-brand-darker text-white rounded-md hover:bg-gold hover:text-brand-darker transition-colors duration-300"
                        >
                            {isLoading ? <ActivityIndicator size={25} color={ENUMS.COLORS.BRAND_DARK} /> : t("newAddressForm.submit")}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default NewAddressModal;
