import React, { useState, useEffect } from 'react';
import { components } from '@context/Index';
import Loading from '@components/loading/Loading';
import { useBaseContext } from '@context/baseContext';

function Home() {
  let { Categories, Products, Slider, UnderSlider } = components;
  const { isLoadingCategories } = useBaseContext();


  // const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setIsLoading(false);
  //   }, 2000);
  //   return () => clearTimeout(timer); 
  // }, []);

  if (isLoadingCategories) {
    return <Loading item="" showText={false} />;
  }

  return (
    <div className='gap-5 flex flex-col relative p-3'>
      <Slider />
      <UnderSlider />
      <Categories />
      <Products />
    </div>
  );
}

export default Home;
