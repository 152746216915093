import { useAuth } from '@context/AuthContext'
import { components, translation, defaultProfile } from '@context/Index'
import { uploadUrl } from '@helpers/Helpers'
import Icons from '@helpers/IconsHelper'
import { errorNotify, successNotify } from '@mbs-dev/react-helpers'
import { updateUser } from '@utils/api/UserApi'
import { TabType } from '@utils/types/ProfileType'
import React from 'react'

export type ProfileTabsProps = {
  selectedTab: string,
  setSelectedTab: React.Dispatch<React.SetStateAction<string>>
}

const ProfileTabs: React.FC<ProfileTabsProps> = ({ selectedTab, setSelectedTab }) => {
  const { ProfileTab, ProfileImage } = components
  const { PiShoppingBagFill, BsCartCheck, CiLocationOn, FiUser, FiLogOut } = Icons
  const { logout, user, userToken, refetchUser } = useAuth()
  const [loading, setLoading] = React.useState<boolean>(false)
  const { t } = translation.useTranslation().i18n

  const handleImageUpdate = (file: File) => {
    setLoading(true)
    const formData = new FormData()
    formData.append('photo', file)

    updateUser(userToken, user?.id, formData)
      .then(() => {
        setLoading(false)
        refetchUser()
        successNotify(t("profile.success"), 1500)
      })
      .catch(() => {
        setLoading(false)
        errorNotify(t("profile.error"), 1500)
      })
  }

  const tabs = React.useMemo<TabType[]>(() => [
    { Icon: PiShoppingBagFill, title: "Orders", action: () => setSelectedTab("Orders") },
    { Icon: BsCartCheck, title: "Track my orders", action: () => setSelectedTab("Track my orders") },
    { Icon: CiLocationOn, title: "Addresses", action: () => setSelectedTab("Addresses") },
    { Icon: FiUser, title: "Account details", action: () => setSelectedTab("Account details") },
    { Icon: FiLogOut, title: "Logout", action: () => logout() }
  ], [])

  return (
    <div className="flex flex-col gap-2">
      {user && (
        <ProfileImage
          loading={loading}
          initialImage={user?.photo ? uploadUrl + user?.photo : defaultProfile }
          onImageChange={handleImageUpdate}
        />
      )}
      {tabs.map((tab) => (
        <ProfileTab
          key={tab.title}
          Icon={tab.Icon}
          title={tab.title}
          action={tab.action!}
          selected={selectedTab === tab.title}
        />
      ))}
    </div>
  )
}

export default ProfileTabs
