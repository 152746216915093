import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import en from './en.json';
import fr from './fr.json';

// Set up i18n
i18n.use(initReactI18next).init({
    compatibilityJSON: 'v3',
    lng: 'fr', // Default language
    fallbackLng: 'fr', // Fallback language in case translation is missing
    resources: {
        en: {
            translation: en,
        },
        fr: {
            translation: fr,
        },
    },
});

export default i18n;