import React, { useState } from "react";
import { uploadUrl } from "@helpers/Helpers";
import { API } from "@utils/api/API_INSTANCE";
import { useBaseContext } from "@context/baseContext";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { FaChevronRight, FaChevronDown, FaFilter, FaTimes, FaSearch } from "react-icons/fa";
import { handleScrollTop } from "@mbs-dev/react-helpers";
import { Helmet } from "react-helmet-async";
import { BsFillGridFill } from "react-icons/bs";
import { CiGrid2H } from "react-icons/ci";
import ProductsTile from "@components/products/ProductTile";
import { translation } from "@context/Index";
import { t } from "i18next";
import { useDebounce } from "@hooks/useDebounce";

const ITEMS_PER_PAGE = 12;

const StorePage: React.FC = () => {
  const { categorySlug } = useParams<{ categorySlug?: string }>();
  const navigate = useNavigate();
  const { categories, isLoadingCategories } = useBaseContext();
  const { language } = translation.useTranslation().i18n;
  const [searchParams, setSearchParams] = useSearchParams();

  // UI states
  const [openCategory, setOpenCategory] = useState<string | null>(null);
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [isGridView, setIsGridView] = useState<boolean>(false);

  const initialSearchQuery = searchParams.get("search") || "";
  const [searchQuery, setSearchQuery] = useState(initialSearchQuery);

  const debouncedSearchQuery = useDebounce(searchQuery, 1000);

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery({
    queryKey: ["store_products", categorySlug, language, searchQuery],
    queryFn: async ({ pageParam = 1 }) => {
      const languageCode = language.split("-")[0];
      const response = await API.get("/products.jsonld", {
        params: {
          parent: "NULL",
          language_code: languageCode,
          ...(categorySlug ? { "category.categoryTranslates.slug": categorySlug } : {}),
          ...(searchQuery
            ? /^\d/.test(searchQuery)
              ? { 'child.barcode': searchQuery }
              : { 'productTranslates.title': searchQuery }
            : {}),
          page: pageParam,
          items_per_page: ITEMS_PER_PAGE,
        },
      });
      return response.data["hydra:member"] || [];
    },
    getNextPageParam: (lastPage, allPages) =>
      lastPage.length === ITEMS_PER_PAGE ? allPages.length + 1 : undefined,
    initialPageParam: 1,
  });

  // Combine all fetched product pages into a single list
  const allProducts = data?.pages.flat() || [];

  // Handle category selection (Preserve search query)
  const handleCategoryChange = (slug: string | null) => {
    setSearchParams(searchQuery ? { search: searchQuery, category: slug || "" } : { category: slug || "" });
    navigate(slug ? `/store/${slug}` : "/store");
    setIsFilterOpen(false);
    handleScrollTop();
  };

  React.useEffect(() => {
    setSearchParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams);
      if (debouncedSearchQuery) {
        newParams.set("search", debouncedSearchQuery);
      } else {
        newParams.delete("search");
      }
      return newParams;
    });
  }, [debouncedSearchQuery, setSearchParams]);

  // Handle search input (Preserve category)
  // const handleSearchChange = debounce((query: string) => {
  //   setSearchParams(categorySlug ? { category: categorySlug, search: query } : { search: query });
  // }, 800);

  return (
    <>
      <Helmet>
        <title>Argori - Boutique</title>
      </Helmet>

      <div className={`flex flex-col md:flex-row gap-8 p-${isGridView ? "3" : "8"}`}>
        {/* Mobile Filter Button */}
        <button
          className="md:hidden flex items-center justify-center bg-[#258f67] text-white py-2 px-4 rounded-lg w-full"
          onClick={() => setIsFilterOpen(!isFilterOpen)}
        >
          {isFilterOpen ? <FaTimes size={18} /> : <FaFilter size={18} />}
          {isFilterOpen ? "Fermer le filtre" : "Filtrer les catégories"}
        </button>

        {/* Sidebar - Categories */}
        <aside
          className={`w-full md:w-1/4 bg-white p-6 rounded-xl shadow-md border border-gray-200 transition-all duration-300 ease-in-out
        ${isFilterOpen ? "max-h-[400px] overflow-y-auto block" : "max-h-0 hidden md:block overflow-hidden md:max-h-none md:overflow-visible"}`}
        >
          <h2 className="text-xl font-semibold mb-4 text-[#258f67]">Catégories</h2>
          {isLoadingCategories ? (
            <p className="text-gray-500">{t("loadingCategories")}</p>
          ) : (
            <ul className="space-y-3">
              <li className="cursor-pointer">
                <input
                  type="radio"
                  name="category"
                  id="all-products"
                  checked={!categorySlug}
                  onChange={() => handleCategoryChange(null)}
                  className="hidden"
                />
                <label
                  htmlFor="all-products"
                  className={`flex items-center gap-3 p-2 rounded-lg transition cursor-pointer ${!categorySlug ? "bg-[#258f67] text-white" : "hover:bg-gray-100 text-gray-700"
                    }`}
                >
                  {t("store.allProducts")}
                </label>
              </li>
              {categories.map((category: any) => {
                const catSlug = category.categoryTranslates[0].slug;
                const isActive = categorySlug === catSlug;
                const isOpen = openCategory === catSlug;
                const hasSubcategories = category.subCategory.length > 0;

                return (
                  <li key={category.id}>
                    <div
                      className={`flex items-center justify-between cursor-pointer p-2 rounded-lg transition ${isActive ? "bg-[#258f67] text-white" : "hover:bg-gray-100 text-gray-700"
                        }`}
                      onClick={() => setOpenCategory(isOpen ? null : catSlug)}
                    >
                      <div className="flex items-center gap-3" onClick={() => handleCategoryChange(catSlug)}>
                        <img
                          src={uploadUrl + category.image}
                          alt={category.categoryTranslates[0].title}
                          className="w-8 h-8 rounded-md object-cover"
                        />
                        {category.categoryTranslates[0].title}
                      </div>
                      {hasSubcategories && (isOpen ? <FaChevronDown /> : <FaChevronRight />)}
                    </div>
                    {isOpen && hasSubcategories && (
                      <ul className="ml-5 mt-2 border-l border-gray-300 pl-4">
                        {category.subCategory.map((sub: any) => {
                          const subSlug = sub.categoryTranslates[0].slug;
                          const isSubActive = categorySlug === subSlug;

                          return (
                            <li key={sub.id} className="cursor-pointer">
                              <input
                                type="radio"
                                name="category"
                                id={`sub-category-${sub.id}`}
                                checked={isSubActive}
                                onChange={() => handleCategoryChange(subSlug)}
                                className="hidden"
                              />
                              <label
                                htmlFor={`sub-category-${sub.id}`}
                                className={`flex items-center gap-3 p-2 rounded-lg transition cursor-pointer ${isSubActive ? "bg-[#258f67] text-white" : "hover:bg-gray-100 text-gray-700"
                                  }`}
                              >
                                <img
                                  src={uploadUrl + sub.image}
                                  alt={sub.categoryTranslates[0].title}
                                  className="w-6 h-6 rounded-md object-cover"
                                />
                                {sub.categoryTranslates[0].title}
                              </label>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </li>
                );
              })}
            </ul>
          )}
        </aside>

        {/* Main Section */}
        <div className="w-full md:w-3/4">
          {/* Search Input */}
          <div className="relative mb-6">
            <input
              type="text"
              placeholder={t("store.searchPlaceholder")}
              className="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-[#258f67]"
              // defaultValue={searchQuery}
              value={searchQuery} // Controlled input
              onChange={(e) => setSearchQuery(e.target.value)} // Update local state
            />
            <FaSearch className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-400" />
          </div>

          <h2 className="text-lg font-semibold mb-4 text-[#258f67]">
            {t("store.productDisplay", { count: allProducts.length })}
          </h2>

          {/* Grid & List View Toggle */}
          <div className="md:hidden flex justify-end gap-2 mb-4">
            <button onClick={() => setIsGridView(false)} className={`p-2 rounded-lg ${!isGridView ? "bg-[#258f67] text-white" : "bg-gray-200"}`}>
              <CiGrid2H size={20} />
            </button>
            <button onClick={() => setIsGridView(true)} className={`p-2 rounded-lg ${isGridView ? "bg-[#258f67] text-white" : "bg-gray-200"}`}>
              <BsFillGridFill size={20} />
            </button>
          </div>

          {/* Products List */}
          <div className={`grid ${isGridView ? "grid-cols-2" : "grid-cols-1"} sm:grid-cols-2 lg:grid-cols-4 gap-${isGridView ? "2" : "6"}`}>
            {allProducts.map((product) => (
              <ProductsTile key={product.id} product={product} isGridView={isGridView} />
            ))}
          </div>

          {hasNextPage && (
            <div className="flex justify-center mt-4">
              <button onClick={() => fetchNextPage()} disabled={isFetchingNextPage} className="px-4 py-2 bg-[#258f67] text-white rounded-lg disabled:opacity-50">
                {isFetchingNextPage ? `${t("loading")}` : `${t("seeMore")}`}
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default StorePage;
