import { useAuth } from '@context/AuthContext'
import { components } from '@context/Index'
import { errorNotify, successNotify } from '@mbs-dev/react-helpers'
import { useQuery } from '@tanstack/react-query'
import { getReviews, postReview } from '@utils/api/Reviews'
import { reviewsProps } from '@utils/types/ReviewsType'
import React from 'react'

const Review: React.FC<reviewsProps> = ({ productId }) => {

  let { ReviewsCarousel, NewReview, Loading } = components
  const [formHeight, setFormHeight] = React.useState<number>(0);
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const { user } = useAuth()

  let { data: reviews, isLoading: loadingReviews, refetch: refetchReviwes, isError, failureReason } = useQuery({
    queryKey: ['reviews', productId],
    queryFn: () => getReviews(productId),
    enabled: !!productId
  })

  const onSubmit = (rating: number, comment: string) => {
    let body = {
      rating,
      message: comment,
      user: "api/users/" + user?.id,
      product: "api/products/" + productId
    }
    setLoading(true)
    postReview(body)
      .then((res) => {
        setLoading(false)
        refetchReviwes()
        successNotify("Review submitted successfully", 1500)
      })
      .catch(err => {
        setLoading(false)
        console.error(err);
        errorNotify("Error submitting review", 1500)
      })
  }

  // React.useEffect(() => {
  //   reviews && console.log({ reviews });
  // }, [reviews])

  return (
    <div className='lg:container lg:mx-auto py-3 text-brand-darker'>
      <ReviewsCarousel slides={reviews} options={{loop:true}} />
      <div className='gap-3'	>
        <NewReview onSubmit={onSubmit} setFormHeight={setFormHeight} isLoading={isLoading} />
      </div>

    </div>
  )
}

export default Review