import { API } from "./API_INSTANCE"


export const postUser = (formData: FormData) => {
    return API.post('/users', formData, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
        }
    })
        .then((res) => res.data)
        .catch(err => { throw err })
}

export const getUser = (userToken: string | null) => {
    // console.log({userToken});
    return API.get('/me', {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${userToken}`
        }
    })
        .then((res) => res.data)
        .catch(err => { throw err })
}

export const updateUser = (userToken: string | null, userID: string, FormData: FormData) => {
    return API.put('/users/' + userID, FormData, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${userToken}`
        }
    })
        .then((res) => res.data)
        .catch(err => { throw err })
}

export const postUserAddress = (body: any, userToken: string) => {
    return API.post('/user_addresses', body, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${userToken}`
        }
    })
        .then((res) => res.data)
        .catch(err => { throw err })
}

export const updatetUserAddress = (body: any, addressId: string, userToken: string) => {
    return API.put('/user_addresses/' + addressId, body, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${userToken}`
        }
    })
        .then((res) => res.data)
        .catch(err => { throw err })
}