import * as Yup from "yup"

export const ContactVS = Yup.object().shape({
    gender: Yup.string().required("Veuillez sélectionner votre civilité"),
    fullName: Yup.string().required("Le nom complet est requis"),
    email: Yup.string().email("Email invalide").required("L'email est requis"),
    clienteNumber: Yup.string(),
    // clienteNumber: Yup.string().required("Le numéro client est requis"),
    telephoneFixe: Yup.string().matches(/^[0-9\s\-+]+$/, "Numéro invalide"),
    subject: Yup.string().required("Le sujet est requis"),
    message: Yup.string().required("Le message est requis"),
});