import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { API } from "@utils/api/API_INSTANCE";

interface Product {
    id: number;
    fullTitle: string;
    label: string;
    child?: Product[];
}

interface OfflineOrderItem {
    id: number;
    quantity: number;
    price: number;
    product: Product;
}

interface OfflineOrder {
    id: number;
    uid: string;
    reference: string;
    fullName: string;
    email: string;
    telephone: string;
    address: string;
    ville: string;
    zipCode: number;
    price: number;
    itemsQuantity: number;
    comment?: string;
    orderStatus: string;
    paymentMethod: string;
    paymentStatus: string;
    offlineOrderItems: OfflineOrderItem[];
    createdAt: string;
}

const OfflineOrderDetails: React.FC = () => {
    const { orderRef } = useParams<{ orderRef: string }>();
    const [order, setOrder] = useState<OfflineOrder | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchOrderDetails = async () => {
            try {
                const response = await API.get(`/offline_orders.json?reference=${orderRef}`);
                setOrder(response.data[0]);
            } catch (err) {
                setError("Erreur lors du chargement de la commande.");
            } finally {
                setLoading(false);
            }
        };

        fetchOrderDetails();
    }, [orderRef]);

    if (loading) return <p className="text-center text-gray-500">Chargement...</p>;
    if (error) return <p className="text-center text-red-500">{error}</p>;
    if (!order) return <p className="text-center text-gray-500">Commande introuvable.</p>;

    // Format the date as MM/DD/YYYY
    const formattedDate = new Date(order.createdAt).toLocaleDateString("en-US");

    return (
        <div className="max-w-6xl mx-auto bg-white shadow-lg rounded-lg p-6 mt-6">
            {/* Title and Order Date on Same Line */}
            <div className="flex justify-between items-center border-b border-gray-300 pb-4">
                <h2 className="text-2xl font-bold text-green-700">
                    🛍️ Détails de la commande
                </h2>
                <span className="text-gray-600">| Passée le : {formattedDate}</span>
            </div>

            {/* Order Details & Address in Same Line */}
            <div className="grid grid-cols-2 gap-8 border-b border-gray-300 pb-4">
                {/* Order Details */}
                <div className="border-r pr-4">
                    <h3 className="text-lg font-semibold text-green-700">📌 Commande</h3>
                    <p><strong>🆔 Référence :</strong> {order.reference}</p>
                    <p><strong>👤 Client :</strong> {order.fullName}</p>
                    <p><strong>💰 Montant total :</strong> {order.price} €</p>
                    <p><strong>📦 Statut :</strong> {order.orderStatus}</p>
                    <p><strong>💳 Paiement :</strong> {order.paymentMethod} ({order.paymentStatus})</p>
                </div>

                {/* Address Details */}
                <div>
                    <h3 className="text-lg font-semibold text-green-700">📍 Adresse</h3>
                    <p><strong>🏙️ Ville :</strong> {order.ville}</p>
                    <p><strong>📮 Code Postal :</strong> {order.zipCode}</p>
                    <p><strong>📍 Adresse :</strong> {order.address}</p>
                    <p><strong>📧 Email :</strong> {order.email}</p>
                    <p><strong>📞 Téléphone :</strong> {order.telephone}</p>
                </div>
            </div>

            {/* Ordered Items */}
            <h3 className="text-lg font-semibold text-green-700 mt-4">🛒 Articles commandés</h3>
            <ul className="mt-2">
                {order.offlineOrderItems.map((item) => (
                    <li key={item.id} className="border p-2 rounded-lg shadow-sm mt-2">
                        <p className="font-semibold text-gray-700">{item.product.child?.[0]?.label || item.product.label}</p>
                        <p className="pt-2"><strong>📦 Quantité :</strong> {item.quantity}</p>
                        <p className="pt-1"><strong>💰 Prix :</strong> {item.price} €</p>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default OfflineOrderDetails;
