import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import Icons from '@helpers/IconsHelper'
import { translation } from "@context/Index"

const Breadcrumbs: React.FC = () => {

  const { t } = translation.useTranslation().i18n
  const location = useLocation()
  const { FiChevronRight, IoHomeOutline } = Icons

  // Break down the current path into its parts
  const pathnames = location.pathname.split('/').filter(x => x);

  return (
    <div className="py-4 px-10 text-lg">
      <ul className="flex flex-wrap">
        <li >
          <Link to="/" className='flex flex-row items-center gap-1 text-brand-darker'><IoHomeOutline />{t('home')}</Link>
        </li>
        {pathnames.map((value, index) => {
          const pathTo = `/${pathnames.slice(0, index + 1).join('/')}`;
          return (
            index < pathnames.length - 1 ? (
              <li key={index} className='flex items-center justify-center opacity-50'>
                <FiChevronRight size="15" />
                <p>{value}</p>
              </li>
            ) : (
              <li key={index} className='flex items-center justify-center  opacity-50'>
                <FiChevronRight size="15" />
                <p>{value}</p>
              </li>
            )
          );
        })}
      </ul>
    </div>
  );
}

export default Breadcrumbs