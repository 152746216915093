import { itemProps } from '@utils/types/MenuTypes'
import React from 'react'
import { Link } from 'react-router-dom'
import Icons from '@helpers/IconsHelper'
import {animation, components} from '@context/Index'
import { useWindowWidthContext } from '@context/WindowWidthContext'



function MenuItem({item}:{item:itemProps}) {

  const [expanded,setExpanded] = React.useState<boolean>(false)
  const {BiChevronDown} = Icons
  const {AnimatePresence } = animation
  const {DropDownMenu} = components
  let {windowWidth} = useWindowWidthContext()

  return (
    <>
    <li className='relative text-center md:text-left flex justify-center flex-grow'  >
      {item?.children 
      ? <button  
          onMouseEnter={()=>windowWidth >= 768 && setExpanded(true)} 
          onMouseLeave={()=>windowWidth >= 768 && setExpanded(false)} 
          onClick={()=>setExpanded(!expanded)}
          className='flex justify-center md:justify-start items-center gap-2'
        >
        <p className='text-md text-center font-bold text-brand-darker transition hover:text-brand-color-lighter'>{item.name}</p>
        <BiChevronDown size={12}/>
        <AnimatePresence>
          {expanded && <DropDownMenu item={item} key="dropdown"/> }
        </AnimatePresence>
      </button>
      :<Link to={item.uri} className="flex justify-center items-center">
        <p className='text-md text-center font-bold text-brand-darker transition hover:text-brand-color-lighter'>{item.name}</p>
      </Link>}
    </li>
    </>
  )
}

export default MenuItem