import React from 'react'
import { useBaseContext } from '@context/baseContext'
import { components } from "@context/Index"
import { useAuth } from '@context/AuthContext'
const Footer: React.FC = () => {

  let { DescAndIcons, ContactData, FooterCategories, FooterLinks, Payments } = components
  let { websiteInfos } = useBaseContext()
  const { usersCountryCode } = useAuth()

  return (
    <footer className="bg-brand-darker ">
      <div className="p-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4">
        {websiteInfos && <DescAndIcons websiteInfos={websiteInfos} />}
        {websiteInfos && <ContactData websiteInfos={websiteInfos} />}
        <FooterLinks />
        <FooterCategories />
      </div>
      <Payments/>
    </footer>
  )
}

export default Footer