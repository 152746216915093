import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { profileInfoIV } from '@utils/initValues/ProfileInfoIV';
import { profileInfoVS } from '@utils/schemas/ProfileSchemas';
import { useAuth } from '@context/AuthContext';
import { components } from '@context/Index';
import { updateUser } from '@utils/api/UserApi';
import { errorNotify, successNotify } from '@mbs-dev/react-helpers';
import { ENUMS } from '@helpers/ENUMS';

interface FormValues {
  username: string;
  fullName: string;
}

const ProfileInfo: React.FC = () => {
  const { t } = useTranslation();
  const { user, refetchUser, userToken } = useAuth();
  const [loading, setLoading] = React.useState<boolean>(false);
  const { ActivityIndicator } = components;

  const initialValues = React.useMemo<FormValues>(() => ({
    username: user?.username || '',
    fullName: user?.fullName || '',
  }), [user]);

  const formik = useFormik<FormValues>({
    initialValues,
    validationSchema: profileInfoVS,
    onSubmit: (values) => {
      if (formik.isValid) {
        setLoading(true)
        const formData = new FormData();
        formData.append('username', values.username);
        formData.append('fullName', values.fullName);
        updateUser(userToken, user?.id, formData)
          .then(res => {
            console.log({ res });
            setLoading(false)
            successNotify(t("profile.success"), 1500);
            // Delay the refetchUser call by 1 second (1000 milliseconds)
            setTimeout(() => {
              refetchUser();
            }, 2000);
          })
          .catch(err => {
            console.error("Error updating profile picture :", err.response)
            setLoading(false)
            errorNotify(t("profile.error"), 1500);
          })
      }
    },
  });

  const { handleSubmit, handleChange, handleBlur, touched, errors, values } = formik;

  return (
    <form onSubmit={handleSubmit} className="mx-auto p-6 bg-white shadow-md rounded flex flex-col gap-4">
      <div className='flex flex-row flex-wrap gap-3'>
        <div className="flex flex-col flex-grow">
          <label htmlFor="username" className="block text-gray-700 font-bold mb-2">
            {t('profile.form.username')}
          </label>
          <input
            id="username"
            name="username"
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.username}
            className={`shadow appearance-none border rounded-full w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${touched.username && errors.username ? 'border-red-500' : ''}`}
          />
          {touched.username && errors.username && (
            <p className="text-red-500 text-xs italic mt-1">{errors.username}</p>
          )}
        </div>

        <div className="flex flex-col flex-grow">
          <label htmlFor="fullName" className="block text-gray-700 font-bold mb-2">
            {t('profile.form.fullName')}
          </label>
          <input
            id="fullName"
            name="fullName"
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.fullName}
            className={`shadow appearance-none border rounded-full w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${touched.fullName && errors.fullName ? 'border-red-500' : ''}`}
          />
          {touched.fullName && errors.fullName && (
            <p className="text-red-500 text-xs italic mt-1">{errors.fullName}</p>
          )}
        </div>
      </div>

      <div className="flex items-center justify-end">
        {loading
          ? <ActivityIndicator size={30} color={ENUMS.COLORS.BRAND_DARK} />
          : <button
            type="submit"
            className="bg-brand-color hover:bg-brand-darker text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline transition-colors duration-300"
          >
            {t('profile.form.submit')}
          </button>
        }
      </div>
    </form>
  );
};

export default ProfileInfo;