import { components, translation } from '@context/Index'
import { ENUMS } from '@helpers/ENUMS'
import React from 'react'

const WholeSaleMerchant = () => {
  const { Header, Breadcrumbs, BecomePartnerForm, BecomeWholesaleDescEN, BecomeWholesaleDescFR } = components
  const { t, language } = translation.useTranslation().i18n
  return (
    <>
      <Header title={t('navBar.become') + ' ' + t('navBar.wholeSaleMerchant').toLowerCase()} />
      <Breadcrumbs />
      {language.split('-')[0] === 'en' ? <BecomeWholesaleDescEN /> :<BecomeWholesaleDescFR />  }
      <BecomePartnerForm partnerType={ENUMS.PARTNER_TYPE.WHOLESALE_MERCHANT} />
    </>
  )
}

export default WholeSaleMerchant